import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { adminProfileUpdate, getAdminDetails, uploadImage } from "../../../utils/ApiEndpointFunctions"
import { useFormik } from 'formik';
import { updateAdminProfileSchema } from "../../common/Validation"
import { useAdminContext } from "../../../context/adminContext";
import toast from 'react-hot-toast';
import Spinwheel from "../../common/Spinwheel";
import { ImageUrl } from "../../../utils/BaseUrl";
// import { useHistory } from 'react-router-dom';


const Profiledetails = () => {

    const { adminDetail, } = useAdminContext()
    const [signInWheel, setSignInWheel] = useState(false)
    const navigate = useNavigate();
    // const history = useHistory();
    const { setAdminDetail } = useAdminContext();

    const getAdminDetail = async () => {
        const res = await getAdminDetails();
        if (res?.status === 200) {
            setAdminDetail(res.data?.data)
        }
    }
    const adminUpdateIntial = {
        profileImage: adminDetail?.profileImage,
        name: adminDetail?.name,
        // role: "",
        email: adminDetail?.email,
        phoneNumber: adminDetail?.phoneNumber
    }

    const handleSubmit = async (values) => {
        setSignInWheel(true)
        const res = await adminProfileUpdate(values);
        if (res.status === 200) {
            toast.success('Update successfully')
            getAdminDetail();
            navigate("/dashboard", { replace: true })
            setSignInWheel(false)
        }
    }

    const formik = useFormik({
        initialValues: adminUpdateIntial,
        onSubmit: handleSubmit,
        validationSchema: updateAdminProfileSchema
    });
    const profileChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append("file", file);
            formData.append("type", 1)
            formData.append("folder", "admin")
            const res = await uploadImage('/users/fileUpload', formData)
            if (res.status === 200 && res.data?.status === 1) {
                formik.setFieldValue("profileImage", res.data?.data?.fileName)
                toast.success("File uploaded")
            }
        }
    }
    document.body.classList.add("body-bg");
    return (
        <>
            <main>
                <section className="section-bg">
                    <div className="container-fluid">
                        <div className="row top-bg after-mview">
                            <img
                                className="left-mask-bg d-none d-xl-block"
                                src="/images/Mask Group 650.png"
                                alt="..."
                            />
                            <img
                                className="right-mask-bg d-none d-xl-block"
                                src="/images/Mask Group 650.png"
                                alt="..."
                            />
                            {/* <div className="d-flex justify-content-center">
                            <img
                                className="bottom-mask-bg d-none d-xl-block"
                                src="/images/Mask Group 650.png"
                                alt="..."
                            />
                            </div> */}
                            <div className="col-xl-3 col-lg-5 col-md-7 col-sm-12 mx-auto d-flex justify-content-center align-items-center">
                                <div className="d-grid text-center">
                                    <div className="d-flex justify-content-center">
                                        <img
                                            src="/images/logo.png"
                                            alt="logo"
                                            height={104}
                                            width={104}
                                        />
                                    </div>
                                    <br />
                                    <br />
                                    <h2 className="sign-in">Profile Details</h2>
                                    <p>Enter your profile details and contact details</p>
                                    <br />
                                    <form action="" className="text-start" onSubmit={formik.handleSubmit} >
                                        <label htmlFor="profile" className="form-label"> Profile Photo</label>
                                       
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="position-relative">
                                                {<img src={formik.values.profileImage ? ImageUrl + formik.values.profileImage : process.env.PUBLIC_URL + "/images/person-circle.svg"} width="70px" height="70px" className="rounded-circle" alt="Selected file" />}


                                                {
                                                    formik.values.profileImage && <>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-camera pro-camera" viewBox="0 0 16 16">
                                                            <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1v6zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z" />
                                                            <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
                                                        </svg>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x xmark" viewBox="0 0 16 16" onClick={() => { formik.setFieldValue("profileImage", "others/person-circle.svg")}} >
                                                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                        </svg>
                                                        </>
                                                }
                                            </div>
                                            <div className="btn_upload">
                                                <input type="file" id="upload_file" className="form-control" name="file" accept="image/*" onChange={profileChange} />
                                                Upload
                                            </div>
                                        </div>
                                        <br />
                                        <label htmlFor="first" className="form-label">Name</label>
                                        <div className="position-relative"><input className="form-control" type="text" id="first" name="name" placeholder="Enter your name" value={formik.values.name} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-fill input-icon" viewBox="0 0 16 16">
                                            <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                                        </svg>
                                            {formik.touched.name && formik.errors.name ? (<div className='req-error'>{formik.errors.name}</div>) : null}
                                        </div>

                                        {/* <br />
                                        <label
                                            className="form-label text-start d-block"
                                            htmlFor="modal"
                                        >
                                            Module
                                        </label>

                                        <select name="role" id="modal" className="form-select form-control" value={formik.values.role} onChange={formik.handleChange} onBlur={formik.handleBlur} >
                                            <option value="">Select module</option>
                                            <option value="Shopping">Shopping</option>
                                            <option value="Job">Job</option>
                                            <option value="Ticket">Ticket</option>
                                            <option value="Other">Other</option>
                                        </select> */}

                                        <br />
                                        <label htmlFor="id" className="form-label">Email Address</label>
                                        <div className="position-relative"><input className="form-control" type="email" id="id" name="email" placeholder="Enter your email address" value={formik.values.email} onChange={formik.handleChange} onBlur={formik.handleBlur} disabled />
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope-fill  input-icon" viewBox="0 0 16 16">
                                                <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z" />
                                            </svg>
                                            {formik.touched.email && formik.errors.email ? (<div className='req-error'>{formik.errors.email}</div>) : null}
                                        </div>
                                        <br />
                                        <label htmlFor="phone" className="form-label">Phone Number</label>
                                        <div className="position-relative"><input className="form-control" type="tel" name="phoneNumber" id="phone" placeholder="Enter your phone number" value={formik.values.phoneNumber} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-telephone-fill input-icon" viewBox="0 0 16 16">
                                                <path fillRule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                                            </svg>
                                            {formik.touched.phoneNumber && formik.errors.phoneNumber ? (<div className='req-error'>{formik.errors.phoneNumber}</div>) : null}
                                        </div>
                                        <br />
                                        <div className="text-center d-flex justify-content-around">
                                            <button type="submit" className="btn-custom w-auto mt-2"  > {signInWheel ? <Spinwheel sizeClass="spin-small" /> : "Update"}</button>
                                            <div className="btn-custom w-auto mt-2" onClick={() => { window.history.back(); }}  >Go Back</div>
                                        </div>
                                    </form>
                                    <br />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
};

export default Profiledetails;
