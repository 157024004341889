import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { ImageUrl } from '../../../utils/BaseUrl'
import Spinwheel from '../../common/Spinwheel'

function Table({ tableData, status, selectedPage, setSelectPage, setRemoveData, tableSpin, limit }) {
  const location = useLocation();
  return (
    <div className="card table-responsive" >
      <table className="table table-sm table-hover table-nowrap card-table">
        <thead>
          <tr>
            <th>
              <span
                className="list-sort text-muted"
                
              >
                S.No
              </span>
            </th>
            <th>
              <span
                className="list-sort text-muted"
                
              >
                Name
              </span>
            </th>
            <th >
              <span
                className="list-sort text-muted"

              >
                Phone number
              </span>
            </th>
            <th>
              <span
                className="list-sort text-muted"

              >
                Address
              </span>
            </th>
            <th>
              <span
                className="list-sort text-muted"

              >
                Location
              </span>
            </th>
            
            <th >
              <span
                className="list-sort text-muted"
                
              >
                Description
              </span>
            </th>
            <th colSpan={2}>
              <span
                className="list-sort text-muted"

              >
                Active
              </span>
            </th>
           
          </tr>
        </thead>
        {
          tableSpin ?
        <tbody className="list fs-base">
          { tableData?.length > 0 ? tableData?.map((ele, index) => {
           
            return (<tr key={index} >
              <td>{((selectedPage) * limit) + index + 1}</td>
              <td>
                <div className="avatar avatar-xs align-middle me-2">
                  <img
                    className="avatar-img rounded-circle"
                    src={ele?.image ? ImageUrl + encodeURIComponent(ele?.image) : process.env.PUBLIC_URL + "/images/person-circle.svg"}
                    alt=""
                  />
                </div>
                <span
                  className="item-name text-reset"
                >
                  {ele?.name ? ele?.name : "-"}
                </span>
              </td>
              <td>
                <span
                  className="item-name text-reset"
                >
                  {ele?.phoneNumber ? ele?.phoneNumber : "-"}
                </span>
              </td>
              <td className='position-relative'>
                  {ele?.address2 ? (
                    <div className="tooltip1">
                      {ele?.address2?.substr(0, 20)}...
                      <div className='wrapper'>
                        <div className="tooltip1-text">{ele?.address2}</div>
                      </div>
                    </div>
                  ) : (
                    "-"
                  )}
              </td>
             
              <td className='position-relative'>
                {ele?.address ? (
                  <div className="tooltip1">
                    {ele?.address?.substr(0, 20)}...
                    <div className='wrapper'>
                      <div className="tooltip1-text">{ele?.address}</div>
                    </div>
                  </div>
                ) : (
                  "-"
                )}
              </td>
             
              <td className='position-relative'>
                {ele?.description ? (
                  <div className="tooltip1">
                    {ele?.description?.substr(0, 20)}...
                    <div className='wrapper'>
                      <div className="tooltip1-text">{ele?.description}</div>
                    </div>
                  </div>
                ) : (
                  "-"
                )}
              </td>
              <td>
                <div className={`badge ${ele?.isPinned ? "badge bg-success-soft" : "bg-dark-soft"} `}>
                  {ele?.isPinned ? "Active": "Inactive"}
                </div>
              </td>
              <td className="text-end">
                <div className="dropdown">
                  <span
                    className="dropdown-ellipses dropdown-toggle"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="fe fe-more-vertical" />
                  </span>
                  <div className="dropdown-menu dropdown-menu-end">
                    <Link to={`/map/update?id=${ele?._id}`} className="dropdown-item" state={{ ...location.state, status,data:ele }} >
                      Edit
                    </Link>
                    <div data-bs-toggle="modal" data-bs-target="#exampleModal" className="dropdown-item" style={{ cursor: "pointer" }} onClick={() => { setRemoveData(ele) }} >
                      Remove
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            )
          }) : <tr align="center">
                <td colSpan="12">
                  <h1>
                    Data Not Found
                  </h1>
                </td>
              </tr> }
            </tbody> : (
              <tbody>
                <tr align="center">
                  <td colSpan="12">
                    <Spinwheel />
                  </td>
                </tr>
              </tbody>
            )
        }
      </table>
    </div>
  )
}

export default Table