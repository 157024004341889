import React from 'react'
import { Link } from 'react-router-dom'
import Spinwheel from '../../common/Spinwheel'
import moment from 'moment'

function Table({ tableData, getOrderById, setSelectedItemId, tableSpin, updateStatusFormik, updateStatusModalId, orderShowModalId }) {

  return (
    <div className="card p-3  table-responsive" >

      <table className="table table-sm table-hover table-nowrap card-table">
        <thead>
          <tr>
            <th>
              <span
                className="list-sort text-muted"

              >
                Order Id
              </span>
            </th>
            <th>
              <span
                className="list-sort text-muted"

              >
                Product name
              </span>
            </th>
            <th>
              <span
                className="list-sort text-muted"

              >
                Order date
              </span>
            </th>

            <th>
              <span
                className="list-sort text-muted"

              >
                Exp Delivery date
              </span>
            </th>

            <th>
              <span
                className="list-sort text-muted"

              >
                status
              </span>
            </th>

            <th>
              <span
                className="list-sort text-muted"

              >
                Amount
              </span>
            </th>
            <th>
              <span
                className="list-sort text-muted"

              >
                User Email
              </span>
            </th>
            <th colSpan={2}>
              <span
                className="list-sort text-muted"

              >
                User no
              </span>
            </th>
          </tr>
        </thead>
        {tableSpin ?
          <tbody className="list fs-base">
            {tableData?.length > 0 ? tableData?.map?.((ele, index) => {
                // console.log(ele)
              return <tr key={index}>
                <td><div data-bs-toggle="modal" data-bs-target={`#${orderShowModalId}`} className='text-primary' style={{ cursor: "pointer" }} onClick={() => { getOrderById(ele?._id) }}>{ele?.orderDetails?.orderId ? ele?.orderDetails?.orderId : "-"}</div></td>
                <td>
                  <span
                    className="item-name text-reset"
                  >
                    {ele?.products.name ? ele?.products.name : "-"}
                  </span>
                </td>
                <td>
                  <span
                    className="item-name text-reset"
                   
                  >
                    {ele?.createdAt ? moment(ele.createdAt).format("DD/MM/YYYY") : "-"}
                  </span>
                </td>

                <td>
                  <span
                    className="item-name text-reset"

                  >
                    {ele?.expectedDeliveryTime ? moment(ele.expectedDeliveryTime).format("DD/MM/YYYY") : "-"}
                  </span>
                </td>
                <td>
                  <div
                    className="item-name text-reset text-capitalize"
                  >
                    {ele?.status ? ele?.status : "-"}
                  </div>
                </td>
                <td>
                  <span
                    className="item-name text-reset"
                  >
                    {ele?.price ? ele?.price * ele?.quantity : "-"}
                  </span>
                </td>
                <td>
                  <div
                    className="item-phone text-reset"
                  // href="tel:1-123-456-4890"
                  >
                    {ele?.orderDetails?.deleveryAddress?.email ? ele?.orderDetails?.deleveryAddress?.email : "-"}
                  </div>
                </td>
                <td>
                  <div
                    className="item-phone text-reset"
                  // href="tel:1-123-456-4890"
                  >
                    {ele?.orderDetails?.deleveryAddress?.phone ? ele?.orderDetails?.deleveryAddress?.phone : "-"}
                  </div>
                </td>
                <td className="text-end">
                  <div className="dropdown">
                    <Link
                      className="dropdown-ellipses dropdown-toggle"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="fe fe-more-vertical" />
                    </Link>
                    <div className="dropdown-menu dropdown-menu-end">

                      <div onClick={() => { setSelectedItemId(ele?._id); updateStatusFormik.setFieldValue("status", ele?.status) }} data-bs-toggle="modal" data-bs-target={`#${updateStatusModalId}`} className="dropdown-item" style={{ cursor: "pointer" }}>
                        Update status
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            }) : <tr align="center">
              <td colSpan="12">
                <h1>
                  Data Not Found
                </h1>
              </td>
            </tr>
            }
          </tbody> : <tbody>
            <tr align="center">
              <td colSpan="12">
                <Spinwheel />
              </td>
            </tr>
          </tbody>
        }
      </table>
    </div>
  )
}

export default Table