import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { useAdminContext } from '../../../../context/adminContext';
import { createOrUpdateMovieShowApi, getMovieShowApi, removeMovieShowApi } from '../../../../utils/ApiEndpointFunctions';
import Header from '../../../common/Header';
import Pagination from '../../../common/Pagination';
import Spinwheel from '../../../common/Spinwheel';
import { ImageUrl } from '../../../../utils/BaseUrl';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import AddOrViewMovieShow from './addShow';
import { useFormik } from 'formik';
import { movieShowInitialValues, movieShowValidationSchema } from "../../../common/Validation"
import { toast } from 'react-hot-toast';
import RemoveModal from '../../../common/RemoveModal';
import { debounce } from 'lodash';

function MovieShow() {
  const navigate = useNavigate()
  const [tableSpin, setTableSpin] = useState(true);
  const [movieShow, setMovieShow] = useState([]);
  const [selectedPage, setSelectPage] = useState(0);
  const [page, setPage] = useState(0);
  const [showLoader, setShowLoader] = useState(false)
  const param = useParams()
  const movieShowModalId = "movieShowModalId"
  const removeMovieShowModalId = "removeMovieShowModalId"
  const [selectedShowId, setSelectedShowId] = useState(null)
  const limit = 10;

  const getMovieShowList = async (selectedPage, search) => {
    try {
      setTableSpin(false)
      const res = await getMovieShowApi(search || "", selectedPage + 1, limit, param.id)
      if (res.status === 200) {
        setMovieShow(res.data?.data?.data)
        const totaldata = res.data?.data?.totalData

        setPage(Math.ceil(totaldata / limit));
      }
      setTableSpin(true)
    } catch (error) {
      setTableSpin(true)
    }
  }

  const handleSubmit = async (values) => {
    const payload = {
      ...values,
      movieId: param.id,
      movieCinemaId: values.movieCinemaId?.value
    }
    if (selectedShowId) {
      payload._id = selectedShowId
    }
    try {
      setShowLoader(true)
      const res = await createOrUpdateMovieShowApi(payload);
      if (res?.status === 200) {
        toast.success(res.data?.message)
        setShowLoader(false)
        getMovieShowList(0)
        document.getElementById(movieShowModalId).click()
        setSelectedShowId(null)
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setShowLoader(false)
    }
  }

  const formik = useFormik({
    initialValues: movieShowInitialValues,
    onSubmit: handleSubmit,
    validationSchema: movieShowValidationSchema,
  });




  const handlePageClick = (event) => {
    setSelectPage(event.selected);
    getMovieShowList(event.selected)
  };

  const handleModelView = (show) => {
    formik.setValues({
      movieCinemaId: { value: show?.cinemaDetails?._id, label: show?.cinemaDetails?.name },
      timeDate: show?.timeDate,
      noOfSeats: show?.noOfSeats,
      price: show?.price,
      tax: show?.tax,
      language: show?.language,
    })
    setSelectedShowId(show?._id)
  }

  const handleRemoveShow = async () => {
    try {
      const res = await removeMovieShowApi(selectedShowId);
      if (res?.status === 200) {
        toast.success(res.data?.message)
        getMovieShowList(0)
        document.getElementById(removeMovieShowModalId).click()
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
  const onChange = async (e) => {
    let search = e.target.value;
    getMovieShowList(0, search)
  };

  const debouncedOnChange = debounce(onChange, 500);


  useEffect(() => {
    if (param.id) {
      getMovieShowList(selectedPage)
    }
  }, [])



  return (
    <>
      {/*<Sidebar />*/}
      <div className="main-content vh-100 vh_md-auto overflow-y-a">
        <Header title="Movie" />
        {/* CARDS */}
        <div className="container-fluid">
          <div className="row">
            <nav className="mb-5">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">
                    <i className="fe fe-home"></i> Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  <Link to={`/ticket/movies`}>
                    Movies
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  show
                </li>
              </ol>
            </nav>
            <div className="mb-4" style={{ textAlign: "end" }}>
              <div className='d-flex justify-content-end gap-2' style={{ display: "-webkit-inline-box" }}>
                <div className='d-flex gap-2 align-items-center justify-content-center'>
                  <button data-bs-toggle="modal" data-bs-target={`#${movieShowModalId}`} onClick={(e) => { formik.resetForm(e); setSelectedShowId(null) }} className="btn-custom w-auto" style={{ cursor: "pointer" }}>
                    Add
                  </button>
                  <div className="position-relative">
                    <input
                      className="form-search me-3"
                      type="search"
                      name=""
                      onChange={debouncedOnChange}
                      placeholder="Search"
                    />
                    <img
                      className="users-search"
                      src="/images/Icon feather-search.svg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <hr />

            <div className="card p-3 table-responsive" >
              <table className="table table-hover table-nowrap card-table">
                <thead>
                  <tr>
                    <th>
                      <span
                        className="list-sort text-muted"
                      >
                        S.No
                      </span>
                    </th>
                    <th>
                      <span
                        className="list-sort text-muted"
                      >
                        Cinema name
                      </span>
                    </th>
                    <th>
                      <span
                        className="list-sort text-muted"
                      >
                        Date
                      </span>
                    </th>
                    <th>
                      <span
                        className="list-sort text-muted"
                      >
                        Time
                      </span>
                    </th>
                    <th>
                      <span
                        className="list-sort text-muted"
                      >
                        Language
                      </span>
                    </th>
                    <th>
                      <span
                        className="list-sort text-muted"
                      >
                        Seats
                      </span>
                    </th>
                    <th>
                      <span
                        className="list-sort text-muted"
                      >
                        Price
                      </span>
                    </th>
                    <th></th>


                  </tr>
                </thead>
                {
                  tableSpin ?
                    <tbody className="list fs-base">
                      {movieShow?.length > 0 ? movieShow?.map((ele, index) => {
                        return (<tr key={index} >
                          <td>{index + 1}</td>
                          <td className='position-relative'>
                            {ele?.cinemaDetails?.name?.length > 30 ? <span>
                              {ele?.cinemaDetails?.name?.substr(0, 30)}...
                              <div className="tooltip1 item-name text-reset" ></div>
                              <div className='wrapper'>
                                <div className="tooltip1-text">{ele?.cinemaDetails?.name}</div>
                              </div>
                            </span> :
                              <span>{ele?.cinemaDetails?.name || "-"}</span>}

                          </td>
                          <td className='position-relative'>
                            <span>{ele?.timeDate ? moment(ele?.timeDate).format("DD/MM/YYYY") : "-"}</span>
                          </td>
                          <td className='position-relative'>
                            <span>{ele?.timeDate ? moment(ele?.timeDate).format("h:mm a") : "-"}</span>
                          </td>
                          <td className='position-relative'>
                            {ele?.language || "-"}
                          </td>
                          <td className="position-relative">
                            {ele?.noOfSeats || 0}
                          </td>
                          <td className="position-relative">
                            {ele?.price || 0}
                          </td>

                          <td className="text-end">
                            {ele?.status?.toLowerCase() != "transferred" &&
                              <div className="dropdown">
                                <span
                                  className="dropdown-ellipses dropdown-toggle"
                                  role="button"
                                  data-bs-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <i className="fe fe-more-vertical" />
                                </span>
                                <div className="dropdown-menu dropdown-menu-end">
                                  <div data-bs-toggle="modal" data-bs-target={`#${movieShowModalId}`} className=' dropdown-item' style={{ cursor: "pointer" }} onClick={() => { handleModelView(ele) }}>
                                    View / Edit show
                                  </div>
                                  <div data-bs-toggle="modal" data-bs-target={`#${removeMovieShowModalId}`} className=' dropdown-item' style={{ cursor: "pointer" }} onClick={() => { setSelectedShowId(ele?._id) }}>
                                    Remove
                                  </div>
                                </div>
                              </div>}
                          </td>
                        </tr>
                        )
                      }) : <tr align="center">
                        <td colSpan="9">
                          <h1>
                            Data Not Found
                          </h1>
                        </td>
                      </tr>}
                    </tbody> : (
                      <tbody>
                        <tr align="center">
                          <td colSpan="9">
                            <Spinwheel />
                          </td>
                        </tr>
                      </tbody>
                    )
                }
              </table>
            </div>
            <div className="d-flex justify-content-center">
              {movieShow?.length > 0 ? <Pagination
                clickHandler={handlePageClick}
                page={page}
                selectedPage={selectedPage}
              />
                : null}
            </div>
          </div>
          <AddOrViewMovieShow formik={formik} modalId={movieShowModalId} addLoader={showLoader} />
          <RemoveModal removeHandler={handleRemoveShow} modalId={removeMovieShowModalId} message={"Are you sure you want to remove?"} />
        </div>
      </div>
    </>
  )
}

export default MovieShow