import React from 'react'
import { Link } from 'react-router-dom';
import { useAdminContext } from '../../context/adminContext';
import { ImageUrl } from '../../utils/BaseUrl';

function Header({title}) {
  const { adminDetail } = useAdminContext();
  return (
    <>
      <div>
        <img
          className="dashboard-mask-top-bg d-none d-xl-block"
          src="/images/Mask Group 650.png"
          alt="Mask Group 650.png"
        />
        <img
          className="dashboard-mask-bottom-bg d-none d-xl-block"
          src="/images/Mask Group 650.png"
          alt="Mask Group 650.png"
        />
      </div>
      <div className="header">
        <div className="container-fluid">
          {/* Body */}
          <div className="header-body">
            <div className="row align-items-end">
              <div className="col">
                {/* Pretitle */}
                <h6 className="header-pretitle">Overview</h6>
                {/* Title */}
                <h1 className="header-title">{title}</h1>
              </div>
              <div className="col-auto">
                {/* Button */}
                <div className="dropdown">
                  <button
                    className="btn btn-second dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      src={`${adminDetail?.profileImage ? ImageUrl + encodeURIComponent(adminDetail?.profileImage) : process.env.PUBLIC_URL + "/images/person-circle.svg"}`}
                      alt=""
                      height={24}
                      width={24}
                      className="rounded-circle me-2"
                    />{adminDetail?.name}
                  </button>
                  <ul className="dropdown-menu">
                    <li>
                      <Link className="dropdown-item" to={"/profiledetails"}>
                        Profile
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>{" "}
            {/* / .row */}
          </div>{" "}
          {/* / .header-body */}
        </div>
      </div>
    </>
  )
}

export default Header