import React from 'react'
import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({ text }) => <div>{text}</div>;
function GoogleMapLocation(props) {
  const { lat, lng, onClick, defaultCenter } = props;

  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: process.env.REACT_APP_Google_Api_Key }}
      yesIWantToUseGoogleMapApiInternals
      defaultCenter={{ lat: Number(lat), lng: Number(lng) }}
      defaultZoom={defaultCenter || 15}
      onClick={(ele) => {
        onClick(ele)
      }}
      center={{ lat: Number(lat), lng: Number(lng) }}
    >
      <AnyReactComponent
        lat={Number(lat)}
        lng={Number(lng)}
        text={<div style={{ position: "relative" }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" style={{top:"30px"}} fill="currentColor" class="bi bi-geo-alt-fill text-danger" viewBox="0 0 16 16">
            <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6" />
          </svg>
        </div>}
      />
    </GoogleMapReact>
  )
}

export default GoogleMapLocation