import React from 'react'
import Spinwheel from '../../../common/Spinwheel'
import { ImageUrl } from '../../../../utils/BaseUrl'
import { Link } from 'react-router-dom'

function OtherTable({ tableData, tableSpin, setIsEdit, setRemoveData, formik }) {

  const updateDataHandler = (ele) => {

    formik.setValues({
      _id: ele._id ? ele._id : undefined,
      name: ele.name ? ele.name : "",
      file: ele.file ? ele.file : "",
    })

  }
  return (
    <div><table className="table table-sm table-hover table-nowrap card-table">
      <thead>
        <tr>
          <th><span className="list-sort text-muted">Name</span></th>
          <th colSpan={2}><span className="list-sort text-muted">file</span></th>
          
        </tr>
      </thead>
      <tbody>

        {
          tableSpin ? <tr>
            <td colSpan={20}>
              <div className="text-center">
                <Spinwheel />
              </div>
            </td>
          </tr> :
            tableData?.length ? tableData?.map(ele => {
              return (
                <tr key={ele?._id}>
                  <td>{ele.name}</td>
               
                  <td className='position-relative'>
                    {ele?.file ? (
                      <div className="tooltip1">
                        <Link to={ImageUrl + encodeURIComponent(ele?.file)} target="__blank">{ele?.file?.substr?.(0, 20)}...</Link>
                        <div className='wrapper'>
                          <div className="tooltip1-text">{ele?.file}</div>
                        </div>
                      </div>
                    ) : (
                      "-"
                    )}
                  </td>

                  <td className="text-end">
                    <div className="dropdown">
                      <span
                        className="dropdown-ellipses dropdown-toggle"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i className="fe fe-more-vertical" />
                      </span>
                      <div className="dropdown-menu dropdown-menu-end">
                        <div data-bs-toggle="modal" data-bs-target="#ManageStatusModal" className="dropdown-item" style={{ cursor: "pointer" }} onClick={() => { setIsEdit(true); updateDataHandler(ele) }} >
                          View/Edit
                        </div>
                        <div data-bs-toggle="modal" data-bs-target="#removedoc" className="dropdown-item" style={{ cursor: "pointer" }} onClick={() => { setRemoveData(ele) }} >
                          Remove
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              )
            }) : <tr>
              <td colSpan={12}><h1 className="text-center">Data not found</h1></td>
            </tr>}

      </tbody>
    </table>
    </div>
  )
}

export default OtherTable