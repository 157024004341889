
import { useEffect, useState } from 'react';
import './App.css';
import { Toaster } from 'react-hot-toast';

import Mainroute from './routes/Mainroute';
// import Mainroute from './mainRoute/Mainroute'
import { useAdminContext } from "./context/adminContext"
import { getAdminDetails } from './utils/ApiEndpointFunctions';
import { getToken } from './utils/HelperFuncitons';
import Spinwheel from './components/common/Spinwheel';
import { BrowserRouter } from 'react-router-dom';

function App() {
  const { adminDetail, setAdminDetail } = useAdminContext();
  const getAdminDetail = async () => {
    try {
      const res = await getAdminDetails();
      if (res?.status === 200) {
        setAdminDetail(res.data?.data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    if (getToken())
      getAdminDetail();
  }, [])
  
  return (
    <BrowserRouter>
    <div className="App">
      <Mainroute />
      <Toaster
        position="top-right"
        reverseOrder={false}
      />

    </div>
    </BrowserRouter>
  );
}

export default App;
