import { useFormik } from 'formik';
import React from 'react'
import { otherInitialValue, otherValidation } from '../../../common/Validation';
import Spinwheel from '../../../common/Spinwheel';
import Attachment from '../../../common/Attachment';

function OtherForm({ formik, addDocLoader, docSpin, handlefile, submitHandler}) {

  
  return (
    <div>

      <div className="row row-cols-2">
        <div>
          <div className='d-flex'>
            <label htmlFor="name" className="form-label">Name</label>
          </div>
          <div className="position-relative">
            <input className="form-control border border-1" type="text" id="name" placeholder="Enter..." name="name" value={formik.values?.name} onChange={formik.handleChange} onBlur={formik.handleBlur} />
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-text input-icon" viewBox="0 0 16 16">
              <path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5M5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1z" />
              <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1" />
            </svg>
            {formik.touched.name && formik.errors.name ? (<div className='req-error'>{formik.errors.name}</div>) : null}
          </div>
        </div>

        <Attachment formik={formik} docSpin={docSpin} handlefile={handlefile} />
      </div>

      <div className="modal-footer border-0 justify-content-center pt-4">
        <button type="button" className="btn-custom w-auto" disabled={addDocLoader} onClick={(e) => { e.preventDefault(); formik.handleSubmit(); }}  >{addDocLoader ? <Spinwheel sizeClass="spin-small" /> : "Submit"}</button>
        <button type="button" className="btn-custom w-auto" data-bs-dismiss='modal' >
          Cancel
        </button>
      </div>
      
    </div>
  )
}

export default OtherForm