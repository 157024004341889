import React, { useEffect, useState } from "react";

import Sidebar from "../../../Layout/Sidebar";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useAdminContext } from "../../../context/adminContext";
import { useFormik } from "formik";
import { userEditIntialValue, userEditValidation } from "../../common/Validation"
import { ImageUrl } from "../../../utils/BaseUrl";
import Header from "../../common/Header";
import { getUserByIdApi, updateUserByIdApi, uploadImage } from "../../../utils/ApiEndpointFunctions";
import toast from "react-hot-toast";
import moment from "moment";
import ReactDatePicker from 'react-datepicker';
import Spinwheel from "../../common/Spinwheel";


export default function Usersedit() {
    const { adminDetail, } = useAdminContext()
    const params = useParams();
    const [id, setId] = useState(params?.id)
    const navigate = useNavigate()
    const [loader, setLoader] = useState(false);
    const [fileTypeError, setFileTypeError] = useState("")

    const handleSubmit = async (values) => {
        setLoader(true)
        try {
            const res = await updateUserByIdApi(values);
            if (res.status === 200) {
                toast.success(res.data?.message)
                navigate("/users", { replace: true })
            }
        } catch (error) {
            console.log(error);
            
            error?.response?.data?.message ?  toast.error(error?.response?.data?.message):
            toast.error("Something went wrong")
        }
        setLoader(false)
    }



    const profileChange = async (e) => {
        try {
            const file = e.target.files[0];
            userEditFormik.setFieldTouched(`profile`, true)
            const types = ['image/jpeg', 'image/png', 'image/gif']
            if (file) {
                if (types.includes(file?.type)) {
                setFileTypeError("")

                const formData = new FormData();
                formData.append("file", file);
                formData.append("type", 5)
                formData.append("folder", "profile")
                const res = await uploadImage('/users/fileUpload', formData)
                if (res.status === 200 && res.data?.status === 1) {
                    userEditFormik.setFieldValue("profile", res.data?.data?.fileName)
                    toast.success("File uploaded")
                }
            } else {
                setFileTypeError("Must be image only")
                    userEditFormik.setFieldValue("profile",'')
            }

            }
        } catch (error) {
            console.log(error);
        }
    }

    const fetchUserDetails = async (id) => {
        try {
            const res = await getUserByIdApi(id);
            if (res.status === 200) {
                userEditFormik.setFieldValue("_id", res.data?.data?._id ? res.data?.data?._id : "")
                userEditFormik.setFieldValue("name", res.data?.data?.name ? res.data?.data?.name : "")
                userEditFormik.setFieldValue("email", res.data?.data?.email ? res.data?.data?.email : "")
                userEditFormik.setFieldValue("phoneNumber", res.data?.data?.phoneNumber ? res.data?.data?.phoneNumber : "")
                userEditFormik.setFieldValue("dob", res.data?.data?.dob ? res.data?.data?.dob : "")
                // userEditFormik.setFieldValue("role", res.data?.data?.role ? res.data?.data?.role : "");
                userEditFormik.setFieldValue("birthPlace", res.data?.data?.birthPlace ? res.data?.data?.birthPlace : "");
                userEditFormik.setFieldValue("gender", res.data?.data?.gender ? res.data?.data?.gender : "")
                userEditFormik.setFieldValue("profile", res.data?.data?.userDetails?.profile ? res.data?.data?.userDetails?.profile : "")
                userEditFormik.setFieldValue("compensation", res.data?.data?.userDetails?.compensation ? res.data?.data?.userDetails?.compensation : "");
                userEditFormik.setFieldValue("country", res.data?.data?.userDetails?.country ? res.data?.data?.userDetails?.country : "");
                userEditFormik.setFieldValue("experience", res.data?.data?.userDetails?.experience ? res.data?.data?.userDetails?.experience : "");
                userEditFormik.setFieldValue("organization", res.data?.data?.userDetails?.organization ? res.data?.data?.userDetails?.organization : "");
                userEditFormik.setFieldValue("jobTitle", res.data?.data?.userDetails?.jobTitle ? res.data?.data?.userDetails?.jobTitle : "");
                userEditFormik.setFieldValue("qualification", res.data?.data?.userDetails?.qualification ? res.data?.data?.userDetails?.qualification : "");
                userEditFormik.setFieldValue("correspondence_Address", res.data?.data?.userDetails?.correspondence_Address ? res.data?.data?.userDetails?.correspondence_Address : "");
                userEditFormik.setFieldValue("correspondence_country", res.data?.data?.userDetails?.correspondence_country ? res.data?.data?.userDetails?.correspondence_country : "");
                userEditFormik.setFieldValue("correspondence_zipcode", res.data?.data?.userDetails?.correspondence_zipcode ? res.data?.data?.userDetails?.correspondence_zipcode : "");
                userEditFormik.setFieldValue("work_zipcode", res.data?.data?.userDetails?.work_zipcode ? res.data?.data?.userDetails?.work_zipcode : "");
                userEditFormik.setFieldValue("work_country", res.data?.data?.userDetails?.work_country ? res.data?.data?.userDetails?.work_country : "");
                userEditFormik.setFieldValue("work_Address", res.data?.data?.userDetails?.work_Address ? res.data?.data?.userDetails?.work_Address : "");
                userEditFormik.setFieldValue("zipCode", res.data?.data?.userDetails?.zipCode ? res.data?.data?.userDetails?.zipCode : "");
            }

        } catch (error) {
            console.log(error);
        }

    };

    const userEditFormik = useFormik({
        initialValues: userEditIntialValue,
        onSubmit: handleSubmit,
        validationSchema: userEditValidation

    })

    useEffect(() => {

        if (id) {
            fetchUserDetails(id);
        }
    }, [id]);


    return (
        <>
            {/*<Sidebar />*/}
            <div className="main-content vh-100 vh_md-auto overflow-y-a">
                <Header title="Users" />
                <div className="container-fluid">
                    <div className="row">
                        <nav className="mb-5">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to="/dashboard">
                                        <i className="fe fe-home"></i> Dashboard
                                    </Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to="/users" className="breadcrumb-item">
                                        Users
                                    </Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Edit users
                                </li>
                            </ol>
                        </nav>
                        <div>
                            <h2 className="report">Edit user</h2>
                        </div>
                        <hr />

                        <div className="col-12" style={{ zIndex: "1" }}>
                            <form action="" onSubmit={userEditFormik.handleSubmit}>
                                <div className="row">
                                    <div className="col-6 mb-3">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="position-relative">
                                                {<img src={userEditFormik?.values?.profile && userEditFormik?.values?.profile != "null" ? ImageUrl + encodeURIComponent(userEditFormik?.values?.profile) : process.env.PUBLIC_URL + "/images/person-circle.svg"} width="70px" height="70px" className="rounded-circle" alt="Selected file" />}
                                                {
                                                    <>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-camera pro-camera" viewBox="0 0 16 16"  >
                                                            <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1v6zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z" />
                                                            <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
                                                        </svg>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x xmark" viewBox="0 0 16 16" onClick={() => { userEditFormik.setFieldValue("profile", "others/person-circle.svg") }} >
                                                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                        </svg>
                                                    </>
                                                }
                                                {fileTypeError && <div className="req-error">{fileTypeError}</div> }
                                            </div>
                                            <div className="btn_upload ">
                                                <input type="file" id="profile" className="form-control" name="profile" accept="image/*" onChange={profileChange} />
                                                Upload
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 mb-3">
                                        <label htmlFor="name" className="form-label"> Name</label>
                                        <div className="position-relative"><input className="form-control" type="text" id="name" placeholder="Enter name" name="name" value={userEditFormik.values.name} onChange={userEditFormik.handleChange} onBlur={userEditFormik.handleBlur} /><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-fill input-icon" viewBox="0 0 16 16">
                                            <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                                        </svg>
                                            {userEditFormik.touched.name && userEditFormik.errors.name ? (<div className='req-error'>{userEditFormik.errors.name}</div>) : null}
                                        </div>
                                    </div>

                                    <div className="col-6 mb-3">
                                        <label htmlFor="email" className="form-label">Email address</label>
                                        <div className="position-relative"><input className="form-control" type="email" id="email" placeholder="Enter email address" name="email" value={userEditFormik.values.email} onChange={userEditFormik.handleChange} onBlur={userEditFormik.handleBlur} />
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope-fill  input-icon" viewBox="0 0 16 16">
                                                <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z" />
                                            </svg>
                                            {userEditFormik.touched.email && userEditFormik.errors.email ? (<div className='req-error'>{userEditFormik.errors.email}</div>) : null}
                                        </div>
                                    </div>
                                    <div className="col-6 mb-3">
                                        <label htmlFor="phoneNumber" className="form-label">Phone number</label>
                                        <div className="position-relative"><input className="form-control" type="tel" id="phoneNumber" placeholder="Enter phone number" name="phoneNumber" value={userEditFormik.values.phoneNumber} onChange={userEditFormik.handleChange} onBlur={userEditFormik.handleBlur} />
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-telephone-fill input-icon" viewBox="0 0 16 16">
                                                <path fillRule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                                            </svg>
                                            {userEditFormik.touched.phoneNumber && userEditFormik.errors.phoneNumber ? (<div className='req-error'>{userEditFormik.errors.phoneNumber}</div>) : null}
                                        </div>
                                    </div>

                                    <div className="col-6 mb-3">
                                        <label htmlFor="birthPlace" className="form-label">Birth place</label>
                                        <div className="position-relative"><input className="form-control" type="tel" id="birthPlace" placeholder="Enter birth place" name="birthPlace" value={userEditFormik.values.birthPlace} onChange={userEditFormik.handleChange} onBlur={userEditFormik.handleBlur} />
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt input-icon" viewBox="0 0 16 16">
                                                <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A32 32 0 0 1 8 14.58a32 32 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10" />
                                                <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                                            </svg>
                                            {userEditFormik.touched.birthPlace && userEditFormik.errors.birthPlace ? (<div className='req-error'>{userEditFormik.errors.birthPlace}</div>) : null}
                                        </div>
                                    </div>

                                    {/* <div className="col-6 mb-3">
                                        <label htmlFor="role" className="form-label">Role</label>
                                        <div className="position-relative"><input className="form-control" type="tel" id="role" placeholder="Enter role" name="role" value={userEditFormik.values.role} onChange={userEditFormik.handleChange} onBlur={userEditFormik.handleBlur} />
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-text input-icon" viewBox="0 0 16 16">
                                                <path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5M5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1z" />
                                                <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1" />
                                            </svg>
                                            {userEditFormik.touched.role && userEditFormik.errors.role ? (<div className='req-error'>{userEditFormik.errors.role}</div>) : null}
                                        </div>
                                    </div> */}

                                    <div className="col-6 mb-3">
                                        <label htmlFor="gender" className="form-label">Gender</label>
                                        <div className="position-relative">
                                            <select class="form-select" aria-label="Default select example" name="gender" value={userEditFormik.values.gender} onChange={userEditFormik.handleChange} onBlur={userEditFormik.handleBlur} >
                                                <option value="">Select</option>
                                                <option value="Male">Male</option>
                                                <option value="Female">Female</option>
                                            </select>
                                            {userEditFormik.touched.gender && userEditFormik.errors.gender ? (<div className='req-error'>{userEditFormik.errors.gender}</div>) : null}
                                        </div>
                                    </div>

                                    <div className="col-6 mb-3">
                                        <label htmlFor="dob" className="form-label">DOB</label>
                                        <div className="position-relative">
                                            <ReactDatePicker
                                                selected={userEditFormik.values.dob ? new Date(moment(userEditFormik.values.dob?.toString(), "MM-DD-YYYY").format()) : undefined}
                                                name='dob'
                                                id="dob"
                                                onChange={(e) => {
                                                    e ?
                                                        userEditFormik.setFieldValue("dob", moment(e).format("MM-DD-YYYY")) :
                                                        userEditFormik.setFieldValue("dob", "")
                                                }}
                                                onBlur={userEditFormik.handleBlur}
                                                className='form-control w-100'
                                                // isClearable={userEditFormik.values.dob === "" ? false : true}
                                                placeholderText='DOB'
                                                // minDate={path === "Add" ? new Date() : new Date(moment(userEditFormik.values.dob?.toString(), "MM-DD-YYYY")) < new Date() ? new Date(moment(userEditFormik.values.dob?.toString(), "MM-DD-YYYY")) : new Date()}
                                                autoComplete="off"
                                                dateFormat={"dd/MM/yyyy"}
                                            />
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar input-icon" viewBox="0 0 16 16">
                                                <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z" />
                                            </svg>
                                            {userEditFormik.touched.dob && userEditFormik.errors.dob ? (<div className='req-error'>{userEditFormik.errors.dob}</div>) : null}
                                        </div>
                                    </div>

                                    <div className="col-6 mb-3">
                                        <label htmlFor="compensation" className="form-label">Compensation</label>
                                        <div className="position-relative"><input className="form-control" type="tel" id="compensation" placeholder="Enter compensation" name="compensation" value={userEditFormik.values.compensation} onChange={userEditFormik.handleChange} onBlur={userEditFormik.handleBlur} />
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cash-coin input-icon" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M11 15a4 4 0 1 0 0-8 4 4 0 0 0 0 8m5-4a5 5 0 1 1-10 0 5 5 0 0 1 10 0" />
                                                <path d="M9.438 11.944c.047.596.518 1.06 1.363 1.116v.44h.375v-.443c.875-.061 1.386-.529 1.386-1.207 0-.618-.39-.936-1.09-1.1l-.296-.07v-1.2c.376.043.614.248.671.532h.658c-.047-.575-.54-1.024-1.329-1.073V8.5h-.375v.45c-.747.073-1.255.522-1.255 1.158 0 .562.378.92 1.007 1.066l.248.061v1.272c-.384-.058-.639-.27-.696-.563h-.668zm1.36-1.354c-.369-.085-.569-.26-.569-.522 0-.294.216-.514.572-.578v1.1zm.432.746c.449.104.655.272.655.569 0 .339-.257.571-.709.614v-1.195z" />
                                                <path d="M1 0a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h4.083q.088-.517.258-1H3a2 2 0 0 0-2-2V3a2 2 0 0 0 2-2h10a2 2 0 0 0 2 2v3.528c.38.34.717.728 1 1.154V1a1 1 0 0 0-1-1z" />
                                                <path d="M9.998 5.083 10 5a2 2 0 1 0-3.132 1.65 6 6 0 0 1 3.13-1.567" />
                                            </svg>
                                            {userEditFormik.touched.compensation && userEditFormik.errors.compensation ? (<div className='req-error'>{userEditFormik.errors.compensation}</div>) : null}
                                        </div>
                                    </div>

                                    <div className="col-6 mb-3">
                                        <label htmlFor="country" className="form-label">Country</label>
                                        <div className="position-relative"><input className="form-control" type="tel" id="country" placeholder="Enter country" name="country" value={userEditFormik.values.country} onChange={userEditFormik.handleChange} onBlur={userEditFormik.handleBlur} />
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-globe-americas input-icon" viewBox="0 0 16 16">
                                                <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0M2.04 4.326c.325 1.329 2.532 2.54 3.717 3.19.48.263.793.434.743.484q-.121.12-.242.234c-.416.396-.787.749-.758 1.266.035.634.618.824 1.214 1.017.577.188 1.168.38 1.286.983.082.417-.075.988-.22 1.52-.215.782-.406 1.48.22 1.48 1.5-.5 3.798-3.186 4-5 .138-1.243-2-2-3.5-2.5-.478-.16-.755.081-.99.284-.172.15-.322.279-.51.216-.445-.148-2.5-2-1.5-2.5.78-.39.952-.171 1.227.182.078.099.163.208.273.318.609.304.662-.132.723-.633.039-.322.081-.671.277-.867.434-.434 1.265-.791 2.028-1.12.712-.306 1.365-.587 1.579-.88A7 7 0 1 1 2.04 4.327Z" />
                                            </svg>
                                            {userEditFormik.touched.country && userEditFormik.errors.country ? (<div className='req-error'>{userEditFormik.errors.country}</div>) : null}
                                        </div>
                                    </div>

                                    <div className="col-6 mb-3">
                                        <label htmlFor="zipCode" className="form-label">Zip code</label>
                                        <div className="position-relative"><input className="form-control" type="tel" id="zipCode" placeholder="Enter zip code" name="zipCode" value={userEditFormik.values.zipCode} onChange={userEditFormik.handleChange} onBlur={userEditFormik.handleBlur} />
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-globe-americas input-icon" viewBox="0 0 16 16">
                                                <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0M2.04 4.326c.325 1.329 2.532 2.54 3.717 3.19.48.263.793.434.743.484q-.121.12-.242.234c-.416.396-.787.749-.758 1.266.035.634.618.824 1.214 1.017.577.188 1.168.38 1.286.983.082.417-.075.988-.22 1.52-.215.782-.406 1.48.22 1.48 1.5-.5 3.798-3.186 4-5 .138-1.243-2-2-3.5-2.5-.478-.16-.755.081-.99.284-.172.15-.322.279-.51.216-.445-.148-2.5-2-1.5-2.5.78-.39.952-.171 1.227.182.078.099.163.208.273.318.609.304.662-.132.723-.633.039-.322.081-.671.277-.867.434-.434 1.265-.791 2.028-1.12.712-.306 1.365-.587 1.579-.88A7 7 0 1 1 2.04 4.327Z" />
                                            </svg>
                                            {userEditFormik.touched.zipCode && userEditFormik.errors.zipCode ? (<div className='req-error'>{userEditFormik.errors.zipCode}</div>) : null}
                                        </div>
                                    </div>

                                    <div className="col-6 mb-3">
                                        <label htmlFor="experience" className="form-label">Experience</label>
                                        <div className="position-relative"><input className="form-control" type="tel" id="experience" placeholder="Enter experience" name="experience" value={userEditFormik.values.experience} onChange={userEditFormik.handleChange} onBlur={userEditFormik.handleBlur} />
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bar-chart-line input-icon" viewBox="0 0 16 16">
                                                <path d="M11 2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v12h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3h1V7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7h1zm1 12h2V2h-2zm-3 0V7H7v7zm-5 0v-3H2v3z" />
                                            </svg>
                                            {userEditFormik.touched.experience && userEditFormik.errors.experience ? (<div className='req-error'>{userEditFormik.errors.experience}</div>) : null}
                                        </div>
                                    </div>

                                    <div className="col-6 mb-3">
                                        <label htmlFor="jobTitle" className="form-label">Job title</label>
                                        <div className="position-relative"><input className="form-control" type="tel" id="jobTitle" placeholder="Enter job title" name="jobTitle" value={userEditFormik.values.jobTitle} onChange={userEditFormik.handleChange} onBlur={userEditFormik.handleBlur} />
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-house-door-fill input-icon" viewBox="0 0 16 16">
                                                <path d="M0 1.5V13a1 1 0 0 0 1 1V1.5a.5.5 0 0 1 .5-.5H14a1 1 0 0 0-1-1H1.5A1.5 1.5 0 0 0 0 1.5z" />
                                                <path d="M3.5 2A1.5 1.5 0 0 0 2 3.5v11A1.5 1.5 0 0 0 3.5 16h6.086a1.5 1.5 0 0 0 1.06-.44l4.915-4.914A1.5 1.5 0 0 0 16 9.586V3.5A1.5 1.5 0 0 0 14.5 2h-11zm6 8.5a1 1 0 0 1 1-1h4.396a.25.25 0 0 1 .177.427l-5.146 5.146a.25.25 0 0 1-.427-.177V10.5z" />
                                            </svg>
                                            {userEditFormik.touched.jobTitle && userEditFormik.errors.jobTitle ? (<div className='req-error'>{userEditFormik.errors.jobTitle}</div>) : null}
                                        </div>
                                    </div>

                                    <div className="col-6 mb-3">
                                        <label htmlFor="organization" className="form-label">Organization</label>
                                        <div className="position-relative"><input className="form-control" type="tel" id="Organization" placeholder="Enter organization" name="organization" value={userEditFormik.values.organization} onChange={userEditFormik.handleChange} onBlur={userEditFormik.handleBlur} />
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-buildings input-icon" viewBox="0 0 16 16">
                                                <path d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022M6 8.694 1 10.36V15h5zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5z" />
                                                <path d="M2 11h1v1H2zm2 0h1v1H4zm-2 2h1v1H2zm2 0h1v1H4zm4-4h1v1H8zm2 0h1v1h-1zm-2 2h1v1H8zm2 0h1v1h-1zm2-2h1v1h-1zm0 2h1v1h-1zM8 7h1v1H8zm2 0h1v1h-1zm2 0h1v1h-1zM8 5h1v1H8zm2 0h1v1h-1zm2 0h1v1h-1zm0-2h1v1h-1z" />
                                            </svg>
                                            {userEditFormik.touched.organization && userEditFormik.errors.organization ? (<div className='req-error'>{userEditFormik.errors.organization}</div>) : null}
                                        </div>
                                    </div>

                                    <div className="col-6 mb-3">
                                        <label htmlFor="qualification" className="form-label">Qualification</label>
                                        <div className="position-relative"><input className="form-control" type="tel" id="qualification" placeholder="Enter qualification" name="qualification" value={userEditFormik.values.qualification} onChange={userEditFormik.handleChange} onBlur={userEditFormik.handleBlur} />
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-journal-bookmark-fill input-icon" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M6 1h6v7a.5.5 0 0 1-.757.429L9 7.083 6.757 8.43A.5.5 0 0 1 6 8z" />
                                                <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2" />
                                                <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1z" />
                                            </svg>
                                            {userEditFormik.touched.qualification && userEditFormik.errors.qualification ? (<div className='req-error'>{userEditFormik.errors.qualification}</div>) : null}
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <div className="fs-3 fw-bold">Correspondence Address</div>
                                <hr />
                                <div className="row">
                                    <div className="col-4"> <label htmlFor="correspondence_Address" className="form-label"> Address</label>
                                        <div className="position-relative"><input className="form-control" type="text" id="correspondence_Address" placeholder="Enter address" name="correspondence_Address" value={userEditFormik.values.correspondence_Address} onChange={userEditFormik.handleChange} onBlur={userEditFormik.handleBlur} />
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-house-door-fill input-icon" viewBox="0 0 16 16">
                                                <path d="M6.5 14.5v-3.505c0-.245.25-.495.5-.495h2c.25 0 .5.25.5.5v3.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5Z" />
                                            </svg>
                                            {userEditFormik.touched.correspondence_Address && userEditFormik.errors.correspondence_Address ? (<div className='req-error'>{userEditFormik.errors.correspondence_Address}</div>) : null}
                                        </div>
                                    </div>

                                    <div className="col-4"> <label htmlFor="correspondence_country" className="form-label">Country</label>
                                        <div className="position-relative"><input className="form-control" type="text" id="correspondence_country" placeholder="Enter country" name="correspondence_country" value={userEditFormik.values.correspondence_country} onChange={userEditFormik.handleChange} onBlur={userEditFormik.handleBlur} />
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-house-door-fill input-icon" viewBox="0 0 16 16">
                                                <path d="M6.5 14.5v-3.505c0-.245.25-.495.5-.495h2c.25 0 .5.25.5.5v3.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5Z" />
                                            </svg>
                                            {userEditFormik.touched.correspondence_country && userEditFormik.errors.correspondence_country ? (<div className='req-error'>{userEditFormik.errors.correspondence_country}</div>) : null}
                                        </div>
                                    </div>

                                    <div className="col-4"> <label htmlFor="correspondence_zipcode" className="form-label"> Zip code</label>
                                        <div className="position-relative"><input className="form-control" type="text" id="correspondence_zipcode" placeholder="Enter zip code" name="correspondence_zipcode" value={userEditFormik.values.correspondence_zipcode} onChange={userEditFormik.handleChange} onBlur={userEditFormik.handleBlur} />
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-house-door-fill input-icon" viewBox="0 0 16 16">
                                                <path d="M6.5 14.5v-3.505c0-.245.25-.495.5-.495h2c.25 0 .5.25.5.5v3.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5Z" />
                                            </svg>
                                            {userEditFormik.touched.correspondence_zipcode && userEditFormik.errors.correspondence_zipcode ? (<div className='req-error'>{userEditFormik.errors.correspondence_zipcode}</div>) : null}
                                        </div>
                                    </div>
                                </div>

                                <br />
                                {/* <div className="fs-3 fw-bold">Work Address</div>
                                <hr />
                                <div className="row">
                                    <div className="col-4"> <label htmlFor="work_Address" className="form-label"> Address</label>
                                        <div className="position-relative"><input className="form-control" type="text" id="work_Address" placeholder="Enter address" name="work_Address" value={userEditFormik.values.work_Address} onChange={userEditFormik.handleChange} onBlur={userEditFormik.handleBlur} />
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-house-door-fill input-icon" viewBox="0 0 16 16">
                                                <path d="M6.5 14.5v-3.505c0-.245.25-.495.5-.495h2c.25 0 .5.25.5.5v3.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5Z" />
                                            </svg>
                                            {userEditFormik.touched.work_Address && userEditFormik.errors.work_Address ? (<div className='req-error'>{userEditFormik.errors.work_Address}</div>) : null}
                                        </div>
                                    </div>

                                    <div className="col-4"> <label htmlFor="work_country" className="form-label">Country</label>
                                        <div className="position-relative"><input className="form-control" type="text" id="work_country" placeholder="Enter country" name="work_country" value={userEditFormik.values.work_country} onChange={userEditFormik.handleChange} onBlur={userEditFormik.handleBlur} />
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-house-door-fill input-icon" viewBox="0 0 16 16">
                                                <path d="M6.5 14.5v-3.505c0-.245.25-.495.5-.495h2c.25 0 .5.25.5.5v3.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5Z" />
                                            </svg>
                                            {userEditFormik.touched.work_country && userEditFormik.errors.work_country ? (<div className='req-error'>{userEditFormik.errors.work_country}</div>) : null}
                                        </div>
                                    </div>

                                    <div className="col-4"> <label htmlFor="work_zipcode" className="form-label"> Zip code</label>
                                        <div className="position-relative"><input className="form-control" type="text" id="work_zipcode" placeholder="Enter zip code" name="work_zipcode" value={userEditFormik.values.work_zipcode} onChange={userEditFormik.handleChange} onBlur={userEditFormik.handleBlur} />
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-house-door-fill input-icon" viewBox="0 0 16 16">
                                                <path d="M6.5 14.5v-3.505c0-.245.25-.495.5-.495h2c.25 0 .5.25.5.5v3.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5Z" />
                                            </svg>
                                            {userEditFormik.touched.work_zipcode && userEditFormik.errors.work_zipcode ? (<div className='req-error'>{userEditFormik.errors.work_zipcode}</div>) : null}
                                        </div>
                                    </div>
                                </div>
                                <br /> */}

                                <div className="text-center d-flex ">
                                    <button type="submit" className="btn-custom w-auto mt-2" disabled={loader}>{loader ? <Spinwheel sizeClass="spin-small" /> : "Save"}</button>
                                    <div className="btn-custom w-auto ms-4 mt-2" onClick={() => { window.history.back(); }}  >Back</div>
                                </div>
                            </form>
                            <br />
                        </div>
                        <div className="col-lg-8"></div>
                    </div>
                </div >
            </div >
        </>
    );
}
