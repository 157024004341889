import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import { DrivingLicenseIntialvalue, DrivingLicenseValidation, EmployerInformationInitialValue, EmployerInformationValidation, HouseholdMemberInitialValue, HouseholdMemberValidation, NationalIdFormIntialvalue, NationalIdFormValidation, VisaIntialValue, VisaValidation, WorkpermitCiApplicatonFormIntialValue, WorkpermitCiApplicatonFormValidation, WorkpermitCiCardIntialValue, WorkpermitCiCardValidation, otherInitialValue, otherValidation, passportIntialValue, passportValidationSchma, userDocumentsInitialValues, userDocumentsValidationSchema } from '../../common/Validation';
import { deleteUserUploadDocumentApi, getAllDocumentCategoryApi, getUsersDocumentByCategoryIdApi, removeUserDocumentsApi, uploadAndUpdateUsersDocumentApi, uploadImage } from '../../../utils/ApiEndpointFunctions';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import RemoveModal from '../../common/RemoveModal';
import Select from 'react-select';
import WorkpermitCiCard from './forms/WorkpermitCiCard';
import WorkpermitApplicationFrom from './forms/WorkpermitApplicationFrom';
import VisaForm from './forms/VisaForm';
import PassportForm from './forms/PassportForm';
import NationalIdForm from './forms/NationalIdForm';
import HouseholdDetailForm from './forms/HouseholdDetailForm';
import EmployerInfoForm from './forms/EmployerInfoForm';
import DriverLicence from './forms/DriverLicence';
import WorkpermitApplicationTable from './tables/WorkpermitApplicationTable';
import WorkpermitCiCardTable from './tables/WorkpermitCiCardTable';
import VisaTable from './tables/VisaTable';
import PassportTable from './tables/PassportTable';
import NationalIdTable from './tables/NationalIdTable';
import HouseHoldDetailTable from './tables/HouseHoldDetailTable';
import EmployerInfoTable from './tables/EmployerInfoTable';
import DriverLicenceTable from './tables/DriverLicenceTable';
import OtherForm from './forms/OtherForm';
import OtherTable from './tables/OtherTable';


function DocumentTable(props) {
  const { tableDat, getUserDetails } = props
  const [tableData,setTableData] = useState([]);
  const [addDocLoader, setAddDocLoader] = useState(false);
  const params = useParams();
  const [documentCategory,setDocumentCategory] = useState();
  const [id, setId] = useState(params?.id)
  const [selectedCategory, setSelectedCategory] = useState();
  const [tableSpin, setTableSpin] = useState(true);
  const [isEdit, setIsEdit] = useState(false)
  const [removeData, setRemoveData] = useState();

  const handlefile = async (e, formik) => {
    try {
      
      const file = e.target.files[0];
      if (file) {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("type", 2)
        formData.append("folder", "document")
        const res = await uploadImage('/users/fileUpload', formData)
        if (res.status === 200 && res.data?.status === 1) {
          formik.setFieldValue(`file`, res.data?.data?.fileName)
          toast.success("File uploaded")
        }
      }
    } catch (error) {
      console.log(error)
    }
    
  }

  const removeHandler = async () => {
    try {
      const res = await deleteUserUploadDocumentApi(removeData?._id);
      if (res.status === 200) {
        toast.success(res.data?.message)
        getUsersDocumentByCategoryId()
      }
    } catch (error) {
      console.log(error)
      toast.error("Something went wrong")
    }
  }

 

  const getAllDocumentCategory = async () => {
    try {
      const res = await getAllDocumentCategoryApi();
      if (res.status === 200) {
        
        let resultData = res.data?.data?.map(ele => ({ label: ele.docName, value: ele }))
        setDocumentCategory(resultData)
        categorySelectHandler(resultData[0].value)
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong")
    }
  }

  const getUsersDocumentByCategoryId = async () => {
   try {
     if (!selectedCategory?._id) return;
     setTableSpin(true)
     const res = await getUsersDocumentByCategoryIdApi(id, selectedCategory?._id);
     if (res.status === 200) {
       setTableData(res.data?.data);
     }
   } catch (error) {
    toast.error("Something went wrong")
   }
    setTableSpin(false);
  }

  useEffect(() => {
    getAllDocumentCategory()
  }, [id])


  useEffect(() => {
    getUsersDocumentByCategoryId()
  }, [selectedCategory])

 
  const submitHandler = async (values) => {
    try {
      
      setAddDocLoader(true)
      const res = await uploadAndUpdateUsersDocumentApi({ ...values, userId: id, categoryId: documentsFormik.values.categoryId?._id })
      if (res.status === 200) {
        toast.success(res.data?.message)
        getUserDetails(id)
        getUsersDocumentByCategoryId()
        document.getElementById("ManageStatusModal").click()
      }

    } catch (error) {
      console.log(error)
      toast.error(error?.response?.data?.message ? error?.response?.data?.message : "something went wrong")
    }
    setAddDocLoader(false)
  }

  const categorySelectHandler = (value) => {
    setSelectedCategory(value)
    documentsFormik.setFieldValue("categoryId", value)
  }

  const formikResetHandler = () => {
    Boolean("6683d2ce59dc0f175ad0f7b3" === selectedCategory?._id) && formikWorkpermitApplication.resetForm();
    Boolean("6683d96059dc0f175ad0fb36" === selectedCategory?._id) && formikWorkpermitCiCard.resetForm();
    Boolean("6630d19b5e582e7a5013b92d" === selectedCategory?._id) && formikVisaForm.resetForm();
    Boolean("661e048f887564589f8cca6a" === selectedCategory?._id) && formikPassportForm.resetForm();
    Boolean("6630ab705e582e7a5013b55f" === selectedCategory?._id) && formikNationalIdForm.resetForm();
    Boolean("669a859cba8ab59bee9cda17" === selectedCategory?._id) &&formikHouseholdDetailForm.resetForm();
    Boolean("669a869dae51b8d2aa9000ab" === selectedCategory?._id) && formikEmployerInfoForm.resetForm();
    Boolean("663246b8a636a791fc74efd9" === selectedCategory?._id) && formikDriverLicence.resetForm();
    Boolean("669a86e3ae51b8d2aa9000d0" === selectedCategory?._id) &&formikOtherForm.resetForm();
  }

  const documentsFormik = useFormik({
    initialValues: userDocumentsInitialValues,
    onSubmit: submitHandler,
    validationSchema: userDocumentsValidationSchema
  });

  const formikWorkpermitApplication = useFormik({
    initialValues: WorkpermitCiApplicatonFormIntialValue,
    onSubmit: submitHandler,
    validationSchema: WorkpermitCiApplicatonFormValidation
  })

  const formikWorkpermitCiCard = useFormik({
    initialValues: WorkpermitCiCardIntialValue,
    onSubmit: submitHandler,
    validationSchema: WorkpermitCiCardValidation
  })

  const formikVisaForm = useFormik({
    initialValues: VisaIntialValue,
    onSubmit: submitHandler,
    validationSchema: VisaValidation
  })

  const formikPassportForm = useFormik({
    initialValues: passportIntialValue,
    onSubmit: submitHandler,
    validationSchema: passportValidationSchma
  })

  const formikOtherForm = useFormik(({
    initialValues: otherInitialValue,
    onSubmit: submitHandler,
    validationSchema: otherValidation
  }))

  const formikNationalIdForm = useFormik(({
    initialValues: NationalIdFormIntialvalue,
    onSubmit: submitHandler,
    validationSchema: NationalIdFormValidation
  }))

  const formikHouseholdDetailForm = useFormik(({
    initialValues: HouseholdMemberInitialValue,
    onSubmit: submitHandler,
    validationSchema: HouseholdMemberValidation
  }))

  const formikEmployerInfoForm = useFormik(({
    initialValues: EmployerInformationInitialValue,
    onSubmit: submitHandler,
    validationSchema: EmployerInformationValidation
  }))

  const formikDriverLicence = useFormik(({
    initialValues: DrivingLicenseIntialvalue,
    onSubmit: submitHandler,
    validationSchema: DrivingLicenseValidation
  }))



  
  

  return (
    <><div className="card p-3  table-responsive" style={{minHeight:"400px"}} >
     
      <div className='d-flex justify-content-between'>
        <div className="" style={{minWidth:"250px"}}>
          <Select
            className="basic-single mb-2 w-100"
            classNamePrefix="select"
            isSearchable
            value={selectedCategory ? { value: selectedCategory, label: selectedCategory?.docName } : null}
            options={documentCategory}
            onChange={(ele) => { categorySelectHandler(ele.value) }}
          />
        </div>
        <div data-bs-toggle="modal" data-bs-target={`#ManageStatusModal`} className="btn-custom w-auto ms-0 mt-0 mb-3 " onClick={(e) => { setIsEdit(false); formikResetHandler(); }} >Add</div>
        </div>
        <hr />
      {Boolean("6683d2ce59dc0f175ad0f7b3" === selectedCategory?._id) && <WorkpermitApplicationTable formik={formikWorkpermitApplication} tableData={tableData} tableSpin={tableSpin} setIsEdit={setIsEdit} setRemoveData={setRemoveData} />}
      {Boolean("6683d96059dc0f175ad0fb36" === selectedCategory?._id) && <WorkpermitCiCardTable formik={formikWorkpermitCiCard} tableData={tableData} tableSpin={tableSpin} setIsEdit={setIsEdit} setRemoveData={setRemoveData} />}
      {Boolean("6630d19b5e582e7a5013b92d" === selectedCategory?._id) && <VisaTable formik={formikVisaForm} tableData={tableData} tableSpin={tableSpin} setIsEdit={setIsEdit} setRemoveData={setRemoveData} />}
      {Boolean("661e048f887564589f8cca6a" === selectedCategory?._id) && <PassportTable formik={formikPassportForm} tableData={tableData} tableSpin={tableSpin} setIsEdit={setIsEdit} setRemoveData={setRemoveData} />}
      {Boolean("6630ab705e582e7a5013b55f" === selectedCategory?._id) && <NationalIdTable formik={formikNationalIdForm} tableData={tableData} tableSpin={tableSpin} setIsEdit={setIsEdit} setRemoveData={setRemoveData} />}
      {Boolean("669a859cba8ab59bee9cda17" === selectedCategory?._id) && <HouseHoldDetailTable formik={formikHouseholdDetailForm} tableData={tableData} tableSpin={tableSpin} setIsEdit={setIsEdit} setRemoveData={setRemoveData} />}
      {Boolean("669a869dae51b8d2aa9000ab" === selectedCategory?._id) && <EmployerInfoTable formik={formikEmployerInfoForm} tableData={tableData} tableSpin={tableSpin} setIsEdit={setIsEdit} setRemoveData={setRemoveData} />}
      {Boolean("663246b8a636a791fc74efd9" === selectedCategory?._id) && <DriverLicenceTable formik={formikDriverLicence} tableData={tableData} tableSpin={tableSpin} setIsEdit={setIsEdit} setRemoveData={setRemoveData} />}
      {Boolean("669a86e3ae51b8d2aa9000d0" === selectedCategory?._id) && <OtherTable formik={formikOtherForm} tableData={tableData} tableSpin={tableSpin} setIsEdit={setIsEdit} setRemoveData={setRemoveData} />}
      

      <div className="modal fade" id="ManageStatusModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0">
              <button type="button" className="btn-close block-close" data-bs-dismiss="modal" aria-label="Close" ></button>
            </div>
            <div className="modal-body ">
              <div className="col-lg-12" style={{ zIndex: "1" }}>
                <form action="" className='p-4' >
                  <h2 style={{ textAlign: "left" }}>
                    Document form
                  </h2>

                  <Select
                    className="basic-single mb-2"
                    classNamePrefix="select"
                    isSearchable
                    value={documentsFormik.values.categoryId ? { value: documentsFormik.values.categoryId, label: documentsFormik.values.categoryId?.docName }:null}
                    name="categoryId"
                    options={documentCategory}
                    isDisabled={isEdit}
                    onChange={(ele) => { documentsFormik.setFieldValue("categoryId", ele.value)}}
                  />
                  {documentsFormik.touched.categoryId && documentsFormik.errors.categoryId ? (<div className='req-error'>{documentsFormik.errors.categoryId}</div>) : null}
                  
                  
                  {Boolean("6683d2ce59dc0f175ad0f7b3" === documentsFormik.values.categoryId?._id) && <WorkpermitApplicationFrom formik={formikWorkpermitApplication} handlefile={handlefile} addDocLoader={addDocLoader} submitHandler={submitHandler} />}
                  {Boolean("6683d96059dc0f175ad0fb36" === documentsFormik.values.categoryId?._id) && <WorkpermitCiCard formik={formikWorkpermitCiCard} documentsFormik={documentsFormik} handlefile={handlefile} addDocLoader={addDocLoader} submitHandler={submitHandler} />}
                  {Boolean("6630d19b5e582e7a5013b92d" === documentsFormik.values.categoryId?._id) && <VisaForm formik={formikVisaForm} documentsFormik={documentsFormik} handlefile={handlefile} addDocLoader={addDocLoader} submitHandler={submitHandler} />}
                  {Boolean("661e048f887564589f8cca6a" === documentsFormik.values.categoryId?._id) && <PassportForm formik={formikPassportForm} documentsFormik={documentsFormik} handlefile={handlefile} addDocLoader={addDocLoader} submitHandler={submitHandler} />}
                  {Boolean("6630ab705e582e7a5013b55f" === documentsFormik.values.categoryId?._id) && <NationalIdForm formik={formikNationalIdForm} documentsFormik={documentsFormik} handlefile={handlefile} addDocLoader={addDocLoader} submitHandler={submitHandler}/>}
                  {Boolean("669a859cba8ab59bee9cda17" === documentsFormik.values.categoryId?._id) && <HouseholdDetailForm formik={formikHouseholdDetailForm} documentsFormik={documentsFormik} handlefile={handlefile} addDocLoader={addDocLoader} submitHandler={submitHandler} />}
                  {Boolean("669a869dae51b8d2aa9000ab" === documentsFormik.values.categoryId?._id) && <EmployerInfoForm formik={formikEmployerInfoForm} documentsFormik={documentsFormik} handlefile={handlefile} addDocLoader={addDocLoader} submitHandler={submitHandler} />}
                  {Boolean("663246b8a636a791fc74efd9" === documentsFormik.values.categoryId?._id) && <DriverLicence formik={formikDriverLicence} documentsFormik={documentsFormik} handlefile={handlefile} addDocLoader={addDocLoader} submitHandler={submitHandler}  />}
                  {Boolean("669a86e3ae51b8d2aa9000d0" === documentsFormik.values.categoryId?._id) && <OtherForm formik={formikOtherForm} documentsFormik={documentsFormik} handlefile={handlefile} addDocLoader={addDocLoader} submitHandler={submitHandler} />}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <RemoveModal removeHandler={removeHandler} modalId="removedoc" message={<>Are you sure you want to<br /> remove this?</>} />
    </div></>
  )
}

export default DocumentTable