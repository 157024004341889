import { useFormik } from 'formik';
import React, { useCallback, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useAdminContext } from '../../../context/adminContext';
import { ImageUrl } from '../../../utils/BaseUrl';
import { companyPostInitialValues, companyPostValidationSchema } from '../../common/Validation';
import { addCompanyApi, addIndustryAndSkillsApi, getAllIndustryAndSkillsByType, uploadImage } from '../../../utils/ApiEndpointFunctions';
import toast from 'react-hot-toast';
import Spinwheel from '../../common/Spinwheel';
import { debounce } from 'lodash';
import AsyncCreatableSelect from "react-select/async-creatable";
import Header from '../../common/Header';

const AddCompany = () => {
 
  const { adminDetail, } = useAdminContext();
  const [addCompanySpin, setaddCompanySpin] = useState(false)
  const [fileTypeError, setFileTypeError] = useState("")
  const location = useNavigate();

  const getAllIndustry = async () => {
    try {
      const res = await getAllIndustryAndSkillsByType("industry")
      if (res.status === 200) {
        let skills = res.data?.data
        return skills?.map((ele) => {
          return { value: ele.name, label: ele.name }
        })
      }
    } catch (error) {
    }
  }

  const industryDebaunce = useCallback(
    debounce((inputText, callback) => {
      getAllIndustry(inputText).then((options) => callback(options));
    }, 500),
    []
  );

  const handleNewIndustry = async (newData) => {
    try {
      if (newData?.__isNew__) {
        let payload = { type: 'industry', name: newData.value }
       await addIndustryAndSkillsApi(payload);
      }
    } catch (error) {
      console.log(error)
    }
  }



  const handleSubmit = async (values) => {
    setaddCompanySpin(true)
    setTimeout(async () => {
      try {
        const res = await addCompanyApi(values)
        if (res.status === 200) {
          toast.success(res.data?.message)
          setaddCompanySpin(false)
          location("/job", { replace: true })
        }
      } catch (error) {
        toast.error(error?.response?.data?.message)
        setaddCompanySpin(false)
      }
    }, 500)
  }
  const companyFormik = useFormik({
    initialValues: companyPostInitialValues,
    onSubmit: handleSubmit,
    validationSchema: companyPostValidationSchema
  });

  const profileChange = async (e) => {
    const file = e.target.files[0];
    companyFormik.setFieldTouched(`logo`,true)
    const types = ['image/jpeg', 'image/png', 'image/gif']
    if(file){
      if (types.includes(file?.type)) {
        setFileTypeError("")
        const formData = new FormData();
        formData.append("file", file);
        formData.append("type", 5)
        formData.append("folder", "company")
        const res = await uploadImage('/users/fileUpload', formData)
        if (res.status === 200 && res.data?.status === 1) {
          companyFormik.setFieldValue("logo", res.data?.data?.fileName)
          toast.success("File uploaded")
        }
      } else {
        // formik.setFieldValue("logo", "")
        setFileTypeError("Must be image only")
      }
    }
    
  }

  return (
    <>
      {/*<Sidebar />*/}
      <div className="main-content vh-100 vh_md-auto overflow-y-a">
        <Header title="Jobs" />
        <div className="container-fluid">
          <div className="row">
            <nav className="mb-5">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">
                    <i className="fe fe-home"></i> Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/job" className="breadcrumb-item">
                    Job
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Add-Company
                </li>
              </ol>
            </nav>
            <div>
              <h2 className="report">Company Details</h2>
            </div>
            <hr />

            <div >
              <form action="" onSubmit={companyFormik.handleSubmit} >
                <div className="row">
                  <div className="col-4"><div className="">
                    <label htmlFor="profile" className="form-label">Company Logo</label>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="position-relative">
                        {<img src={companyFormik.values.logo ? ImageUrl + companyFormik.values.logo : process.env.PUBLIC_URL + "/images/person-circle.svg"} width="70px" height="70px" className="rounded-circle" alt="Selected file" />}
                        {
                          <>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-camera pro-camera" viewBox="0 0 16 16">
                              <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1v6zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z" />
                              <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x xmark" viewBox="0 0 16 16" onClick={() => { companyFormik.setFieldValue("logo", "others/person-circle.svg") }} >
                              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                            </svg>
                          </>
                        }
                      </div>
                      <div className="btn_upload">
                        <input type="file" id="profile" className="form-control" name="logo" accept="image/*" onChange={profileChange} />
                        Upload
                      </div>
                    </div>
                    {companyFormik.touched.logo && companyFormik.errors.logo ? (<div className='req-error'><br />{fileTypeError?.length > 0 ? fileTypeError : companyFormik.errors.logo}</div>) : null}
                  </div></div>
                  <div className="col-4"><div className="">
                    <label
                      className="form-label text-start d-block"
                      htmlFor="industry"
                    >
                      Industry
                    </label>
                    <AsyncCreatableSelect
                      cacheOptions
                      defaultOptions
                      isSearchable={true}
                      loadOptions={industryDebaunce}
                      createOptionPosition="first"
                      className="basic-multi-select"
                      name="industry"
                      id='industry'
                      placeholder="Select Industry"
                      onChange={(selectedOptions) => {
                        handleNewIndustry(selectedOptions)
                        companyFormik.setFieldValue("industry", selectedOptions?.value)
                      }}
                      onBlur={companyFormik.handleBlur}
                      maxMenuHeight={9 * 36}
                    />
                    {companyFormik.touched.industry && companyFormik.errors.industry ? (<div className='req-error'>{companyFormik.errors.industry}</div>) : null}
                  </div></div>
                  <div className="col-4"><div className="">
                    <label htmlFor="name" className="form-label">Company Name</label>
                    <div className="position-relative">
                      <input className="form-control" type="text" id="name" placeholder="Enter your company name" name="name" value={companyFormik.values?.name} onChange={companyFormik.handleChange} onBlur={companyFormik.handleBlur} />
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-fill input-icon" viewBox="0 0 16 16">
                        <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                      </svg>
                      {companyFormik.touched.name && companyFormik.errors.name ? (<div className='req-error'>{companyFormik.errors.name}</div>) : null}
                    </div>
                  </div></div>
                </div>

                <div className="row">
                  <div className="col-4">
                    <div>
                      <label htmlFor="emailAddress" className="form-label">Email</label>
                      <div className="position-relative">
                        <input className="form-control" type="email" id="emailAddress" placeholder="Enter your email" name="emailAddress" value={companyFormik.values.emailAddress} onChange={companyFormik.handleChange} onBlur={companyFormik.handleBlur} />
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={16}
                          height={16}
                          fill="currentColor"
                          className="bi bi-envelope-fill input-icon"
                          viewBox="0 0 16 16"
                        >
                          <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z" />
                        </svg>
                        {companyFormik.touched.emailAddress && companyFormik.errors.emailAddress ? (<div className='req-error'>{companyFormik.errors.emailAddress}</div>) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div>
                      <label htmlFor="phoneNumber" className="form-label">Phone Number</label>
                      <div className="position-relative">
                        <input className="form-control" type="tel" id="phoneNumber" placeholder="Enter your phone number" name="phoneNumber" value={companyFormik.values.phoneNumber} onChange={companyFormik.handleChange} onBlur={companyFormik.handleBlur} />

                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-telephone-fill input-icon" viewBox="0 0 16 16">
                          <path fillRule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                        </svg>
                        {companyFormik.touched.phoneNumber && companyFormik.errors.phoneNumber ? (<div className='req-error'>{companyFormik.errors.phoneNumber}</div>) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div>
                      <label htmlFor="url" className="form-label">Website</label>
                      <div className="position-relative">
                        <input className="form-control" type="text" id="url" placeholder="https://example.com" name='url' value={companyFormik.values.url} onChange={companyFormik.handleChange} onBlur={companyFormik.handleBlur} />
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-globe input-icon" viewBox="0 0 16 16">
                          <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 0 0 5.145 4H7.5V1.077zM4.09 4a9.267 9.267 0 0 1 .64-1.539 6.7 6.7 0 0 1 .597-.933A7.025 7.025 0 0 0 2.255 4H4.09zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5h2.49zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5H4.847zM8.5 5v2.5h2.99a12.495 12.495 0 0 0-.337-2.5H8.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5H4.51zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5H8.5zM5.145 12c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12H5.145zm.182 2.472a6.696 6.696 0 0 1-.597-.933A9.268 9.268 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472zM3.82 11a13.652 13.652 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5H3.82zm6.853 3.472A7.024 7.024 0 0 0 13.745 12H11.91a9.27 9.27 0 0 1-.64 1.539 6.688 6.688 0 0 1-.597.933zM8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855.173-.324.33-.682.468-1.068H8.5zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm2.802-3.5a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5h2.49zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 0 0-3.072-2.472c.218.284.418.598.597.933zM10.855 4a7.966 7.966 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4h2.355z" />
                        </svg>
                        {companyFormik.touched.url && companyFormik.errors.url ? (<div className='req-error'>{companyFormik.errors.url}</div>) : null}
                      </div>
                    </div>
                  </div>
                </div>

               
                <div className="row">
                  <div className="col-4">
                    <div>
                      <label htmlFor="description" className="form-label">Description</label>
                      <div className="position-relative">

                        <textarea className="form-control" id='description' value={companyFormik.values.description} onChange={companyFormik.handleChange} onBlur={companyFormik.handleBlur} ></textarea>

                        {companyFormik.touched.description && companyFormik.errors.description ? (<div className='req-error'>{companyFormik.errors.description}</div>) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div>
                      <label htmlFor="locality" className="form-label">Location/Locality</label>
                      <div className="position-relative">
                        <input className="form-control" type="text" id="locality" placeholder="Enter your locality" name="locality" value={companyFormik.values.locality} onChange={companyFormik.handleChange} onBlur={companyFormik.handleBlur} />
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-geo-alt input-icon" viewBox="0 0 16 16">
                          <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                          <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                        </svg>
                        {companyFormik.touched.locality && companyFormik.errors.locality ? (<div className='req-error'>{companyFormik.errors.locality}</div>) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="text-center d-flex justify-content-around">
                      <button type="submit" className="btn-custom w-auto mt-2" disabled={addCompanySpin} >{addCompanySpin ? <Spinwheel sizeClass="spin-small" /> : "Save"}</button>
                      <div className="btn-custom w-auto mt-2" onClick={() => { window.history.back(); }}  >Back</div>
                    </div>
                  </div>
                </div>
              
                

              </form>
              <br />
            </div>
            <div className="col-lg-8"></div>
          </div>


        </div>
      </div>
    </>
  );
}

export default AddCompany