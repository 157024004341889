import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { useAdminContext } from '../../../../context/adminContext';
import { getAllBusRoutesPGApi, getAllBusTicketPGApi,  getAllHotelPGApi,  removeBusRouteApi,  removeHotelByIdApi,  updateBusTicketStatusApi,} from '../../../../utils/ApiEndpointFunctions';
import Header from '../../../common/Header';
import { ticketStatusInitalValue, ticketStatusValidationSchema } from "../../../common/Validation"
import Pagination from '../../../common/Pagination';
import Spinwheel from '../../../common/Spinwheel';
import { ImageUrl } from '../../../../utils/BaseUrl';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import UpdateTicketStatus from '../../../common/updateTicketStatus';
import { toast } from 'react-hot-toast';
import { debounce } from 'lodash';
import moment from 'moment';
import RemoveModal from '../../../common/RemoveModal';

function AllHotel() {
  const navigate = useNavigate()
  const [tableSpin, setTableSpin] = useState(true);
  const [data, setData] = useState([]);
  let [selectedPage, setSelectPage] = useState(0);
  const [page, setPage] = useState(0);
  const hotelModalId = "hotelModalId"
  const ticketStatusModalId = "ticketStatusModalId"
  const removeHotelModalId = "removeHotelModalId"
  const [selectedBusTicket, setSelectedBusTicket] = useState(null)
  const [selectedHotel,setSelectedHotel] = useState(null)
  const [updateTicket, setUpdateTicket] = useState(false)
  const limit = 10;

  const getAllHotelList = async (selectedPage, search) => {
    try {
      setTableSpin(false)
      const res = await getAllHotelPGApi(search || "", selectedPage + 1, limit)
      if (res.status === 200) {
        setData(res.data?.data?.data)
        const totaldata = res.data?.data?.totalData

        setPage(Math.ceil(totaldata / limit));
      }
      setTableSpin(true)
    } catch (error) {
      setTableSpin(true)
    }
  }

  const handlePageClick = (event) => {
    setSelectPage(event.selected);
    getAllHotelList(event.selected)
  };

  const handleSubmit = async (values) => {
    const payload = {
      ...values,
      _id: selectedBusTicket,
    }
    try {
      setUpdateTicket(true)
      const res = await updateBusTicketStatusApi(payload);
      if (res?.status === 200) {
        toast.success(res.data?.message)
        setUpdateTicket(false)
        getAllHotelList(0)
        document.getElementById(ticketStatusModalId).click()
        setSelectedBusTicket(null)
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setUpdateTicket(false)
    }
  }


  const formik = useFormik({
    initialValues: ticketStatusInitalValue,
    onSubmit: handleSubmit,
    validationSchema: ticketStatusValidationSchema,
  })
  const onChange = async (e) => {
    let search = e.target.value;
    getAllHotelList(0, search)
  };

  const debouncedOnChange = debounce(onChange, 500);


  const handleRemoveHotel = async () => {
   try {
     const res = await removeHotelByIdApi(selectedHotel);
     if (res?.status === 200) {
       toast.success(res.data?.message)
       getAllHotelList(0)
       document.getElementById(removeHotelModalId).click()
     }
   } catch (error) {
     toast.error(error?.response?.data?.message);
   }
 }

  useEffect(() => {
    getAllHotelList(selectedPage)
  }, [])




  return (
    <>
      {/*<Sidebar />*/}
      <div className="main-content vh-100 vh_md-auto overflow-y-a">
        <Header title="Hotel" />
        {/* CARDS */}
        <div className="container-fluid">
          <div className="row">
            <nav className="mb-5">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">
                    <i className="fe fe-home"></i> Dashboard
                  </Link>
                </li>
                <Link to="/ticket/hotel/booking" className="breadcrumb-item active" aria-current="page">
                Hotel Booking
                </Link>
                <li className="breadcrumb-item active" aria-current="page">
                  Hotels
                </li>
              </ol>
            </nav>
            <div className="mb-4" style={{ textAlign: "end" }}>
              <div className='d-flex justify-content-end gap-2' style={{ display: "-webkit-inline-box" }}>
                <div className='d-flex gap-2 align-items-center justify-content-center'>
                <button onClick={() => navigate(`/ticket/hotel/carousel`)} className="btn-custom w-auto" style={{ cursor: "pointer" }}>
                    Carousel
                  </button>
                  <button onClick={() => navigate(`/ticket/hotel/hotel`)} className="btn-custom w-auto" style={{ cursor: "pointer" }}>
                    Add
                  </button>
                  <div className="position-relative">
                    <input
                      className="form-search me-3"
                      type="search"
                      name=""
                      onChange={debouncedOnChange}
                      placeholder="Search"
                    />
                    <img
                      className="users-search"
                      src="/images/Icon feather-search.svg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <hr />

            <div className="card p-3 table-responsive" >
              <table className="table table-hover table-nowrap card-table">
                <thead>
                  <tr>
                    <th>
                      <span
                        className="list-sort text-muted"
                      >
                        S.No
                      </span>
                    </th>
                    <th>
                      <span
                        className="list-sort text-muted"
                      >
                        Name
                      </span>
                    </th>
                    <th>
                      <span
                        className="list-sort text-muted"
                      >
                        Property type
                      </span>
                    </th>
                  
                    <th>
                      <span
                        className="list-sort text-muted"
                      >
                        Minimum price
                      </span>
                    </th>
                    <th>
                      <span
                        className="list-sort text-muted"
                      >
                        Star rating
                      </span>
                    </th>
                    <th>
                      <span
                        className="list-sort text-muted"
                      >
                        Free cancellation
                      </span>
                    </th>
                    <th>
                      <span
                        className="list-sort text-muted"
                      >
                        Address
                      </span>
                    </th>
                    <th></th>


                  </tr>
                </thead>
                {
                  tableSpin ?
                    <tbody className="list fs-base">
                      {data?.length > 0 ? data?.map((ele, index) => {
                        return (<tr key={index} >
                          <td>{index + 1}</td>
                           <div className='position-relative d-flex'>
                              <div className="avatar avatar-xs align-middle me-2">
                                 <img
                                    className="avatar-img rounded-circle"
                                    src={ele?.image ? ImageUrl + encodeURIComponent(ele?.image) : process.env.PUBLIC_URL + "/images/person-circle.svg"}
                                    alt=""
                                 />
                              </div>
                              <p className='mb-0 capitalize'>{ele?.name?.length > 30 ? <span>
                              {ele?.name?.substr(0, 30)}...
                              <div className="tooltip1 item-name text-reset" ></div>
                              <div className='wrapper'>
                                <div className="tooltip1-text">{ele?.name}</div>
                              </div>
                            </span> :
                              <span>{ele?.name || "-"}</span>}</p>
                           </div>
                          <td className='position-relative'>
                            {ele?.propertyType?.length > 30 ? <span>
                              {ele?.propertyType?.substr(0, 30)}...
                              <div className="tooltip1 item-name text-reset" ></div>
                              <div className='wrapper'>
                                <div className="tooltip1-text">{ele?.propertyType}</div>
                              </div>
                            </span> :
                              <span>{ele?.propertyType || "-"}</span>}

                          </td>
                      
                          <td className='position-relative'>
                              <span>{ele?.minimumPrice || "-"}</span>
                          </td>
                          <td className='position-relative'>
                            <span>{ele?.starRating || "-"}</span>
                          </td>
                          <td className='position-relative'>
                            <span>{ele?.isFreeCancellation ? "Yes" : "No"}</span>
                          </td>
                          <td className='position-relative'>
                            {ele?.address?.length > 30 ? <span>
                              {ele?.address?.substr(0, 30)}...
                              <div className="tooltip1 item-name text-reset" ></div>
                              <div className='wrapper'>
                                <div className="tooltip1-text">{ele?.address}</div>
                              </div>
                            </span> :
                              <span>{ele?.address || "-"}</span>}
                          </td>
                          <td className="text-end">
                            {ele?.status?.toLowerCase() != "transferred" &&
                              <div className="dropdown">
                                <span
                                  className="dropdown-ellipses dropdown-toggle"
                                  role="button"
                                  data-bs-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <i className="fe fe-more-vertical" />
                                </span>
                                <div className="dropdown-menu dropdown-menu-end">
                                <div data-bs-toggle="modal" data-bs-target={`#${removeHotelModalId}`} className='dropdown-item' style={{ cursor: "pointer" }} onClick={() => { setSelectedHotel(ele?._id) }}>
                                    Remove
                                  </div>
                                  <Link to={`/ticket/hotel/hotel/${ele?._id}`} className='dropdown-item'>
                                    View /edit
                                  </Link>
                                  <Link to={`/ticket/hotel/hotel/all-room/${ele?._id}`} className='dropdown-item'>
                                    View / add room
                                  </Link>
                                </div>
                              </div>}
                          </td>
                        </tr>
                        )
                      }) : <tr align="center">
                        <td colSpan="9">
                          <h1>
                            Data Not Found
                          </h1>
                        </td>
                      </tr>}
                    </tbody> : (
                      <tbody>
                        <tr align="center">
                          <td colSpan="9">
                            <Spinwheel />
                          </td>
                        </tr>
                      </tbody>
                    )
                }
              </table>
            </div>
            <div className="d-flex justify-content-center">
              {data?.length > 0 ? <Pagination
                clickHandler={handlePageClick}
                page={page}
                selectedPage={selectedPage}
              />
                : null}
            </div>
          </div>
          <RemoveModal removeHandler={handleRemoveHotel} modalId={removeHotelModalId} message={"Are you sure you want to remove?"} />
        </div>
      </div>
    </>
  )
}

export default AllHotel