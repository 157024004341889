import React from 'react'
import Select from 'react-select';

function AddCrouselModal(props) {
  const {formik, modalId,title, options, currentValue} = props
  return (
    <div className="modal fade" id={modalId} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-md modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header border-0">
            <button type="button" className="btn-close block-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body  block-user">
            <form action="" className="text-start" onSubmit={formik.handleSubmit} >
              <label htmlFor="name" className="form-label">{title}</label>
              <Select
                className="basic-single"
                classNamePrefix="select"
                // isLoading={true}
                isSearchable
                name="color"
                options={options}
                value={currentValue}
                onChange={(ele) => {formik?.setFieldValue("id",ele.value)}}
              />
              {formik.touched.id && formik.errors.id ? (<div className='req-error'>{formik.errors.id}</div>) : null}
         
              
             
             
              <br />
              <div className="text-center d-flex justify-content-center">
                <button type="submit" className="btn-custom w-auto m-2" >{"Save"}</button>
                <button type="button" className="btn-custom w-auto m-2" data-bs-dismiss='modal' >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddCrouselModal