import React, { useMemo, useRef, useState } from 'react'
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { useAdminContext } from '../../../context/adminContext';

import { ImageUrl } from '../../../utils/BaseUrl';
import Header from '../../common/Header';
import JoditEditor from 'jodit-react';
import { useFormik } from 'formik';
import { pageManagementIntialValue, pageMangagementValidationSchema } from '../../common/Validation';
import { addOrUpdatePageByNameApi, getPageByNameApi } from '../../../utils/ApiEndpointFunctions';
import Spinwheel from '../../common/Spinwheel';


function PageManagement() {
  const { adminDetail } = useAdminContext();
  const [selectedPage, setSelectPage] = useState(0);
  const [pageData, setPageData] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [loader, setLoader] = useState(true)
  const [addPageLoader, setAddPageLoader] = useState(false)
  const [pageType, setPageType] = useState(["About us", "Terms and conditions", "Privacy policy"]);
  const [type, setType] = useState("About us")


  const editor = useRef(null);

  const config = useMemo(() => {
    return {
      disablePlugins: ['Find', "Preview", "fullsize", "Change", "About", 'Print', "image", "file", "spellcheck", "selectall", "copyformat", "table", "hr", "symbols", "lineHeight", "classSpan", "paragraph"],
      removeButtons: ['undo', 'redo', 'Print', 'find', "video", "cut", "copy", "paste", "image", "source", "symbols", "lineHeight", "selectall", "paragraph", "superscript", "subscript"],
      readonly: false,
      placeholder: 'Start writing...',
      askBeforePasteFromWord: false,
      askBeforePasteHTML: false,
      enter: "br",
      hidePoweredByJodit: true
    }
  }, []
  );

  const getPages = async () => {
    try {
      setLoader(true)
      const res = await getPageByNameApi(type);
      if (res?.status === 200) {
        setPageData(res.data?.data)
        formik.setFieldValue("content", res.data?.data?.content)
      }
    } catch (error) {
      console.log(error);
    }
    setLoader(false)
  }

  const handleSubmit = async (values) => {
    try {
      setAddPageLoader(true)
      const res = await addOrUpdatePageByNameApi(values)
      if (res?.status === 200) {
        toast.success(res.data?.message)
        // getPages()
        setIsEdit(false)
        setPageData(res.data?.data)
      }
    } catch (error) {
      toast.error(error.message)
    }
    setAddPageLoader(false)

  }

  const formik = useFormik({
    initialValues: pageManagementIntialValue,
    onSubmit: handleSubmit,
    validationSchema: pageMangagementValidationSchema
  })

  useEffect(() => {
    getPages()
  }, [type])



  return (
    <>
      {/*<Sidebar />*/}
      <div className="main-content vh-100 vh_md-auto overflow-y-a">
        <Header title="Page management" />
        <div className="container-fluid">
          <div className="row">
            <nav className="mb-5">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">
                    <i className="fe fe-home"></i> Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  pages
                </li>
              </ol>
            </nav>
            <div className="mb-4" style={{ textAlign: "end" }}>

            </div>
            <hr />

            <div className="card p-3 shadow">
              <nav>
                <div className="nav nav-tabs mb-3" id="nav-tab" role="tablist">

                  {pageType?.map((ele, index) => {

                    return (
                      <button key={index} className={`nav-link me-5 ${index === 0 ? "active" : ""}`} id={`nav-tab-${ele}`} data-bs-toggle="tab" data-bs-target={`#nav-tab-${ele}`} type="button" role="tab" aria-controls={`nav-tab-${ele}`} aria-selected={index === 0} onClick={(e) => { formik.setFieldValue("name", ele); setType(ele); setIsEdit(ele === type ? isEdit : false) }}>
                        <div>
                          {ele}
                        </div>
                      </button>
                    )
                  })
                  }

                </div>
              </nav>

              <div className="tab-content" id="nav-tabContent">
                {pageType?.map((ele, index) => {
                  return (
                    <div key={index} className={`tab-pane fade ${index === 0 ? "active show" : ""}`} id={`nav-tab-${ele}`} role="tabpanel" aria-labelledby={`nav-tab-${ele}`}>
                      {loader ? <div className='text-center'><Spinwheel /></div> :
                        isEdit || !pageData?.content ?
                          <div>
                            <div>
                              <JoditEditor
                                ref={editor}
                                value={formik.values.content}
                                config={config}
                                onBlur={(e) => {
                                  formik.setFieldTouched("content", true)
                                  e === "<p><br></p>" ? formik.setFieldValue("content", "") : formik.setFieldValue("content", e);
                                }}
                                onChange={(e) => { }}
                              />
                            </div>
                            <div className='mt-3'>
                              <button type="button" className="btn-custom w-auto" onClick={(e) => { formik.handleSubmit() }}  >{addPageLoader ? <Spinwheel sizeClass="spin-small"/> : "Submit"}</button>
                              {pageData?.content && <button type="button" className="btn-custom w-auto ms-3" onClick={(e) => { setIsEdit(false) }}  >Cancel</button>}
                            </div>
                          </div>

                          :
                          <div>
                            <div>
                              <p dangerouslySetInnerHTML={{ __html: pageData?.content }}></p>
                            </div>
                            <div className='mt-3'>
                              <button type="button" className="btn-custom w-auto" onClick={(e) => { setIsEdit(true) }}  >Edit</button>
                            </div>
                          </div>
                      }
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default PageManagement