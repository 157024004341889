import React from 'react'
import Spinwheel from '../../../common/Spinwheel'
import Select from 'react-select';
function AddOrUpdateTrainCoachModal({ formik, modalId, addLoader }) {

   const trainCoachOptions = [
      { label: "AC 2 Tier (2A)", value: "AC 2 Tier (2A)" },
      { label: "AC 3 Tier (3A)", value: "AC 3 Tier (3A)" },
      { label: "Sleeper (SL)", value: "Sleeper (SL)" },
      { label: "2S", value: "2S" },
   ]

   return (
      <div className="modal fade" id={modalId} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
         <div className="modal-dialog modal-sm modal-dialog-centered">
            <div className="modal-content">
               <div className="modal-header border-0">
                  <button type="button" className="btn-close block-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div className="modal-body">
                  <div className="col-lg-12" style={{ zIndex: "1" }}>
                     <form action="" className='p-4' >
                        <h2 className='text-center'>
                           Coach
                        </h2>
                        <div className='d-flex flex-column gap-2'>
                           {/* <div className="position-relative">
                              <label htmlFor='name'>Name</label>
                              <input className="form-control border border-1" type="text" id="name" placeholder="Enter name of train" name="name" value={formik.values?.name} onChange={formik.handleChange} onBlur={() => formik.handleBlur} />
                              {formik.touched?.name ? (<div className='req-error'>{formik.errors?.name}</div>) : null}
                           </div> */}
                           <div className="position-relative">
                              <label htmlFor='NoOFSeat'>Seats</label>
                              <input className="form-control border border-1" type="number" id="NoOFSeat" placeholder="Enter NoOFSeat" name="NoOFSeat" value={formik.values?.NoOFSeat} onChange={formik.handleChange} onBlur={() => formik.handleBlur} />
                              {formik.touched?.NoOFSeat ? (<div className='req-error'>{formik.errors?.NoOFSeat}</div>) : null}
                           </div>
                           <div className="position-relative">
                              <label htmlFor='price'>Price</label>
                              <input className="form-control border border-1" type="number" id="price" placeholder="Enter price" name="price" value={formik.values?.price} onChange={formik.handleChange} onBlur={() => formik.handleBlur} />
                              {formik.touched?.price ? (<div className='req-error'>{formik.errors?.departurePlace}</div>) : null}
                           </div>
                           <div>
                              <label htmlFor="coachType" className="form-label">Coach type</label>
                              <Select
                                 className="basic-single"
                                 name="coachType"
                                 options={trainCoachOptions}
                                 value={{ label: formik.values.coachType, value: formik.values.coachType }}
                                 onChange={(e) => formik.setFieldValue("coachType", e?.value)}
                              />
                              {formik.touched.coachType && formik.errors.coachType ? (<div className='req-error'>{formik.errors.coachType}</div>) : null}
                           </div>
                        </div>
                        <div className="modal-footer border-0 justify-content-center pt-4">
                           <button type="button" className="btn-custom w-auto" onClick={(e) => { formik?.handleSubmit() }}  >{addLoader ? <Spinwheel sizeClass="spin-small" /> : "Save"}</button>
                           <button type="button" className="btn-custom w-auto" data-bs-dismiss='modal' >
                              Cancel
                           </button>
                        </div>
                     </form>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}

export default AddOrUpdateTrainCoachModal