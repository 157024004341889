import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import { useAdminContext } from '../../../context/adminContext';
import Sidebar from '../../../Layout/Sidebar';
import { deleteNewsByIdApi, getAllNewsApi, publishOrUnpublishApi } from '../../../utils/ApiEndpointFunctions';
import { ImageUrl } from '../../../utils/BaseUrl';
import Header from '../../common/Header';
import Pagination from '../../common/Pagination';
import RemoveModal from '../../common/RemoveModal';
import Modal from './Modal';
import ModalVideo from './ModalVideo';
import Table from './Table';

function News() {
  const { adminDetail } = useAdminContext();
  const [selectedPage, setSelectPage] = useState(0);
  const [news, setNews] = useState();
  const [removeData, setRemoveData] = useState(null);
  const [tableSpin, setTableSpin] = useState(true);
  const [newsData, setNewsData] = useState({});
  const navigate = useNavigate()
  const [modalUpdate, setModalUpdate] = useState({
    id: "",
    status: false,
    type: ""
  })

  const [page, setPage] = useState(0);
  const [type, setType] = useState("article")
  const [searchText, setSearchText] = useState("");
  const limit = 10;
  const getAllNews = async (selectedPage) => {
    setTableSpin(false)
    const res = await getAllNewsApi(limit, selectedPage + 1, searchText)
    if (res.status === 200) {
      const totalData = res.data?.totalData
      setPage(Math.ceil(totalData / limit));
      setNews(res.data?.data)
      setTableSpin(true)
    }
  }
  const onChange = async (e) => {
    let search = e.target.value;
    setTableSpin(false);
    setSearchText(search)
    const res = await getAllNewsApi(limit, 1, search);
    if (res.status === 200) {
      setNews(res.data?.data);
      const totalData = res.data?.totalData
      setPage(Math.ceil(totalData / limit));
      setSelectPage(0)
      setTableSpin(true);
    }
  };

  const debouncedOnChange = debounce(onChange, 500);

  const removeHandler = async (e) => {
    e.preventDefault()
    try {
      if (removeData._id) {
        const res = await deleteNewsByIdApi(removeData?._id);
        if (res.status === 200) {
          toast.success(res.data?.message)
          getAllNews(selectedPage, type);
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getAllNews(0);
  }, [])

  const handlePageClick = (event) => {
    setSelectPage(event.selected);
    getAllNews(event.selected);
  };

  const updateStatus = async (e) => {
    e.preventDefault();
    try {
      let res = await publishOrUnpublishApi({ id: modalUpdate?.id, status: !modalUpdate?.status });
      if (res?.status === 200) {
        toast.success(`${modalUpdate.status === true ? "Unpublished" : "Published"} successfully`);
        getAllNews(selectedPage);
      }
    } catch (error) {
      toast.error("something went wrong")
      console.log(error)
    }
  }

  

  return (
    <>
      {/*<Sidebar />*/}
      <div className="main-content vh-100 vh_md-auto overflow-y-a">
        <Header title={<>Info &amp; News</>} />
        <div className="container-fluid">
          <div className="row">
            <nav className="mb-5">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard" >
                    <i className="fe fe-home"></i> Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Info &amp; news
                </li>
              </ol>
            </nav>
            <div className="d-flex justify-content-end mb-4 flex-wrap">
              <div className='d-flex gap-2 justify-content-end align-items-center' style={{ display: "-webkit-inline-box" }}>


                {/* <button type="submit" className="btn-custom w-auto mt-2" disabled={addSpin} >{addSpin ? <Spinwheel sizeClass="spin-small" /> : path}</button> */}
                <div className="btn-custom w-auto" onClick={() => { navigate("carousel") }}>
                  Manage carousel
                </div>

                <div className="btn-custom w-auto" onClick={() => { navigate("add") }}>Add</div>
                <div className="position-relative">
                  <input
                    className="form-search me-3"
                    type="search"
                    name=""
                    onChange={debouncedOnChange}
                    placeholder="Search"
                  />
                  <img
                    className="users-search"
                    src="/images/Icon feather-search.svg"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <hr />
              <Table
                status="Official"
                selectedPage={selectedPage}
                limit={limit}
                tableData={news}
                // removeData={removeData}
                setRemoveData={setRemoveData}
                tableSpin={tableSpin}
                setModalUpdate={setModalUpdate}
                setNewsData={setNewsData}
              />
          </div>
          <div className="d-flex justify-content-center">
            {news?.length > 0 ? <Pagination
              clickHandler={handlePageClick}
              page={page}
              selectedPage={selectedPage}
            /> : null}
          </div>
        </div>
      </div>
      <RemoveModal removeHandler={removeHandler} modalId="removeNews" message={<>Are you sure you want to<br /> remove this?</>} />
      <RemoveModal removeHandler={updateStatus} modalId="publishedUnpublished" message={<>Are you sure you want to<br /> { modalUpdate.status === true ? "unpublish" : "publish"} this?</>} />
      <Modal
        modalUpdate={modalUpdate}
        setModalUpdate={setModalUpdate}
        getAllNews={getAllNews}
        type={type}
        selectedPage={selectedPage}
      />
      <ModalVideo
        newsData={newsData}
        setNewsData={setNewsData}
      />
    </>
  )
}

export default News