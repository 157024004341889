import moment from 'moment'
import React from 'react'
import Spinwheel from '../../../common/Spinwheel'
import { ImageUrl } from '../../../../utils/BaseUrl'
import { Link } from 'react-router-dom'

function PassportTable({ tableData, tableSpin, setIsEdit, setRemoveData, formik }) {

  const updateDataHandler = (ele) => {
    
    formik.setValues({
      _id: ele._id ? ele._id : undefined,
      name: ele.name ? ele.name : "",
      nationality: ele.nationality ? ele.nationality : "",
      dob: ele.dob ? ele.dob : "",
      gender: ele.gender ? ele.gender : "",
      dateOfIssue: ele.dateOfIssue ? ele.dateOfIssue : "",
      dateOfExpiry: ele.dateOfExpiry ? ele.dateOfExpiry : "",
      placeOfBirth: ele.placeOfBirth ? ele.placeOfBirth : "",
      issuedPlace: ele.issuedPlace ? ele.issuedPlace : "",
      file: ele.file ? ele.file : "",
    })

  }
  return (
    <div>
      <table className="table table-sm table-hover table-nowrap card-table">
        <thead>
          <tr>
            <th><span className="list-sort text-muted">Name</span></th>
            <th><span className="list-sort text-muted">Nationality</span></th>
            <th><span className="list-sort text-muted">Date of Birth</span></th>
            <th><span className="list-sort text-muted">Sex</span></th>
            <th><span className="list-sort text-muted">Date of Issue</span></th>
            <th><span className="list-sort text-muted">Date of Expiry</span></th>
            <th><span className="list-sort text-muted">Place of Birth</span></th>
            <th><span className="list-sort text-muted">Issued Place</span></th>
            <th colSpan={2}><span className="list-sort text-muted">file</span></th>
          </tr>
        </thead>
        <tbody >
        {
            tableSpin ? <tr>
              <td colSpan={20}>
                <div className="text-center">
                  <Spinwheel />
                </div>
              </td>
            </tr> :
        tableData?.length ? tableData?.map(ele => {
          return (
            
              <tr>
                <td>{ele.name}</td>
                <td>{ele.nationality}</td>
                <td>{moment(ele.dob).format("DD/MM/YYYY")}</td>
                <td>{ele.gender}</td>
                <td>{moment(ele.dateOfIssue).format("DD/MM/YYYY")}</td>
                <td>{moment(ele.dateOfExpiry).format("DD/MM/YYYY")}</td>
                <td>{ele.placeOfBirth}</td>
                <td>{ele.issuedPlace}</td>
              <td className='position-relative'>
                {ele?.file ? (
                  <div className="tooltip1">
                    <Link to={ImageUrl + encodeURIComponent(ele?.file)} target="__blank">{ele?.file?.substr?.(0, 20)}...</Link>
                    <div className='wrapper'>
                      <div className="tooltip1-text">{ele?.file}</div>
                    </div>
                  </div>
                ) : (
                  "-"
                )}
              </td>

              <td className="text-end">
                <div className="dropdown">
                  <span
                    className="dropdown-ellipses dropdown-toggle"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="fe fe-more-vertical" />
                  </span>
                  <div className="dropdown-menu dropdown-menu-end">
                    <div data-bs-toggle="modal" data-bs-target="#ManageStatusModal" className="dropdown-item" style={{ cursor: "pointer" }} onClick={() => { setIsEdit(true); updateDataHandler(ele) }} >
                      View/Edit
                    </div>
                    <div data-bs-toggle="modal" data-bs-target="#removedoc" className="dropdown-item" style={{ cursor: "pointer" }} onClick={() => { setRemoveData(ele) }} >
                      Remove
                    </div>
                  </div>
                </div>
              </td>
              </tr>
            
          )
        }) : <tr>
            <td className='text-center' colSpan="12"><h1>Data not found</h1></td>
          </tr>}
        </tbody>
      </table>

    </div>
  )
}

export default PassportTable