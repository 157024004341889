import React, { useEffect, useState } from 'react'
import Spinwheel from '../../../common/Spinwheel'
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import AsyncSelect from 'react-select/async';
import { getAllTrainStationApi } from '../../../../utils/ApiEndpointFunctions';
function AddOrUpdateTrainStopModal({ formik, modalId, addLoader }) {
const [data,setData] = useState([])

   const TrainStationOptions = async () => {
      try {
         const res = await getAllTrainStationApi()
         let result = res.data?.data?.map(station => { return { label: station?.name, value: station?._id } })
         setData(result)
         return result
      } catch (error) {
         console.log(error);
         return []
      }
   }

   useEffect(()=>{
      if(formik.values.stationId?.name){
         let findStation = data.filter(station=>station?.value==formik.values.stationId?._id)?.[0]
         if(findStation){
            formik.setFieldValue("stationId",findStation)
         }
      }
   },[formik?.values])


   return (
      <div className="modal fade" id={modalId} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
         <div className="modal-dialog modal-sm modal-dialog-centered">
            <div className="modal-content">
               <div className="modal-header border-0">
                  <button type="button" className="btn-close block-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div className="modal-body">
                  <div className="col-lg-12" style={{ zIndex: "1" }}>
                     <form action="" className='p-4' >
                        <h2 className='text-center'>
                           Stop
                        </h2>
                        <div className='d-flex flex-column gap-2'>
                           <div>
                              <label htmlFor="stationId" className="form-label">Station</label>
                              <AsyncSelect cacheOptions defaultOptions value={formik.values.stationId} onChange={(e) => formik.setFieldValue("stationId", e)} loadOptions={TrainStationOptions} />
                              {formik.touched.stationId && formik.errors.stationId ? (<div className='req-error'>{formik.errors.stationId}</div>) : null}
                           </div>
                           <div className="position-relative">
                              <label htmlFor='plateFormNo'>Plateform No</label>
                              <input className="form-control border border-1" type="text" id="plateFormNo" placeholder="Enter name of train" name="plateFormNo" value={formik.values?.plateFormNo} onChange={formik.handleChange} onBlur={() => formik.handleBlur} />
                              {formik.touched?.plateFormNo ? (<div className='req-error'>{formik.errors?.plateFormNo}</div>) : null}
                           </div>
                           <div>
                              <label htmlFor='departureTime'>Departure time</label>
                              <DateTimePicker name='departureTime' className="w-100 form-control border border-1" format="dd-MM-yyy h:mm:ss a" onChange={(e) => formik.setFieldValue("departureTime", e)} value={formik.values?.departureTime ? new Date(formik.values?.departureTime) : null} minDate={new Date(formik.values?.arrivalTime)} />
                              {formik.touched?.departureTime ? (<div className='req-error'>{formik.errors?.departureTime}</div>) : null}
                           </div>
                           <div>
                              <label htmlFor='arrivalTime'>Arrival time</label>
                              <DateTimePicker name='arrivalTime' className="w-100 form-control border border-1" format="dd-MM-yyy h:mm:ss a" onChange={(e) => formik.setFieldValue("arrivalTime", e)} value={formik.values?.arrivalTime ? new Date(formik.values?.arrivalTime) : null} maxDate={new Date(formik.values?.departureTime)}/>
                              {formik.touched?.arrivalTime ? (<div className='req-error'>{formik.errors?.arrivalTime}</div>) : null}

                           </div>
                        </div>
                        <div className="modal-footer border-0 justify-content-center pt-4">
                           <button type="button" className="btn-custom w-auto" onClick={(e) => { formik?.handleSubmit() }}  >{addLoader ? <Spinwheel sizeClass="spin-small" /> : "Save"}</button>
                           <button type="button" className="btn-custom w-auto" data-bs-dismiss='modal' >
                              Cancel
                           </button>
                        </div>
                     </form>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}

export default AddOrUpdateTrainStopModal