import React from 'react';
import PropTypes from 'prop-types';

const TableRow = ({ row, headers }) => {
  return (
    <tr>
      {headers.map((header, index) => (
        <td className='text-start' key={index}>{row[header.key]}</td>
      ))}
    </tr>
  );
};

TableRow.propTypes = {
  row: PropTypes.object.isRequired,
  headers: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default TableRow;
