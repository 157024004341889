import * as Yup from 'yup'
// admin sign in
export const SignInInitaialValue = {
  email: "",
  password: "",
  checkbox: false
}

export const SignInValidationSchema = Yup.object({
  email: Yup.string()
    .email('Invalid email format')
    .required('Email is required'),
  password: Yup.string().required("Password is required").min(8, 'Password should be minimum 8 character'),
  // checkbox:Yup.bool().oneOf([true], 'must be checked').required()
})

// forget password

export const forgetPasswordIntial = {
  email: "",
  otp: undefined
}

export const forgetPasswordSchema = Yup.object({
  email: Yup.string()
    .email('Invalid email')
    .required('Email is required'),
  otp: Yup.number("Otp should be number").required("Otp is required"),
})

// otp validation 

export const optIntialValue = {
  email: ""
}

export const otpValidationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email")
    .required("Email is required")
})

export const inviteVendorInitialValue = {
  email: "",
  role: "shopVendor",
}

export const inviteVendorValidationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email")
    .required("Email is required")
})

// update admin profile

export const adminUserIntialValues = {
  email: "",
  password: "",
  name: "",
  profileImage: "",
  role: "admin"
}

export const adminUserValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
  password: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .required('Password is required'),
  name: Yup.string()
    .required('Name is required'),
  profileImage: Yup.string(),
  role: Yup.string().required("Role is required"),
  // .required('Profile image URL is required'),
});

export const vendorUserIntialValues = {
  email: "",
  name: "",
  profileImage: "",
  role: "vendor",
  password: "",
  _id: ""
}

export const VendorUserValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
  name: Yup.string()
    .required('Name is required'),
  profileImage: Yup.string(),
  role: Yup.string(),
  password: Yup.string().required('Password is required').when('_id', ([_id], schema) => {

    if (_id) {
      return Yup.string()
    }
    return schema;
  },
  ),


});

export const updateAdminIntialValue = {
  profileImage: "",
  name: "",
  // role: "",
  email: "",
  phoneNumber: undefined
}

export const updateAdminProfileSchema = Yup.object({
  profileImage: Yup.string(),
  name: Yup.string().required('Name is required')
    .min(3, 'Name must be at least 3 characters')
    .max(25, 'Name must not exceed 25 characters')
    .matches(/^[a-zA-Z\s]+$/, 'Name must only contain letters and spaces'),
  // role: Yup.string().required('Required'),
  email: Yup.string().email().required('Email is required'),
  phoneNumber: Yup.string()
    .required('Phone number is required')
    .matches(/^\+?[1-9]\d{1,15}$/, 'Invalid phone number')
})

// Essential number validation

export const AddEssentialInitialValue = {
  logo: "",
  name: "",
  phone: [""]
}

export const essentialValidation =
  Yup.object().shape({
    logo: Yup.string(),
    name: Yup.string().required('Name is required'),
    phone: Yup.array().of(Yup.string().required('Phone number is required').matches(/^\+?[1-9]\d{1,15}$/, 'Invalid phone number')).min(1, 'At least one phone number is required'),
  });



// user Edit Validation

export const userEditIntialValue = {
  // work_zipcode: "",
  // work_country: "",
  // work_Address: "",
  name: "",
  email: "",
  phoneNumber: "",
  dob: "",
  compensation: "",
  country: "",
  experience: "",
  organization: "",
  jobTitle: "",
  qualification: "",
  correspondence_Address: "",
  correspondence_country: "",
  correspondence_zipcode: "",
  // role: "",
  birthPlace: "",
  zipCode: "",
  gender: "",
}




export const userEditValidation = Yup.object().shape({
  name: Yup.string().trim().required('Name is required'),
  email: Yup.string().trim().email('Invalid email address').required('Email is required'),
  phoneNumber: Yup.string().required('Phone number is required')
    .matches(/^\+?[1-9]\d{1,15}$/, 'Invalid phone number'),
  dob: Yup.date().required('Date of birth is required'),
  compensation: Yup.number().typeError('Compensation must be a number').required('Compensation is required'),
  country: Yup.string().trim().required('Country is required'),
  experience: Yup.string().trim().required('Experience is required'),
  organization: Yup.string().trim().required('Organization is required'),
  jobTitle: Yup.string().trim().required('Job title is required'),
  qualification: Yup.string().trim().required('Qualification is required'),
  correspondence_Address: Yup.string().trim().required('Home address is required'),
  correspondence_country: Yup.string().trim().required('Home country is required'),
  correspondence_zipcode: Yup.string().trim().required('Home zipcode is required'),
  // role: Yup.string().trim().required('Role is required'),
  birthPlace: Yup.string().trim().required('Birth place is required'),
  zipCode: Yup.string().trim().required('Zip code is required'),
  gender: Yup.string().trim().required('Gender is required'),
  // work_zipcode: Yup.string().trim().required('Work zipcode is required'),
  // work_country: Yup.string().trim().required('Work country is required'),
  // work_Address: Yup.string().trim().required('Work address is required'),
});

// reset Password validation

export const resetpasswordInitial = {
  password: "",
  confirmPassword: ""
}

export const resetPasswordValidation = Yup.object({
  password: Yup.string().required("Password is required").min(8, 'Password should be minimum 8 character'),
  confirmPassword: Yup.string()
    .required('Confirm Password is required')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
})

// add Embasseies validation

export const addEmbassiesIntialValues = {
  imageLogo: "",
  myDocumentId: "",
  name: "",
  address: ""
}

export const addEmbassiesValidation = Yup.object({
  // image: Yup.string().required("Required"),
  myDocumentId: Yup.string().required("Categore is required"),
  name: Yup.string().required("Office Name is required"),
  address: Yup.string().required("Address is required")
})


// add map validation

export const mapIntialValue = {
  image: "",
  name: "",
  address: "",
  isPinned: false,
  status: "",
  address2: "",
  description: "",
  phoneNumber: "",
}

export const mapValidationSchema = Yup.object({
  image: Yup.string(),
  name: Yup.string().required('Name is required')
    .min(3, 'Name must be at least 3 characters')
    .max(100, 'Name must not exceed 100 characters'),
  // .matches(/^[a-zA-Z\s]+$/, 'Name must only contain letters and spaces'),
  phoneNumber: Yup.string().required('Phone number is required')
    .matches(/^\+?[1-9]\d{1,15}$/, 'Invalid phone number'),
  address: Yup.string().required("Location is required"),
  address2: Yup.string().required("Address is required"),
  description: Yup.string().required("Descrition is required"),
  isPinned: Yup.boolean(),
  status: Yup.string().required("Map type is required"),
})

// jobs post validation 


export const jobsPostInitialValues = {
  title: "",
  companyId: "",
  workExp: "",
  description: "",
  employmentType: "",
  skillsRequired: [],
  noOfPosition: "",
  salaryRangeFrom: "",
  salaryRangeTo: "",
  rangeSelector: false,
  location: "",
}

export const jobsPostValidationSchema = Yup.object({
  title: Yup.string().required('Title is required').max(60, 'Title must not exceed 60 characters'),
  companyId: Yup.mixed().required('Company is required'),
  workExp: Yup.string().required('Work experience is required'),
  employmentType: Yup.string().required('Employment type is required'),
  skillsRequired: Yup.array().min(1, 'At least one skill is required'),
  description: Yup.string().required('Description is required'),
  noOfPosition: Yup.mixed().test('noOfPosition', 'Required Employee must be a number between 1 to 1000', value => {
    if (!value) return false;
    return !isNaN(value) && value >= 1 && value <= 1000;
  }).required('Required Employee is required'),
  salaryRangeFrom: Yup.number().required('Salary range from is required').min(0, 'Salary range must be a positive number or zero').typeError('Salary range must be number'),
  salaryRangeTo: Yup.number().required('Salary range to is required').min(Yup.ref('salaryRangeFrom'), 'Salary range to must be greater').typeError('Salary range must be number'),
  location: Yup.string().required("Location is required"),
})

//add company validation

export const companyPostInitialValues = {
  logo: "",
  name: "",
  emailAddress: "",
  phoneNumber: "",
  url: "",
  description: "",
  locality: "",
  industry: "",
  address: ""
}

export const companyPostValidationSchema = Yup.object({
  logo: Yup.string().required("Logo is required"),
  name: Yup.string().required('Company name is required').max(60, 'Company name must not exceed 60 characters'),
  emailAddress: Yup.string()
    .email('Invalid email format')
    .required('Email is required'),
  phoneNumber: Yup.string().required('Phone number is required')
    .matches(/^\+?[1-9]\d{1,15}$/, 'Invalid phone number'),
  url: Yup.string().url('Invalid URL').required('URL is required'),
  description: Yup.string().required('Description is required'),
  locality: Yup.string().required('Location is required'),
  industry: Yup.string().required('Industry is required'),
  address: Yup.string().required('Address is required'),
})

// rating validation

export const ratingIntialValues = {
  name: "",
  rating: "",
  companyId: "",
  review: "",
  jobId: ""
}

export const ratingValidatonSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  rating: Yup.mixed().test('valid-rating', 'Rating must be a number between 1 to 5', value => {
    if (!value) return false;
    const ratingNum = parseFloat(value);
    return !isNaN(ratingNum) && ratingNum >= 1 && ratingNum <= 5;
  }).required('Rating is required'),
  companyId: Yup.string().required('Company ID is required'),
  review: Yup.string().required('Review is required'),
  // jobId: Yup.string(),
});

// add meesage validation

export const addSosMessIntialValues = {
  name: ""
}

export const addSosMessValidationSchema = Yup.object().shape({
  name: Yup.string().required('Message is required'),
})

// news validation

export const newsUploadInitialValues = {
  newsTitle: "",
  newsHeading: "",
  publishDate: "",
  newsContent: '',
  publishBy: "",
  type: "article",
  thumbnail: "",
  isPublished: false,
  onPriority: false,
  isBanner: false,
  video: "",
  newsSource: "",
  videoTimeDuration: ""
}

export const newsUploadValidationSchema = Yup.object().shape({

  type: Yup.string().required('Type is required'),
  thumbnail: Yup.string().required('Thumbnail is required').when('type', ([type], schema) => {
    if (type === 'video') {
      return Yup.string()
    }
    return schema;
  },
  ),
  video: Yup.string().when('type', ([type], schema) => {
    if (type === 'video') {
      return Yup.string().required('video is required')
    }
    return schema;
  },
  ),

  newsTitle: Yup.string().required('News title is required')
    .max(150, 'New title must not exceed 150 characters'),
  newsHeading: Yup.string().required('News heading is required').max(150, 'New heading must not exceed 150 characters'),
  publishDate: Yup.string().required('Publish date is required')
    .test('valid-date', 'Invalid date format. Use MM-DD-YYYY', (value) => {
      const dateRegex = /^(\d{2})-(\d{2})-(\d{4})$/;
      if (!dateRegex.test(value)) {
        return false;
      }
      const [month, day, year] = value.split('-').map(Number);
      const dateObject = new Date(year, month - 1, day);
      return (
        dateObject.getDate() === day &&
        dateObject.getMonth() === month - 1 &&
        dateObject.getFullYear() === year
      );
    })
  ,
  newsContent: Yup.string().required('News content is required'),
  newsSource: Yup.string().required('News content is required'),
  publishBy: Yup.string().required('Published by is required').max(100, 'Published by must not exceed 100 characters'),

});

export const movieInitialValues = {
  name: "",
  title: "",
  description: "",
  coverImg: "",
  img: "",
  duration: "00:00:00",
  languages: [],
  casts: [{ name: "", img: "", role: "" }],
  isBanner: 0
}

export const movieValidationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  title: Yup.string().required('Title is required'),
  description: Yup.string().required('Description is required'),
  coverImg: Yup.string().required('Cover image is required'),
  img: Yup.string().required('Image is required'),
  duration: Yup.string().required('Duration is required'),
  languages: Yup.array().min(1, "Must have 1 language").required('Languages is required'),
  casts: Yup.array(Yup.object().shape({
    name: Yup.string().required('Name is required'),
    img: Yup.string().required('Image is required'),
    role: Yup.string().required('Role is required'),
  })).min(1, "Must have 1 cast").required('Casts is required'),


});



export const movieShowInitialValues = {
  movieCinemaId: {},
  timeDate: "",
  noOfSeats: "",
  price: "",
  tax: "",
  language: "",
}

export const movieShowValidationSchema = Yup.object().shape({
  movieCinemaId: Yup.object().required('Please select cinema').test('select-cinema', 'Please select cinema', (value) => {
    if (value?.value) {
      return true;
    }
  }),
  timeDate: Yup.string().required('Movie show timing is required'),
  noOfSeats: Yup.number().typeError("No of seat must be number").required('No of seats is required').positive("must be a positive number"),
  price: Yup.number().required('Price is required').typeError("Price must be number").positive("must be a positive number"),
  tax: Yup.number().required('Tax is required').typeError("Tax must be number").positive("must be a positive number"),
  language: Yup.string().required('Language is required'),
});

export const busRouteInitialValue = {
  busOperatorId: {},
  departure: "",
  departurePlace: "",
  arrivalPlace: "",
  arrivalTime: "",
  totalSeat: "",
  price: "",
  busType: {},
  seatType: {},
  pickPoint: [{
    place: "",
    time: "",
  }],
  dropPoint: [{
    place: "",
    time: "",
  }]
}

export const busRouteValidationSchema = Yup.object().shape({
  busOperatorId: Yup.object().required('Please select operator').test('select-operator', 'Please select operator', (value) => {
    if (value?.value) {
      return true;
    }
  }),
  departurePlace: Yup.string().required('Departure place is required'),
  departure: Yup.string().required('Departure time is required'),
  arrivalPlace: Yup.string().required('Arrival place is required'),
  arrivalTime: Yup.string().required('Arrival time is required'),
  totalSeat: Yup.number().typeError("Total seat must be number").required('Total seat is required'),
  price: Yup.number().typeError("Price must be number").required('Price is required'),
  busType: Yup.object().required('Please select bus type').test('select-bus-type', 'Please select bus type', (value) => {
    if (value?.value) {
      return true;
    }
  }),
  seatType: Yup.object().required('Please select seat type').test('select-seat-type', 'Please select seat type', (value) => {
    if (value?.value) {
      return true;
    }
  }),
  pickPoint: Yup.array(Yup.object().shape({
    place: Yup.string().required('Place is required'),
    time: Yup.string().required('Time is required'),
  })).min(1, "Must have 1 pickup point").required('Pickup point is required'),
  dropPoint: Yup.array(Yup.object().shape({
    place: Yup.string().required('Place is required'),
    time: Yup.string().required('Time is required'),
  })).min(1, "Must have 1 drop point").required('Drop point is required'),
});

export const hotelInitialValue = {
  name: "",
  address: "",
  image: "",
  starRating: "",
  minimumPrice: "",
  propertyType: "",
  hotelCondition: "",
  isFreeCancellation: false,
  about: "",
  googleLocation: {
    address: "",
    lang: "",
    lat: ""
  },
  facilities: [{
    name: "",
    icon: "",
  }],
}


export const hotelValidationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  address: Yup.string().required('Address is required'),
  image: Yup.string().required('Image is required'),
  starRating: Yup.number().min(1, "Minimum 1 rating").max(7, "Maximum 7 rating").required('Star rating is required').typeError("Rating must be a number"),
  minimumPrice: Yup.number().min(0, "Must be postive number").required('Minimum price is required').typeError("Minimum price must be a number"),
  propertyType: Yup.string().required('Property type is required'),
  hotelCondition: Yup.string().required('Hotel condition is required'),
  about: Yup.string().required('About is required'),
  googleLocation: Yup.object().required('Please select google location').test('select-googleLocation', 'Please select google location', (value) => {
    if (value?.address && value?.lang && value?.lat) {
      return true;
    }
  }),
  facilities: Yup.array(Yup.object().shape({
    name: Yup.string().required('Name is required'),
    icon: Yup.string().required('Icon is required'),
  })).min(1, "Must have 1 facilities").required('Facilities required'),
});

export const hotelRoomInitialValue = {
  name: "",
  price: "",
  mealPreference: "",
  bedPreference: "",
  guestNumber: "",
  images: [""],
  facilities: [{
    name: "",
    icon: "",
  }],
}


export const hotelRoomValidationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  price: Yup.number().min(0, "Must be postive number").required('Price is required').typeError("Price must be a number"),
  guestNumber: Yup.number().min(0, "Must be postive number").required('Guests is required').typeError("Guest must be a number"),
  mealPreference: Yup.string().required('Meal preference is required'),
  bedPreference: Yup.string().required('Bed preference is required'),
  images: Yup.array().min(1, "Must have 1 image").required('Images required'),
  facilities: Yup.array(Yup.object().shape({
    name: Yup.string().required('Name is required'),
    icon: Yup.string().required('Icon is required'),
  })).min(1, "Must have 1 facilities").required('Facilities required'),
});


export const ticketStatusInitalValue = {
  status: "",
  ticketFile: "",
}

export const ticketStatusValidationSchema = Yup.object().shape({
  status: Yup.string().required('Status is required'),
  ticketFile: Yup.string().required('Attachment is required'),
});

export const busOperatorInitialValue = {
  image: "",
  name: "",
  phone: "",
  address: "",

}

export const busOperatorValidationSchema = Yup.object().shape({
  image: Yup.string().required('Image is required'),
  name: Yup.string().required('Name is required'),
  phone: Yup.string().required('Phone is required'),
  address: Yup.string().required('Address is required'),

});

export const movieCinemaInitalValue = {
  name: "",
  location: "",
}

export const movieCinemaValidationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  location: Yup.string().required('Location is required'),
});

export const trainInitialValue = {
  trainName: "",
  trainNumber: "",
  departureTime: "",
  departurePlace: "",
  arrivalPlace: "",
  arrivalTime: "",
}

export const trainValidationSchema = Yup.object().shape({
  trainName: Yup.string().required('Name is required'),
  trainNumber: Yup.string().required('Train number is required'),
  departureTime: Yup.string().required('Departure date is required'),
  departurePlace: Yup.mixed().required('Departure place is required'),
  arrivalPlace: Yup.mixed().required('Arrival place is required'),
  arrivalTime: Yup.string().required('Arrival date is required'),
});

export const trainCoachInitialValue = {
  // name:"",
  NoOFSeat: "",
  price: "",
  coachType: "",
}

export const trainCoachValidationSchema = Yup.object().shape({
  // name:Yup.string().required('Name is required'),
  NoOFSeat: Yup.string().required('Seats is required'),
  price: Yup.string().required('Price is required'),
  coachType: Yup.string().required('Coach type is required'),
});

export const trainStationInitialValue = {
  name: "",
  alias: "",
}

export const trainStationValidationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  alias: Yup.string().required('Alias is required'),
});

export const trainStopInitialValue = {
  plateFormNo: "",
  departureTime: "",
  arrivalTime: "",
  stationId: {},
}

export const trainStopValidationSchema = Yup.object().shape({
  plateFormNo: Yup.string().required('Plateform No is required'),
  departureTime: Yup.string().required('Departure time is required'),
  arrivalTime: Yup.string().required('Arrival time is required'),
  stationId: Yup.object().required('Please select station').test('select-station', 'Please select station', (value) => {
    if (value?.value) {
      return true;
    }
  }),

});




// add form validation

export const addFormIntialValues = {
  formLink: "",
  myDocumentId: ""
}

export const addFormValidationSchema = Yup.object().shape({
  formLink: Yup.string()
    .required('Form link is required'),
  myDocumentId: Yup.string()
    .required('Document is required'),
});

// entertainment validation 

export const entertainmentInitialValues = {
  title: "",
  subtitle: "",
  publishDate: "",
  content: '',
  contentSource: "",
  type: "inshorts",
  thumbnail: "",
  isPublish: false,
  isBanner: false,
  video: "",
  creatorName: "",
  videoTimeDuration: ""
}

export const entertainmentValidationSchema = Yup.object().shape({
  title: Yup.string().required('Title is required')
    .max(150, 'Title must not exceed 150 characters'),
  subtitle: Yup.string().required('Subtitle is required')
    .max(150, 'Subtitle must not exceed 150 characters'),
  publishDate: Yup.string().required('Publish date is required').test('valid-date', 'Invalid date format. Use MM-DD-YYYY', (value) => {
    const dateRegex = /^(\d{2})-(\d{2})-(\d{4})$/;
    if (!dateRegex.test(value)) {
      return false;
    }
    const [month, day, year] = value.split('-').map(Number);
    const dateObject = new Date(year, month - 1, day);
    return (
      dateObject.getDate() === day &&
      dateObject.getMonth() === month - 1 &&
      dateObject.getFullYear() === year
    );
  }),
  content: Yup.string().required('Content is required'),
  creatorName: Yup.string().required('Creator name is required'),
  contentSource: Yup.string().required('Content source is required')
    .max(100, 'Content source must not exceed 100 characters'),
  type: Yup.string().required('Type is required'),
  thumbnail: Yup.string().required('Thumbnail is required').when('type', ([type], schema) => {
    if (type === 'video') {
      return Yup.string()
    }
    return schema;
  },
  ),
  video: Yup.string().when('type', ([type], schema) => {
    if (type === 'video') {
      return Yup.string().required('Video is required')
    }
    return schema;
  },
  ),
});

// add doc validation

export const addDocIntialValue = {
  docName: '',
  imageLogo: '',
}

export const addDocValidationSchema = Yup.object().shape({
  docName: Yup.string().required("Doc name is required"),
  imageLogo: Yup.string().required("Image is requred")
})

// living validation 

export const livingInitialValues = {
  title: "",
  subtitle: "",
  publishDate: '',
  content: '',
  contentSource: "",
  type: "article",
  thumbnail: "",
  isPublish: false,
  isBanner: false,
  isPopular: false,
  creatorName: "",
  video: "",
  videoTimeDuration: ""
}

export const livingValidationSchema = Yup.object().shape({
  title: Yup.string().required('Title is required')
    .max(150, 'Title must not exceed 150 characters'),
  subtitle: Yup.string().required('Subtitle is required').max(150, 'Subtitle must not exceed 150 characters'),
  publishDate: Yup.string().required('Publish date is required').test('valid-date', 'Invalid date format. Use MM-DD-YYYY', (value) => {
    const dateRegex = /^(\d{2})-(\d{2})-(\d{4})$/;
    if (!dateRegex.test(value)) {
      return false;
    }
    const [month, day, year] = value.split('-').map(Number);
    const dateObject = new Date(year, month - 1, day);
    return (
      dateObject.getDate() === day &&
      dateObject.getMonth() === month - 1 &&
      dateObject.getFullYear() === year
    );
  }),
  content: Yup.string().required('Content is required'),
  creatorName: Yup.string().required('Creator name is required'),
  contentSource: Yup.string().required('Content source is required').max(100, 'Content source must not exceed 100 characters'),
  type: Yup.string().required('Type is required'),
  thumbnail: Yup.string().required('Thumbnail is required').when('type', ([type], schema) => {
    if (type === 'video') {
      return Yup.string()
    }
    return schema;
  },
  ),
  video: Yup.string().when('type', ([type], schema) => {
    if (type === 'video') {
      return Yup.string().required('Video is required')
    }
    return schema;
  },
  ),
});

// study validations 
export const courseInitialValues = {
  courseTitle: "",
  publisher: "",
  isBanner: false,
  isPopular: false,
  isPublished: false,
  courseThumbnail: "",
  content: "",
  publishDate: "",
  completionTime: "",
  creditPoints: "",
}

export const courseValidationSchema = Yup.object().shape({
  courseTitle: Yup.string().required('Course title is required').max(150, 'Course title must not exceed 150 characters'),
  publisher: Yup.string().required('Creator Name is required').max(100, 'Creator Name must not exceed 100 characters'),
  courseThumbnail: Yup.string().required('Course thumbnail is required'),
  content: Yup.string().required('Description is required'),
  publishDate: Yup.string().required('Publish date is required').test('valid-date', 'Invalid date format. Use MM-DD-YYYY', (value) => {
    const dateRegex = /^(\d{2})-(\d{2})-(\d{4})$/;
    if (!dateRegex.test(value)) {
      return false;
    }
    const [month, day, year] = value.split('-').map(Number);
    const dateObject = new Date(year, month - 1, day);
    return (
      dateObject.getDate() === day &&
      dateObject.getMonth() === month - 1 &&
      dateObject.getFullYear() === year
    );
  }),

  completionTime: Yup.number().required("Completion time is required").typeError("must be a number"),
  creditPoints: Yup.number().required("Credit points time is required").typeError("must be a number"),
});

export const addChapterIntialValue = {
  courseId: "",
  courseChapter: [{
    type: "video",
    chapterTitle: "",
    thumbnail: "",
    videos: "",
    videoTimeDuration: "",
    content: "",
  }]
}

export const addChapterValidationSchema = Yup.object().shape({
  courseId: Yup.string().required('Course is required'),
  courseChapter: Yup.array().of(
    Yup.object().shape({
      type: Yup.string().oneOf(['article', 'video']).required('Chapter type is required'),
      chapterTitle: Yup.string().required('Chapter title is required').max(150, 'chapter title must not exceed 150 characters'),
      thumbnail: Yup.string().required('Thumbnail is required').when('type', ([type], schema) => {
        if (type === 'video') {
          return Yup.string()
        }
        return schema;
      },
      ),
      videos: Yup.string().when('type', ([type], schema) => {
        if (type === 'video') {
          return Yup.string().required('Video is required')
        }
        return schema;
      },
      ),
      content: Yup.string().required("Content is required")
    })
  ),
});

export const addQuizInitialValue = {
  courseId: "",
  quesAndAns: [{
    questions: "",
    correctAnswer: "",
    answer: [{
      name: ""
    }, {
      name: ""
    }],
  }]
}

export const addQuizValidationSchema = Yup.object().shape({
  courseId: Yup.string().required('Course is required'),
  quesAndAns: Yup.array().of(
    Yup.object().shape({
      questions: Yup.string().required('Question is required'),
      correctAnswer: Yup.string().required('Answer is required')
        .test("is-valid-answer", "Answer should be in options", (value, validationContext) => {
          const { parent, createError } = validationContext;
          let element = parent?.answer?.filter((ele, i, arr) => {
            if (ele?.name === value) return true;
            return false;
          })
          if (element?.length === 1) {
            return true;
          } else if (element?.length > 1) {
            return createError({ message: "Answer should be match with one option" })
          } else {
            return false;
          }
        })
      ,
      answer: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().required('Option is required')
        })
      ),
    })
  ),
});


// shop

export const addShopInitialValue = {
  name: "",
  emailId: "",
  location: ""
}

export const addShopValidationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  emailId: Yup.string()
    .email('Invalid email format')
    .required('Email is required'),
  location: Yup.string().required("Address is required")
})

export const addProductInitialValue = {

  products: [{
    name: "",
    category: "",
    subcategory: "",
    price: "",
    deletedPrice: "",
    discount: "",
    productImage: [],
    desc: "",
    size: [],
    color: [{ color: "", colorImage: "" }],
    remainingIs: "",
    status: "",
  }]
}

export const addProductValidationSchema = Yup.object().shape({

  products: Yup.array().of(Yup.object().shape({
    name: Yup.string().required('Product name is required'),
    category: Yup.mixed().required('Category is required'),
    subcategory: Yup.mixed().required('Subcategory is required'),
    price: Yup.string()
      .required('Price is required')
      .test('is-positive', 'Price must be a positive number', value => parseFloat(value) >= 0),
    deletedPrice: Yup.string()
      .required('Deleted price is required')
      .test('is-positive', 'Deleted price must be a positive number', value => parseFloat(value) >= 0),
    discount: Yup.string()
      .required('Discount is required')
      .test('is-valid-discount', 'Discount must be between 0 and 100', value => {
        const discountValue = parseFloat(value);
        return discountValue >= 0 && discountValue <= 100;
      }),
    productImage: Yup.array().of(Yup.string())
      .min(1, 'At least one product image is required'),

    desc: Yup.string().required('Description is required'),
    size: Yup.array().of(Yup.string())
      .min(1, 'At least one size is required'),
    color: Yup.array().of(Yup.object().shape({
      color: Yup.string().required('Color is required'),
      colorImage: Yup.string().required('Color image is required'),
    }))
      .min(1, 'At least one product image is required'),
    status: Yup.string().required('Status is required'),
    remainingIs: Yup.string()
      .required('Remaining is required')
      .test('is-positive', 'Remaining must be a positive number', value => parseFloat(value) >= 0),
  })).min(1, 'At least one product is required'),
});

export const updateProductInitialValue = {
  name: "",
  category: "",
  subcategory: "",
  price: "",
  deletedPrice: "",
  discount: "",
  productImage: [],
  desc: "",
  size: [],
  color: [{ color: "", colorImage: "" }],
  remainingIs: "",
}

export const updateProductValidationSchma = Yup.object().shape({
  name: Yup.string().required('Product name is required'),
  category: Yup.mixed().required('Category is required'),
  subcategory: Yup.mixed().required('Subcategory is required'),
  price: Yup.string()
    .required('Price is required')
    .test('is-positive', 'Price must be a positive number', value => parseFloat(value) >= 0),
  deletedPrice: Yup.string()
    .required('Deleted price is required')
    .test('is-positive', 'Deleted price must be a positive number', value => parseFloat(value) >= 0),
  discount: Yup.string()
    .required('Discount is required')
    .test('is-valid-discount', 'Discount must be between 0 and 100', value => {
      const discountValue = parseFloat(value);
      return discountValue >= 0 && discountValue <= 100;
    }),
  productImage: Yup.array().of(Yup.string())
    .min(1, 'At least one product image is required'),
  desc: Yup.string().required('Description is required'),
  size: Yup.array().of(Yup.string())
    .min(1, 'At least one size is required'),
  remainingIs: Yup.string()
    .required('Remaining is required')
    .test('is-positive', 'Remaining must be a positive number', value => parseFloat(value) >= 0),

  color: Yup.array().of(Yup.object().shape({
    color: Yup.string().required('Color is required'),
    colorImage: Yup.string().required('Color image is required'),
  }))
    .min(1, 'At least one product image is required'),
})





export const manageCarouselIntialValue = {
  id: ''
}

export const manageCarouselValidationSchema = Yup.object().shape({
  id: Yup.mixed().required('Id is required')
});

export const pageManagementIntialValue = {
  name: "About us",
  content: ""
}

export const pageMangagementValidationSchema = Yup.object().shape({
  name: Yup.mixed().required('name is required'),
  content: Yup.mixed().required('content is required')
});

export const systemAndreportInitialValue = {
  from: "",
  to: "",
  type: "users"
}

export const systemAndreportValidationSchema = Yup.object().shape({
  from: Yup.string().required('name is required'),
  to: Yup.string().required('To is required'),
  type: Yup.string().required('Type is required'),
})

export const addApplinksInitialValue = {
  name: "",
  image: "",
  androidUrl: "",
  iosUrl: "",
}

export const addApplinksValidationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  image: Yup.string().required('Image is required'),
  androidUrl: Yup.string().url("Invalid Url").required('Android url is required'),
  iosUrl: Yup.string().url("Invalid Url").required('IOS url is required'),
})

export const categoryInitialValue = {
  name: "",
  image: "",
  description: ""
}

export const categoryValidationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  image: Yup.string().required('Image is required'),
})

export const subCategoryInitialValue = {
  name: "",

}

export const subCategoryValidationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
})

export const userDocumentsInitialValues = {
  userId: "",
  categoryId: "",
  name: "",
  nationality: "",
  dob: '',
  gender: "",
  dateOfIssue: '',
  dateOfExpiry: '',
  placeOfBirth: "",
  issuedPlace: "",
  nrcNumber: "",
  fatherName: "",
  bloodGroup: "",
  phoneNumber: "",
  address: "",
  drivingLicense: "",
  visaNumber: "",
  passportNumber: "",
  validUntil: '',
  typeOfVisa: "",
  householdMemberQty: '',
  companyName: "",
  companyID: "",
  companyAddress: "",
  registrationDate: '',
  typeOfBusiness: "",
  workPermitNo: "",
  typeOfWork: "",
  allowWorkUntil: '',
  aliasName: "",
  parentName: "",
  religion: "",
  language: "",
  permanentAddress: "",
  myanmarPhone: "",
  relationship: "",
  companyPhone: "",
  entryDateThailand: '',
  addressInThailand: "",
  thaiPhone: "",
  ciOldLicenseNumber: "",
}

export const passportIntialValue = {
  name: '',
  nationality: '',
  dob: '',
  gender: '',
  dateOfIssue: '',
  dateOfExpiry: '',
  placeOfBirth: '',
  issuedPlace: '',
}

export const passportValidationSchma = Yup.object({
  name: Yup.string().required('Name is required'),
  nationality: Yup.string().required('Nationality is required'),
  dob: Yup.date().required('Date of Birth is required'),
  gender: Yup.string().required('Gender is required'),
  dateOfIssue: Yup.date().required('Date of Issue is required'),
  dateOfExpiry: Yup.date().required('Date of Expiry is required'),
  placeOfBirth: Yup.string().required('Place of Birth is required'),
  issuedPlace: Yup.string().required('Issued Place is required'),
  file: Yup.string().required('File is required'),
});

export const WorkpermitCiCardIntialValue = {
  workPermitNo: '',
  name: '',
  dob: '',
  companyAddress: '',
  dateOfIssue: '',
  dateOfExpiry: '',
  typeOfWork: '',
  allowWorkUntil: '',
  file: '',
}

export const WorkpermitCiCardValidation = Yup.object({
  workPermitNo: Yup.string().required('WorkPermit is required'),
  name: Yup.string().required('Name is required'),
  dob: Yup.date().required('Date of Birth is required'),
  companyAddress: Yup.string().required('Company Address is required'),
  dateOfIssue: Yup.date().required('Date of Issue is required'),
  dateOfExpiry: Yup.date().required('Date of Expiry is required'),
  typeOfWork: Yup.string().required('Type of Work is required'),
  allowWorkUntil: Yup.date().required('Allow Work Until is required'),
  file: Yup.string().required('File is required'),
});

export const WorkpermitCiApplicatonFormIntialValue = {
  name: '',
  aliasName: '',
  dob: '',
  nrcNumber: '',
  parentName: '',
  religion: '',
  language: '',
  permanentAddress: '',
  myanmarPhone: '',
  relationship: '',
  companyName: '',
  companyAddress: '',
  companyPhone: '',
  entryDateThailand: '',
  addressInThailand: '',
  thaiPhone: '',
  ciOldLicenseNumber: '',
  dateOfIssue: '',
  file: '',
  placeOfBirth: "",
  issuedPlace: '',
}

export const WorkpermitCiApplicatonFormValidation = Yup.object({
  name: Yup.string().required('Name is required'),
  aliasName: Yup.string().required('Alias name is required'),
  dob: Yup.date().required('Date of Birth is required'),
  nrcNumber: Yup.string().required('Nrc is required'),
  parentName: Yup.string().required('Parent Name is required'),
  religion: Yup.string().required('Religion is required'),
  language: Yup.string().required('Language is required'),
  permanentAddress: Yup.string().required('Permanent Address is required'),
  myanmarPhone: Yup.string().required('Myanmar Phone is required'),
  relationship: Yup.string().required('Relationship is required'),
  companyName: Yup.string().required('Company Name is required'),
  companyAddress: Yup.string().required('Company Address is required'),
  companyPhone: Yup.string().required('Company Phone is required'),
  entryDateThailand: Yup.date().required('Entry Date in Thailand is required'),
  addressInThailand: Yup.string().required('Address in Thailand is required'),
  thaiPhone: Yup.string().required('Thai Phone is required'),
  ciOldLicenseNumber: Yup.string().required('CI Old License Number is required'),
  dateOfIssue: Yup.date().required('Date of Issue is required'),
  placeOfBirth: Yup.string().required('Place of Birth is required'),
  issuedPlace: Yup.string().required('Issued Place is required'),
  file: Yup.string().required('File is required'),
});

export const NationalIdFormIntialvalue = {
  nrcNumber: '',
  name: '',
  fatherName: '',
  dob: '',
  bloodGroup: '',
  gender: '',
  phoneNumber: '',
  address: '',
  file: '',
}

export const NationalIdFormValidation = Yup.object({
  nrcNumber: Yup.string().required('NRC Number is required'),
  name: Yup.string().required('Name is required'),
  fatherName: Yup.string().required("Father Name is required"),
  dob: Yup.date().required('Date of Birth is required'),
  bloodGroup: Yup.string().required("Blood Group is required"),
  gender: Yup.string().required('Gender is required'),
  phoneNumber: Yup.string().required("Phone is required"),
  address: Yup.string().required("Address is required"),
  file: Yup.string().required("file is required"),
})

export const DrivingLicenseIntialvalue = {
  name: '',
  dateOfIssue: '',
  dateOfExpiry: '',
  dob: '',
  drivingLicense: '',
  file: '',
}

export const DrivingLicenseValidation = Yup.object({
  name: Yup.string().required('Name is required'),
  dateOfIssue: Yup.date().required('Date of Issue is required'),
  dateOfExpiry: Yup.date().required('Date of Expiry is required'),
  dob: Yup.date().required('Date of Birth is required'),
  drivingLicense: Yup.string().required('Driving License is required'),
  file: Yup.string().required('File is required'),
})

export const VisaIntialValue = {
  visaNumber: '',
  passportNumber: '',
  validUntil: '',
  typeOfVisa: '',
  file: '',
}


export const VisaValidation = Yup.object({
  visaNumber: Yup.string().required('Visa Number is required'),
  passportNumber: Yup.string().required('Passport Number is required'),
  validUntil: Yup.date().required('Valid Until is required'),
  typeOfVisa: Yup.string().required('Type of Visa is required'),
  file: Yup.string().required('File is required'),
})

export const HouseholdMemberInitialValue = {
  name: "",
  householdMemberQty: "",
  address: "",
  file: "",
}

export const HouseholdMemberValidation = Yup.object({
  name: Yup.string().required('Name is required'),
  householdMemberQty: Yup.number().required('Household Member Quantity is required').typeError("must be a number"),
  address: Yup.string().required("Address is required"),
  file: Yup.string().required("File is required"),
})

export const otherInitialValue = {
  name: "",
  file: "",
}

export const otherValidation = Yup.object({
  name: Yup.string().required('Name is required'),
  file: Yup.string().required("File is required"),
})


export const EmployerInformationInitialValue = {
  companyName: '',
  companyID: '',
  registrationDate: '',
  dateOfIssue: '',
  dateOfExpiry: '',
  companyAddress: '',
  typeOfBusiness: '',
  file: '',
}


export const EmployerInformationValidation = Yup.object({
  companyName: Yup.string().required('Company Name is required'),
  companyID: Yup.string().required('Company ID is required'),
  registrationDate: Yup.date().required('Registration Date is required'),
  dateOfIssue: Yup.date().required('Date of Issue is required'),
  dateOfExpiry: Yup.date().required('Date of Expiry is required'),
  companyAddress: Yup.string().required('Company Address is required'),
  typeOfBusiness: Yup.string().required('Type of Business is required'),
  file: Yup.string().required('File is required'),
});

export const userDocumentsValidationSchema = Yup.object().shape({
  categoryId: Yup.mixed().required('Category is required'),
});

const userDocumentsValidationSchema1 = Yup.object().shape({
  categoryId: Yup.mixed().required('Category is required'),
  name: Yup.string().required('Name is required'),
  nationality: Yup.string().required('Nationality is required'),
  dob: Yup.date().required('Date of Birth is required'),
  gender: Yup.string().required('Gender is required'),
  dateOfIssue: Yup.date().required('Date of Issue is required'),
  dateOfExpiry: Yup.date().required('Date of Expiry is required'),
  placeOfBirth: Yup.string().required('Place of Birth is required'),
  issuedPlace: Yup.string().required('Issued Place is required'),
  workPermitNo: Yup.string().required('Work Permit is required'),
  companyAddress: Yup.string().required('Company Address is required'),
  typeOfWork: Yup.string().required('Type of Work is required'),
  allowWorkUntil: Yup.date().required('Allow Work Until is required'),
  aliasName: Yup.string().required('Alias Name is required'),
  nrcNumber: Yup.string().required('NRC Number is required'),
  parentName: Yup.string().required('Parent Name is required'),
  religion: Yup.string().required('Religion is required'),
  language: Yup.string().required('Language is required'),
  permanentAddress: Yup.string().required('Permanent Address is required'),
  myanmarPhone: Yup.string().required('Myanmar Phone is required'),
  relationship: Yup.string().required('Relationship is required'),
  companyName: Yup.string().required('Company Name is required'),
  companyPhone: Yup.string().required('Company Phone is required'),
  entryDateThailand: Yup.date().required('Entry Date in Thailand is required'),
  addressInThailand: Yup.string().required('Address in Thailand is required'),
  thaiPhone: Yup.string().required('Thai Phone is required'),
  ciOldLicenseNumber: Yup.string().required('CI Old License Number is required'),
  fatherName: Yup.string().required('Father Name is required'),
  bloodGroup: Yup.string().required('Blood Group is required'),
  phone: Yup.string().required('Phone is required'),
  address: Yup.string().required('Address is required'),
  drivingLicense: Yup.string().required('Driving License is required'),
  visaNumber: Yup.string().required('Visa Number is required'),
  passportNumber: Yup.string().required('Passport Number is required'),
  validUntil: Yup.date().required('Valid Until is required'),
  typeOfVisa: Yup.string().required('Type of Visa is required'),
  householdMemberQty: Yup.number().required('Household Member Quantity is required').typeError("must be a number"),
  companyID: Yup.string().required('Company ID is required'),
  registrationDate: Yup.date().required('Registration Date is required'),
  typeOfBusiness: Yup.string().required('Type of Business is required'),
});


export const bannerInitialValue = {
  image: "",
  description: "",
}

export const bannerValidationSchema = Yup.object().shape({
  image: Yup.string().required('Image is required'),
  description: Yup.string().required('Description is required'),

});

export const compensationRecommendation = {
  spousePassport: "",
  repersentativeLetter: "",
  marriedCerticated: "",
  marriedRecommendation: "",
}

export const compensationRecommendationValidation = Yup.object().shape({
  spousePassport: Yup.string()
    .trim()
    .required('Spouse Passport is required'),

  representativeLetter: Yup.string()
    .trim()
    .required('Representative Letter is required'),

  marriedCertificate: Yup.string()
    .trim()
    .required('Marriage Certificate is required'),

  marriedRecommendation: Yup.string()
    .trim()
    .required('Marriage Recommendation is required'),
});


export const bodyDispatchRecommendation = {
  passport: "",
  nationalRegistration: "",
  spousePassport: "",
  spouseNRC: "",
  deathCertificate: "",
  criminalRecord: "",
}

export const bodyDispatchRecommendationValidation = Yup.object().shape({
  passport: Yup.string()
    .trim()
    .required('Passport is required'),

  nationalRegistration: Yup.string()
    .trim()
    .required('National Registration is required'),

  spousePassport: Yup.string()
    .trim()
    .required('Spouse Passport is required'),

  spouseNRC: Yup.string()
    .trim()
    .required('Spouse NRC is required'),

  deathCertificate: Yup.string()
    .trim()
    .required('Death Certificate is required'),

  criminalRecord: Yup.string()
    .trim()
    .required('Criminal Record is required'),
});

export const prisonRecommendation = {
  visitorPassport: "",
  name: "",
  address: "",
  passport: "",
  nationalRegistration: "",
  houseHoldlist: "",
  prisonerNumber: "",
}

export const prisonRecommendationValidation = Yup.object().shape({
  visitorPassport: Yup.string()
    .trim()
    .required('Visitor Passport is required'),

  name: Yup.string()
    .trim()
    .required('Name is required'),

  address: Yup.string()
    .trim()
    .required('Address is required'),

  passport: Yup.string()
    .trim()
    .required('Passport is required'),

  nationalRegistration: Yup.string()
    .trim()
    .required('National Registration is required'),

  houseHoldlist: Yup.string()
    .trim()
    .required('Household List is required'),

  prisonerNumber: Yup.string()
    .trim()
    .required('Prisoner Number is required'),
});

export const sponsorRecommendation = {
  passport: "",
  religiousRecommendation: "",
  saranaIdentityCard: "",
}

export const sponsorRecommendationValidation = Yup.object().shape({
  passport: Yup.string()
    .trim()
    .required('Passport is required'),

  religiousRecommendation: Yup.string()
    .trim()
    .required('Religious Recommendation is required'),

  saranaIdentityCard: Yup.string()
    .trim()
    .required('Sarana Identity Card is required'),
});

export const visaExtensionRecommendation = {
  passport: "",
  universityRecommendation: "",
  hospitalRecommendation: "",
}

export const visaExtensionRecommendationValidation = Yup.object().shape({
  passport: Yup.string()
    .trim()
    .required('Passport is required'),

  universityRecommendation: Yup.string()
    .trim()
    .required('University Recommendation is required'),

  hospitalRecommendation: Yup.string()
    .trim()
    .required('Hospital Recommendation is required'),
});

export const dependentRecommendation = {
  spousePassport: "",
  parentsPassport: "",
  taxpayer: "",
  voucher: "",
  marriedCerticated: "",
}

export const dependentRecommendationValidation = Yup.object().shape({
  spousePassport: Yup.string()
    .trim()
    .required('Spouse Passport is required'),

  parentsPassport: Yup.string()
    .trim()
    .required('Parents Passport is required'),

  taxpayer: Yup.string()
    .trim()
    .required('Taxpayer information is required'),

  voucher: Yup.string()
    .trim()
    .required('Voucher is required'),

  marriedCerticated: Yup.string()
    .trim()
    .required('Marriage Certificate is required'),
});

export const studentRecommendation = {
  passport: "",
  universityRecommendation: "",
}

export const studentRecommendationValidation = Yup.object().shape({
  passport: Yup.string()
    .trim()
    .required('Passport is required'),

  universityRecommendation: Yup.string()
    .trim()
    .required('University Recommendation is required'),
});

export const birthRecommendation = {
  parentsPassport: "",
  thaiBirthCertificate: "",
  myanmarBirthCertificate: "",
}

export const birthRecommendationValidation = Yup.object().shape({
  parentsPassport: Yup.string()
    .trim()
    .required('Parents Passport is required'),

  thaiBirthCertificate: Yup.string()
    .trim()
    .required('Thai Birth Certificate is required'),

  myanmarBirthCertificate: Yup.string()
    .trim()
    .required('Myanmar Birth Certificate is required'),
});

export const bankAcOpeningRecommendation = {
  passportPhoto: "",
  passport: "",
  ci: "",
}

export const bankAcOpeningRecommendationValidation = Yup.object().shape({
  passportPhoto: Yup.string()
    .trim()
    .required('Passport Photo is required'),

  passport: Yup.string()
    .trim()
    .required('Passport is required'),

  ci: Yup.string()
    .trim()
    .required('CI is required'),
});

export const ciLostRecommendation = {
  lostRecommendation: "",
  passport: "",
  labourCard: "",
  passportPhoto: "",
}

export const ciLostRecommendationValidation = Yup.object().shape({
  lostRecommendation: Yup.string()
    .trim()
    .required('Lost Recommendation is required'),

  passport: Yup.string()
    .trim()
    .required('Passport is required'),

  labourCard: Yup.string()
    .trim()
    .required('Labour Card is required'),

  passportPhoto: Yup.string()
    .trim()
    .required('Passport Photo is required'),
});

export const addressVerifyAndApprove = {
  passport: "",
  passportPhoto: "",
  address: "",
}

export const addressVerifyAndApproveValidation = Yup.object().shape({
  passport: Yup.string()
    .trim()
    .required('Passport is required'),

  passportPhoto: Yup.string()
    .trim()
    .required('Passport Photo is required'),

  address: Yup.string()
    .trim()
    .required('Address is required'),
});

export const cerficateOfIdentityInitialValue = {
  applicationLetter: "",
  applicationForm: "",
  lostRecommendation: "",
  passport: "",
  nationalRegistration: "",
  returnTicket: "",
  passportPhoto: "",
}

export const cerficateOfIdentityValidation = Yup.object().shape({
  applicationLetter: Yup.string()
    .trim()
    .required('Application Letter is required'),

  applicationForm: Yup.string()
    .trim()
    .required('Application Form is required'),

  lostRecommendation: Yup.string()
    .trim()
    .required('Lost Recommendation is required'),

  passport: Yup.string()
    .trim()
    .required('Passport is required'),

  nationalRegistration: Yup.string()
    .trim()
    .required('National Registration is required'),

  returnTicket: Yup.string()
    .trim()
    .required('Return Ticket is required'),

  passportPhoto: Yup.string()
    .trim()
    .required('Passport Photo is required'),
});


export const religiousPassport = {
  applicationLetter: "",
  applicationForm: "",
  universityRecommendation: "",
  nationalRegistration: "",
  houseHoldlist: "",
  currentPassport: "",
  saranaIdentityCard: "",
}

export const religiousPassportValidation = Yup.object().shape({
  applicationLetter: Yup.string()
    .trim()
    .required('Application Letter is required'),

  applicationForm: Yup.string()
    .trim()
    .required('Application Form is required'),

  universityRecommendation: Yup.string()
    .trim()
    .required('University Recommendation is required'),

  nationalRegistration: Yup.string()
    .trim()
    .required('National Registration is required'),

  houseHoldlist: Yup.string()
    .trim()
    .required('Household List is required'),

  currentPassport: Yup.string()
    .trim()
    .required('Current Passport is required'),

  saranaIdentityCard: Yup.string()
    .trim()
    .required('Sarana Identity Card is required'),
});

export const studentPassport = {
  applicationLetter: "",
  applicationForm: "",
  nationalRegistration: "",
  houseHoldlist: "",
  currentPassport: "",
  universityRecommendation: "",
  studentCard: "",
}

export const studentPassportValidation = Yup.object().shape({
  applicationLetter: Yup.string()
    .trim()
    .required('Application Letter is required'),

  applicationForm: Yup.string()
    .trim()
    .required('Application Form is required'),

  nationalRegistration: Yup.string()
    .trim()
    .required('National Registration is required'),

  houseHoldlist: Yup.string()
    .trim()
    .required('Household List is required'),

  currentPassport: Yup.string()
    .trim()
    .required('Current Passport is required'),

  universityRecommendation: Yup.string()
    .trim()
    .required('University Recommendation is required'),

  studentCard: Yup.string()
    .trim()
    .required('Student Card is required'),
});

export const dependentPassportIntialValue = {
  applicationLetter: "",
  applicationForm: "",
  marriedCerticate: "",
  nationalRegistration: "",
  houseHoldlist: "",
  currentPassport: "",
  granterPassport: "",
}

export const dependentPassportValidation = Yup.object().shape({
  applicationLetter: Yup.string()
    .trim()
    .required('Application Letter is required'),

  applicationForm: Yup.string()
    .trim()
    .required('Application Form is required'),

  marriedCertificate: Yup.string()
    .trim()
    .required('Married Certificate is required'),

  nationalRegistration: Yup.string()
    .trim()
    .required('National Registration is required'),

  houseHoldlist: Yup.string()
    .trim()
    .required('Household List is required'),

  currentPassport: Yup.string()
    .trim()
    .required('Current Passport is required'),

  granterPassport: Yup.string()
    .trim()
    .required('Granter Passport is required'),
});

export const jobPassportIntialValue = {
  applicationLetter: "",
  applicationForm: "",
  workPermit: "",
  companyRecommendation: "",
  nationalRegistration: "",
  houseHoldlist: "",
  currentPassport: "",
}

export const jobPassportValidation = Yup.object().shape({
  applicationLetter: Yup.string()
    .trim()
    .required('Application Letter is required'),

  applicationForm: Yup.string()
    .trim()
    .required('Application Form is required'),

  workPermit: Yup.string()
    .trim()
    .required('Work Permit is required'),

  companyRecommendation: Yup.string()
    .trim()
    .required('Company Recommendation is required'),

  nationalRegistration: Yup.string()
    .trim()
    .required('National Registration is required'),

  houseHoldlist: Yup.string()
    .trim()
    .required('Household List is required'),

  currentPassport: Yup.string()
    .trim()
    .required('Current Passport is required'),
});


const visaFormFeilds = [
  { name: "firstName", type: "input", inputType: "text", placeholder: "First name", label: "First name" },
  { name: "middleName", type: "input", inputType: "text", placeholder: "Middle name", label: "Middle name" },
  { name: "lastName", type: "input", inputType: "text", placeholder: "Last name", label: "Last name" },
  { name: "formerName", type: "input", inputType: "text", placeholder: "Former name", label: "Former name" },
  { name: "nationality", type: "input", inputType: "text", placeholder: "Nationality", label: "Nationality" },
  { name: "nationalityAtBirth", type: "input", inputType: "text", placeholder: "Nationality at birth", label: "Nationality at birth" },
  { name: "birthPlace", type: "input", inputType: "text", placeholder: "Birth place", label: "Birth place" },
  { name: "typeOfTravelDocument", type: "input", inputType: "text", placeholder: "Type of travel document", label: "Type of travel document" },
  { name: "travelDocumentNo", type: "input", inputType: "text", placeholder: "Travel document no", label: "Travel document no" },
  { name: "travelDocumentIssueDate", type: "date", inputType: "date", placeholder: "Travel document issue date", label: "Travel document issue date" },
  { name: "travelDocumentIssuePlace", type: "input", inputType: "text", placeholder: "Travel document issued place", label: "Travel document issued place" },
  { name: "travelDocumentIssueExpiry", type: "date", inputType: "date", placeholder: "Travel document expiry date", label: "Travel document expiry date" },
  { name: "occupation", type: "input", inputType: "text", placeholder: "Occupation", label: "Occupation" },
  { name: "currentAddress", type: "input", inputType: "text", placeholder: "Current address", label: "Current address" },
  { name: "phone", type: "input", inputType: "text", placeholder: "Phone", label: "Phone" },
  { name: "phone1", type: "input", inputType: "text", placeholder: "Phone", label: "Phone1" },
  { name: "email", type: "input", inputType: "text", placeholder: "Email", label: "Email" },
  { name: "permanentAddress", type: "input", inputType: "text", placeholder: "Permanent address", label: "Permanent address" },
  { name: "thaiArrivalDate", type: "date", inputType: "date", placeholder: "Thai arrival date", label: "Thai arrival date" },
  { name: "travelingBy", type: "input", inputType: "text", placeholder: "Traveling by", label: "Traveling by" },
  { name: "fightNo", type: "input", inputType: "text", placeholder: "Fight No or vessel's name", label: "Fight No or vessel's name" },
  { name: "proposedStayDuration", type: "input", inputType: "text", placeholder: "Duration", label: "Duration" },
  { name: "previousThaiVisitDate", type: "date", inputType: "date", placeholder: "Previous thailand visit date", label: "Previous thailand visit date" },
  { name: "purposeOfVisit", type: "input", inputType: "text", placeholder: "Purpose of visit", label: "Purpose of visit" },
  { name: "travelDocumentValidCountry", type: "react-select", inputType: "text", placeholder: "Valid Countries for travel document", label: "Valid Countries for travel document" },
  { name: "proposedThaiAddress", type: "input", inputType: "text", placeholder: "Proposed thai address", label: "Proposed thai address" },
  { name: "localGuarantorName", type: "input", inputType: "text", placeholder: "Local guarantor name", label: "Local guarantor name" },
  { name: "localGuarantorAddress", type: "input", inputType: "text", placeholder: "Local guarantor address", label: "Local guarantor address" },
  { name: "thaiGuarantorName", type: "input", inputType: "text", placeholder: "Thailand guarantor name", label: "Thailand guarantor name" },
  { name: "thaiGuarantorAddress", type: "input", inputType: "text", placeholder: "Thailand guarantor address", label: "Thailand guarantor address" },
  { name: "localGuarantorPhone", type: "input", inputType: "text", placeholder: "Local guarantor phone", label: "Local guarantor phone" },
  { name: "thaiGuarantorPhone", type: "input", inputType: "text", placeholder: "Thailand guarantor phone", label: "Thailand guarantor phone" },
  { name: "signature", type: "input", inputType: "file", placeholder: "Signature", label: "Signature" },
  { name: "date", type: "date", inputType: "date", placeholder: "Date", label: "Date" },
]

const visaFormInitialValues = {
  firstName: "",
  middleName: "",
  lastName: "",
  formerName: "",
  nationality: "",
  nationalityAtBirth: "",
  birthPlace: "",
  typeOfTravelDocument: "",
  travelDocumentNo: "",
  travelDocumentIssueDate: "",
  travelDocumentIssuePlace: "",
  travelDocumentIssueExpiry: "",
  occupation: "",
  currentAddress: "",
  phone: "",
  phone1: "",
  email: "",
  permanentAddress: "",
  thaiArrivalDate: "",
  travelingBy: "",
  fightNo: "",
  proposedStayDuration: "",
  previousThaiVisitDate: "",
  purposeOfVisit: "",
  travelDocumentValidCountry: [],
  proposedThaiAddress: "",
  localGuarantorName: "",
  localGuarantorAddress: "",
  thaiGuarantorName: "",
  thaiGuarantorAddress: "",
  localGuarantorPhone: "",
  thaiGuarantorPhone: "",
  signature: "",
  date: "",
}

const visaFormValidationSchema =  {
  firstName: Yup.string().trim().required('First name is required'),
  middleName: Yup.string().trim(),
  lastName: Yup.string().trim().required('Last name is required'),
  formerName: Yup.string().trim(),
  nationality: Yup.string().trim().required('Nationality is required'),
  nationalityAtBirth: Yup.string().trim().required('Nationality at birth is required'),
  birthPlace: Yup.string().trim().required('Birth place is required'),
  typeOfTravelDocument: Yup.string().trim().required('Type of travel document is required'),
  travelDocumentNo: Yup.string().trim().required('Travel document number is required'),
  travelDocumentIssueDate: Yup.date()
    .required('Travel document issue date is required')
    .typeError('Invalid date format'),
  travelDocumentIssuePlace: Yup.string().trim().required('Travel document issue place is required'),
  travelDocumentIssueExpiry: Yup.date()
    .required('Travel document expiry date is required')
    .typeError('Invalid date format'),
  occupation: Yup.string().trim().required('Occupation is required'),
  currentAddress: Yup.string().trim().required('Current address is required'),
  phone: Yup.string()
    .trim()
    .required('Phone number is required')
    .matches(/^\d+$/, 'Phone must be a valid number'),
  phone1: Yup.string()
    .trim()
    .matches(/^\d+$/, 'Phone must be a valid number'),
  email: Yup.string()
    .trim()
    .email('Invalid email format')
    .required('Email is required'),
  permanentAddress: Yup.string().trim().required('Permanent address is required'),
  thaiArrivalDate: Yup.date()
    .required('Thai arrival date is required')
    .typeError('Invalid date format'),
  travelingBy: Yup.string().trim().required('Traveling by information is required'),
  fightNo: Yup.string().trim().required('Flight number is required'),
  proposedStayDuration: Yup.string().trim().required('Durations is required'),
  previousThaiVisitDate: Yup.date()
    .typeError('Invalid date format'),
  purposeOfVisit: Yup.string().trim().required('Purpose of visit is required'),
  // travelDocumentValidCountry: Yup..required('Travel document valid country is required'),
  proposedThaiAddress: Yup.string().trim().required('Proposed Thai address is required'),
  localGuarantorName: Yup.string().trim().required('Local guarantor name is required'),
  localGuarantorAddress: Yup.string().trim().required('Local guarantor address is required'),
  thaiGuarantorName: Yup.string().trim().required('Thai guarantor name is required'),
  thaiGuarantorAddress: Yup.string().trim().required('Thai guarantor address is required'),
  localGuarantorPhone: Yup.string()
    .trim()
    .required('Local guarantor phone is required')
    .matches(/^\d+$/, 'Phone must be a valid number'),
  thaiGuarantorPhone: Yup.string()
    .trim()
    .required('Thai guarantor phone is required')
    .matches(/^\d+$/, 'Phone must be a valid number'),
  signature: Yup.string().trim().required('Signature is required'),
  date: Yup.date().required('Date is required').typeError('Invalid date format'),
}




export const applicationFeildRowData = {
  "Passport for Job (PJ)": {
    feilds: [
      { name: "applicationLetter", type: "input", inputType: "file", placeholder: "applicationLetter", label: "Application letter" },
      { name: "applicationForm", type: "input", inputType: "file", placeholder: "applicationForm", label: "Application form" },
      { name: "workPermit", type: "input", inputType: "file", placeholder: "workPermit", label: "Work permit" },
      { name: "companyRecommendation", type: "input", inputType: "file", placeholder: "companyRecommendation", label:"Company recommendation" },
      { name: "nationalRegistration", type: "input", inputType: "file", placeholder: "nationalRegistration", label: "National registration" },
      { name: "houseHoldlist", type: "input", inputType: "file", placeholder: "houseHoldlist", label: "Household list" },
      { name: "currentPassport", type: "input", inputType: "file", placeholder: "currentPassport", label: "Current passport" },
    ],
    initialValue: jobPassportIntialValue,
    validation: jobPassportValidation
  },
  "Passport for Dependent (PT)": {
    feilds: [
      { name: "applicationLetter", type: "input", inputType: "file", placeholder: "applicationLetter", label: "Application letter" },
      { name: "applicationForm", type: "input", inputType: "file", placeholder: "applicationForm", label: "Application form" },
      { name: "marriedCerticate", type: "input", inputType: "file", placeholder: "marriedCerticate", label: "Married certificate"},
      { name: "nationalRegistration", type: "input", inputType: "file", placeholder: "nationalRegistration", label: "National registration" },
      { name: "houseHoldlist", type: "input", inputType: "file", placeholder: "houseHoldlist", label: "Household list" },
      { name: "currentPassport", type: "input", inputType: "file", placeholder: "currentPassport", label: "Current passport" },
      { name: "granterPassport", type: "input", inputType: "file", placeholder: "granterPassport", label: "Granter Passport" },
    ],
    initialValue: dependentPassportIntialValue,
    validation: dependentPassportValidation,
  },
  "Passport for Student (PE)": {
    feilds: [
      { name: "applicationLetter", type: "input", inputType: "file", placeholder: "applicationLetter", label: "Application letter" },
      { name: "applicationForm", type: "input", inputType: "file", placeholder: "applicationForm", label: "Application form" },
      { name: "nationalRegistration", type: "input", inputType: "file", placeholder: "nationalRegistration", label: "National registration" },
      { name: "houseHoldlist", type: "input", inputType: "file", placeholder: "houseHoldlist", label: "Household list" },
      { name: "currentPassport", type: "input", inputType: "file", placeholder: "currentPassport", label: "Current passport" },
      { name: "universityRecommendation", type: "input", inputType: "file", placeholder: "universityRecommendation", label: "University recommendation" },
      { name: "studentCard", type: "input", inputType: "file", placeholder: "studentCard", label: "Student card" },
    ],
    initialValue: studentPassport,
    validation: studentPassportValidation
  },
  "Passport for Religious (PR)": {
    feilds: [
      { name: "applicationLetter", type: "input", inputType: "file", placeholder: "applicationLetter", label: "Application letter" },
      { name: "applicationForm", type: "input", inputType: "file", placeholder: "applicationForm", label: "Application form" },
      { name: "universityRecommendation", type: "input", inputType: "file", placeholder: "universityRecommendation", label: "University recommendation" },
      { name: "nationalRegistration", type: "input", inputType: "file", placeholder: "nationalRegistration", label: "National registration" },
      { name: "houseHoldlist", type: "input", inputType: "file", placeholder: "houseHoldlist", label: "Household list" },
      { name: "currentPassport", type: "input", inputType: "file", placeholder: "currentPassport", label: "Current passport" },
      { name: "saranaIdentityCard", type: "input", inputType: "file", placeholder: "saranaIdentityCard", label: "Sarana identity card"},
    ],
    initialValue: religiousPassport,
    validation: religiousPassportValidation
  },
  "Certificate of Identity": {
    feilds: [
      { name: "applicationLetter", type: "input", inputType: "file", placeholder: "applicationLetter", label: "Application letter" },
      { name: "applicationForm", type: "input", inputType: "file", placeholder: "applicationForm", label: "Application form" },
      { name: "lostRecommendation", type: "input", inputType: "file", placeholder: "lostRecommendation", label: "Lost recommendation" },
      { name: "passport", type: "input", inputType: "file", placeholder: "passport", label: "Passport" },
      { name: "nationalRegistration", type: "input", inputType: "file", placeholder: "nationalRegistration", label: "National registration" },
      { name: "returnTicket", type: "input", inputType: "file", placeholder: "returnTicket", label: "Return Ticket" },
      { name: "passportPhoto", type: "input", inputType: "file", placeholder: "passportPhoto", label: "Passport size photo" },
    ],
    initialValue: cerficateOfIdentityInitialValue,
    validation: cerficateOfIdentityValidation
  },
  "Address verify and approval": {
    feilds: [
      { name: "passport", type: "input", inputType: "file", placeholder: "passport", label: "Passport" },
      { name: "passportPhoto", type: "input", inputType: "file", placeholder: "passportPhoto", label: "Passport size photo" },
      { name: "address", type: "input", inputType: "text", placeholder: "address", label: "Address" },

    ],
    initialValue: addressVerifyAndApprove,
    validation: addressVerifyAndApproveValidation
  },
  "The recommendation for CI lost": {
    feilds: [
      { name: "lostRecommendation", type: "input", inputType: "file", placeholder: "lostRecommendation", label: "Lost recommendation" },
      { name: "passport", type: "input", inputType: "file", placeholder: "passport", label: "Passport" },
      { name: "labourCard", type: "input", inputType: "file", placeholder: "labourCard", label: "Labour card" },
      { name: "passportPhoto", type: "input", inputType: "file", placeholder: "passportPhoto", label: "Passport size photo" },

    ],
    initialValue: ciLostRecommendation,
    validation: ciLostRecommendationValidation
  },
  "The recommendation to open bank account": {
    feilds: [
      { name: "passportPhoto", type: "input", inputType: "file", placeholder: "passportPhoto", label: "Passport size photo" },
      { name: "passport", type: "input", inputType: "file", placeholder: "passport", label: "Passport" },
      { name: "ci", type: "input", inputType: "file", placeholder: "ci", label: "Ci" },
    ],
    initialValue: bankAcOpeningRecommendation,
    validation: bankAcOpeningRecommendationValidation
  },
  "The recommendation for birth certification": {
    feilds: [
      { name: "parentsPassport", type: "input", inputType: "file", placeholder: "parentsPassport", label: "Parent passport" },
      { name: "thaiBirthCertificate", type: "input", inputType: "file", placeholder: "thaiBirthCertificate", label: "Thai birth certificate" },
      { name: "myanmarBirthCertificate", type: "input", inputType: "file", placeholder: "myanmarBirthCertificate", label: "Myanmar birth certificate"},
    ],
    initialValue: birthRecommendation,
    validation: birthRecommendationValidation
  },
  "The recommendation for dependent": {
    feilds: [
      { name: "spousePassport", type: "input", inputType: "file", placeholder: "spousePassport", label: "Spouse passport" },
      { name: "parentsPassport", type: "input", inputType: "file", placeholder: "parentsPassport", label: "Parent passport" },
      { name: "taxpayer", type: "input", inputType: "file", placeholder: "taxpayer", label: "Taxpayer" },
      { name: "voucher", type: "input", inputType: "file", placeholder: "voucher", label: "Voucher" },
      { name: "marriedCerticated", type: "input", inputType: "file", placeholder: "marriedCerticated", label: "Married certificate" },
    ],
    initialValue: dependentRecommendation,
    validation: dependentRecommendationValidation
  },
  "The recommendation for student": {
    feilds: [
      { name: "passport", type: "input", inputType: "file", placeholder: "passport", label: "Passport" },
      { name: "universityRecommendation", type: "input", inputType: "file", placeholder: "universityRecommendation", label: "University recommendation" },

    ],
    initialValue: studentRecommendation,
    validation: studentRecommendationValidation
  },
  "The recommendation for visa extention": {
    feilds: [
      { name: "passport", type: "input", inputType: "file", placeholder: "passport", label: "Passport" },
      { name: "universityRecommendation", type: "input", inputType: "file", placeholder: "universityRecommendation", label: "University recommendation" },
      { name: "hospitalRecommendation", type: "input", inputType: "file", placeholder: "hospitalRecommendation", label: "Hospital recommendation" },
    ],
    initialValue: visaExtensionRecommendation,
    validation: visaExtensionRecommendationValidation
  },

  "The recommendation for sponsor letter": {
    feilds: [
      { name: "passport", type: "input", inputType: "file", placeholder: "passport", label: "Passport" },
      { name: "religiousRecommendation", type: "input", inputType: "file", placeholder: "religiousRecommendation", label: "Religious recommendation" },
      { name: "saranaIdentityCard", type: "input", inputType: "file", placeholder: "saranaIdentityCard", label: "Sarana identity Card"},
    ],
    initialValue: sponsorRecommendation,
    validation: sponsorRecommendationValidation
  },

  "The recommendation for prison entry": {
    feilds: [
      { name: "visitorPassport", type: "input", inputType: "file", placeholder: "visitorPassport", label: "Visitor passport" },
      { name: "name", type: "input", inputType: "text", placeholder: "name", label: "Name" },
      { name: "address", type: "input", inputType: "text", placeholder: "address", label: "Address" },
      { name: "passport", type: "input", inputType: "file", placeholder: "passport", label: "Passport" },
      { name: "nationalRegistration", type: "input", inputType: "file", placeholder: "nationalRegistration", label: "National registration" },
      { name: "houseHoldlist", type: "input", inputType: "file", placeholder: "houseHoldlist", label: "Household list" },
      { name: "prisonerNumber", type: "input", inputType: "text", placeholder: "prisonerNumber", label: "Prisoner number" },
    ],
    initialValue: prisonRecommendation,
    validation: prisonRecommendationValidation
  },
  "The recommendation for body dispatch": {
    feilds: [
      { name: "passport", type: "input", inputType: "file", placeholder: "passport", label: "Passport" },
      { name: "nationalRegistration", type: "input", inputType: "file", placeholder: "nationalRegistration", label: "National registration" },
      { name: "spousePassport", type: "input", inputType: "file", placeholder: "spousePassport", label: "Spouse passport" },
      { name: "spouseNRC", type: "input", inputType: "file", placeholder: "spouseNRC", label: "Spouse NRC" },
      { name: "deathCertificate", type: "input", inputType: "file", placeholder: "deathCertificate", label: "Death certificate" },
      { name: "criminalRecord", type: "input", inputType: "file", placeholder: "criminalRecord", label: "Criminal record" },
    ],
    initialValue: bodyDispatchRecommendation,
    validation: bodyDispatchRecommendationValidation
  },
  "The recommendation for compensation": {
    feilds: [
      { name: "spousePassport", type: "input", inputType: "file", placeholder: "spousePassport", label: "Spouse passport" },
      { name: "repersentativeLetter", type: "input", inputType: "file", placeholder: "repersentativeLetter", label: "Repersentative letter" },
      { name: "marriedCerticated", type: "input", inputType: "file", placeholder: "marriedCerticated", label: "Married certificate" },
      { name: "marriedRecommendation", type: "input", inputType: "file", placeholder: "marriedRecommendation", label: "Married recommendation" },
    ],
    initialValue: compensationRecommendation,
    validation: compensationRecommendationValidation
  },
  "TM 30 Registration": {
    feilds: [
      // { name: "isThai", type: "input", inputType: "checkbox", placeholder: "Thai", label: "Thai" },
      // { name: "isForeigner", type: "input", inputType: "checkbox", placeholder: "Foreigner", label: "Foreigner" },
      { name: "nationlityType", type: "select", inputType: "select", placeholder: "Gender", label: "Gender", options: [{ name: "Thai", value: "thai" }, { name: "Foreigner", value: "foreigner" }] },
      { name: "thaiNationalIdNo", type: "input", inputType: "text", placeholder: "Thai National ID No", label: "Thai National ID No" },
      { name: "passportNumber", type: "input", inputType: "text", placeholder: "Passport number", label: "Passport number" },
      { name: "nationality", type: "input", inputType: "text", placeholder: "Nationality", label: "Nationality" },
      { name: "email", type: "input", inputType: "email", placeholder: "Email", label: "Email" },
      { name: "phone", type: "input", inputType: "text", placeholder: "Phone", label: "Phone" },
      { name: "firstName", type: "input", inputType: "text", placeholder: "First name", label: "First name" },
      { name: "middleName", type: "input", inputType: "text", placeholder: "Middle name", label: "Middle name" },
      { name: "lastName", type: "input", inputType: "text", placeholder: "Last name", label: "Last name" },
      { name: "gender", type: "select", inputType: "select", placeholder: "Gender", label: "Gender", options: [{name:"Male", value: "male"}, {name: "Female", value: "female"}] },
      { name: "referenceData", type: "list", inputType: "file", feilds: { name: "", file: "" }, label: "Reference data (less than 2Mb per file)"},
    ],
    initialValue: {
      nationlityType:"thai",
      thaiNationalIdNo: "",
      passportNumber: "",
      nationality: "",
      email: "",
      phone: "",
      firstName: "",
      middleName: "",
      lastName: "",
      gender: "male",
      referenceData: [{ name: "", file: "" }]
    },
    validation: Yup.object().shape({
      
      thaiNationalIdNo: Yup.string()
        .trim()
        .test(
          'thaiNationalIdRequired',
          'Thai National ID No. is required for Thai',
          function (value) {
           
            return this.parent.nationlityType === 'thai' ? value ? true : false : true;
          }
        ),
      passportNumber: Yup.string()
        .trim()
        .test(
          'passportNumberRequired',
          'Passport number is required for foreigners',
          function (value) {
            return this.parent.nationlityType === 'foreigner' ? value ? true : false : true;
          }
        ),
      nationality: Yup.string()
        .trim()
        .test(
          'nationalityRequired',
          'Nationality is required for foreigners',
          function (value) {
            
            return this.parent.nationlityType === 'foreigner' ? value ? true : false : true;
          }
        ),
      email: Yup.string()
        .trim()
        .email('Invalid email format')
        .required('Email is required'),
      phone: Yup.string()
        .trim()
        .required('Phone is required')
        .matches(/^\d+$/, 'Phone must be a valid number'),
      firstName: Yup.string()
        .trim()
        .required('First name is required'),
      middleName: Yup.string()
        .trim(),
      lastName: Yup.string()
        .trim()
        .required('Last name is required'),
      referenceData: Yup.array()
        .of(Yup.object())
        .required('Reference data is required')
        .min(1, 'At least one reference is required'),
    })
  },
  "TM 47 Registration" : {
    feilds: [
      { name: "writtenAt", type: "input", inputType: "text", placeholder: "Written at", label: "Written at" },
      { name: "name", type: "input", inputType: "text", placeholder: "Name", label: "Name" },
      { name: "nationality", type: "input", inputType: "text", placeholder: "Nationality", label: "Nationality" },
      { name: "visa", type: "select", inputType: "select", placeholder: "Visa", label: "Visa", options: [{ name: "Tourist", value: "tourist" }, { name: "NON-IMM", value: "NON-IMM" }] },
      { name: "date", type: "date", inputType: "date", placeholder: "Date", label: "Date" },
      { name: "enteredThaiDate", type: "date", inputType: "date", placeholder: "Enter Thailand date", label: "Enter Thailand date" },
      { name: "enterBy", type: "input", inputType: "text", placeholder: "Enter by", label: "Enter by" },
      { name: "passportNumber", type: "input", inputType: "text", placeholder: "Passport number", label: "Passport number" },
      { name: "arrivalCardNo", type: "input", inputType: "text", placeholder: "Arrival card no", label: "Arrival card no" },
      { name: "presentAddress90Days", type: "input", inputType: "text", placeholder: "90 days present address", label: "90 days present address" },
      { name: "lane", type: "input", inputType: "text", placeholder: "Lane/Road", label: "Lane/Road" },
      { name: "tambol", type: "input", inputType: "text", placeholder: "Tambol", label: "Tambol" },
      { name: "amphur", type: "input", inputType: "text", placeholder: "Amphur", label: "Amphur" },
      { name: "province", type: "input", inputType: "text", placeholder: "Province", label: "Province" },
      { name: "phone", type: "input", inputType: "text",  placeholder: "Phone", label: "Phone"},
      { name: "signature", type: "input", inputType: "file", placeholder: "Signature", label: "Signature"},
    ],
    initialValue: {
      writtenAt: "",
      name: "",
      nationality: "",
      visa: "tourist",
      date: "",
      enteredThaiDate: "",
      enterBy: "",
      passportNumber: "",
      arrivalCardNo: "",
      presentAddress90Days: "",
      lane: "",
      tambol: "",
      amphur: "",
      province: "",
      phone: "",
      signature: "",
    },
    validation: Yup.object().shape({
      writtenAt: Yup.string().trim().required('Written at location is required'),
      name: Yup.string().trim().required('Name is required'),
      nationality: Yup.string().trim().required('Nationality is required'),
      visa: Yup.string().trim().required('Visa type is required'),
      date: Yup.string().required('Date is required').typeError('Invalid date format'),
      enteredThaiDate: Yup.string().required('Entered Thai date is required').typeError('Invalid date format'),
      enterBy: Yup.string().trim().required('Entry method is required'),
      passportNumber: Yup.string().trim().required('Passport number is required'),
      arrivalCardNo: Yup.string().trim().required('Arrival card number is required'),
      presentAddress90Days: Yup.string().trim().required('Present address for 90 days is required'),
      lane: Yup.string().trim().required('Lane is required'),
      tambol: Yup.string().trim().required('Tambol is required'),
      amphur: Yup.string().trim().required('Amphur is required'),
      province: Yup.string().trim().required('Province is required'),
      phone: Yup.string()
        .trim()
        .required('Phone number is required')
        .matches(/^\d+$/, 'Phone must be a valid number'),
      signature: Yup.string().trim().required('Signature is required'),
    })
  },
  "Tourist Visa": {
    feilds: [
      ...visaFormFeilds,
      { name: "passport", type: 'input', inputType: 'file', placeholder: "Passport", label:'Passport'},
      { name: "travelDocument", type: 'input', inputType: 'file', placeholder: "Travel document", label:'Travel document'},
      { name: "photo", type: 'input', inputType: 'file', placeholder: "Photograph", label:'Photograph'},
      { name: "currentLocationDocs", type: 'input', inputType: 'file', placeholder: "Current location file", label:'Current location file'},
      { name: "travelBookingConfirmation", type: 'input', inputType: 'file', placeholder: "Booking confirmation", label:'Booking confirmation'},
      { name: "accommodationInThailand", type: 'input', inputType: 'file', placeholder: "Accommodation", label:'Accommodation'},
      // { name: "familyDetails", type: 'Array', inputType: "Array", feilds: {}
    ],
    initialValue: {
      ...visaFormInitialValues,
      passport: "",
      travelDocument: "",
      photo: "",
      currentLocationDocs: "",
      travelBookingConfirmation: "",
      accommodationInThailand: "",
      // familyDetails: []
    },
    validation: Yup.object().shape({
      ...visaFormValidationSchema,
      passport: Yup.string().required('Passport is required'),
      travelDocument: Yup.string().required('Travel document is required'),
      photo: Yup.string().required('Photograph is required'),
      currentLocationDocs: Yup.string().required('Current location file is required'),
      travelBookingConfirmation: Yup.string().required('Booking confirmation is required'),
      accommodationInThailand: Yup.string().required('Accommodation is required'),
      // familyDetails: Yup.array().of(Yup.object().shape({

      // }))
    })
  },
  "Medical Treatment Visa": {
    feilds: [
      ...visaFormFeilds,
      { name: "passport", type: 'input', inputType: 'file', placeholder: "Passport", label: 'Passport' },
      { name: "travelDocument", type: 'input', inputType: 'file', placeholder: "Travel document", label: 'Travel document' },
      { name: "photo", type: 'input', inputType: 'file', placeholder: "Photograph", label: 'Photograph' },
      { name: "currentLocationDocs", type: 'input', inputType: 'file', placeholder: "Current location file", label: 'Current location file' },
      { name: "travelBookingConfirmation", type: 'input', inputType: 'file', placeholder: "Booking confirmation", label: 'Booking confirmation' },
      { name: "accommodationInThailand", type: 'input', inputType: 'file', placeholder: "Accommodation", label: 'Accommodation' },
      { name: "appointmentHospital", type: 'input', inputType: 'file', placeholder: "Hospital appointment", label: 'Hospital appointment' },

    ],
    initialValue: {
      ...visaFormInitialValues,
      passport: "",
      travelDocument: "",
      photo: "",
      currentLocationDocs: "",
      travelBookingConfirmation: "",
      accommodationInThailand: "",
      appointmentHospital: "",
 
    },
    validation: Yup.object().shape({
      ...visaFormValidationSchema,
      passport: Yup.string().required('Passport is required'),
      travelDocument: Yup.string().required('Travel document is required'),
      photo: Yup.string().required('Photograph is required'),
      currentLocationDocs: Yup.string().required('Current location file is required'),
      travelBookingConfirmation: Yup.string().required('Booking confirmation is required'),
      accommodationInThailand: Yup.string().required('Accommodation is required'),
      appointmentHospital: Yup.string().required('Appointment is required'),
      
    })
  },
  "Non-Immigrant B / Employee":{
    feilds: [
      ...visaFormFeilds,
      { name: "passport", type: 'input', inputType: 'file', placeholder: "Passport", label: 'Passport' },
      { name: "travelDocument", type: 'input', inputType: 'file', placeholder: "Travel document", label: 'Travel document' },
      { name: "photo", type: 'input', inputType: 'file', placeholder: "Photograph", label: 'Photograph' },
      { name: "currentLocationDocs", type: 'input', inputType: 'file', placeholder: "Current location", label: 'Current location' },
      { name: "financialEvidence", type: 'input', inputType: 'file', placeholder: "Financial evidence", label: 'Financial evidence' },
      { name: "WP32", type: 'input', inputType: 'file', placeholder: "WP32", label: 'WP32' },
    ],
    initialValue: {
      ...visaFormInitialValues,
      passport: "",
      travelDocument: "",
      photo: "",
      currentLocationDocs: "",
      financialEvidence: "",
      WP32:""

    },
    validation: Yup.object().shape({
      ...visaFormValidationSchema,
      passport: Yup.string().required('Passport is required'),
      travelDocument: Yup.string().required('Travel document is required'),
      photo: Yup.string().required('Photograph is required'),
      currentLocationDocs: Yup.string().required('Current location is required'),
      financialEvidence: Yup.string().required('Financial evidence is required'),
      WP32: Yup.string().required('WP32 is required'),     
    }),
  },

  "Non-Immigrant B / Business Visit" :{
    feilds: [
      ...visaFormFeilds,
      { name: "passport", type: 'input', inputType: 'file', placeholder: "Passport", label: 'Passport' },
      { name: "travelDocument", type: 'input', inputType: 'file', placeholder: "Travel document", label: 'Travel document' },
      { name: "photo", type: 'input', inputType: 'file', placeholder: "Photograph", label: 'Photograph' },
      { name: "currentLocationDocs", type: 'input', inputType: 'file', placeholder: "Current location", label: 'Current location' },
      { name: "financialEvidence", type: 'input', inputType: 'file', placeholder: "Financial evidence", label: 'Financial evidence' },
      { name: "employerLetterFromThai", type: 'input', inputType: 'file', placeholder: "Employer Letter From Thailand", label: 'Employer Letter From Thailand' },
    ],
    initialValue: {
      ...visaFormInitialValues,
      passport: "",
      travelDocument: "",
      photo: "",
      currentLocationDocs: "",
      financialEvidence: "",
      employerLetterFromThai:""
    },
    validation: Yup.object().shape({
      ...visaFormValidationSchema,
      passport: Yup.string().required('Passport is required'),
      travelDocument: Yup.string().required('Travel document is required'),
      photo: Yup.string().required('Photograph is required'),
      currentLocationDocs: Yup.string().required('Current location is required'),
      financialEvidence: Yup.string().required('Financial evidence is required'),
      employerLetterFromThai: Yup.string().required('Employee letter from thai is required')
    }),
  },

  "Non-Immigrant / Frequent Business Visit (3 years)": {
    feilds: [
      ...visaFormFeilds,
      { name: "passport", type: 'input', inputType: 'file', placeholder: "Passport", label: 'Passport' },
      { name: "travelDocument", type: 'input', inputType: 'file', placeholder: "Travel document", label: 'Travel document' },
      { name: "photo", type: 'input', inputType: 'file', placeholder: "Photograph", label: 'Photograph' },
      { name: "appliedCountryResidence", type: 'input', inputType: 'file', placeholder: "Applied country residence", label: 'Applied country residence' },
      { name: "financialEvidence", type: 'input', inputType: 'file', placeholder: "Financial evidence", label: 'Financial evidence' },
      { name: "frequentBusinessVisitProof", type: 'input', inputType: 'file', placeholder: "Proof of frequent business visit", label: 'Proof of frequent business visit' },
      { name: "employmentProof", type: 'input', inputType: 'file', placeholder: "Proof of employment", label: 'Proof of employment' },
      { name: "certification", type: 'input', inputType: 'file', placeholder: "Certification", label: 'Certification' },
   
    ],
    initialValue: {
      ...visaFormInitialValues,
      passport: "",
      travelDocument: "",
      photo: "",
      appliedCountryResidence: "",
      financialEvidence: "",
      frequentBusinessVisitProof: "",
      employmentProof: "",
      certification: ""
    },
    validation: Yup.object().shape({
      ...visaFormValidationSchema,
      passport: Yup.string().required('Passport is required'),
      travelDocument: Yup.string().required('Travel document is required'),
      photo: Yup.string().required('Photograph is required'),
      appliedCountryResidence: Yup.string().required('Applied country residence is required'),
      financialEvidence: Yup.string().required('Financial evidence is required'),
      frequentBusinessVisitProof: Yup.string().required('Proof of frequent business visit is required'),
      employmentProof: Yup.string().required('Proof of employment is required'),
      certification: Yup.string().required('Certification is required'),
    })

  },
  "Non-Immigrant B / Employed as a academic staff / teacher in Thailand": {
    feilds: [
      ...visaFormFeilds,
      { name: "passport", type: 'input', inputType: 'file', placeholder: "Passport", label: 'Passport' },
      { name: "travelDocument", type: 'input', inputType: 'file', placeholder: "Travel document", label: 'Travel document' },
      { name: "photo", type: 'input', inputType: 'file', placeholder: "Photograph", label: 'Photograph' },
      { name: "currentLocationDocs", type: 'input', inputType: 'file', placeholder: "Current location file", label: 'Current location file' },
      { name: "financialEvidence", type: 'input', inputType: 'file', placeholder: "Financial evidence", label: 'Financial evidence' },
      { name: "letterRelevantAuthority", type: 'input', inputType: 'file', placeholder: "Letter from relevant authority", label: 'Letter from relevant authority' },
      { name: "criminalRecordClearance", type: 'input', inputType: 'file', placeholder: "Criminal record clearance", label: 'Criminal record clearance' },
    ],
    initialValue: {
      ...visaFormInitialValues,
      passport: "",
      travelDocument: "",
      photo: "",
      currentLocationDocs: "",
      financialEvidence: "",
      letterRelevantAuthority: "",
      criminalRecordClearance: "",
      
    },
    validation: Yup.object().shape({
      ...visaFormValidationSchema,
      passport: Yup.string().required('Passport is required'),
      travelDocument: Yup.string().required('Travel document is required'),
      photo: Yup.string().required('Photograph is required'),
      currentLocationDocs: Yup.string().required('Current location file is required'),
      financialEvidence: Yup.string().required('Financial evidence is required'),
      letterRelevantAuthority: Yup.string().required('Letter from relevant authority is required'),
      criminalRecordClearance: Yup.string().required('Criminal record clearance is required'),
    })
  },
  "Non-Immigrant IB / Employment (BOI)":{
    feilds: [
      ...visaFormFeilds,
      { name: "passport", type: 'input', inputType: 'file', placeholder: "Passport", label: 'Passport' },
      { name: "travelDocument", type: 'input', inputType: 'file', placeholder: "Travel document", label: 'Travel document' },
      { name: "photo", type: 'input', inputType: 'file', placeholder: "Photograph", label: 'Photograph' },
      { name: "currentLocationDocs", type: 'input', inputType: 'file', placeholder: "Current location file", label: 'Current location file' },
      { name: "financialEvidence", type: 'input', inputType: 'file', placeholder: "Financial evidence", label: 'Financial evidence' },
      { name: "letterBOI", type: 'input', inputType: 'file', placeholder: "Letter from BOI", label: 'Letter from BOI' },
      
    ],
    initialValue: {
      ...visaFormInitialValues,
      passport: "",
      travelDocument: "",
      photo: "",
      currentLocationDocs: "",
      financialEvidence: "",
      letterBOI: "",
    
    },
    validation: Yup.object().shape({
      ...visaFormValidationSchema,
      passport: Yup.string().required('Passport is required'),
      travelDocument: Yup.string().required('Travel document is required'),
      photo: Yup.string().required('Photograph is required'),
      currentLocationDocs: Yup.string().required('Current location file is required'),
      financialEvidence: Yup.string().required('Financial evidence is required'),
      letterBOI: Yup.string().required('Letter is required'),
     
    })
  },
  "Non-Immigrant IB / Business Visit (BOI)":{
    feilds: [
      ...visaFormFeilds,
      { name: "passport", type: 'input', inputType: 'file', placeholder: "Passport", label: 'Passport' },
      { name: "travelDocument", type: 'input', inputType: 'file', placeholder: "Travel document", label: 'Travel document' },
      { name: "photo", type: 'input', inputType: 'file', placeholder: "Photograph", label: 'Photograph' },
      { name: "currentLocationDocs", type: 'input', inputType: 'file', placeholder: "Current location file", label: 'Current location file' },
      { name: "financialEvidence", type: 'input', inputType: 'file', placeholder: "Financial evidence", label: 'Financial evidence' },
      { name: "letterBOI", type: 'input', inputType: 'file', placeholder: "Letter from BOI", label: 'Letter from BOI' },

    ],
    initialValue: {
      ...visaFormInitialValues,
      passport: "",
      travelDocument: "",
      photo: "",
      currentLocationDocs: "",
      financialEvidence: "",
      letterBOI: "",

    },
    validation: Yup.object().shape({
      ...visaFormValidationSchema,
      passport: Yup.string().required('Passport is required'),
      travelDocument: Yup.string().required('Travel document is required'),
      photo: Yup.string().required('Photograph is required'),
      currentLocationDocs: Yup.string().required('Current location file is required'),
      financialEvidence: Yup.string().required('Financial evidence is required'),
      letterBOI: Yup.string().required('Letter is required'),
    })
  },
  "Non-Immigrant L-A / Migrant Worker under MOU L-A":{
    feilds: [
      ...visaFormFeilds,
      { name: "passport", type: 'input', inputType: 'file', placeholder: "Passport", label: 'Passport' },
      { name: "travelDocument", type: 'input', inputType: 'file', placeholder: "Travel document", label: 'Travel document' },
      { name: "photo", type: 'input', inputType: 'file', placeholder: "Photograph", label: 'Photograph' },
      { name: "currentLocationDocs", type: 'input', inputType: 'file', placeholder: "Current location file", label: 'Current location file' },
      { name: "letterMinistoryOfLabour", type: 'input', inputType: 'file', placeholder: "Letter from minisitory of labour", label: 'Letter from minisitory of labour' },
      { name: "employerLetter", type: 'input', inputType: 'file', placeholder: "Employer letter", label: 'Employee letter' },
      { name: "workPermit", type: 'input', inputType: 'file', placeholder: "Work permit", label: 'Employee letter' },
      { name: "workExperice", type: 'input', inputType: 'file', placeholder: "Work permit", label: 'Employee letter' },
      { name: "professionalQualification", type: 'input', inputType: 'file', placeholder: "Professional qualification", label: 'Professional qualification' },
      { name: "previousEmployment", type: 'input', inputType: 'file', placeholder: "Previous employment", label: 'Previous employment' },
      
    ],
    initialValue: {
      ...visaFormInitialValues,
      passport: "",
      travelDocument: "",
      photo: "",
      currentLocationDocs: "",
      letterMinistoryOfLabour:"",
      employerLetter: "",
      workPermit: "",
      workExperice: "",
      professionalQualification: "",
      previousEmployment: "",

    },
    validation: Yup.object().shape({
      ...visaFormValidationSchema,
      passport: Yup.string().required('Passport is required'),
      travelDocument: Yup.string().required('Travel document is required'),
      photo: Yup.string().required('Photograph is required'),
      currentLocationDocs: Yup.string().required('Current location file is required'),
      letterMinistoryOfLabour: Yup.string().required('Letter ministory of labour is required'),
      employerLetter: Yup.string().required('Employer letter is required'),
      workPermit: Yup.string().required('Work permit is required'),
      workExperice: Yup.string().required('Work permit is required'),
      professionalQualification: Yup.string().required('Professional qualification is required'),
      previousEmployment: Yup.string().required('Previous employment is required'),
    })
  },
  "Non-Immigrant L-A / Children under 18 years old of Non-Immigrant L-A Visa Holder (O(L-A))":{
    feilds: [
      ...visaFormFeilds,
      { name: "passport", type: 'input', inputType: 'file', placeholder: "Passport", label: 'Passport' },
      { name: "travelDocument", type: 'input', inputType: 'file', placeholder: "Travel document", label: 'Travel document' },
      { name: "photo", type: 'input', inputType: 'file', placeholder: "Photograph", label: 'Photograph' },
      { name: "currentLocationDocs", type: 'input', inputType: 'file', placeholder: "Current location file", label: 'Current location file' },
      { name: "NonImmigrantLAVisaHolder", type: 'input', inputType: 'file', placeholder: "Non-Immigrant L-A visa of the Non-Immigrant L-A visa holder", label: 'Non-Immigrant L-A visa of the Non-Immigrant L-A visa holder' },
      { name: "relationProof", type: 'input', inputType: 'file', placeholder: "Relation proof", label: 'Relation proof' },
      

    ],
    initialValue: {
      ...visaFormInitialValues,
      passport: "",
      travelDocument: "",
      photo: "",
      currentLocationDocs: "",
      NonImmigrantLAVisaHolder:"",
      relationProof:""
    },
    validation: Yup.object().shape({
      ...visaFormValidationSchema,
      passport: Yup.string().required('Passport is required'),
      travelDocument: Yup.string().required('Travel document is required'),
      photo: Yup.string().required('Photograph is required'),
      currentLocationDocs: Yup.string().required('Current location file is required'),
      NonImmigrantLAVisaHolder: Yup.string().required('Non-Immigrant L-A visa of the Non-Immigrant L-A visa holder is required'),
      relationProof: Yup.string().required('Relation proof is required'),
    })
  },
  "DESTINATION THAILAND VISA (DTV)":{
    feilds: [
      ...visaFormFeilds,
      { name: "passport", type: 'input', inputType: 'file', placeholder: "Passport", label: 'Passport' },
      { name: "photo", type: 'input', inputType: 'file', placeholder: "Photograph", label: 'Photograph' },
      { name: "currentLocationDocs", type: 'input', inputType: 'file', placeholder: "Current location file", label: 'Current location file' },
      { name: "financialEvidence", type: 'input', inputType: 'file', placeholder: "Financial evidence file", label: 'Financial evidence file' },
      { name: "emplomentCerficate", type: 'input', inputType: 'file', placeholder: "Employment certificate", label: 'Employment certificate' },
      { name: "portfyolio", type: 'input', inputType: 'file', placeholder: "portfolio", label: 'portfolio' },
      { name: "hospitalConfirmation", type: 'input', inputType: 'file', placeholder: "Hospital confirmation", label: 'Hospital confirmation' },
      { name: "spouse", type: 'input', inputType: 'file', placeholder: "Spouse", label: 'Spouse' },
     
    ],
    initialValue: {
      ...visaFormInitialValues,
      passport: "",
      photo: "",
      currentLocationDocs: "",
      financialEvidence: "",
      employmentCerficate: "",
      portfyolio: "",
      hospitalConfirmation: "",
      spouse: "",
    },
    validation: Yup.object().shape({
      ...visaFormValidationSchema,
      passport: Yup.string().required('Passport is required'),
      photo: Yup.string().required('Photograph is required'),
      currentLocationDocs: Yup.string().required('Current location file is required'),
      financialEvidence: Yup.string().required('Financial evidence is required'),
      employmentCerficate: Yup.string().required('Employment certificate is required'),
      portfolio: Yup.string().required('Portfolio is required'),
      hospitalConfirmation: Yup.string().required('Hospital confirmation is required'),
      spouse: Yup.string().required('Spouse is required'),
      
    })
  },
  "NON-ED PLUS VISA": {
    feilds: [
      ...visaFormFeilds,
      { name: "passport", type: 'input', inputType: 'file', placeholder: "Passport", label: 'Passport' },
      { name: "photo", type: 'input', inputType: 'file', placeholder: "Photograph", label: 'Photograph' },
      { name: "currentLocationDocs", type: 'input', inputType: 'file', placeholder: "Current location file", label: 'Current location file' },
      { name: "financialEvidence", type: 'input', inputType: 'file', placeholder: "Financial evidence file", label: 'Financial evidence file' },
      { name: "universityEnrollmentConfirmation", type: 'input', inputType: 'file', placeholder: "Employment certificate", label: 'Employment certificate' },
      { name: "letterFromUniversity", type: 'input', inputType: 'file', placeholder: "Letter from university", label: 'Letter from university' },
      

    ],
    initialValue: {
      ...visaFormInitialValues,
      passport: "",
      photo: "",
      currentLocationDocs: "",
      financialEvidence: "",
      universityEnrollmentConfirmation:"",
      letterFromUniversity: "",
      
    },
    validation: Yup.object().shape({
      ...visaFormValidationSchema,
      passport: Yup.string().required('Passport is required'),
      photo: Yup.string().required('Photograph is required'),
      currentLocationDocs: Yup.string().required('Current location file is required'),
      financialEvidence: Yup.string().required('Financial evidence is required'),
      universityEnrollmentConfirmation: Yup.string().required('University enrollment confirmation is required'),
      letterFromUniversity: Yup.string().required('Letter from university is required'),

    })
  },
  "Visa Extension": {
    feilds: [
      { name: "writtenAt", type: "input", inputType: "text", placeholder: "Written at", label: "Written at" },
      { name: "firstName", type: "input", inputType: "text", placeholder: "First name", label: "First name" },
      { name: "middleName", type: "input", inputType: "text", placeholder: "Middle name", label: "Middle name" },
      { name: "lastName", type: "input", inputType: "text", placeholder: "Last name", label: "Last name" },
      { name: "nationality", type: "input", inputType: "text", placeholder: "Nationality", label: "Nationality" },
      { name: "placeOfBirth", type: "input", inputType: "text", placeholder: "Place of birth", label: "place of birth" },
      { name: "passportDate", type: "input", inputType: "text", placeholder: "passport date", label: "passport date" },
      { name: "date", type: "date", inputType: "date", placeholder: "Date", label: "Date" },
      { name: "enteredThaiDate", type: "date", inputType: "date", placeholder: "Enter Thailand date", label: "Enter Thailand date" },
      { name: "enterBy", type: "input", inputType: "text", placeholder: "Enter by", label: "Enter by" },
      { name: "passportNumber", type: "input", inputType: "text", placeholder: "Passport number", label: "Passport number" },
      { name: "arrivalCardNo", type: "input", inputType: "text", placeholder: "Arrival card no", label: "Arrival card no" },
      { name: "presentAddress", type: "input", inputType: "text", placeholder: "Present address", label: "Present address" },
      { name: "line", type: "input", inputType: "text", placeholder: "Line", label: "Line" },
      { name: "road", type: "input", inputType: "text", placeholder: "Road", label: "Road" },
      { name: "tambol", type: "input", inputType: "text", placeholder: "Tambol", label: "Tambol" },
      { name: "amphur", type: "input", inputType: "text", placeholder: "Amphur", label: "Amphur" },
      { name: "province", type: "input", inputType: "text", placeholder: "Province", label: "Province" },
      { name: "tempraryStay", type: "input", inputType: "text", placeholder: "Temprary stay", label: "Temprary stay" },
      { name: "lineTemp", type: "input", inputType: "text", placeholder: "Temprary stay Line", label: "Temprary stay Line" },
      { name: "roadTemp", type: "input", inputType: "text", placeholder: "Temprary stay Road", label: "Temprary stay Road" },
      { name: "tambolTemp", type: "input", inputType: "text", placeholder: "Temprary stay Tambol", label: "Temprary stay Tambol" },
      { name: "amphurTemp", type: "input", inputType: "text", placeholder: "Temprary stay Amphur", label: "Temprary stay Amphur" },
      { name: "provinceTemp", type: "input", inputType: "text", placeholder: "Temprary stay Province", label: "Temprary stay Province" },
      { name: "dateTemp", type: "date", inputType: "date", placeholder: "Temprary stay date", label: "Temprary stay date" },      
      { name: "attachedPerson", type: "input", inputType: "text", placeholder: "Attached person", label: "Attached person" },
      { name: "phone", type: "input", inputType: "text", placeholder: "Phone", label: "Phone" },
      { name: "docType", type: "select", inputType: "select", placeholder: "Document type", label: "Document type", options: [{ name: "Passport", value: "passport" }, { name: "traveling document", value: "Traveling document" }] },
      { name: "documentNo", type: "input", inputType: "text", placeholder: "Document no", label: "Document no" },
      { name: "documentIssueDate", type: "input", inputType: "text", placeholder: "Document issue date", label: "Document issue date" },
      { name: "documentIssueAt", type: "input", inputType: "text", placeholder: "Document issue at", label: "Document issue at" },
      { name: "documentExpiry", type: "input", inputType: "text", placeholder: "Document expiry", label: "Document expiry" },
      { name: "kindOfVisa", type: "input", inputType: "text", placeholder: "Kind of visa", label: "Kind of visa" },      
      { name: "arrivedBy", type: "input", inputType: "text", placeholder: "Arrived by", label: "Arrived by" },
      { name: "ArriveFrom", type: "input", inputType: "text", placeholder: "Arrived from", label: "Arrived from" },
      { name: "arrivePort", type: "input", inputType: "text", placeholder: "Arrived port", label: "Arrived port" },
      { name: "portDate", type: "date", inputType: "date", placeholder: "Port date", label: "Port date" },
      { name: "TM6No", type: "input", inputType: "text", placeholder: "TM6 No", label: "TM6 No" },
      { name: "extensionDuration", type: "input", inputType: "text", placeholder: "Extension duration", label: "Extension duration" },
      { name: "reason", type: "input", inputType: "text", placeholder: "Reason", label: "Reason" },
      { name: "age", type: "input", inputType: "text", placeholder: "Age", label: "Age" },
      { name: "addressInThai", type: "input", inputType: "text", placeholder: "Address in thai", label: "Address in thai" },
      { name: "applicationWrittenBy", type: "input", inputType: "text", placeholder: "Applicaton written by", label: "Applicaton written by" },
      { name: "lineThai", type: "input", inputType: "text", placeholder: "Thai line", label: "Thai line" },
      { name: "roadThai", type: "input", inputType: "text", placeholder: "Thai road", label: "Thai road" },
      { name: "tambonThai", type: "input", inputType: "text", placeholder: "Thai tambon", label: "Thai tambon" },
      { name: "amphoeThai", type: "input", inputType: "text", placeholder: "Thai amphoe", label: "Thai amphoe" },
      { name: "changwatThai", type: "input", inputType: "text", placeholder: "Thai changwat", label: "Thai changwat" },
      { name: "signature", type: "input", inputType: "file", placeholder: "Signature", label: "Signature" },
      { name: "writterSignature", type: "input", inputType: "file", placeholder: "Writter signature", label: "Writter signature" },
      { name: "myanmarEmbassyRecommendation", type: "input", inputType: "file", placeholder: "Myanmar embassy recommendation", label: "Myanmar embassy recommendation" },
      { name: "photo", type: "input", inputType: "file", placeholder: "photo", label: "photo" },
      { name: "passport", type: "input", inputType: "file", placeholder: "Passport", label: "Passport" },
      { name: "visaStamp", type: "input", inputType: "file", placeholder: "Passport", label: "Passport" },
    ],
    initialValue : {
      writtenAt: "",
      firstName: "",
      middleName: "",
      lastName: "",
      nationality: "",
      placeOfBirth: "",
      enteredThaiDate:"",
      enterBy:"",
      passportNumber:"",
      arrivalCardNo:"",
      presentAddress:"",
      line:"",
      road:"",
      tambol:"",
      amphur:"",
      province:"",
      tempraryStay:"",
      lineTemp:"",
      roadTemp:"",
      tambolTemp:"",
      amphurTemp:"",
      provinceTemp:"",
      dateTemp:"",
      attachedPerson:"",
      phone:"",
      docType:"",
      documentNo:"",
      documentIssueDate:"",
      documentIssueAt:"",
      documentExpiry:"",
      kindOfVisa:"",
      arrivedBy:"",
      arriveFrom:"",
      arrivePort:"",
      portDate:"",
      TM6No:"",
      extensionDuration:"",
      reason:"",
      age:"",
      addressInThai:"",
      applicationWrittenBy:"",
      lineThai:"",
      roadThai:"",
      tambonThai:"",
      amphoeThai:"",
      changwatThai:"",
      signature:"",
      writterSignature:"",
      myanmarEmbassyRecommendation:"",
      photo:"",
      passport:"",
      visaStamp:""      
    },
    validation: Yup.object().shape({
      writtenAt: Yup.string().trim().required('Location written at is required'),
      firstName: Yup.string().trim().required('First name is required'),
      middleName: Yup.string().trim(),
      lastName: Yup.string().trim().required('Last name is required'),
      nationality: Yup.string().trim().required('Nationality is required'),
      placeOfBirth: Yup.string().trim().required('Place of birth is required'),
      enteredThaiDate: Yup.string().required('Date entered in Thailand is required').typeError('Invalid date format'),
      enterBy: Yup.string().trim().required('Entry method is required'),
      passportNumber: Yup.string().trim().required('Passport number is required'),
      arrivalCardNo: Yup.string().trim().required('Arrival card number is required'),
      presentAddress: Yup.string().trim().required('Present address is required'),
      line: Yup.string().trim().required('Line is required'),
      road: Yup.string().trim().required('Road is required'),
      tambol: Yup.string().trim().required('Tambol is required'),
      amphur: Yup.string().trim().required('Amphur is required'),
      province: Yup.string().trim().required('Province is required'),
      tempraryStay: Yup.string().trim().required('Temporary stay address is required'),
      lineTemp: Yup.string().trim().required('Temporary stay line is required'),
      roadTemp: Yup.string().trim().required('Temporary stay road is required'),
      tambolTemp: Yup.string().trim().required('Temporary stay tambol is required'),
      amphurTemp: Yup.string().trim().required('Temporary stay amphur is required'),
      provinceTemp: Yup.string().trim().required('Temporary stay province is required'),
      dateTemp: Yup.date().required('Temporary stay date is required').typeError('Invalid date format'),
      attachedPerson: Yup.string().trim().required('Attached person information is required'),
      phone: Yup.string().trim().required('Phone number is required').matches(/^\d+$/, 'Phone must be a valid number'),
      docType: Yup.string().trim().required('Document type is required'),
      documentNo: Yup.string().trim().required('Document number is required'),
      documentIssueDate: Yup.date().required('Document issue date is required').typeError('Invalid date format'),
      documentIssueAt: Yup.string().trim().required('Document issue location is required'),
      documentExpiry: Yup.string().required('Document expiry date is required').typeError('Invalid date format'),
      kindOfVisa: Yup.string().trim().required('Kind of visa is required'),
      arrivedBy: Yup.string().trim().required('Arrival method is required'),
      arriveFrom: Yup.string().trim().required('Arrival from location is required'),
      arrivePort: Yup.string().trim().required('Arrival port is required'),
      portDate: Yup.string().required('Port entry date is required').typeError('Invalid date format'),
      TM6No: Yup.string().trim().required('TM6 number is required'),
      extensionDuration: Yup.string().trim().required('Extension duration is required'),
      reason: Yup.string().trim().required('Reason is required'),
      age: Yup.number().required('Age is required').typeError('Age must be a number'),
      addressInThai: Yup.string().trim().required('Address in Thai is required'),
      applicationWrittenBy: Yup.string().trim().required('Application writer is required'),
      lineThai: Yup.string().trim().required('Thai line is required'),
      roadThai: Yup.string().trim().required('Thai road is required'),
      tambonThai: Yup.string().trim().required('Thai tambon is required'),
      amphoeThai: Yup.string().trim().required('Thai amphoe is required'),
      changwatThai: Yup.string().trim().required('Thai changwat is required'),
      signature: Yup.string().trim().required('Signature is required'),
      writterSignature: Yup.string().trim().required('Writer signature is required'),
      myanmarEmbassyRecommendation: Yup.string().trim().required('Myanmar embassy recommendation is required'),
      photo: Yup.string().trim().required('Photo information is required'),
      passport: Yup.string().trim().required('Passport is required'),
      visaStamp: Yup.string().trim().required('Visa stamp information is required')
    })    
  },
  "Auxiliary form for visa purpose": {
    feilds: [
      { name: "immegrationBureau", type: "input", inputType: "text", placeholder: "Immigration bureau", label: "Immigration bureau" },
      { name: "date", type: "date", inputType: "date", placeholder: "Date", label: "Date" },
      { name: "firstName", type: "input", inputType: "text", placeholder: "First name", label: "First name" },
      { name: "middleName", type: "input", inputType: "text", placeholder: "Middle name", label: "Middle name" },
      { name: "lastName", type: "input", inputType: "text", placeholder: "Last name", label: "Last name" },
      { name: "age", type: "input", inputType: "text", placeholder: "Age", label: "Age" },
      { name: "placeOfBirth", type: "input", inputType: "text", placeholder: "Place of birth", label: "place of birth" },
      { name: "nationality", type: "input", inputType: "text", placeholder: "Nationality", label: "Nationality" },
      { name: "docType", type: "select", inputType: "select", placeholder: "Document type", label: "Document type", options: [{ name: "Passport", value: "passport" }, { name: "traveling document", value: "Traveling document" }] },
      { name: "documentNo", type: "input", inputType: "text", placeholder: "Document no", label: "Document no" },
      { name: "documentIssueDate", type: "input", inputType: "text", placeholder: "Document issue date", label: "Document issue date" },
      { name: "documentIssueAt", type: "input", inputType: "text", placeholder: "Document issue at", label: "Document issue at" },
      { name: "documentExpiry", type: "input", inputType: "text", placeholder: "Document expiry", label: "Document expiry" },
      { name: "kindOfVisa", type: "input", inputType: "text", placeholder: "Kind of visa", label: "Kind of visa" },
      { name: "arrivedBy", type: "input", inputType: "text", placeholder: "Arrived by", label: "Arrived by" },
      { name: "ArriveFrom", type: "input", inputType: "text", placeholder: "Arrived from", label: "Arrived from" },
      { name: "arrivePort", type: "input", inputType: "text", placeholder: "Arrived port", label: "Arrived port" },
      { name: "portDate", type: "date", inputType: "date", placeholder: "Port date", label: "Port date" },
      { name: "TM6No", type: "input", inputType: "text", placeholder: "TM6 No", label: "TM6 No" },
      { name: "reason", type: "input", inputType: "text", placeholder: "Reason", label: "Reason" },
      { name: "signature", type: "input", inputType: "file", placeholder: "Signature", label: "Signature" },
      { name: "addressInThai", type: "input", inputType: "text", placeholder: "Address in thai", label: "Address in thai" },
      { name: "applicationWrittenBy", type: "input", inputType: "text", placeholder: "Applicaton written by", label: "Applicaton written by" },
      { name: "lineThai", type: "input", inputType: "text", placeholder: "Thai line", label: "Thai line" },
      { name: "roadThai", type: "input", inputType: "text", placeholder: "Thai road", label: "Thai road" },
      { name: "tambonThai", type: "input", inputType: "text", placeholder: "Thai tambon", label: "Thai tambon" },
      { name: "amphoeThai", type: "input", inputType: "text", placeholder: "Thai amphoe", label: "Thai amphoe" },
      { name: "changwatThai", type: "input", inputType: "text", placeholder: "Thai changwat", label: "Thai changwat" },
      { name: "writterSignature", type: "input", inputType: "file", placeholder: "Writter signature", label: "Writter signature" },
      { name: "photo", type: "input", inputType: "file", placeholder: "photo", label: "photo" },

    ],
    initialValue: {
      immegrationBureau: "",
      date: "",
      firstName: "",
      middleName: "",
      lastName: "",
      age: "",
      placeOfBirth: "",
      nationality: "",
      docType: "",
      documentNo: "",
      documentIssueDate: "",
      documentIssueAt: "",
      documentExpiry: "",
      kindOfVisa: "",
      arrivedBy: "",
      arriveFrom: "",
      arrivePort: "",
      portDate: "",
      TM6No: "",
      reason: "",
      signature: "",
      addressInThai: "",
      applicationWrittenBy: "",
      lineThai: "",
      roadThai: "",
      tambonThai: "",
      amphoeThai: "",
      changwatThai: "",
      writterSignature: "",
      photo: "",
    },
    validation: Yup.object().shape({
      immegrationBureau: Yup.string().trim().required('Immigration Bureau is required'),
      date: Yup.string().required('Date is required').typeError('Invalid date format'),
      firstName: Yup.string().trim().required('First name is required'),
      middleName: Yup.string().trim(),
      lastName: Yup.string().trim().required('Last name is required'),
      age: Yup.number().required('Age is required').typeError('Age must be a number'),
      placeOfBirth: Yup.string().trim().required('Place of birth is required'),
      nationality: Yup.string().trim().required('Nationality is required'),
      docType: Yup.string().trim().required('Document type is required'),
      documentNo: Yup.string().trim().required('Document number is required'),
      documentIssueDate: Yup.string().required('Document issue date is required').typeError('Invalid date format'),
      documentIssueAt: Yup.string().trim().required('Document issue location is required'),
      documentExpiry: Yup.date().required('Document expiry date is required').typeError('Invalid date format'),
      kindOfVisa: Yup.string().trim().required('Kind of visa is required'),
      arrivedBy: Yup.string().trim().required('Arrival method is required'),
      arriveFrom: Yup.string().trim().required('Arrival from location is required'),
      arrivePort: Yup.string().trim().required('Arrival port is required'),
      portDate: Yup.string().required('Port entry date is required').typeError('Invalid date format'),
      TM6No: Yup.string().trim().required('TM6 number is required'),
      reason: Yup.string().trim().required('Reason is required'),
      signature: Yup.string().trim().required('Signature is required'),
      addressInThai: Yup.string().trim().required('Address in Thai is required'),
      applicationWrittenBy: Yup.string().trim().required('Application writer is required'),
      lineThai: Yup.string().trim().required('Thai line is required'),
      roadThai: Yup.string().trim().required('Thai road is required'),
      tambonThai: Yup.string().trim().required('Thai tambon is required'),
      amphoeThai: Yup.string().trim().required('Thai amphoe is required'),
      changwatThai: Yup.string().trim().required('Thai changwat is required'),
      writterSignature: Yup.string().trim().required('Writer signature is required'),
      photo: Yup.string().trim().required('Photo information is required'),
    })
  },

  "Oversea Worker Identity Card in Myanmar": {
    feilds: [
      { name: "date", type: "date", inputType: "date", placeholder: "Date", label: "Date" },
      { name: "region", type: "input", inputType: "text", placeholder: "Region", label: "Region" },
      { name: "township", type: "input", inputType: "text", placeholder: "Township", label: "Township" },
      { name: "village", type: "input", inputType: "text", placeholder: "Village", label: "Village" },
      { name: "mark", type: "input", inputType: "text", placeholder: "Mark", label: "Mark" },
      { name: "nationalIdCardNo", type: "input", inputType: "text", placeholder: "National id card no", label: "National id card no" },
      { name: "country", type: "input", inputType: "text", placeholder: "Country", label: "Country" },
      { name: "companyName", type: "input", inputType: "text", placeholder: "Company name", label: "Company name" },
      { name: "employment", type: "input", inputType: "text", placeholder: "Employment", label: "Employment" },
      { name: "salary", type: "input", inputType: "text", placeholder: "Salary", label: "Salary" },
      { name: "byAbout", type: "input", inputType: "text", placeholder: "byAbout", label: "byAbout" },
      { name: "visitPermission", type: "input", inputType: "text", placeholder: "byAbout", label: "byAbout" },
      { name: "name", type: "input", inputType: "text", placeholder: "Name", label: "Name" },
      { name: "phone", type: "input", inputType: "text", placeholder: "Phone", label: "Phone" },
      { name: "email", type: "input", inputType: "text", placeholder: "Email", label: "Email" },
      { name: "otherName", type: "input", inputType: "text", placeholder: "Other name", label: "Other name" },
      { name: "fatherName", type: "input", inputType: "text", placeholder: "Father name", label: "Father name" },
      { name: "motherName", type: "input", inputType: "text", placeholder: "Mother name", label: "Mother name" },
      { name: "age", type: "input", inputType: "text", placeholder: "Age", label: "Age" },
      { name: "companyAddress", type: "input", inputType: "text", placeholder: "Company address", label: "Company address" },
      { name: "labourCard", type: "input", inputType: "file", placeholder: "Labour card", label: "Labour card" },
      { name: "passport", type: "input", inputType: "file", placeholder: "Passport", label: "Passport" },
      { name: "nrc", type: "input", inputType: "file", placeholder: "NRC", label: "NRC" },
      { name: "workPermit", type: "input", inputType: "file", placeholder: "Work permit", label: "Work permit" },
      { name: "ci", type: "input", inputType: "file", placeholder: "CI", label: "CI" },
      { name: "offerLatter", type: "input", inputType: "file", placeholder: "Offer latter", label: "Offer latter" },
      { name: "photo", type: "input", inputType: "file", placeholder: "Photo", label: "Photo" },
      { name: "leaveForm", type: "input", inputType: "file", placeholder: "Leave form", label: "Leave form" },
      { name: "UID", type: "input", inputType: "file", placeholder: "UID", label: "UID" },
      { name: "EID", type: "input", inputType: "file", placeholder: "EID", label: "EID" },
    ],
    initialValue: {
      date: "",
      region: "",
      township: "",
      village: "",
      mark: "",
      nationalIdCardNo: "",
      country: "",
      companyName: "",
      employment: "",
      salary: "",
      byAbout: "",
      visitPermission: "",
      name: "",
      phone: "",
      email: "",
      otherName: "",
      fatherName: "",
      motherName: "",
      age: "",
      companyAddress: "",
      labourCard: "",
      passport: "",
      nrc: "",
      workPermit: "",
      ci: "",
      offerLatter: "",
      photo: "",
      leaveForm: "",
      UID: "",
      EID: "",
    },
    validation: Yup.object().shape({
      date: Yup.string().required("Date is required"),
      region: Yup.string().required("Region is required"),
      township: Yup.string().required("Township is required"),
      village: Yup.string().required("Village is required"),
      mark: Yup.string().required("Mark is required"),
      nationalIdCardNo: Yup.string().required("National id card no is required"),
      country: Yup.string().required("Country is required"),
      companyName: Yup.string().required("Company name is required"),
      employment: Yup.string().required("Employment is required"),
      salary: Yup.string().required("Salary is required"),
      byAbout: Yup.string().required("byAbout is required"),
      visitPermission: Yup.string().required("Visit permission is required"),
      name: Yup.string().required("Name is required"),
      phone: Yup.string().trim().required('Phone is required').matches(/^\d+$/, 'Phone must be a valid number'),
      email: Yup.string().required("Email is required"),
      otherName: Yup.string().required("Other name is required"),
      fatherName: Yup.string().required("Father name is required"),
      motherName: Yup.string().required("Mother name is required"),
      age: Yup.string().required("Age is required"),
      companyAddress: Yup.string().required("Company address is required"),
      labourCard: Yup.string().required("Labour card is required"),
      passport: Yup.string().required("Passport is required"),
      nrc: Yup.string().required("NRC is required"),
      workPermit: Yup.string().required("Work permit is required"),
      ci: Yup.string().required("CI is required"),
      offerLatter: Yup.string().required("Offer latter is required"),
      photo: Yup.string().required("Photo is required"),
      leaveForm: Yup.string().required("Leave form is required"),
      UID: Yup.string().required("UID is required"),
      EID: Yup.string().required("EID is required"),
    })
  },
  "Work permit" : {
    feilds: [
      { name: "photo", type: "input", inputType: "file", placeholder: "Photo", label: "Photo" },
      { name: "medicalCertificate", type: "input", inputType: "file", placeholder: "Medical certificate", label: "Medical certificate" },
      { name: "passport", type: "input", inputType: "file", placeholder: "Passport", label: "Passport" },
      { name: "employmentLetter", type: "input", inputType: "file", placeholder: "Employment letter", label: "Employment letter" },
      { name: "degreeCertificate", type: "input", inputType: "file", placeholder: "Certificate of degree", label: "Certificate of degree" },
      { name: "addressInThai", type: "input", inputType: "text", placeholder: "Address in thailand", label: "Address in thailand" },
    ],
    initialValue: {
      photo: "",
      medicalCertificate: "",
      passport: "",
      employmentLetter: "",
      degreeCertificate: "",
      addressInThai: "",
    },
    validation: Yup.object().shape({
      photo: Yup.string().required("Photo is required"),
      medicalCertificate: Yup.string().required("Medical certificate is required"),
      passport: Yup.string().required("Passport is required"),
      employmentLetter: Yup.string().required("Employment letter is required"),
      degreeCertificate: Yup.string().required("Certificate of degree is required"),
      addressInThai: Yup.string().required("Address in thailand is required"),
    })
  },
  "CI book application or extension (Myanmar side)": {
    feilds: [    
      { name: "appointmentLetter", type: "input", inputType: "file", placeholder: "Appointment letter", label: "Appointment letter" },
      { name: "nationalIdCard", type: "input", inputType: "file", placeholder: "National Id card", label: "National Id card" },
      { name: "houseHoldList", type: "input", inputType: "file", placeholder: "House hold list", label: "House hold list" },
      { name: "CI", type: "input", inputType: "file", placeholder: "CI", label: "CI" },
      { name: "photo", type: "input", inputType: "file", placeholder: "Photo", label: "Photo" },
      { name: "covidTest", type: "input", inputType: "file", placeholder: "Covid test", label: "Covid test" },
    ],
    initialValue: {
      appointmentLetter: "",
      nationalIdCard: "",
      houseHoldList: "",
      oldCiBook: "",
      photo: "",
      covidTest: "",
    },
    validation: Yup.object().shape({
      appointmentLetter: Yup.string().required("Appointment letter is required"),
      nationalIdCard: Yup.string().required("National Id card is required"),
      houseHoldList: Yup.string().required("House hold list is required"),
      oldCiBook: Yup.string().required("CI old book is required"),
      photo: Yup.string().required("Photo is required"),
      covidTest: Yup.string().required("Covid test is required"),
    })
  },
  "CI book application or extension (Thailand side)": {
    feilds: [
      { name: "nationalIdCard", type: "input", inputType: "file", placeholder: "National Id card", label: "National Id card" },
      { name: "houseHoldList", type: "input", inputType: "file", placeholder: "House hold list", label: "House hold list" },
      { name: "EmployerRecommendation", type: "input", inputType: "file", placeholder: "Employer recommendation", label: "Employer recommendation" },
      { name: "pinkCard", type: "input", inputType: "file", placeholder: "Pink card", label: "Pink card" },
      { name: "CI", type: "input", inputType: "file", placeholder: "CI", label: "CI" },
      { name: "photo", type: "input", inputType: "file", placeholder: "Photo", label: "Photo" },
      { name: "contract", type: "input", inputType: "file", placeholder: "Contract", label: "Contract" },

    ],
    initialValue: {
      nationalIdCard: "",
      houseHoldList: "",
      employerRecommendation: "",
      pinkCard: "",
      oldCiBook: "",
      photo: "",
      contract: "",
    },
    validation: Yup.object().shape({
      nationalIdCard: Yup.string().required("National Id card is required"),
      houseHoldList: Yup.string().required("House hold list is required"),
      employerRecommendation: Yup.string().required("Employer recommendation is required"),
      pinkCard: Yup.string().required("Pink card is required"),
      oldCiBook: Yup.string().required("CI old book is required"),
      photo: Yup.string().required("Photo is required"),
      contract: Yup.string().required("Contract is required"),
    })
  },

  "Myanmar Worker’s Application for work permit": {
    feilds: [
      { name: "name", type: "input", inputType: "text", placeholder: "Name", label: "Name"},
      { name: "nationality", type: "input", inputType: "text", placeholder: "Nationality", label: "Nationality"},
      { name: "gender", type: "input", inputType: "text", placeholder: "Gender", label: "Gender"},
      { name: "dateOfbirth", type: "date", inputType: "date", placeholder: "Date of birth", label: "Date of birth"},
      { name: "addressInThai", type: "input", inputType: "text", placeholder: "Address in Thailand", label: "Address in Thailand"},
      { name: "pinCode", type: "input", inputType: "text", placeholder: "Pin code", label: "Pin code"},
      { name: "phone", type: "input", inputType: "text", placeholder: "Phone", label: "Phone"},
      { name: "travelDocumentType", type: "select", inputType: "select", placeholder: "Travel document type", label: "ravel document type", options: [{ name: "Passport", value: "passport" }, { name: "Other", value: "other" }] },
      { name: "travelIssueDate", type: "date", inputType: "date", placeholder: "Passport issue date", label: "Passport issue date" },
      { name: "travelIssueAt", type: "input", inputType: "text", placeholder: "Travel document issue at", label: "Travel document issue at" },
      { name: "travelNo", type: "input", inputType: "text", placeholder: "Travel document No", label: "Travel document No" },
      { name: "Kind of visa", type: "input", inputType: "text", placeholder: "Kind of visa", label: "Kind of visa" },
      { name: "visaNo", type: "input", inputType: "text", placeholder: "Visa no", label: "Visa no" },
      { name: "visaIssueAt", type: "input", inputType: "text", placeholder: "Visa issue at", label: "Visa issue at" },      
      { name: "visaIssueDate", type: "date", inputType: "date", placeholder: "Visa issue date", label: "Visa issue date" },      
      { name: "visaExpiryDate", type: "date", inputType: "date", placeholder: "Visa expiry date", label: "Visa expiry date" },      
      { name: "enterThaiOn", type: "date", inputType: "date", placeholder: "Enter thailand on", label: "Enter thailand on" },      
      { name: "immigrationOfficeAddress", type: "input", inputType: "text", placeholder: "Immigration office address", label: "Immigration office address" },      
      { name: "expiredThaiOn", type: "date", inputType: "date", placeholder: "Expire thailand on", label: "Expire thailand on" },
      { name: "employerName", type: "input", inputType: "text", placeholder: "Employer name", label: "Employer name" },
      { name: "employerAddress", type: "input", inputType: "text", placeholder: "Employer address", label: "Employer address" },
      { name: "employerPostCode", type: "input", inputType: "text", placeholder: "Employer post code", label: "Employer post code" },
      { name: "companyName", type: "input", inputType: "text", placeholder: "Company name", label: "Company name" },
      { name: "companyAddress", type: "input", inputType: "text", placeholder: "Company address", label: "Company address" },
      { name: "companyPostCode", type: "input", inputType: "text", placeholder: "Company post code", label: "Company post code" },
      { name: "typeOfBusiness", type: "input", inputType: "text", placeholder: "Type of business", label: "Type of business" },
      { name: "permissionToWorkBy", type: "input", inputType: "text", placeholder: "Permission to work by", label: "Permission to work by" },
      { name: "permissionDocumentNo", type: "input", inputType: "text", placeholder: "Permission document no", label: "Permission document no" },
      { name: "permissionDate", type: "date", inputType: "date", placeholder: "Permission date", label: "Permission date" },
      { name: "permissionEndDate", type: "date", inputType: "date", placeholder: "Permission end date", label: "Permission end date" },
      { name: "occupation", type: "input", inputType: "text", placeholder: "Occupation", label: "Occupation" },
      { name: "jobDescription", type: "input", inputType: "text", placeholder: "Job description", label: "Job description" },
      { name: "wage", type: "input", inputType: "text", placeholder: "Wage", label: "Wage" },
      { name: "otherBenefit", type: "input", inputType: "text", placeholder: "Other benefit", label: "Other benefit" },
      { name: "parentName", type: "input", inputType: "text", placeholder: "Parant name", label: "Parant name" },
      { name: "religious", type: "input", inputType: "text", placeholder: "Religious", label: "Religious" },
      { name: "nrcCardNo", type: "input", inputType: "text", placeholder: "NRC Card no", label: "NRC Card no" },
      { name: "parmanentAddessInMyanmar", type: "input", inputType: "text", placeholder: "Parmanent address in myanmar", label: "Parmanent address in myanmar" },
      { name: "signature", type: "input", inputType: "file", placeholder: "Signature", label: "Signature" },
    ],
    initialValue: {
      name: "",
      nationality: "",
      gender: "",
      dateOfbirth: "",
      addressInThai: "",
      pinCode: "",
      phone: "",
      travelDocumentType: "",
      travelIssueDate: "",
      travelIssueAt: "",
      travelNo: "",
      kindOfVisa: "",
      visaNo: "",
      visaIssueAt: "",
      visaIssueDate: "",
      visaExpiryDate: "",
      enterThaiOn: "",
      immigrationOfficeAddress: "",
      expiredThaiOn: "",
      employerName: "",
      employerAddress: "",
      employerPostCode: "",
      companyName: "",
      companyAddress: "",
      companyPostCode: "",
      typeOfBusiness: "",
      permissionToWorkBy: "",
      permissionDocumentNo: "",
      permissionDate: "",
      permissionEndDate: "",
      occupation: "",
      jobDescription: "",
      wage: "",
      otherBenefit: "",
      parentName: "",
      religious: "",
      nrcCardNo: "",
      parmanentAddessInMyanmar: "",
      signature: "",
    }, 
    validation: Yup.object().shape({
      name: Yup.string().required("Name is required"),
      nationality: Yup.string().required("Nationality is required"),
      gender: Yup.string().required("Gender is required"),
      dateOfbirth: Yup.string().required("Date of birth is required"),
      addressInThai: Yup.string().required("Address in Thai is required"),
      pinCode: Yup.string().required("Pin code is required"),
      phone: Yup.string().trim().required('Phone is required').matches(/^\d+$/, 'Phone must be a valid number'),
      travelDocumentType: Yup.string().required("Travel document type is required"),
      travelIssueDate: Yup.string().required("Travel issue date is required"),
      travelIssueAt: Yup.string().required("Travel issue at is required"),
      travelNo: Yup.string().required("Travel document No is required"),
      kindOfVisa: Yup.string().required("Kind of visa is required"),
      visaNo: Yup.string().required("Visa no is required"),
      visaIssueAt: Yup.string().required("Visa issue at is required"),
      visaIssueDate: Yup.string().required("Visa issue date is required"),
      visaExpiryDate: Yup.string().required("Visa expiry date is required"),
      enterThaiOn: Yup.string().required("Enter Thailand on is required"),
      immigrationOfficeAddress: Yup.string().required("Immigration office address is required"),
      expiredThaiOn: Yup.string().required("Expire Thailand on is required"),
      employerName: Yup.string().required("Employer name is required"),
      employerAddress: Yup.string().required("Employer address is required"),
      employerPostCode: Yup.string().required("Employer post code is required"),
      companyName: Yup.string().required("Company name is required"),
      companyAddress: Yup.string().required("Company address is required"),
      companyPostCode: Yup.string().required("Company post code is required"),
      typeOfBusiness: Yup.string().required("Type of business is required"),
      permissionToWorkBy: Yup.string().required("Permission to work by is required"),
      permissionDocumentNo: Yup.string().required("Permission document no is required"),
      permissionDate: Yup.string().required("Permission date is required"),
      permissionEndDate: Yup.string().required("Permission end date is required"),
      occupation: Yup.string().required("Occupation is required"),
      jobDescription: Yup.string().required("Job description is required"),
      wage: Yup.string().required("Wage is required"),
      otherBenefit: Yup.string().required("Other benefit is required"),
      parentName: Yup.string().required("Parent name is required"),
      religious: Yup.string().required("Religious is required"),
      nrcCardNo: Yup.string().required("NRC Card no is required"),
      parmanentAddessInMyanmar: Yup.string().required("Parmanent address in myanmar is required"),
      signature: Yup.mixed().required("Signature is required")
    })
  },
  "Power of Antony": {
    feilds:[
      { name: "writtenAt", type: "input", inputType: "text", placeholder: "Written at", label: "Written at" },
      { name: "employerName", type: "input", inputType: "text", placeholder: "Employer name", label: "Employer name" },
      { name: "employmentName", type: "input", inputType: "text", placeholder: "Employment", label: "Employment" },
      { name: "companyName", type: "input", inputType: "text", placeholder: "Company name", label: "Company name" },
      { name: "phone", type: "input", inputType: "text", placeholder: "Phone", label: "Phone" },
      { name: "locatedOn", type: "input", inputType: "text", placeholder: "Located on", label: "Located on" },
      { name: "lane", type: "input", inputType: "text", placeholder: "Lane", label: "Lane" },
      { name: "road", type: "input", inputType: "text", placeholder: "Road", label: "Road" },
      { name: "subDistrict", type: "input", inputType: "text", placeholder: "Sub-District", label: "Sub-District" },
      { name: "district", type: "input", inputType: "text", placeholder: "District", label: "District" },
      { name: "province", type: "input", inputType: "text", placeholder: "Province", label: "Province" },
      { name: "signature", type: "input", inputType: "file", placeholder: "Signature", label: "Signature" },
      { name: "signatureGrantor", type: "input", inputType: "file", placeholder: "Grantor signature", label: "Grantor signature" },
      { name: "signatureGrantee", type: "input", inputType: "file", placeholder: "Grantee signature", label: "Grantee signature" },
      { name: "signatureWitness1", type: "input", inputType: "file", placeholder: "Witness1 signature", label: "Witness1 signature" },
      { name: "signatureWitness2", type: "input", inputType: "file", placeholder: "Witness2 signature", label: "Witness2 signature" },
    ],
    initialValue: {
      writtenAt: "",
      employerName: "",
      employment: "",
      companyName: "",
      phone: "",
      locatedOn: "",
      lane: "",
      road: "",
      subDistrict: "",
      district: "",
      province: "",
      signature: "",
      signatureGrantor: "",
      signatureGrantee: "",
      signatureWitness1: "",
      signatureWitness2: ""
    },
    validation: Yup.object().shape({
      writtenAt: Yup.string().required("Written at is required"),
      employerName: Yup.string().required("Employer name is required"),
      employment: Yup.string().required("Employment is required"),
      companyName: Yup.string().required("Company name is required"),
      phone: Yup.string().trim().required('Phone is required').matches(/^\d+$/, 'Phone must be a valid number'),
      locatedOn: Yup.string().required("Located on is required"),
      lane: Yup.string().required("Lane is required"),
      road: Yup.string().required("Road is required"),
      subDistrict: Yup.string().required("Sub-District is required"),
      district: Yup.string().required("District is required"),
      province: Yup.string().required("Province is required"),
      signature: Yup.mixed().required("Signature is required"),
      signatureGrantor: Yup.mixed().required("Grantor signature is required"),
      signatureGrantee: Yup.mixed().required("Grantee signature is required"),
      signatureWitness1: Yup.mixed().required("Witness1 signature is required"),
      signatureWitness2: Yup.mixed().required("Witness2 signature is required")
    })
  },
  "MOU Contract between employer and employee": {
    feilds: [

    ], 
    initialValue: {

    },
    validation: Yup.object().shape({})
  }
}

