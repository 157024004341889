import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from 'formik';
import { SignInValidationSchema, SignInInitaialValue } from "../../common/Validation"
import Spinwheel from "../../common/Spinwheel"
import { setToken } from "../../../utils/HelperFuncitons";
import { getAdminDetails, signIN } from "../../../utils/ApiEndpointFunctions"
import toast from 'react-hot-toast';
import { useAdminContext } from "../../../context/adminContext";

const Login = (props) => {

    const [errors, setErrors] = useState("");
    const [eyes, setEye] = useState({ eyeblur: "d-none", slashblur: "" })
    const [signInWheel, setSignInWheel] = useState(false)
    const navigate = useNavigate();
    const { setAdminDetail } = useAdminContext();

    const getAdminDetail = async () => {
        const res = await getAdminDetails();
        if (res?.status === 200) {
            setAdminDetail(res.data?.data)
        }
    }

    const handleShowPassword = () => {
        const ele = document.getElementById("pass")
        ele.type = ele.type === "password" ? "text" : "password";
        setEye({ eyeblur: eyes.eyeblur === "d-none" ? "" : "d-none", slashblur: eyes.slashblur === "d-none" ? "" : "d-none" })
    }

    const handleSubmit = async (values) => {
        setSignInWheel(true)

        setTimeout(async () => {
            try {
                const res = await signIN(values);
                if (res?.status === 200 && res?.data?.status === 1) {
                    const token = res?.data?.data?.token
                    setToken(token);
                    props.setToken(token);
                    navigate("/dashboard");
                    setSignInWheel(false)
                    getAdminDetail();
                    toast.success("login successfully", { duration: 1000 })
                } else {
                    
                    toast.error(res?.data?.message, { duration: 1000 });
                    setErrors(res?.data?.message)
                }

            } catch (error) {
                console.log(error);
                error?.response?.data?.message === "Email and password not found" ?
                    toast.error("Email id and password do not exist") :
                    error?.response?.data?.message ?
                    toast.error(error?.response?.data?.message) :
                    toast.error("something went wrong");
                setSignInWheel(false);
            }
        }, 500)
    }

    const formik = useFormik({
        initialValues: SignInInitaialValue,
        onSubmit: handleSubmit,
        validationSchema: SignInValidationSchema
    });



    useEffect(() => {
        setErrors("")
    }, [formik.values])
    document.body.classList.add("body-bg");

    return (
        <>
            <main>
                <section className="section-bg">
                    <div className="container-fluid">
                        <div className="row top-bg">
                            <img
                                className="left-mask-bg d-none d-xl-block"
                                src="/images/Mask Group 650.png"
                                alt=""
                            />

                            <img
                                className="right-mask-bg d-none d-xl-block"
                                src="/images/Mask Group 650.png"
                                alt=""
                            />
                            <div className="d-flex justify-content-center">
                                <img
                                    className="bottom-mask-bg d-none d-xl-block"
                                    src="/images/Mask Group 650.png"
                                    alt=""
                                />
                            </div>
                            <div className="col-xl-3 col-lg-5 col-md-7 col-sm-12 mx-auto">
                                <div className="d-grid text-center">
                                    <div className="d-flex justify-content-center">
                                        <img
                                            src="/images/logo.png"
                                            alt="logo"
                                            height={104}
                                            width={104}
                                        />
                                    </div>
                                    <br />
                                    <br />
                                    <h2 className="sign-in">Sign In</h2>
                                    <p>Sign in to your admin account</p>
                                    <br />
                                    <div className='password-error'>{errors}</div>
                                    <form action="" onSubmit={formik.handleSubmit}>
                                        <label
                                            className="form-label text-start d-block"
                                            htmlFor="email"
                                        >
                                            Email address
                                        </label>
                                        <div className="position-relative">
                                            <input
                                                className="form-control"
                                                type="email"
                                                name="email"
                                                id="email"
                                                placeholder="Enter your email"
                                                value={formik.values.email}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width={16}
                                                height={16}
                                                fill="currentColor"
                                                className="bi bi-envelope-fill input-icon"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z" />
                                            </svg>
                                            {formik.touched.email && formik.errors.email ? (<div className='req-error'>{formik.errors.email}</div>) : null}
                                        </div>
                                        <br />
                                        <label
                                            className="form-label text-start d-block"
                                            htmlFor="pass"
                                        >
                                            Enter a Password
                                        </label>
                                        <div className="position-relative" >
                                            <input
                                                className="form-control"
                                                type="password"
                                                name="password"
                                                id="pass"
                                                placeholder="Enter your password"
                                                value={formik.values.password}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            <div onClick={handleShowPassword} >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={16}
                                                    height={16}
                                                    fill="currentColor"
                                                    className={`bi bi-eye-fill input-icon ${eyes?.eyeblur}`}
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                                                    <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                                                </svg>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={16}
                                                    height={16}
                                                    fill="currentColor"
                                                    className={`bi bi-eye-slash-fill input-icon ${eyes?.slashblur} `}
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />
                                                    <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z" />
                                                </svg>
                                            </div>
                                            {formik.touched.password && formik.errors.password ? (<div className='req-error'>{formik.errors.password}</div>) : null}
                                        </div>
                                        <br />
                                        <div className="d-flex justify-content-end">
                                            {/* <div>
                                                <input
                                                    type="checkbox"
                                                    name="checkbox"
                                                    id="Remember"
                                                    checked={formik.values.checkbox}
                                                    onChange={formik.handleChange} className="me-2"
                                                />
                                                <label htmlFor="Remember"> Remember me </label>
                                                {formik.touched.checkbox && formik.errors.checkbox ? (<div className='req-error'>{formik.errors.checkbox}</div>) : null}
                                            </div> */}
                                            <div  >
                                                <Link
                                                    to="/forgotpassword"
                                                    className="text-decoration-underline link-tag"
                                                >
                                                    Forgot Password?
                                                </Link>
                                            </div>
                                        </div>
                                        <br />
                                        <br />
                                        <button type="submit" className="btn-custom"  disabled={signInWheel} > {signInWheel ? <Spinwheel sizeClass="spin-small" /> : "SIGN IN "} </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
};

export default Login;
