import React from 'react'
import Spinwheel from '../../common/Spinwheel'
import { Link } from 'react-router-dom'
import { ImageUrl } from '../../../utils/BaseUrl'

function EssentialTable({ essentialSpin, allEssential, selectedPage, limit, setRemoveData }) {

  return (
    <table className="table table-sm table-hover table-nowrap card-table">
      <thead>
        <tr>
          <th>
            <span
              className="list-sort text-muted"
            >
              S.No
            </span>
          </th>
          <th>
            <span
              className="list-sort text-muted"
            >
              Name
            </span>
          </th>
          <th colSpan={2}>
            <span
              className="list-sort text-muted"
            >
              Contact
            </span>
          </th>
        </tr>
      </thead>
      {essentialSpin ?
        <tbody className="list fs-base">
          {allEssential?.length > 0 ? allEssential?.map?.((ele, index) => {
            return <tr key={index}>
              <td>{(selectedPage * limit) + index + 1}</td>
              <td>
                {/* Avatar */}
                <div className="avatar avatar-xs align-middle me-2">
                  <img
                    className="avatar-img rounded-circle"
                    src={ele?.logo ? ImageUrl + ele?.logo : process.env.PUBLIC_URL + "/images/person-circle.svg"}
                    alt=""
                  />
                </div>
                <span
                  className="item-name text-reset"
                >
                  {ele?.name}
                </span>
              </td>
              <td>
                <div
                  className="item-phone text-reset"
                // href="tel:1-123-456-4890"
                >
                  {ele?.phone?.join(", ")}
                </div>
              </td>
              <td className="text-end">
                <div className="dropdown">
                  <Link
                    className="dropdown-ellipses dropdown-toggle"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="fe fe-more-vertical" />
                  </Link>
                  <div className="dropdown-menu dropdown-menu-end">
                    <Link to="/essentialnumber/update" className="dropdown-item" state={{ essentialData: ele }} >
                      Edit
                    </Link>
                    <div onClick={() => { setRemoveData(ele) }} data-bs-toggle="modal" data-bs-target="#exampleModal" className="dropdown-item" style={{ cursor: "pointer" }}>
                      Remove
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          }) : <tr align="center">
            <td colSpan="4">
              <h1>
                Data Not Found
              </h1>
            </td>
          </tr>
          }
        </tbody> : <tbody>
          <tr align="center">
            <td colSpan="4">
              <Spinwheel />
            </td>
          </tr>
        </tbody>
      }
    </table>
  )
}

export default EssentialTable