import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { addQuizQuestionsApi, updateQuizQuestionsApi } from '../../../utils/ApiEndpointFunctions';
import Header from '../../common/Header';
import Spinwheel from '../../common/Spinwheel';
import { addQuizInitialValue, addQuizValidationSchema } from '../../common/Validation';

function Quiz() {
  const navigate = useNavigate()
  const [addSpin, setAddSpin] = useState(false)
  const location = useLocation();
  const params = useParams();
  let path = location.pathname
  path = path.split("/")?.filter((e) => {
    if (e === "add" || e === "update") {
      return true
    }
    return false;
  })[0];
  path = (path[0].toUpperCase() + path.slice(1))



  const handleSubmit = async (values) => {
    try {
      let res
      setAddSpin(true)
      res = path === 'Add' ? await addQuizQuestionsApi(values) : await updateQuizQuestionsApi(values)
      if (res?.status === 200) {
        toast.success(res.data?.message)
       navigate(`/course/quiz/${params?.id}`, { replace: true })
      }
      setAddSpin(false)
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setAddSpin(false)
    }
  }

  const addNew = (e, index, arrInd) => {
    e.preventDefault();
    if (0 <= arrInd) {
      formik.setFieldValue(`quesAndAns[${index}].answer`, [...formik.values?.quesAndAns[index]["answer"], { name: "" }])
    } else if (arrInd === undefined) {
      formik.setFieldValue("quesAndAns", [...formik.values?.quesAndAns, {
        questions: "",
        correctAnswer: "",
        answer: [{ name: "" }, { name: "" }],
      }])
    }
  }

  const removeItem = (e, index, arrInd) => {
    

    e.preventDefault()
    if (0 <= arrInd) {
      formik.setFieldValue(`quesAndAns[${index}].answer`, formik.values.quesAndAns[index]["answer"]?.filter?.((ele, i) => {
        if (arrInd === i) return false;
        return true;
      }))
      // formik.setFieldTouched(`quesAndAns[${index}].answer`, formik.touched.quesAndAns[index]["answer"]?.filter?.((ele, i) => {
      //   if (arrInd === i) return false;
      //   return true;
      // }))
    } 
    else if (arrInd === undefined) {
      formik.setFieldValue("quesAndAns", formik.values.quesAndAns?.filter((ele, i) => {
        if (index === i) return false;
        return true;
      }))
      // formik.setFieldTouched("quesAndAns", formik.touched.quesAndAns?.filter((ele, i) => {
      //   if (index === i) return false;
      //   return true;
      // }))
    }
  }

  const handleQuizChange = (index, fieldName, value, arrInd) => {
    if (0 <= arrInd) {
      formik.setFieldValue(`quesAndAns[${index}].${fieldName}[${arrInd}].name`, value)
    } else if (arrInd === undefined) {
      formik.setFieldValue(`quesAndAns[${index}].${fieldName}`, value)
    }
  }

  const handleQuizBlur = (index, fieldName, arrInd) => {
    if (0 <= arrInd) {
      formik.setFieldTouched(`quesAndAns[${index}].${fieldName}[${arrInd}].name`, true)
    } else if (arrInd === undefined) {
      formik.setFieldTouched(`quesAndAns[${index}].${fieldName}`, true)
    }
  }

  useEffect(() => {
    if ((path === "Update") && location.state == null) {
      navigate(`/course/quiz/add/${params?.id}`);
    }
  }, [])
  
  const formik = useFormik({
    initialValues: location?.state ? location.state?.quiz1 : { ...addQuizInitialValue, courseId: params?.id },
    onSubmit: handleSubmit,
    validationSchema: addQuizValidationSchema,
  });

  return (
    <>
      {/*<Sidebar />*/}
      <div className="main-content vh-100 vh_md-auto overflow-y-a">
        <Header title="Study" />
        <div className="container-fluid">
          <div className="row">
            <nav className="mb-5">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">
                    <i className="fe fe-home"></i> Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/course" className="breadcrumb-item">
                    Course
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {path}-Quiz
                </li>
              </ol>
            </nav>
            <hr />

            <div className="col-lg-10" style={{ zIndex: "1" }}>
              <form action="" onSubmit={formik.handleSubmit} >
                {formik.values.quesAndAns?.map((ele, index) => {
                  return (
                    <div key={"aaaaaaaa" + index}>
                      <div className=" text-end mb-3">
                        {
                          formik.values.quesAndAns?.length === (index + 1) &&
                          <button type="button" className="me-3 plusButton" onClick={(e) => addNew(e)}><span>+</span></button>}
                        {
                          formik.values.quesAndAns?.length > 1 && <button type="button" className=" plusButton" onClick={(e) => { removeItem(e, index) }} ><span>-</span></button>
                        }
                      </div>
                      <div className='card'>
                        <div className='card-body' style={{ backgroundColor: '#ebe3d93b' }}>
                          <div className='row mb-3'>
                            <div className='col-lg-6'>
                              <label htmlFor={"questions" + index} className="form-label">Question</label>
                              <div className="position-relative">
                                <input className="form-control border" type="text" id={"questions" + index} placeholder="Enter question" name="questions" value={ele.questions} onChange={(e) => { handleQuizChange(index, "questions", e.target.value) }} onBlur={() => { handleQuizBlur(index, "questions") }} />
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-house-door-fill input-icon" viewBox="0 0 16 16">
                                  <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z" />
                                  <path d="M3 8.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm0-5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5v-1z" />
                                </svg>
                                {formik.touched?.quesAndAns?.[index]?.questions && formik.errors?.quesAndAns?.[index]?.questions ? (<div className='req-error'>{formik.errors?.quesAndAns?.[index]?.questions}</div>) : null}
                              </div>
                            </div>
                            <div className='col-lg-6'>
                              <label htmlFor={"correctAnswer" + index} className="form-label">Answer</label>
                              <div className="position-relative">
                                <input className="form-control border" type="text" id={"correctAnswer" + index} placeholder="Enter answer" name="correctAnswer" value={ele.correctAnswer} onChange={(e) => { handleQuizChange(index, "correctAnswer", e.target.value) }} onBlur={(e) => { handleQuizBlur(index, "correctAnswer") }} />
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-house-door-fill input-icon" viewBox="0 0 16 16">
                                  <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z" />
                                  <path d="M3 8.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm0-5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5v-1z" />
                                </svg>
                                {formik.touched?.quesAndAns?.[index]?.correctAnswer && formik.errors?.quesAndAns?.[index]?.correctAnswer ? (<div className='req-error'>{formik.errors?.quesAndAns?.[index]?.correctAnswer === "Answer is required" ?
                                  "Answer is required" : formik.touched?.quesAndAns?.[index]?.answer?.length === formik.values?.quesAndAns?.[index]?.answer?.length ?
                                    formik.errors?.quesAndAns?.[index]?.correctAnswer : null}</div>) : null}
                              </div>
                            </div>
                          </div>
                          <div className='row mb-3'>
                            <div className='my-3 d-flex align-items-center'>
                              <h3 className='m-0 fw-bold '>Options</h3>
                              {ele.answer.length > 0 && <div className='ms-3'>
                                {ele.answer.length < 4 && <button type="button" className="me-3 plusButton " onClick={(e) => addNew(e, index, ele.answer.length - 1)}><span>+</span></button>}
                                {ele.answer.length > 2 && <button type="button" className=" plusButton " onClick={(e) => { removeItem(e, index, ele.answer.length - 1) }} ><span>-</span></button>}
                              </div>
                              }
                            </div>
                            {
                              ele.answer?.map((e, ind) => {
                                return (
                                  <div className='col-lg-3' key={ind}>
                                    <label htmlFor={`answer${index}${ind}`} className="form-label">Option {ind + 1}</label>
                                    <div className="position-relative">
                                      <input className="form-control border" type="text" id={`answer${index}${ind}`} placeholder="Enter option" name="answer" value={e?.name} onChange={(el) => { handleQuizChange(index, "answer", el.target.value, ind) }} onBlur={() => { handleQuizBlur(index, "answer", ind) }} />
                                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-house-door-fill input-icon" viewBox="0 0 16 16">
                                        <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z" />
                                        <path d="M3 8.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm0-5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5v-1z" />
                                      </svg>
                                      {formik.touched?.quesAndAns?.[index]?.answer?.[ind]?.name && formik.errors?.quesAndAns?.[index]?.answer?.[ind]?.name ? (<div className='req-error'>{formik.errors?.quesAndAns?.[index]?.answer?.[ind]?.name}</div>) : null}
                                    </div>
                                  </div>
                                )
                              })
                            }
                          </div>
                        </div>
                      </div>
                      <br />
                    </div>
                  )
                })
                }
                <div className="text-center d-flex justify-content-around">
                  <button type="submit" className="btn-custom w-auto mt-2" disabled={addSpin} >{addSpin ? <Spinwheel sizeClass="spin-small" /> : "Save"}</button>
                  <div className="btn-custom w-auto mt-2" onClick={() => { window.history.back(); }} >Cancel</div>
                </div>
              </form>
              <br />
            </div>
            <div className="col-lg-8"></div>
          </div >
        </div >
      </div >
    </>
  );
}

export default Quiz