import React, { useEffect, useState } from 'react'
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import moment from 'moment';
import { ImageUrl } from '../../../../utils/BaseUrl';
import { Link } from 'react-router-dom';

function ViewHotelBookingModal({ data, modalId, }) {


   return (
      <div className="modal fade" id={modalId} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
         <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">
               <div className="modal-header border-0">
                  <button type="button" className="btn-close block-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div className="modal-body">
                  <div className="col-lg-12" style={{ zIndex: "1" }}>
                     <div action="" className='p-4' >
                        <h1 className='text-center'>
                           Booking
                        </h1>
                        <div className="row">
                           <div className="mb-3">
                              <h4 className='fw-bold'>User name</h4>
                              <p className='text-capitalize mb-0'>{data?.userId?.name || "-"}</p>
                           </div>
                           <h2 className='fw-bold text-center'>Property Details</h2>
                           <div className="row row-cols-lg-3 row-cols-md-2">
                              <div className='position-relative mb-3'>
                                 <h4 className='fw-bold'>Name</h4>
                                 <div className='d-flex'>
                                    <div className="avatar avatar-xs align-middle me-2">
                                       <img
                                          className="avatar-img rounded-circle"
                                          src={data?.hotelDetails?.image ? ImageUrl + data?.hotelDetails?.image : process.env.PUBLIC_URL + "/images/person-circle.svg"}
                                          alt=""
                                       />
                                    </div>
                                    <p className='mb-0 capitalize'>{data?.hotelDetails?.name?.length > 30 ? <span>
                                       {data?.hotelDetails?.name?.substr(0, 30)}...
                                       <div className="tooltip1 item-name text-reset" ></div>
                                       <div className='wrapper'>
                                          <div className="tooltip1-text">{data?.hotelDetails?.name}</div>
                                       </div>
                                    </span> :
                                       <span>{data?.hotelDetails?.name || "-"}</span>}</p>
                                 </div>
                              </div>
                              <div className="mb-3">
                                 <h4 className='fw-bold'>Type</h4>
                                 <div className='position-relative'>
                                    <p className='mb-0'>{data?.hotelDetails?.propertyType}</p>
                                 </div>
                              </div>
                              <div className="mb-3">
                                 <h4 className='fw-bold'>Address</h4>
                                 <div className='position-relative'>
                                    <p className='mb-0'>{data?.hotelDetails?.address}</p>
                                 </div>
                              </div>
                              <div className="mb-3">
                                 <h4 className='fw-bold'>Free cancellation</h4>
                                 <p className='text-capitalize mb-0'>{data?.hotelDetails?.isFreeCancellation ? "Yes" : "No"}</p>
                              </div>
                              <div className="mb-3">
                                 <h4 className='fw-bold'>Rating</h4>
                                 <p className='text-capitalize mb-0'>{data?.hotelDetails?.starRating}</p>
                              </div>
                           </div>
                           <hr />
                           <h2 className='fw-bold text-center'>Room Details</h2>
                           <div className="row row-cols-lg-3 row-cols-md-2">
                           <div className="mb-3">
                                 <h4 className='fw-bold'>Name</h4>
                                 <p className='text-capitalize mb-0'>{data?.roomDetails?.name}</p>
                              </div>
                              <div className="mb-3">
                                 <h4 className='fw-bold'>Bed preference</h4>
                                 <p className='text-capitalize mb-0'>{data?.roomDetails?.bedPreference}</p>
                              </div>
                              <div className="mb-3">
                                 <h4 className='fw-bold'>Meal preference</h4>
                                 <p className='text-capitalize mb-0'>{data?.roomDetails?.mealPreference}</p>
                              </div>
                              <div className="mb-3">
                                 <h4 className='fw-bold'>No of Guest</h4>
                                 <p className='text-capitalize mb-0'>{data?.roomDetails?.guestNumber}</p>
                              </div>

                           </div>
                           <hr />
                           <h2 className='fw-bold text-center'>Payment</h2>
                           <div className="row row-cols-lg-3 row-cols-md-2">
                              <div className="mb-3">
                                 <h4 className='fw-bold'>Amount</h4>
                                 <p className='mb-0'>{data?.payment?.amount}</p>
                              </div>
                              <div className="mb-3">
                                 <h4 className='fw-bold'>Status</h4>
                                 <p className='mb-0'>{data?.payment?.status == "success" ? "Yes" : "No"}</p>
                              </div>
                           </div>

                           <hr />
                           <h2 className='fw-bold text-center'>Booking</h2>
                           <div className="row row-cols-lg-3 row-cols-md-2">
                              <div>
                                 <h4 className='fw-bold'>Check-in</h4>
                                 <p className='mb-0'>{ data?.checkInDate ? moment(data?.checkInDate).format("DD/MM/YYYY h:mm:ss a") : "-"}</p>
                              </div>
                              <div>
                                 <h4 className='fw-bold'>Check-out</h4>
                                 <p className='mb-0'>{ data?.checkOutDate ? moment(data?.checkOutDate).format("DD/MM/YYYY h:mm:ss a") : "-"}</p>
                              </div>
                              <div>
                                 <h4 className='fw-bold'>Status</h4>
                                 <p className='mb-0'>{data?.status}</p>
                              </div>
                              <div>
                                 {data?.ticketFile && <div>
                                    <h4 className='fw-bold'>Attachment</h4>
                                    <Link to={ImageUrl + encodeURIComponent(data?.ticketFile)} target='_blank'>
                                       View
                                    </Link>
                                 </div>}
                              </div>
                           </div>
                           <hr />
                           <h2 className='fw-bold text-center'>Guest</h2>
                           <div className="row row-cols-md-5">
                              <div>
                                 <h4 className='fw-bold'>Name</h4>
                              </div>
                              <div>
                                 <h4 className='fw-bold'>Email</h4>
                              </div>
                              <div>
                                 <h4 className='fw-bold'>Phone</h4>
                              </div>
                              <div>
                                 <h4 className='fw-bold'>Purpose</h4>
                              </div>
                              <div>
                                 <h4 className='fw-bold'>Country</h4>
                              </div>
                              {console.log(data)}
                              
                                 <div>
                                    <p className='mb-0'>{data?.guests?.firstName} {data?.guests?.lastName}</p>
                                 </div>
                                 <div>
                                    <p className='mb-0'>{data?.guests?.email}</p>
                                 </div>
                                 <div>
                                    <p className='mb-0'>{data?.guests?.phone}</p>
                                 </div>
                                 <div>
                                    <p className='mb-0'>{data?.guests?.purpose}</p>
                                 </div>
                                 <div>
                                    <p className='mb-0'>{data?.guests?.country}</p>
                                 </div>
                              
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}

export default ViewHotelBookingModal