import React from 'react'

function InviteVendorModal(props) {
  const { formik, modalId } = props

  return (
    <div className="modal fade" id={modalId} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-md modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header border-0">
            <button type="button" className="btn-close block-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body  block-user">
            <form action="" className="text-start" onSubmit={formik?.handleSubmit} >
              <label htmlFor="email" className="form-label">Email</label>
              <input type="email" className='form-control border border-1' placeholder='Enter email' id='email' name='email' value={formik?.values?.email} onChange={formik?.handleChange} onBlur={formik?.handleChange} />
              {formik.touched.email && formik.errors.email ? (<div className='req-error'>{formik.errors.email}</div>) : null}
              <br />
              
              <label
                className="form-label text-start d-block"
                htmlFor="pass"
              >
                Role
              </label>
              <select name="role" id="modal" className="form-select form-control border border-1" value={formik.values.role} onChange={formik.handleChange} onBlur={formik.handleBlur} >
                <option value="admin">Admin</option>
                <option value="shopVendor">Shoping</option>
                <option value="ticketVendor">Ticket</option>
                <option value="jobVendor">Job</option>
              </select>

              <br />
              <div className="text-center d-flex justify-content-center">
                <button type="submit" className="btn-custom w-auto m-2" >{'Invite'}</button>
                <button type="button" className="btn-custom w-auto m-2" data-bs-dismiss='modal' >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InviteVendorModal