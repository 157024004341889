import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { useAdminContext } from '../../../../context/adminContext';
import { getAllMovieApi, getAllMovieTicketsApi, updateMovieTicketStatusApi } from '../../../../utils/ApiEndpointFunctions';
import Header from '../../../common/Header';
import ViewMovieTicket from './viewTicket';
import { movieShowInitialValues, movieShowValidationSchema, ticketStatusInitalValue, ticketStatusValidationSchema } from "../../../common/Validation"
import Pagination from '../../../common/Pagination';
import Spinwheel from '../../../common/Spinwheel';
import { ImageUrl } from '../../../../utils/BaseUrl';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import UpdateTicketStatus from '../../../common/updateTicketStatus';
import { toast } from 'react-hot-toast';
import { debounce } from 'lodash';

function MovieTicket() {
  const navigate = useNavigate()
  const [tableSpin, setTableSpin] = useState(true);
  const [movies, setMovies] = useState([]);
  let [selectedPage, setSelectPage] = useState(0);
  const [page, setPage] = useState(0);
  const movieTicketModalId = "movieTicketModalId"
  const ticketStatusModalId = "ticketStatusModalId"
  const [selectedMovieTicket, setSelectedMovieTicket] = useState(null)
  const [updateTicket, setUpdateTicket] = useState(false)
  const limit = 10;

  const getAllMovieList = async (selectedPage, search) => {
    try {
      setTableSpin(false)
      const res = await getAllMovieTicketsApi(search || "", selectedPage + 1, limit)
      if (res.status === 200) {
        setMovies(res.data?.data?.data)
        const totaldata = res.data?.data?.totalData

        setPage(Math.ceil(totaldata / limit));
      }
      setTableSpin(true)
    } catch (error) {
      setTableSpin(true)
    }
  }

  const handlePageClick = (event) => {
    setSelectPage(event.selected);
    getAllMovieList(event.selected)
  };

  const handleSubmit = async (values) => {
    const payload = {
      ...values,
      _id: selectedMovieTicket,
    }
    try {
      setUpdateTicket(true)
      const res = await updateMovieTicketStatusApi(payload);
      if (res?.status === 200) {
        toast.success(res.data?.message)
        setUpdateTicket(false)
        getAllMovieList(0)
        document.getElementById(ticketStatusModalId).click()
        setSelectedMovieTicket(null)
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setUpdateTicket(false)
    }
  }


  const formik = useFormik({
    initialValues: ticketStatusInitalValue,
    onSubmit: handleSubmit,
    validationSchema: ticketStatusValidationSchema,
  })
  const onChange = async (e) => {
    let search = e.target.value;
    getAllMovieList(0, search)
  };

  const debouncedOnChange = debounce(onChange, 500);

  useEffect(() => {
    getAllMovieList(selectedPage)
  }, [])




  return (
    <>
      {/*<Sidebar />*/}
      <div className="main-content vh-100 vh_md-auto overflow-y-a">
        <Header title="Movie ticket" />
        {/* CARDS */}
        <div className="container-fluid">
          <div className="row">
            <nav className="mb-5">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">
                    <i className="fe fe-home"></i> Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Movie ticket
                </li>
              </ol>
            </nav>
            <div className="mb-4" style={{ textAlign: "end" }}>
              <div className='d-flex justify-content-end gap-2' style={{ display: "-webkit-inline-box" }}>
                <div className='d-flex gap-2 align-items-center justify-content-center'>
                  <button onClick={() => navigate(`/ticket/movies`)} className="btn-custom w-auto" style={{ cursor: "pointer" }}>
                    Movies
                  </button>
                  <div className="position-relative">
                    <input
                      className="form-search me-3"
                      type="search"
                      name=""
                      onChange={debouncedOnChange}
                      placeholder="Search"
                    />
                    <img
                      className="users-search"
                      src="/images/Icon feather-search.svg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <hr />

            <div className="card p-3 table-responsive" >
              <table className="table table-hover table-nowrap card-table">
                <thead>
                  <tr>
                    <th>
                      <span
                        className="list-sort text-muted"
                      >
                        S.No
                      </span>
                    </th>
                    <th>
                      <span
                        className="list-sort text-muted"
                      >
                        Name
                      </span>
                    </th>
                    <th>
                      <span
                        className="list-sort text-muted"
                      >
                        Movie
                      </span>
                    </th>
                    <th>
                      <span
                        className="list-sort text-muted"
                      >
                        Cinema
                      </span>
                    </th>
                    <th>
                      <span
                        className="list-sort text-muted"
                      >
                        Seats
                      </span>
                    </th>
                    <th>
                      <span
                        className="list-sort text-muted"
                      >
                        Amount
                      </span>
                    </th>
                    <th>
                      <span
                        className="list-sort text-muted"
                      >
                        Payment
                      </span>
                    </th>
                    <th>
                      <span
                        className="list-sort text-muted"
                      >
                        Status
                      </span>
                    </th>
                    <th></th>


                  </tr>
                </thead>
                {
                  tableSpin ?
                    <tbody className="list fs-base">
                      {movies?.length > 0 ? movies?.map((ele, index) => {
                        return (<tr key={index} >
                          <td>{index + 1}</td>
                          <td className='position-relative'>
                            {ele?.userDetails?.name?.length > 30 ? <span>
                              {ele?.userDetails?.name?.substr(0, 30)}...
                              <div className="tooltip1 item-name text-reset" ></div>
                              <div className='wrapper'>
                                <div className="tooltip1-text">{ele?.userDetails?.name}</div>
                              </div>
                            </span> :
                              <span>{ele?.userDetails?.name || "-"}</span>}
                          </td>
                          <td className='position-relative'>
                            <div className="avatar avatar-xs align-middle me-2">
                              <img
                                className="avatar-img rounded-circle"
                                src={ele?.movie?.coverImg ? ImageUrl + ele?.movie?.coverImg : process.env.PUBLIC_URL + "/images/person-circle.svg"}
                                alt=""
                              />
                            </div>
                            {ele?.movie?.name?.length > 30 ? <span>
                              {ele?.movie?.name?.substr(0, 30)}...
                              <div className="tooltip1 item-name text-reset" ></div>
                              <div className='wrapper'>
                                <div className="tooltip1-text">{ele?.movie?.name}</div>
                              </div>
                            </span> :
                              <span>{ele?.movie?.name || "-"}</span>}

                          </td>
                          <td className='position-relative'>
                            {ele?.movieCinema?.name?.length > 30 ? <span>
                              {ele?.movieCinema?.name?.substr(0, 30)}...
                              <div className="tooltip1 item-name text-reset" ></div>
                              <div className='wrapper'>
                                <div className="tooltip1-text">{ele?.movieCinema?.name}</div>
                              </div>
                            </span> :
                              <span>{ele?.movieCinema?.name || "-"}</span>}
                          </td>
                          <td className='position-relative'>
                            {ele?.noOfTicket}
                          </td>
                          <td className='position-relative'>
                            {ele?.totalPrice}
                          </td>
                          <td className='position-relative'>
                            {ele?.payment?.status == "success" ? "Yes" : "No"}
                          </td>
                          <td className='position-relative'>
                            {ele?.status || "Pending"}
                          </td>

                          <td className="text-end">
                              <div className="dropdown">
                                <span
                                  className="dropdown-ellipses dropdown-toggle"
                                  role="button"
                                  data-bs-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <i className="fe fe-more-vertical" />
                                </span>
                                <div className="dropdown-menu dropdown-menu-end">
                                  <div data-bs-toggle="modal" data-bs-target={`#${movieTicketModalId}`} className=' dropdown-item' style={{ cursor: "pointer" }} onClick={() => { setSelectedMovieTicket(ele?._id) }}>
                                    View
                                  </div>
                                 {ele?.status?.toLowerCase()=="pending" &&  <div data-bs-toggle="modal" data-bs-target={`#${ticketStatusModalId}`} className=' dropdown-item' style={{ cursor: "pointer" }} onClick={() => { setSelectedMovieTicket(ele?._id) }}>
                                    Update status
                                  </div>}
                                </div>
                              </div>
                          </td>
                        </tr>
                        )
                      }) : <tr align="center">
                        <td colSpan="9">
                          <h1>
                            Data Not Found
                          </h1>
                        </td>
                      </tr>}
                    </tbody> : (
                      <tbody>
                        <tr align="center">
                          <td colSpan="9">
                            <Spinwheel />
                          </td>
                        </tr>
                      </tbody>
                    )
                }
              </table>
            </div>
            <div className="d-flex justify-content-center">
              {movies?.length > 0 ? <Pagination
                clickHandler={handlePageClick}
                page={page}
                selectedPage={selectedPage}
              />
                : null}
            </div>
          </div>
          <ViewMovieTicket modalId={movieTicketModalId} movieId={selectedMovieTicket} />
          <UpdateTicketStatus modalId={ticketStatusModalId} formik={formik} addLoader={updateTicket} />
        </div>
      </div>
    </>
  )
}

export default MovieTicket