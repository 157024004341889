import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import { deleteCourseApi, getAllCoursesAndChapterList } from '../../../utils/ApiEndpointFunctions';
import { ImageUrl } from '../../../utils/BaseUrl';
import Header from '../../common/Header';
import Pagination from '../../common/Pagination';
import RemoveModal from '../../common/RemoveModal';
import Spinwheel from '../../common/Spinwheel';
import CourseDetailModal from './CourseDetailModal';

function Course() {


  const [tableSpin, setTableSpin] = useState(true);
  const [course, setCourse] = useState([]);
  const [searchText, setSearchText] = useState("")
  const [removeData, setRemoveData] = useState(null);
  const [selectedPage, setSelectPage] = useState(0);
  const [page, setPage] = useState(0);
  const [showModalData, setShowModalData] = useState({})
  const navigate = useNavigate()
  const limit = 10;
  const getCourseList = async (selectedPage) => {
    setTableSpin(false)
    const res = await getAllCoursesAndChapterList(limit, selectedPage + 1, searchText)
    if (res.status === 200) {
      setCourse(res.data?.data)
      const totaldata = res.data?.totalLength;
      setPage(Math.ceil(totaldata / limit));
      setTableSpin(true)
    }
  }

  const removeJobsHandler = async () => {
    try {
      const res = await deleteCourseApi(removeData)
      if (res.status === 200) {
        toast.success(res.data?.message)
        getCourseList(selectedPage)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const onChange = async (e) => {
    let search = e.target.value;
    setTableSpin(false);
    setSearchText(search)
    const res = await getAllCoursesAndChapterList(limit, 1, search);
    if (res.status === 200) {
      setCourse(res.data?.data);
      const totaldata = res.data?.totalLength;
      setPage(Math.ceil(totaldata / limit));
      setSelectPage(0)
      setTableSpin(true);
    }
  };

  const debouncedOnChange = debounce(onChange, 500);

  const handlePageClick = (event) => {
    setSelectPage(event.selected);
    getCourseList(event.selected)
  };

  
  useEffect(() => {
    getCourseList(selectedPage)
  }, [])

  return (
    <>
      <div className="main-content vh-100 vh_md-auto overflow-y-a">
        <Header title="Study" />
        <div className="container-fluid">
          <div className="row">
            <nav className="mb-5">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">
                    <i className="fe fe-home"></i> Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Course
                </li>
              </ol>
            </nav>
            <div className="mb-4" style={{ textAlign: "end" }}>
              <div className='d-flex gap-2 justify-content-end align-items-center' style={{ display: "-webkit-inline-box" }}>

                <div className="btn-custom w-auto" onClick={() => { navigate("/course/carousel") }}>
                  Manage carousel
                </div>

                <div className="btn-custom w-auto" onClick={() => { navigate("/course/add") }}>Add</div>
               
                <div className="position-relative">
                  <input
                    className="form-search me-3"
                    type="search"
                    name=""
                    onChange={debouncedOnChange}
                    placeholder="Search"
                  />
                  <img
                    className="users-search"
                    src="/images/Icon feather-search.svg"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <hr />

            <div className="card p-3 table-responsive" >
              <table className="table table-hover table-nowrap card-table">
                <thead>
                  <tr>
                    <th>
                      <span
                        className="list-sort text-muted"
                      >
                        S.No
                      </span>
                    </th>
                    <th>
                      <span
                        className="list-sort text-muted"

                      >
                        Title
                      </span>
                    </th>
                    <th colSpan={2}>
                      <span
                        className="list-sort text-muted"

                      >
                        Creator Name
                      </span>
                    </th>
                  </tr>
                </thead>
                {
                  tableSpin ?
                    <tbody className="list fs-base">
                      {course?.length > 0 ? course?.map((ele, index) => {
                        
                        return (<tr key={index} >
                          <td>{((selectedPage) * limit) + index + 1}</td>
                          <td className='position-relative'>
                            <div className="avatar avatar-xs align-middle me-2">
                              <img
                                className="avatar-img rounded-circle"
                                src={ele?.courseThumbnail ? ImageUrl + ele?.courseThumbnail : process.env.PUBLIC_URL + "/images/person-circle.svg"}
                                alt=""
                              />
                            </div>
                            {ele?.courseTitle ? (
                              <Link to={`/course/chapter/${ele?._id}`} className="tooltip1 item-name text-reset" state={{name:ele?.courseTitle}} >
                                {ele?.courseTitle?.substr(0, 30)}...
                                <div className='wrapper'>
                                  <div className="tooltip1-text">{ele?.courseTitle}</div>
                                </div>
                              </Link>
                            ) : (
                              "-"
                            )}
                          </td>
                          <td className='position-relative'>
                            {ele?.publisher ? (
                              <div className="tooltip1">
                                {ele?.publisher?.substr(0, 30)}...
                                <div className='wrapper'>
                                  <div className="tooltip1-text">{ele?.publisher}</div>
                                </div>
                              </div>
                            ) : (
                              "-"
                            )}
                          </td>
                          
                          <td className="text-end">
                            <div className="dropdown">
                              <span
                                className="dropdown-ellipses dropdown-toggle"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <i className="fe fe-more-vertical" />
                              </span>
                              <div className="dropdown-menu dropdown-menu-end">
                                <Link to={`/course/update`} className="dropdown-item" state={{ data: { ...ele, id: ele?._id } }} >
                                  Edit
                                </Link>
                                <div data-bs-toggle="modal" data-bs-target="#removeModal" className="dropdown-item" style={{ cursor: "pointer" }} onClick={() => { setRemoveData(ele?._id); }}  >
                                  Remove
                                </div>
                                <Link to={`/course/quiz/${ele?._id}`} className="dropdown-item" >
                                  Quiz
                                </Link>
                                <div data-bs-toggle="modal" data-bs-target="#showCourseDetail" className="dropdown-item" style={{ cursor: "pointer" }} onClick={() => { setShowModalData(ele)}}  >
                                  Course details
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                        )
                      }) : <tr align="center">
                        <td colSpan="9">
                          <h1>
                            Data Not Found
                          </h1>
                        </td>
                      </tr>}
                    </tbody> : (
                      <tbody>
                        <tr align="center">
                          <td colSpan="9">
                            <Spinwheel />
                          </td>
                        </tr>
                      </tbody>
                    )
                }
              </table>

            </div>
          </div>
          <div className="d-flex justify-content-center">
            {course?.length > 0 ? <Pagination
              clickHandler={handlePageClick}
              page={page}
              selectedPage={selectedPage}
            /> :null}
          </div>

        </div>
      </div>
      <RemoveModal removeHandler={removeJobsHandler} modalId="removeModal" message={<>Are you sure you want to<br /> remove this?</>} />
      
      <CourseDetailModal
        showModalData={showModalData}
        setShowModalData={setShowModalData}
      />
    </>
  )
}

export default Course