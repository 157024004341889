import { useFormik } from 'formik'
import moment from 'moment'
import React from 'react'
import ReactDatePicker from 'react-datepicker'
import Spinwheel from '../../../common/Spinwheel'
import { EmployerInformationInitialValue, EmployerInformationValidation } from '../../../common/Validation'
import Attachment from '../../../common/Attachment'

function EmployerInfoForm({ formik, documentsFormik, addDocLoader, docSpin, handlefile, submitHandler }) {

 

  return (

    <div>

    <div className="row row-cols-2">
      <div>

        <div className='d-flex'>
          <label htmlFor="companyName" className="form-label">Company name</label>
        </div>
        <div className="position-relative">
          <input className="form-control border border-1" type="text" id="companyName" placeholder="Enter" name="companyName" value={formik.values?.companyName} onChange={formik.handleChange} onBlur={formik.handleBlur} />
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-text input-icon" viewBox="0 0 16 16">
            <path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5M5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1z" />
            <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1" />
          </svg>
          {formik.touched.companyName && formik.errors.companyName ? (<div className='req-error'>{formik.errors.companyName}</div>) : null}
        </div>
      </div>

      <div>
        <div className='d-flex'>
          <label htmlFor="companyID" className="form-label">Company ID</label>
        </div>
        <div className="position-relative">
          <input className="form-control border border-1" type="text" id="companyID" placeholder="Enter" name="companyID" value={formik.values?.companyID} onChange={formik.handleChange} onBlur={formik.handleBlur} />
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-text input-icon" viewBox="0 0 16 16">
            <path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5M5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1z" />
            <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1" />
          </svg>
          {formik.touched.companyID && formik.errors.companyID ? (<div className='req-error'>{formik.errors.companyID}</div>) : null}
        </div>
      </div>

      <div>
        <label htmlFor="registrationDate" className="form-label">Registration date</label>
        <div className="position-relative">
          <ReactDatePicker
              selected={formik.values.registrationDate ? new Date(formik.values.registrationDate) : ""}
            name='registrationDate'
            id="registrationDate"
            onChange={(e) => {
              e ?
                formik.setFieldValue("registrationDate", e) :
                formik.setFieldValue("registrationDate", "")
            }}
            onBlur={formik.handleBlur}
            className='form-control w-100 border border-1'
            placeholderText='Pickup date'
            autoComplete="off"
            dateFormat={"dd/MM/yyyy"}
          />
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar input-icon" viewBox="0 0 16 16">
            <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z" />
          </svg>

          {formik.touched.registrationDate && formik.errors.registrationDate ? (<div className='req-error'>{formik.errors.registrationDate}</div>) : null}
        </div>
      </div>

      <div>
        <label htmlFor="dateOfIssue" className="form-label">Issue date</label>
        <div className="position-relative">
          <ReactDatePicker
              selected={formik.values.dateOfIssue ? new Date(formik.values.dateOfIssue) : ""}
            name='dateOfIssue'
            id="dateOfIssue"
            onChange={(e) => {
              e ?
                formik.setFieldValue("dateOfIssue", e) :
                formik.setFieldValue("dateOfIssue", "")
            }}
            onBlur={formik.handleBlur}
            className='form-control w-100 border border-1'
            placeholderText='Pickup date'
            autoComplete="off"
            dateFormat={"dd/MM/yyyy"}
          />
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar input-icon" viewBox="0 0 16 16">
            <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z" />
          </svg>

          {formik.touched.dateOfIssue && formik.errors.dateOfIssue ? (<div className='req-error'>{formik.errors.dateOfIssue}</div>) : null}
        </div>
      </div>

      <div>
        <label htmlFor="dateOfExpiry" className="form-label">Expire date</label>
        <div className="position-relative">
          <ReactDatePicker
              selected={formik.values.dateOfExpiry ? new Date(formik.values.dateOfExpiry) : ""}
            name='dateOfExpiry'
            id="dateOfExpiry"
            onChange={(e) => {
              e ?
                formik.setFieldValue("dateOfExpiry", e) :
                formik.setFieldValue("dateOfExpiry", "")
            }}
            onBlur={formik.handleBlur}
            className='form-control w-100 border border-1'
            placeholderText='Pickup date'
            autoComplete="off"
            dateFormat={"dd/MM/yyyy"}
          />
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar input-icon" viewBox="0 0 16 16">
            <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z" />
          </svg>

          {formik.touched.dateOfExpiry && formik.errors.dateOfExpiry ? (<div className='req-error'>{formik.errors.dateOfExpiry}</div>) : null}
        </div>
      </div>

      

      <div>
        <div className='d-flex justify-content-start'>  <label htmlFor="companyAddress" className="form-label"> Company address</label></div>
        <textarea id="companyAddress" name='companyAddress' class="form-control border" placeholder="Enter.." value={formik?.values?.companyAddress} onChange={formik?.handleChange} onBlur={formik?.handleBlur} ></textarea>
          {formik.touched.companyAddress && formik.errors.companyAddress ? (<div className='req-error'>{formik.errors.companyAddress}</div>) : null}
      </div>

      <div>
        <div className='d-flex'>
          <label htmlFor="typeOfBusiness" className="form-label">Business type</label>
        </div>
        <div className="position-relative">
          <input className="form-control border border-1" type="text" id="typeOfBusiness" placeholder="Enter" name="typeOfBusiness" value={formik.values?.typeOfBusiness} onChange={formik.handleChange} onBlur={formik.handleBlur} />
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-text input-icon" viewBox="0 0 16 16">
            <path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5M5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1z" />
            <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1" />
          </svg>
          {formik.touched.typeOfBusiness && formik.errors.typeOfBusiness ? (<div className='req-error'>{formik.errors.typeOfBusiness}</div>) : null}
        </div>
      </div>

     
    </div>

      <Attachment formik={formik} docSpin={docSpin} handlefile={handlefile} />

      <div className="modal-footer border-0 justify-content-center pt-4">
        <button type="button" className="btn-custom w-auto" disabled={addDocLoader} onClick={(e) => { e.preventDefault(); formik.handleSubmit(); }}  >{addDocLoader ? <Spinwheel sizeClass="spin-small" /> : "Submit"}</button>
        <button type="button" className="btn-custom w-auto" data-bs-dismiss='modal' >
          Cancel
        </button>
      </div>
    </div>
  )
}

export default EmployerInfoForm