import { debounce } from 'lodash';
import React, { useState } from 'react'
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAdminContext } from '../../../context/adminContext';
import Sidebar from '../../../Layout/Sidebar'
import { deleteMapById, getMapDataBySearch, mapTypesApi, removeMapCategoryApi } from '../../../utils/ApiEndpointFunctions';
import { ImageUrl } from '../../../utils/BaseUrl';
import Header from '../../common/Header';
import Pagination from '../../common/Pagination';
import RemoveModal from '../../common/RemoveModal';
import Table from './Table';

function Map() {
  const { adminDetail } = useAdminContext();
  const [selectedPage, setSelectPage] = useState(0);
  const [alldata, setAllData] = useState([]);
  // const [allTourist, setAllTourist] = useState([]);
  const [removeData, setRemoveData] = useState(null);
  const [tableSpin, setTableSpin] = useState(true);
  const [page, setPage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const location = useLocation();
  const [type, setType] = useState(location.state?.type)
  const [mapType, setMapType] = useState();
  const limit = 10;
  const [selectedCategory, setSelectedCategory] = useState();
  const navigate = useNavigate();


  const getAllMap = async (selectedPage, type) => {
    setTableSpin(false)
    const res = await getMapDataBySearch(limit, selectedPage + 1, searchText, type)
    if (res.status === 200) {
      const totalData = res.data?.totalLength
      setPage(Math.ceil(totalData / limit));
      setAllData(res.data?.data)
      // setAllTourist(res.data?.data)
      setTableSpin(true)
    }
  }

  const getMapType = async () => {
    const res = await mapTypesApi();
    if (res.status === 200) {
      setMapType(res.data?.data)
      if(!location.state?.type) {
        setType(res.data?.data[0])
        navigate(`${location.pathname}`, { state: { ...location.state, type: res.data?.data[0] }})
      }
    }
  }

  const onChange = async (e) => {
    let search = e.target.value;
    setTableSpin(false);
    setSearchText(search)
    const res = await getMapDataBySearch(limit, 1, search, type);
    if (res.status === 200) {
      setAllData(res.data?.data);
      const totalData = res.data?.totalLength
      setPage(Math.ceil(totalData / limit));
      setSelectPage(0)
      setTableSpin(true);
    }
  };

  const debouncedOnChange = debounce(onChange, 500);

  const removeHandler = async (e) => {
    e.preventDefault()
    if (removeData._id) {
      const res = await deleteMapById(removeData?._id)
      if (res.status === 200) {
        toast.success(res.data?.message)
        getAllMap(selectedPage, type);
      }
    }
  }

  const removeCategoryHandler = async (e) => {
    e.preventDefault()
    const res = await removeMapCategoryApi({ category: selectedCategory })
    if (res.status === 200) {
      toast.success(res.data?.message)
      let index = mapType.indexOf(type)
      index = index > 0 ? index-1 : index + 1 
      document.getElementById(`nav-tab-${mapType[index]}`).click();
      getAllMap(selectedPage,mapType[index]);
      navigate(`${location.pathname}`, { state: { ...location.state, type: mapType[index] } })
      getMapType();
    }
  }

  useEffect(() => {
    if(type)
    getAllMap(0, type);
   
  }, [type])

  useEffect(() => {
    getMapType();
  }, [])

  const handlePageClick = (event) => {
    setSelectPage(event.selected);
    getAllMap(event.selected, type);
  };

  const handleTab = (ele, type) => {
    // ele.preventDefault();
    // getAllMap(0, type);
    // setSelectPage(0);
    navigate(`${location.pathname}`, { state: { ...location.state, type: type } })
    setType(type)
  }




  return (
    <>
      {/*<Sidebar />*/}
      <div className="main-content vh-100 vh_md-auto overflow-y-a">
        <Header title="Map" />
        <div className="container-fluid">
          <div className="row">
            <nav className="mb-5">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">
                    <i className="fe fe-home"></i> Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Map
                </li>
              </ol>
            </nav>
            <div className="mb-4" style={{ textAlign: "end" }}>
              <div style={{ display: "-webkit-inline-box" }}>
                <Link className="btn btn-second me-2" to={"/map/add"} state={{type:location.state?.type}} >Add</Link>
                {/* <button className="btn btn-second me-2" style={{ padding: "3px 10px" }} type="button" data-bs-toggle="dropdown" aria-expanded="false">Add</button> */}
                <div className="dropdown show">
                  {/* <div className="dropdown me-3">
                      <ul className="dropdown-menu" style={{ marginTop: "-10px" }}>
                      <li><Link className="btn btn-second me-2" to={"/map-add"} state={{ status: "Personal" }} >Personal</Link></li>
                      <li><Link className="dropdown-item" to={"/map/add"} state={{ status: "Official" }} >Official</Link></li>
                      <li><Link className="dropdown-item" to={"/map/add"} state={{ status: "touristAttraction" }} >Tourist</Link></li>
                      </ul>
                    </div> */}
                </div>
                <div className="position-relative">
                  <input
                    className="form-search "
                    type="search"
                    name=""
                    onChange={debouncedOnChange}
                    placeholder="Search"
                  />
                  <img
                    className="users-search"
                    src="/images/Icon feather-search.svg"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <hr />

            <div className="card p-3 shadow">
              <nav>
                <div className="nav nav-tabs mb-3" id="nav-tab" role="tablist">

                  {mapType?.map((ele, index) => {

                    return (
                      <button key={index} className={`nav-link me-5 ${location.state?.type ? ele === location.state?.type ? "active" : "" : index === 0 ? "active" : ""}`} id={`nav-tab-${ele}`} data-bs-toggle="tab" data-bs-target={`#nav-tab-${ele}`} type="button" role="tab" aria-controls={`nav-tab-${ele}`} aria-selected={index === 0} onClick={(e) => handleTab(e, ele)}>
                        <div>
                          {ele} <span data-bs-toggle="modal" data-bs-target="#removeCategory" onClick={() => { setSelectedCategory(ele) }} >
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle" viewBox="0 0 16 16">
                              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                            </svg>
                          </span>
                        </div>
                      </button>
                    )
                  })
                  }
                  {/* <button className="nav-link active me-5" id="nav-official-tab" data-bs-toggle="tab" data-bs-target="#nav-official" type="button" role="tab" aria-controls="nav-official" aria-selected="true" onClick={(e) => handleTab(e, "Official")}>Official</button>
                  <button className="nav-link me-5" id="nav-tourist-tab" data-bs-toggle="tab" data-bs-target="#nav-tourist" type="button" role="tab" aria-controls="nav-tourist" aria-selected="false" onClick={(e) => handleTab(e, "touristAttraction")} >Tourist</button> */}
                </div>
              </nav>

              <div className="tab-content" id="nav-tabContent">
                {mapType?.map((ele, index) => {
                  return (
                    <div key={index} className={`tab-pane fade ${location.state?.type ? ele === location.state?.type ? "active show" : "" : index === 0 ? "active show" : "" }`} id={`nav-tab-${ele}`} role="tabpanel" aria-labelledby={`nav-tab-${ele}`}>
                      <Table
                        status={ele}
                        selectedPage={selectedPage}
                        limit={limit}
                        tableData={alldata}
                        // removeData={removeData}
                        setRemoveData={setRemoveData}
                        tableSpin={tableSpin}
                      />
                    </div>
                  )
                })}
                {/* <div className="tab-pane fade active show" id="nav-official" role="tabpanel" aria-labelledby="nav-official-tab">
                  <Table
                    status="Official"
                    selectedPage={selectedPage}
                    limit={limit}
                    tableData={alldata}
                    // removeData={removeData}
                    setRemoveData={setRemoveData}
                    tableSpin={tableSpin}
                  />
                </div>
                <div className="tab-pane fade " id="nav-tourist" role="tabpanel" aria-labelledby="nav-tourist-tab">
                  <Table
                    status="Tourist"
                    selectedPage={selectedPage}
                    limit={limit}
                    tableData={alldata}
                    // removeData={removeData}
                    setRemoveData={setRemoveData}
                    tableSpin={tableSpin}
                  />
                </div> */}
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center">
            {alldata?.length > 0 ? <Pagination
              clickHandler={handlePageClick}
              page={page}
              selectedPage={selectedPage}
            />
              : null}
          </div>
        </div>
      </div>

      <RemoveModal removeHandler={removeHandler} modalId="exampleModal" message={<>Are you sure you want to<br /> remove this?</>} />
      <RemoveModal removeHandler={removeCategoryHandler} modalId="removeCategory" message={<>Are you sure you want to remove category "<>{selectedCategory}</>"? <br /> All related location will <br /> also be removed.</>} />

    </>
  )
}

export default Map