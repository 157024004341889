import React, { useState } from 'react'
import Header from '../../common/Header'
import Spinwheel from '../../common/Spinwheel'
import { Link, useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { UpdateQuiesQuestionOrder, deleteQuizQuestionsApi, getQuizDetailsByCourseIdApi, updateQuizQuestionsApi } from '../../../utils/ApiEndpointFunctions'
import RemoveModal from '../../common/RemoveModal'
import toast from 'react-hot-toast'
import { arrayMove, List } from "react-movable";
function ViewQuiz() {
  const params = useParams();
  const [spinWeel, setSpinWheel] = useState(false)
  const [quiz, setQuiz] = useState([]);
  const [removeData, setRemoveData] = useState();



  const handleOrderChanged = async ({ oldIndex, newIndex }) => {
    const newArray = arrayMove(quiz, oldIndex, newIndex);
    let payload = { courseId: newArray[0]?.courseId, quesAndAns : newArray}
    setQuiz(newArray);
    await UpdateQuiesQuestionOrder(payload)
  };

  const updateQuizHandler = () => {
    let quiz1 = quiz?.map((ele, i) => {
      return {
        _id:ele._id,
        questions: ele?.ques,
        answer: ele?.options,
        correctAnswer: ele?.ans,
      }
    });
    quiz1 = { courseId: quiz[0]?.courseId, quesAndAns: quiz1 };

    
    return quiz1
  }

  const removeHandler = async (e) => {
    e.preventDefault()
    try {
      const res = await deleteQuizQuestionsApi(removeData);
      if (res.status === 200) {
        toast.success("Removed")
        getQuizByCid(params.id)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getQuizByCid = async (id) => {
    try {
      setSpinWheel(false)
      const res = await getQuizDetailsByCourseIdApi(id);
      if (res.status === 200) {
        setQuiz(res.data?.data)
      }
      setSpinWheel(true)
    } catch (error) {
      console.log(error)
      setSpinWheel(true)
    }
  }
  useEffect(() => {
    if (params?.id) {
      getQuizByCid(params.id)
    }
  }, [])
 
  return (
    <>
    
      <div className="main-content vh-100 vh_md-auto overflow-y-a">
        <Header title="Study" />
        <div className="container-fluid">
          <div className="row">
            <div className='d-flex justify-content-between'>
              <nav className="mb-5">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">
                      <i className="fe fe-home"></i> Dashboard
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/course" className="breadcrumb-item">
                      Course
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    View Quiz
                  </li>
                </ol>
              </nav>
              <div className="d-flex justify-content-end mb-4 flex-wrap">
                <div style={{ display: "-webkit-inline-box" }}>
                  <div className="dropdown show">
                    <div className="dropdown me-3"><button className="btn btn-second dropdown-toggle" style={{ padding: "7px 10px" }} type="button" data-bs-toggle="dropdown" aria-expanded="false">Action</button>
                      <ul className="dropdown-menu" style={{ marginTop: "-10px" }}>
                        <li><Link className="dropdown-item" to={`/course/quiz/add/${params?.id}`} >Add quiz</Link></li>
                        {quiz?.length > 0 && <li><Link className="dropdown-item" to={`/course/quiz/update/${params?.id}`} state={{ quiz1: updateQuizHandler() }} >Update quiz</Link></li>}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="col-lg-10">
              <div className='card'>
                <List 
                  values={quiz}
                  onChange={handleOrderChanged}
                  renderList={(item) => {
                    
                    return (
                      <div className='card-body' style={{ backgroundColor: '#ebe3d93b'}}>
                        <div  {...item?.props}>
                          {
                            spinWeel ?
                              quiz?.length > 0 ?
                                item?.children :
                                <div className='d-flex justify-content-center'>
                                  <h1>Data Not Found</h1>
                                </div> :
                              <div className="d-flex justify-content-center">
                                <Spinwheel />
                              </div>}
                        </div>
                        
                      </div>
                    )
                  }}
                  renderItem={(ele) => {
                    
                    return (
                      <div key={"aaaaaaa" + ele.index} >
                        <div className='d-flex align-items-center justify-content-between'>
                          <div style={{cursor:"pointer"}}> 
                          <h3 data-movable-handle {...ele.props} ><span  className='me-2'>{ele?.index + 1}.</span>{ele?.value?.ques}</h3>
                          </div>
                          <div className="dropdown">
                            <span
                              className="dropdown-ellipses dropdown-toggle"
                              role="button"
                              data-bs-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <i className="fe fe-more-vertical" />
                            </span>
                            <div className="dropdown-menu dropdown-menu-end" data-movable-handle >
                              <div data-bs-toggle="modal" data-bs-target="#removeModal" className="dropdown-item" style={{ cursor: "pointer" }} onClick={() => { setRemoveData(ele?.value?._id); }}  >
                                Remove
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className='ms-4 fw-semibold'>
                          {ele?.value?.options?.map((el, ind) => {
                            return <p key={`bbbbbbb${ind}`}><span className='me-2'>{ind + 1}.</span>{el?.name}</p>
                          })
                          }
                        </div>
                        <div className='ms-3'>
                          <h5>Correct Answer</h5>
                          <p style={{ fontSize: 14 }}>{ele?.value?.ans}</p>
                        </div>
                        <hr />
                      </div>
                    )
                  }}
                />
              </div>
              <br />
            </div>
            <div className="col-lg-8"></div>
          </div >
        </div >
      </div >
      <RemoveModal removeHandler={removeHandler} modalId="removeModal" message={<>Are you sure you want to<br /> remove this?</>} />
    </>
  )
}

export default ViewQuiz