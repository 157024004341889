
import React from 'react'
import { ImageUrl } from '../../../utils/BaseUrl'
import moment from 'moment'

function CourseDetailModal({ showModalData, setShowModalData }) {
  return (
    <div className="modal fade" id="showCourseDetail" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static"
      data-bs-keyboard="false" >
      <div className="modal-dialog modal-lg modal-dialog-centered" >
        <div className="modal-content">
          <div className="modal-header border-0">
            <button type="button" className="btn-close block-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { setShowModalData({}) }}></button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-8 d-flex">
                <div className='w-100'>
                  <img src={showModalData?.courseThumbnail ? ImageUrl + showModalData?.courseThumbnail : "/images/image.png"} alt="articles" style={{ height: 200, width: 400, objectFit: 'fill' }} />
                  <br />
                  <br />
                  <p style={{overflowWrap:"anywhere",overflow:"auto"}} dangerouslySetInnerHTML={{ __html: showModalData?.content }}></p>
                </div>
              </div>
              <div className="col-4">
                <div className="mb-3">
                  <h4 className='fw-bold'>Title</h4>
                  <p className='mb-0'>{showModalData?.courseTitle}</p>
                  <hr className='mt-1 fw-bold' style={{ height: '2px' }} />
                </div>
                <div className="mb-3">
                  <h4 className='fw-bold'>Creator name</h4>
                  <p className='mb-0'>{showModalData?.publisher}</p>
                  <hr className='mt-1 fw-bold' style={{ height: '2px' }} />
                </div>
                <div className="mb-3">
                  <h4 className='fw-bold'>Completion time</h4>
                  <p className='mb-0'>{showModalData?.completionTime}</p>
                  <hr className='mt-1 fw-bold' style={{ height: '2px' }} />
                </div>
                <div className="mb-3">
                  <h4 className='fw-bold'>Credit points</h4>
                  <p className='mb-0'>{showModalData?.creditPoints}</p>
                  <hr className='mt-1 fw-bold' style={{ height: '2px' }} />
                </div>
                <div className="mb-3">
                  <h4 className='fw-bold'>Publish date</h4>
                  <p className='mb-0'>{showModalData?.publishDate && moment(showModalData?.publishDate).format("DD/MM/YYYY")}</p>
                  <hr className='mt-1 fw-bold' style={{ height: '2px' }} />
                </div>
                <div className="mb-3">
                  <h4 className='fw-bold'>Popular</h4>
                  <p className='mb-0'>{showModalData?.isPopular ? 'Yes' : 'No'}</p>
                  <hr className='mt-1 fw-bold' style={{ height: '2px' }} />
                </div>
                <div className="mb-3">
                  <h4 className='fw-bold'>Banner</h4>
                  <p className='mb-0'>{showModalData?.isBanner ? 'Yes' : 'No'}</p>
                  <hr className='mt-1 fw-bold' style={{ height: '2px' }} />
                </div>
                <div className="mb-3">
                  <h4 className='fw-bold'>Publish</h4>
                  <p className='mb-0'>{showModalData?.isPublished ? 'Yes' : 'No'}</p>
                  <hr className='mt-1 fw-bold' style={{ height: '2px' }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CourseDetailModal