import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { useAdminContext } from '../../../../context/adminContext';
import {  createOrUpdateTrainStationApi,  createOrUpdateTrainStopApi,  getAllTrainStationPGApi, getAllTrainStopPGApi, removeTrainStationByIdApi, removeTrainStopByIdApi, updateTrainStopSequenceApi, } from '../../../../utils/ApiEndpointFunctions';
import Header from '../../../common/Header';
import { trainStopInitialValue, trainStopValidationSchema } from "../../../common/Validation"
import Pagination from '../../../common/Pagination';
import Spinwheel from '../../../common/Spinwheel';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { toast } from 'react-hot-toast';
import { debounce } from 'lodash';
import RemoveModal from '../../../common/RemoveModal';
import { useParams } from 'react-router-dom';
import AddOrUpdateStationModal from './AddOrUpdateStation';
import moment from 'moment';
import AddOrUpdateTrainStopModal from './AddOrUpdateStopModal';
import { arrayMove, List } from 'react-movable';


function AllTrainStop() {
  const navigate = useNavigate()
  const [tableSpin, setTableSpin] = useState(true);
  const [data, setData] = useState([]);
  let [selectedPage, setSelectPage] = useState(0);
  const [page, setPage] = useState(0);
  const param = useParams()
  const addOrUpdateTrainStopModalId = "addOrUpdateTrainStopModalId"
  const removeStopModalId = "removeStopModalId"
  const [selectedStop, setSelectedStop] = useState(null)
  const [addLoader, setAddLoader] = useState(false)
  const limit = 10;

  const getAllTrainStationList = async (selectedPage, search) => {
    try {
      setTableSpin(false)
      const res = await getAllTrainStopPGApi(param?.id,search || "", selectedPage + 1, limit)
      if (res.status === 200) {
        setData(res.data?.data?.data)
        const totaldata = res.data?.data?.totalData

        setPage(Math.ceil(totaldata / limit));
      }
      setTableSpin(true)
    } catch (error) {
      setTableSpin(true)
    }
  }

  const handlePageClick = (event) => {
    setSelectPage(event.selected);
    getAllTrainStationList(event.selected)
  };

  const handleRemoveMovie = async () => {
   try {
     const res = await removeTrainStopByIdApi(selectedStop);
     if (res?.status === 200) {
       toast.success(res.data?.message)
       getAllTrainStationList(0)
       setSelectedStop(null)
       document.getElementById(removeStopModalId).click()
     }
   } catch (error) {
     toast.error(error?.response?.data?.message);
   }
 }

  const handleSubmit = async (values) => {
    try {
      let payload ={
         ...values,
         stationId:values?.stationId?.value || ""
      }
      if(param?.id){
         payload.trainId = param?.id
      }
      setAddLoader(true)
      const res = await createOrUpdateTrainStopApi(payload);
      if (res?.status === 200) {
        toast.success(res.data?.message)
        setAddLoader(false)
        getAllTrainStationList(0)
        document.getElementById(addOrUpdateTrainStopModalId).click()
        setSelectedStop(null)
       formik.resetForm()
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setAddLoader(false)
    }
  }


  const formik = useFormik({
    initialValues: trainStopInitialValue,
    onSubmit: handleSubmit,
    validationSchema: trainStopValidationSchema,
  })

  const onChange = async (e) => {
    let search = e.target.value;
    getAllTrainStationList(0, search)
  };

  const debouncedOnChange = debounce(onChange, 500);

  const handleOrderChanged = async ({ oldIndex, newIndex }) => {
    const newArray = arrayMove(data, oldIndex, newIndex);
    setData(newArray);
    let payload = newArray.map(ele => ({ _id: ele._id }));
    // try {
    //   const res = await updateTrainStopSequenceApi({ stops: payload });
    //   if (res.status === 200) {
    //     toast.success(res.data?.message)
    //   }
    // } catch (error) {
    //   console.log(error);
    //   toast.error("Something went wrong")
    // }
  };

  useEffect(() => {
   if(param?.id){
      getAllTrainStationList(selectedPage)   
   }
  }, [param?.id])




  return (
    <>
      {/*<Sidebar />*/}
      <div className="main-content vh-100 vh_md-auto overflow-y-a">
        <Header title="Train" />
        {/* CARDS */}
        <div className="container-fluid">
          <div className="row">
            <nav className="mb-5">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">
                    <i className="fe fe-home"></i> Dashboard
                  </Link>
                </li>
                <Link to={"/ticket/train/tickets"} className="breadcrumb-item active" aria-current="page">
                Train Ticket
                </Link>
                <Link to={"/ticket/train/all-train"} className="breadcrumb-item active" aria-current="page">
                Trains
                </Link>
                <li  className="breadcrumb-item active" aria-current="page">
                Stop
                </li>
              </ol>
            </nav>
            <div className="mb-4" style={{ textAlign: "end" }}>
              <div className='d-flex justify-content-end gap-2' style={{ display: "-webkit-inline-box" }}>
                <div className='d-flex gap-2 align-items-center justify-content-center'>
                <button data-bs-toggle="modal" data-bs-target={`#${addOrUpdateTrainStopModalId}`} onClick={() =>formik.resetForm()} className="btn-custom w-auto" style={{ cursor: "pointer" }}>
                  Add
                  </button>

                  <div className="position-relative">
                    <input
                      className="form-search me-3"
                      type="search"
                      name=""
                      onChange={debouncedOnChange}
                      placeholder="Search"
                    />
                    <img
                      className="users-search"
                      src="/images/Icon feather-search.svg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <hr />

            <>
              <List
                values={data}
                onChange={handleOrderChanged}
                renderList={(item) => {
                  return (
                    <div className='card p-3 table-responsive'>
                      <table className="table table-hover table-nowrap card-table">
                        <thead>
                          <tr>
                            <th>
                              <span
                                className="list-sort text-muted"
                              >
                                S.No
                              </span>
                            </th>
                            <th>
                              <span
                                className="list-sort text-muted"
                              >
                                Name
                              </span>
                            </th>
                            <th>
                              <span className="list-sort text-muted">
                                Alias
                              </span>
                            </th>
                            <th>
                              <span className="list-sort text-muted">
                                Departure Time
                              </span>
                            </th>
                            <th>
                              <span className="list-sort text-muted">
                                Arrival Time
                              </span>
                            </th>
                            <th>
                              <span className="list-sort text-muted">
                                Plateform No
                              </span>
                            </th>
                            <th></th>


                          </tr>
                        </thead>

                        <tbody {...item?.props} className="list fs-base">
                          {tableSpin ? data?.length > 0 ? item?.children : <tr align="center">
                            <td colSpan="12">
                              <h1>
                                Data Not Found
                              </h1>
                            </td>
                          </tr> : <tr align="center">
                            <td colSpan="12">
                              <Spinwheel />
                            </td>
                          </tr>}
                        </tbody>
                      </table>
                    </div>
                  )
                }}

                renderItem={(ele) => {
                  return (
                    <tr key={ele.index} >
                      <td data-movable-handle  {...ele.props} >{ele.index + 1}</td>
                      <td className='position-relative'>

                        {ele.value?.stationId?.name?.length > 30 ? <span>
                          <div className="tooltip1 item-name text-reset" >
                            {ele.value?.stationId?.name?.substr(0, 30)}...
                            <div className='wrapper'>
                              <div className="tooltip1-text">{ele.value?.stationId?.name}</div>
                            </div>
                          </div>

                        </span> :
                          <span>{ele.value?.stationId?.name || "-"}</span>}
                      </td>

                      <td className='position-relative'>
                        {ele.value?.stationId?.alias || "-"}
                      </td>
                      <td className='position-relative'>
                        <span>{ele.value?.departureTime ? moment(ele.value?.departureTime).format("DD/MM/YYYY h:mm:ss a") : "-"}</span>
                      </td>
                      <td className='position-relative'>
                        <span>{ele.value?.arrivalTime ? moment(ele.value?.arrivalTime).format("DD/MM/YYYY h:mm:ss a") : "-"}</span>
                      </td>
                      <td className='position-relative'>
                        {ele.value?.plateFormNo || "-"}
                      </td>
                      <td className="text-end">
                        {ele.value?.status?.toLowerCase() != "transferred" &&
                          <div className="dropdown">
                            <span
                              className="dropdown-ellipses dropdown-toggle"
                              role="button"
                              data-bs-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <i className="fe fe-more-vertical" />
                            </span>
                            <div className="dropdown-menu dropdown-menu-end">
                              <div data-bs-toggle="modal" data-bs-target={`#${addOrUpdateTrainStopModalId}`} className=' dropdown-item' style={{ cursor: "pointer" }} onClick={() => { formik.setValues(ele.value) }}>
                                View / edit
                              </div>
                              <div data-bs-toggle="modal" data-bs-target={`#${removeStopModalId}`} className=' dropdown-item' style={{ cursor: "pointer" }} onClick={() => { setSelectedStop(ele.value?._id) }}>
                                Remove
                              </div>
                            </div>
                          </div>}
                      </td>
                    </tr>
                  )
                }}
              />
            </>

            {/* <div className="card p-3 table-responsive" >
              <table className="table table-hover table-nowrap card-table">
                <thead>
                  <tr>
                    <th>
                      <span
                        className="list-sort text-muted"
                      >
                        S.No
                      </span>
                    </th>
                    <th>
                      <span
                        className="list-sort text-muted"
                      >
                        Name
                      </span>
                    </th>
                    <th> 
                     <span className="list-sort text-muted">
                        Alias
                      </span>
                    </th>
                    <th> 
                     <span className="list-sort text-muted">
                     Departure Time
                      </span>
                    </th>
                    <th> 
                     <span className="list-sort text-muted">
                     Arrival Time
                      </span>
                    </th>
                    <th> 
                     <span className="list-sort text-muted">
                        Plateform No
                      </span>
                    </th>
                    <th></th>


                  </tr>
                </thead>
                {
                  tableSpin ?
                    <tbody className="list fs-base">
                      {data?.length > 0 ? data?.map((ele, index) => {
                        return (<tr key={index} >
                          <td>{index + 1}</td>
                          <td className='position-relative'>
                         
                            {ele?.stationId?.name?.length > 30 ? <span>
                              <div className="tooltip1 item-name text-reset" >
                              {ele?.stationId?.name?.substr(0, 30)}...
                              <div className='wrapper'>
                                <div className="tooltip1-text">{ele?.stationId?.name}</div>
                              </div>
                              </div>
                             
                            </span> :
                              <span>{ele?.stationId?.name || "-"}</span>}
                          </td>
                          
                          <td className='position-relative'>
                            {ele?.stationId?.alias || "-"}
                          </td>
                          <td className='position-relative'>
                            <span>{ele?.departureTime ? moment(ele?.departureTime).format("DD/MM/YYYY h:mm:ss a") : "-"}</span>
                          </td>
                          <td className='position-relative'>
                            <span>{ele?.arrivalTime ? moment(ele?.arrivalTime).format("DD/MM/YYYY h:mm:ss a") : "-"}</span>
                          </td>
                          <td className='position-relative'>
                            {ele?.plateFormNo || "-"}
                          </td>
                          <td className="text-end">
                            {ele?.status?.toLowerCase() != "transferred" &&
                              <div className="dropdown">
                                <span
                                  className="dropdown-ellipses dropdown-toggle"
                                  role="button"
                                  data-bs-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <i className="fe fe-more-vertical" />
                                </span>
                                <div className="dropdown-menu dropdown-menu-end">
                                  <div data-bs-toggle="modal" data-bs-target={`#${addOrUpdateTrainStopModalId}`} className=' dropdown-item' style={{ cursor: "pointer" }} onClick={() => {formik.setValues(ele) }}>
                                    View / edit
                                  </div>
                                  <div data-bs-toggle="modal" data-bs-target={`#${removeStopModalId}`} className=' dropdown-item' style={{ cursor: "pointer" }} onClick={() => { setSelectedStop(ele?._id) }}>
                                    Remove
                                  </div>
                                </div>
                              </div>}
                          </td>
                        </tr>
                        )
                      }) : <tr align="center">
                        <td colSpan="9">
                          <h1>
                            Data Not Found
                          </h1>
                        </td>
                      </tr>}
                    </tbody> : (
                      <tbody>
                        <tr align="center">
                          <td colSpan="9">
                            <Spinwheel />
                          </td>
                        </tr>
                      </tbody>
                    )
                }
              </table>
            </div> */}

            <div className="d-flex justify-content-center">
              {data?.length > 0 ? <Pagination
                clickHandler={handlePageClick}
                page={page}
                selectedPage={selectedPage}
              />
                : null}
            </div>
          </div>
          <AddOrUpdateTrainStopModal modalId={addOrUpdateTrainStopModalId} formik={formik} addLoader={addLoader}/>
          <RemoveModal removeHandler={handleRemoveMovie} modalId={removeStopModalId} message={"Are you sure you want to remove?"} />
        </div>
      </div>
    </>
  )
}

export default AllTrainStop