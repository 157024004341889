import React, { useEffect, useState } from 'react'
import Pagination from '../../common/Pagination';

import { Link } from 'react-router-dom';
import Header from '../../common/Header';
import { useAdminContext } from '../../../context/adminContext';
import { debounce } from 'lodash';
import { addCategoryToCarouselApi, getAllAddedCarouselCategoryApi, getAllUnCarouselCategoryApi, removeCategoryFromCarouselApi } from '../../../utils/ApiEndpointFunctions';
import toast from 'react-hot-toast';
import AddCrouselModal from '../../common/AddCrouselModal';
import { useFormik } from 'formik';

import { manageCarouselIntialValue, manageCarouselValidationSchema } from '../../common/Validation';
import CarouselTable from './CarouselTable';
import RemoveModal from '../../common/RemoveModal';


function ManageCategoryCarousel() {

  const { adminDetail } = useAdminContext();
  let [selectedPage, setSelectPage] = useState(0);
  const [category, setCategory] = useState([]);
  const [removeData, setRemoveData] = useState(null);
  const [tableSpin, setTableSpin] = useState(true);
  const [categoryData, setcategoryData] = useState({});
  const carouselModalId = "carouselModalID";
  const removeCarouselModalId = "removeCarouselModalId"
  const [carouselOptions, setCarouselOptions] = useState([]);
  const [modalUpdate, setModalUpdate] = useState({
    id: "",
    status: false,
    type: ""
  })

  const [page, setPage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const limit = 10;

  const getAllAddedCarouselNews = async () => {
    setTableSpin(false)
    const res = await getAllAddedCarouselCategoryApi(searchText)
    if (res.status === 200) {
      const totalData = res.data?.totalData
      setPage(Math.ceil(totalData / limit));
      setCategory(res.data?.data)
      setTableSpin(true)
    }
  }

  const getAllUnCarouselNews = async () => {
    const res = await getAllUnCarouselCategoryApi('')
    if (res.status === 200) {
      
      setCarouselOptions(res.data?.data?.map((ele, index) => {
        return {
          label: ele?.name,
          value: ele
        }
      }))

    }

  }

  const onChange = async (e) => {
    let search = e.target.value;
    setTableSpin(false);
    setSearchText(search)
    const res = await getAllAddedCarouselCategoryApi(search);
    if (res.status === 200) {
      
      setCategory(res.data?.data);
      const totalData = res.data?.totalData
      setPage(Math.ceil(totalData / limit));
      setSelectPage(0)
      setTableSpin(true);
    }
  };

  const debouncedOnChange = debounce(onChange, 500);

 

  useEffect(() => {
    getAllAddedCarouselNews();

  }, [])

  const handlePageClick = (event) => {
    selectedPage = event.selected
    setSelectPage(event.selected);
    getAllAddedCarouselNews(event.selected);
  };

  const handleTab = (ele, t) => {
    ele.preventDefault();
    selectedPage = 0;
    setSelectPage(0);
    
    getAllAddedCarouselNews();
  }

  const addCarousel = async (values) => {
    document.getElementById(carouselModalId).click()
    try {
      
      const res = await addCategoryToCarouselApi({ id: values.id?._id });
      if (res.status === 200) {
        toast.success(res.data?.message)
        getAllAddedCarouselNews(selectedPage);
      }
    } catch (error) {
      toast.error("Something went wrong")
    }
  }

  const handleRemoveData = async (data) => {
    
    document.getElementById(removeCarouselModalId).click();
    try {
      const res = await removeCategoryFromCarouselApi({ _id: removeData })
      if (res.status === 200) {
        getAllAddedCarouselNews()
        toast.success(res.data?.message)
      }
    } catch (error) {
      toast.error("Something went wrong")
    }
  }

  const carouselFormik = useFormik({
    initialValues: manageCarouselIntialValue,
    onSubmit: addCarousel,
    validationSchema: manageCarouselValidationSchema
  })

  return (
    <>
      {/*<Sidebar />*/}
      <div className="main-content vh-100 vh_md-auto overflow-y-a">
        <Header title="Category" />
        <div className="container-fluid">
          <div className="row">
            <nav className="mb-5">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard" >
                    <i className="fe fe-home"></i> Dashboard
                  </Link>
                </li>
                <Link to="/category" className="breadcrumb-item active" aria-current="page">
                  Category
                </Link>
                <li className="breadcrumb-item active" aria-current="page">
                  Carousel
                </li>
              </ol>
            </nav>
            <div className="d-flex justify-content-end mb-4 flex-wrap">
              <div className='d-flex gap-3 justify-content-end align-items-center' style={{ display: "-webkit-inline-box" }}>
                <div className="btn-custom" data-bs-toggle="modal" data-bs-target={`#${carouselModalId}`} onClick={() => { carouselFormik.resetForm(); getAllUnCarouselNews() }}>
                  Add
                </div>
                
              </div>
            </div>
            <hr />

            <div className="card p-3 ">
              <CarouselTable
                selectedPage={selectedPage}
                limit={limit}
                tableData={category}
                setTableData={setCategory}
                setRemoveData={setRemoveData}
                tableSpin={tableSpin}
                setModalUpdate={setModalUpdate}
                setcategoryData={setcategoryData}
                carouselFormik={carouselFormik}
                carouselModalId={carouselModalId}
                
              />
              </div>
          </div>
          <div className="d-flex justify-content-center">
            {category?.length > limit ? <Pagination
              clickHandler={handlePageClick}
              page={page}
              selectedPage={selectedPage}
            /> : null}
          </div>
        </div>
      </div>
      <AddCrouselModal modalId={carouselModalId} formik={carouselFormik} title="Select course" options={carouselOptions} currentValue={carouselFormik?.values?.id ? { label: carouselFormik?.values?.id?.name, value: carouselFormik?.values?.id } : null} />
     

      <RemoveModal removeHandler={handleRemoveData} modalId={removeCarouselModalId} message={<>Are you sure you want to<br /> remove this?</>} />
    </>
  )
}

export default ManageCategoryCarousel