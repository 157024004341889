import React, { useEffect, useState } from 'react'
import Spinwheel from '../../common/Spinwheel'
import { Link, useParams, useNavigate } from 'react-router-dom';
import { ImageUrl } from '../../../utils/BaseUrl';
import { getUserByIdApi } from '../../../utils/ApiEndpointFunctions';
import Header from '../../common/Header';
import { useFormik } from 'formik';
import { userDocumentsInitialValues, userDocumentsValidationSchema } from '../../common/Validation';
import DocumentTable from './DocumentTable';
import moment from 'moment';
import DocumentApplicationTable from './DocumentApplicationTable';

function UserDetails() {
  const params = useParams();
  const [id, setId] = useState(params?.id)
  const [userDetails, setUserDetails] = useState({});
  const [loader, setLoader] = useState(true);
  const [activeTab, setActiveTab] = useState('profile');
  const navigate = useNavigate()
  

  const fetchUserDetails = async (id) => {
    try {
      const res = await getUserByIdApi(id);
      if (res.status === 200) {
        setUserDetails(res.data?.data)
      }

    } catch (error) {
      console.log(error);
    }
    setLoader(false);
  };

  useEffect(() => {

    if (id) {
      fetchUserDetails(id);
    }
  }, [id]);

  return (
    <>
      <div className="main-content vh-100 vh_md-auto overflow-y-a">
        <Header title="Users" />
        <div className="container-fluid">
          <div className="row">
            <nav className="mb-5">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">
                    <i className="fe fe-home"></i> Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/users" className="breadcrumb-item">
                    Users
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  View details
                </li>
              </ol>
            </nav>

            {loader ? (
              <div className="text-center">
                <Spinwheel />
              </div>
            ) : (
              <>
                  
                <div className="row align-items-end">
                  <div className="col-auto">
                    <div className="avatar avatar-xxl header-avatar-top mt-3">
                        <img src={userDetails?.userDetails?.profile && userDetails?.userDetails?.profile != "null" ? `${ImageUrl}${userDetails?.userDetails?.profile}` : "/images/person-circle.svg"} alt="profile" className="avatar-img rounded-circle border border-4 border-body" />
                    </div>
                  </div>
                  <div className="col mb-3 ms-n3 ms-md-n2">
                    <h1 className="header-title">
                      {userDetails.name}
                    </h1>
                  </div>
                  <div className="col-12 col-md-auto mt-2 mt-md-0 mb-md-3">
                  
                  </div>
                    <div className="btn-custom w-auto ms-0 mt-0 " onClick={() => { navigate(`/users/edit/${userDetails?._id}`) }}  >Edit</div>
                </div>

                <div className='d-flex justify-content-between'>
                  <ul className="nav nav-tabs">
                    <li className="nav-item">
                      <button
                        className={`nav-link ${activeTab === 'profile' ? 'active' : ''}`}
                        onClick={() => setActiveTab('profile')}
                      >
                        Profile details
                      </button>
                    </li>
                      <li className="nav-item">
                        <button
                          className={`nav-link ${activeTab === 'document' ? 'active' : ''}`}
                          onClick={() => setActiveTab('document')}
                        >
                          Document
                        </button>
                      </li>
                      <li className="nav-item">
                        <button
                          className={`nav-link ${activeTab === 'document-application' ? 'active' : ''}`}
                          onClick={() => setActiveTab('document-application')}
                        >
                          Document application
                        </button>
                      </li>
                  </ul>
                  <div>

                  </div>
                </div>


                {activeTab === 'profile' && (

                  <div className="row card">
                    <div className="row mt-3">
                      <div className="user-details">
                        <table className="table table-md table-hover card-table">
                          <tbody>

                              <tr>
                                <td>Status:</td>
                                <td>
                                  <div className={`badge ${userDetails?.isActive ? "badge bg-success-soft" : "bg-dark-soft"} `}>
                                    {userDetails?.isActive ? "Active" : "Blocked"}
                                  </div>
                                </td>
                              </tr>
                            <tr>
                              <td>Email:</td>
                              <td>{userDetails?.email}</td>
                            </tr>
                            <tr>
                              <td>Phone number:</td>
                              <td>{userDetails?.phoneNumber}</td>
                            </tr>

                            <tr>
                              <td>Gender:</td>
                              <td>{userDetails?.gender}</td>
                            </tr>
                            <tr>
                              <td>Birth place:</td>
                              <td>{userDetails?.birthPlace}</td>
                            </tr>
                            <tr>
                              <td>DOB:</td>
                                <td>{userDetails?.dob && moment(userDetails?.dob).format("DD/MM/YYYY")}</td>
                            </tr>
                            <tr>
                              <td>Country:</td>
                              <td>{userDetails?.userDetails?.country}</td>
                            </tr>
                            <tr>
                              <td>Zip code:</td>
                              <td>{userDetails?.userDetails?.zipCode}</td>
                            </tr>
                            
                            <tr>
                                <td>Correspondence address:</td>
                              <td>
                                {`${userDetails?.userDetails?.correspondence_Address ? userDetails?.userDetails?.correspondence_Address + ", " : ""}${userDetails?.userDetails?.correspondence_country ? userDetails?.userDetails?.correspondence_country + ", " : ""}${userDetails?.userDetails?.correspondence_zipcode ? userDetails?.userDetails?.correspondence_zipcode + ", " : ""}`}
                              </td>
                            </tr>
                            {/* <tr>
                              <td>Work address:</td>
                              <td>
                                {`${userDetails?.userDetails?.work_Address ? userDetails?.userDetails?.work_Address + ", " : ""}${userDetails?.userDetails?.work_country ? userDetails?.userDetails?.work_country + ", " : ""}${userDetails?.userDetails?.work_zipcode ? userDetails?.userDetails?.work_zipcode + ", " : ""}`}
                              </td>
                            </tr> */}

                            <tr>
                              <td>Compensation:</td>
                              <td>{userDetails?.userDetails?.compensation}</td>
                            </tr>

                            <tr>
                              <td>Job title:</td>
                              <td>{userDetails?.userDetails?.jobTitle}</td>
                            </tr>

                            <tr>
                              <td>Organization:</td>
                              <td>{userDetails?.userDetails?.organization}</td>
                            </tr>

                            <tr>
                              <td>Qualification:</td>
                              <td>{userDetails?.userDetails?.qualification}</td>
                            </tr>

                            <tr>
                              <td>Experience:</td>
                              <td>{userDetails?.userDetails?.experience}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}
                  {activeTab === 'document' && (
                    <DocumentTable tableData={userDetails?.userDocuments} getUserDetails={ fetchUserDetails } />
                  )}

                  {
                    activeTab === 'document-application' && (
                      <DocumentApplicationTable tableData={userDetails?.userDocumentApplications} getUserDetails={ fetchUserDetails } />
                    )
                  }
                
                  <div className="btn-custom w-auto ms-0 mt-0 " onClick={() => { window.history.back(); }}  >Back</div>
              </>
            )}

          </div>
        </div>
      </div>

     
    </>


  )
}

export default UserDetails