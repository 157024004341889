import { debounce } from 'lodash';
import React, { useState } from 'react'
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import { useAdminContext } from '../../../context/adminContext';
import Sidebar from '../../../Layout/Sidebar'
import { deleteJobByIdApi, getAllJobListApi, getCompanyDetailsByIdApi, getAllReviewsByJobIdApi, updateJobStatusApi } from '../../../utils/ApiEndpointFunctions';
import { ImageUrl } from '../../../utils/BaseUrl';
import Header from '../../common/Header';
import Pagination from '../../common/Pagination';
import RemoveModal from '../../common/RemoveModal';
import Spinwheel from '../../common/Spinwheel';
import CompanyDetailModal from './modals/CompanyDetailModal';
import JobsDetails from './modals/JobsDetails';
import moment from 'moment';

function Jobs() {

  const { setUpdateJob } = useAdminContext();
  const [tableSpin, setTableSpin] = useState(true);
  const [jobs, setJobs] = useState([]);
  const [searchText, setSearchText] = useState("")
  const [companyDetail, setCompanyDetail] = useState([]);
  const [removeData, setRemoveData] = useState(null);
  let [selectedPage, setSelectPage] = useState(0);
  const [jobStatus, setJobsStatus] = useState("")
  const [updateStatus, setUpdateStatus] = useState();
  const [page, setPage] = useState(0);
  const [job, setJob] = useState();
  const limit = 10;
  const navigate = useNavigate()
 

  const companyDetailById = async (id) => {
    try {
      const res = await getCompanyDetailsByIdApi(id);
      if (res.status === 200) {
        setCompanyDetail(res.data?.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getJobList = async () => {
    setTableSpin(false)
    const res = await getAllJobListApi(limit, selectedPage + 1, searchText)
    if (res.status === 200) {
      setJobs(res.data?.data)
      const totaldata = res.data?.totalLength;
      setPage(Math.ceil(totaldata / limit));
      setTableSpin(true)
    }
  }

  const removeJobsHandler = async () => {
    const res = await deleteJobByIdApi(removeData)
    if (res.status === 200) {
      toast.success(res.data?.message)
      getJobList(selectedPage)
    }
  }

  const onChange = async (e) => {
    let search = e.target.value;
    setTableSpin(false);
    setSearchText(search)
    const res = await getAllJobListApi(limit, 1, search);
    if (res.status === 200) {
      setJobs(res.data?.data);
      const totaldata = res.data?.totalLength;
      setPage(Math.ceil(totaldata / limit));
      setSelectPage(0)
      setTableSpin(true);
    }
  };

  const debouncedOnChange = debounce(onChange, 500);

  const handlePageClick = (event) => {
    selectedPage = event.selected
    setSelectPage(event.selected);
    getJobList(event.selected)
  };
  const changeStatusHandler = (e) => {
    let status = jobStatus === "Open" ? "Closed" : "Open"
    setJobsStatus(status)
  }

  const updateJobHandler = (ele) => {
    let from = ele?.salaryRange?.split('-')?.[0] ? ele?.salaryRange?.split('-')?.[0] : ""
    let to = ele?.salaryRange?.split('-')?.[1] ? ele?.salaryRange?.split('-')?.[1] : ""
    setUpdateJob({ ...ele, salaryRangeFrom: from, salaryRangeTo: to}) 
  }

  const updateJobStatus = async (e) => {
    e.preventDefault()
    try {
      const res = await updateJobStatusApi({ jobId: updateStatus, status: jobStatus })
      if (res.status === 200) {
        toast.success(res.data?.message)
        getJobList(selectedPage)
      }
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getJobList(selectedPage)

  }, [])

  return (
    <>
      {/*<Sidebar />*/}
      <div className="main-content vh-100 vh_md-auto overflow-y-a">
        <Header title="Jobs" />
        {/* CARDS */}
        <div className="container-fluid">
          <div className="row">
            <nav className="mb-5">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">
                    <i className="fe fe-home"></i> Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Jobs
                </li>
              </ol>
            </nav>
            <div className="mb-4" style={{ textAlign: "end" }}>
              <div style={{ display: "-webkit-inline-box" }}>
                <div className="me-3"><button className="btn-custom" onClick={() => { navigate(`/job/add`)}} >Job post</button>
                  {/* <div className="dropdown me-3"><button className="btn btn-second dropdown-toggle" style={{ padding: "7px 10px" }} type="button" data-bs-toggle="dropdown" aria-expanded="false">Add</button>
                    <ul className="dropdown-menu" style={{ marginTop: "-10px" }}>
                      <li><Link className="dropdown-item" to={"/job/add-Company"} >Add-Company</Link></li>
                      <li><Link className="dropdown-item" to={"/job/add"} >Add-Job</Link></li>
                    </ul>
                  </div> */}
                </div>
                <div className="position-relative">
                  <input
                    className="form-search me-3"
                    type="search"
                    name=""
                    onChange={debouncedOnChange}
                    placeholder="Search"
                  />
                  <img
                    className="users-search"
                    src="/images/Icon feather-search.svg"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <hr />

            
            <div className="card p-3 table-responsive">
                <table className="table table-hover table-nowrap card-table">
                  <thead>
                    <tr>
                      <th>
                        <span
                          className="list-sort text-muted"
                        >
                          S.No
                        </span>
                      </th>
                      <th>
                        <span
                          className="list-sort text-muted"

                        >
                          Title
                        </span>
                      </th>
                   
                    <th>
                      <span
                        className="list-sort text-muted"

                      >
                        Post time
                      </span>
                    </th>
                      <th>
                        <span
                          className="list-sort text-muted"

                        >
                          Status
                        </span>
                      </th>
                      <th>
                        <span
                          className="list-sort text-muted"

                        >
                          Employment
                        </span>
                      </th>
                      <th>
                        <span
                          className="list-sort text-muted"

                        >
                          Required Emp
                        </span>
                      </th>
                      <th>
                        <span
                          className="list-sort text-muted"

                        >
                          work Exp
                        </span>
                      </th>
                      <th>
                        <span
                          className="list-sort text-muted"

                        >
                          Required Skills
                        </span>
                      </th>
                      <th colSpan={2}>
                        <span
                          className="list-sort text-muted"

                        >
                          Description
                        </span>
                      </th>
                    </tr>
                  </thead>
                  {
                    tableSpin ?
                      <tbody className="list fs-base">
                        {jobs?.length > 0 ? jobs?.map((ele, index) => {
                          
                          return (<tr key={index} >
                            <td>{((selectedPage) * limit) + index + 1}</td>
                            <td>
                              <Link
                                className="item-name text-reset"
                                to={`/job/applied-users/${ele?._id}`}
                                state={{ jobName: ele?.title }}

                              >
                                {ele?.title ? ele?.title : "-"}
                              </Link>
                            </td>  
                            <td>
                              <span
                                className="item-phone text-reset"
                              >
                                {ele?.createdAt ? moment(ele?.createdAt).format("DD/MM/YYYY HH:mm") : "-"}
                              </span>
                            </td>
                            <td>
                              <span
                                className="item-phone text-reset"
                              >
                                {ele?.jobStatus ? ele?.jobStatus : "-"}
                              </span>
                            </td>
                            <td>
                              <span
                                className="item-phone text-reset"
                              >
                                {ele?.employmentType ? ele?.employmentType : "-"}
                              </span>
                            </td>
                            <td>
                              <span
                                className="item-phone text-reset"
                              >
                                {ele?.noOfPosition ? ele?.noOfPosition : "-"}
                              </span>
                            </td>
                            <td>
                              <span
                                className="item-phone text-reset"
                              >
                                {ele?.workExp ? ele?.workExp : "-"}
                              </span>
                            </td>
                            {/* <td>
                            <span
                              className="item-phone text-reset"
                            >
                              {ele?.skillsRequired.length > 0 ? ele?.skillsRequired.join(" ") : "-"}
                            </span>
                          </td> */}
                            <td className='position-relative'>
                              {ele?.skillsRequired.length > 0 ? (
                                <div className="tooltip1">
                                  {ele?.skillsRequired.join(", ")?.substr(0, 10)}...
                                  <div className='wrapper'>
                                    <div className="tooltip1-text">{ele?.skillsRequired.join(", ")}</div>
                                  </div>
                                </div>
                              ) : (
                                "-"
                              )}
                            </td>
                            <td className='position-relative'>
                              {ele?.description ? (
                                <div className="tooltip1">
                                  {ele?.description?.substr(0, 8)}...
                                  <div className='wrapper'>
                                    <div className="tooltip1-text">{ele?.description}</div>
                                  </div>
                                </div>
                              ) : (
                                "-"
                              )}
                            </td>

                            <td className="text-end">
                              <div className="dropdown">
                                <span
                                  className="dropdown-ellipses dropdown-toggle"
                                  role="button"
                                  data-bs-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <i className="fe fe-more-vertical" />
                                </span>
                                <div className="dropdown-menu dropdown-menu-end">
                                  <Link to={`/job/update`} className="dropdown-item" onClick={() => { updateJobHandler(ele)}}>
                                    Edit
                                  </Link>
                                  <div data-bs-toggle="modal" data-bs-target="#removeModal" className="dropdown-item" style={{ cursor: "pointer" }} onClick={() => { setRemoveData(ele?._id); }}  >
                                    Remove
                                  </div>
                                  <Link to={`/job/reviews/${ele?.companyId?._id}`} className="dropdown-item" state={{ companyName: ele?.companyId?.name }} >
                                    Review &amp; rating
                                  </Link>

                                  <div data-bs-toggle="modal" data-bs-target="#jobDetailsModal" className="dropdown-item" style={{ cursor: "pointer" }} onClick={() => { setJob(ele) }}  >
                                    Jobs details
                                  </div>


                                  <div data-bs-toggle="modal" data-bs-target="#companyDetailModal" className="dropdown-item" style={{ cursor: "pointer" }} onClick={() => { setJob(ele) }}  >
                                    Company details
                                  </div>

                                  <div data-bs-toggle="modal" data-bs-target="#updateStatusModal" className="dropdown-item" style={{ cursor: "pointer" }} onClick={() => { setUpdateStatus(ele?._id); setJobsStatus(ele?.jobStatus); setJob(ele) }}  >
                                    Open/Close
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                          )
                        }) : <tr align="center">
                          <td colSpan="9">
                            <h1>
                              Data Not Found
                            </h1>
                          </td>
                        </tr>}
                      </tbody> : (
                        <tbody>
                          <tr align="center">
                            <td colSpan="9">
                              <Spinwheel />
                            </td>
                          </tr>
                        </tbody>
                      )
                  }
                </table>

              </div>
            
          </div>
          <div className="d-flex justify-content-center">
            {jobs?.length > 0 ? <Pagination
              clickHandler={handlePageClick}
              page={page}
              selectedPage={selectedPage}
            />
              : null}
          </div>

        </div>
      </div>
      <RemoveModal removeHandler={removeJobsHandler} modalId="removeModal" message={<>Are you sure you want to<br /> remove this?</>} />
      <div className="modal fade" id="updateStatusModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-sm modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0">
              <button type="button" className="btn-close block-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body block-user">
              <div className="col-lg-12" style={{ zIndex: "1" }}>
                <div className='text-left'>
                  <h3 >Are you sure you want to update <b>{job?.title}</b> job  status ?</h3>
                </div>
                <hr />
                <form action="" className='p-0' >

                  <div className='d-flex justify-content-around '>
                    <label htmlFor="isPinned" className="form-label"> Jobs status </label>
                    <div className="form-check form-switch map-togle-container">
                      <input className="form-check-input" type="checkbox" role="switch" id="isPinned" checked={jobStatus === "Open" ? true : false} onChange={changeStatusHandler} />
                    </div>
                  </div>
                  <div className="modal-footer border-0 justify-content-center pt-4">
                    <button type="button" className="btn-custom w-auto" data-bs-dismiss='modal' onClick={(e) => { updateJobStatus(e); }} >Update</button>
                    {/* <button type="button" className="btn-custom w-auto" data-bs-dismiss='modal' >
                    Cancel
                  </button> */}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CompanyDetailModal companyDetail={companyDetail} setCompanyDetail={setCompanyDetail} job={job} />
      <JobsDetails jobDetails={job} setJobDetails={setJob} getJobList={getJobList} />

    </>
  )
}


export default Jobs