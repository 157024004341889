import React from 'react'
import ReactPaginate from 'react-paginate'

function Pagination({ clickHandler, page, selectedPage }) {
  return (
    <ReactPaginate
      nextLabel="Next >"
      onPageChange={clickHandler}
      pageRangeDisplayed={3}
      marginPagesDisplayed={2}
      pageCount={page}
      previousLabel="< Previous"
      pageClassName="page-item"
      pageLinkClassName="page-link"
      previousClassName="page-item"
      previousLinkClassName="page-link"
      nextClassName="page-item"
      nextLinkClassName="page-link"
      breakLabel="..."
      breakClassName="page-item"
      breakLinkClassName="page-link"
      containerClassName="pagination"
      activeClassName="active"
      forcePage={selectedPage}
    />
  )
}

export default Pagination