import { useFormik } from "formik";
import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { useAdminContext } from "../../../context/adminContext";
import Sidebar from "../../../Layout/Sidebar";
import { addSosMessageByAdminApi, getAllSosUsers, getSosLimitationApi } from "../../../utils/ApiEndpointFunctions";
import { ImageUrl } from "../../../utils/BaseUrl";
import Header from "../../common/Header";
import Pagination from "../../common/Pagination";
import Spinwheel from "../../common/Spinwheel";
import { addSosMessIntialValues, addSosMessValidationSchema } from "../../common/Validation";
import AddMessageLimit from "./AddMessageLimit";
import AddMessageModal from "./AddMessageModal";

function Sos() {
  const { adminDetail } = useAdminContext();
  const [sos, setSos] = useState(null);
  const [selectedPage, setSelectPage] = useState(0);
  const [page, setPage] = useState(0);
  const [sosSpin, setSosSpin] = useState(true);
  const [searchText, setSearchText] = useState("");
  const limit = 10;

  const getAllSos = async (selectedPage) => {
    setSosSpin(false);
    const res = await getAllSosUsers(limit, selectedPage + 1, searchText);
    if (res.status === 200) {
      setSos(res.data?.data);
      const totaldata = res.data?.totalData;
      setPage(Math.ceil(totaldata / limit));
      setSosSpin(true);
    }
  };


  const onChange = async (e) => {
    let search = e.target.value;
    setSosSpin(false);
    setSearchText(search)
    const res = await getAllSosUsers(limit, 1, search);
    if (res.status === 200) {
      setSos(res.data?.data);
      const totaldata = res.data?.totalData;
      setPage(Math.ceil(totaldata / limit));
      setSelectPage(0)
      setSosSpin(true);
    }
  };

  const debouncedOnChange = debounce(onChange, 500);

  const handlePageClick = (event) => {
    setSelectPage(event.selected);
    getAllSos(event.selected);
  };

  const handleRatingModal = async () => {
    messageFormik.setValues(addSosMessIntialValues)
    messageFormik.setTouched({})
  }

  const addMessage = async (values) => {
    try {
      const res = await addSosMessageByAdminApi(values);
      if (res.status === 200) {
        if (res.data?.message === "You Can't send more than 4") {
          toast.error("You Can't add more than 4")
        } else {
          toast.success(res.data?.message)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const messageFormik = useFormik({
    initialValues: addSosMessIntialValues,
    onSubmit: addMessage,
    validationSchema: addSosMessValidationSchema
  })

  useEffect(() => {
    getAllSos(0);
  }, []);

  return (
    <>
      {/*<Sidebar />*/}
      <div className="main-content vh-100 vh_md-auto overflow-y-a">
        <Header title="SOS" />
        <div className="container-fluid">
          <div className="row">
            <nav className="mb-5">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">
                    <i className="fe fe-home"></i> Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  SOS
                </li>
              </ol>
            </nav>
            <div className="d-flex justify-content-end mb-4 flex-wrap">
              <div style={{ display: "-webkit-inline-box" }}>
                <div className="dropdown show">
                  <div className="dropdown me-3"><button className="btn btn-second dropdown-toggle" style={{ padding: "7px 10px" }} type="button" data-bs-toggle="dropdown" aria-expanded="false">Message</button>
                    <ul className="dropdown-menu" style={{ marginTop: "-10px" }}>
                      <li>
                        <div data-bs-toggle="modal" data-bs-target="#addMessage" className="dropdown-item" style={{ cursor: "pointer" }} onClick={handleRatingModal} >
                          Add message
                        </div>
                      </li>
                      <li><Link className="dropdown-item" to={"/sos/messagelist"} state={{ status: "touristAttraction" }} >Messages list</Link></li>
                      <div data-bs-toggle="modal" data-bs-target="#addSentLimit" className="dropdown-item" style={{ cursor: "pointer" }} onClick={handleRatingModal} >
                        Add message limit / day
                      </div>
                    </ul>
                  </div>
                </div>
                <div className="position-relative">
                  <input
                    className="form-search me-3"
                    type="search"
                    name=""
                    onChange={debouncedOnChange}
                    placeholder="Search"
                  />
                  <img
                    className="users-search"
                    src="/images/Icon feather-search.svg"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <hr />
          </div>


          <div className="card p-3 table-responsive">
            <table className="table table-sm table-hover table-nowrap card-table">
              <thead>
                <tr>
                  <th>
                    <span
                      className="list-sort text-muted"

                    >
                      S.No
                    </span>
                  </th>
                  <th>
                    <span
                      className="list-sort text-muted"

                    >
                      User Name
                    </span>
                  </th>
                  <th>
                    <span
                      className="list-sort text-muted"

                    >
                      Email ID
                    </span>
                  </th>
                  <th>
                    <span
                      className="list-sort text-muted"

                    >
                      Phone Number
                    </span>
                  </th>
                  <th>
                    <span
                      className="list-sort text-muted"

                    >
                      Message
                    </span>
                  </th>
                </tr>
              </thead>
              {sosSpin ? (
                <tbody className="list fs-base">
                  {sos?.length > 0 ? sos?.map?.((ele, index) => {
                    return (
                      <tr key={index}>
                        <td>{((selectedPage) * limit) + index + 1}</td>
                        <td>
                          {ele?.userDetails?.[0]?.name ? (
                            <>
                              <div className="avatar avatar-xs align-middle me-2">
                                <img
                                  className="avatar-img rounded-circle"
                                  src={(ele?.userDetails?.[0]?.profile !== "" && ele?.userDetails?.[0]?.profile !== null && ele?.userDetails?.[0]?.profile !== "null") ? ImageUrl + ele?.userDetails?.[0]?.profile : process.env.PUBLIC_URL + "/images/person-circle.svg"}
                                  alt=""
                                />
                              </div>
                              <span
                                className="item-name text-reset"
                              >
                                {ele?.userDetails?.[0]?.name}
                              </span>
                            </>
                          ) : (
                            "-"
                          )}
                        </td>
                        <td>
                          <div
                            className="item-email text-reset"
                          // href="mailto:john.doe@company.com"
                          >
                            {ele?.userDetails?.[0]?.email}
                          </div>
                        </td>
                        <td>
                          <div className="item-name text-reset">
                            {ele?.userDetails?.[0]?.phoneNumber
                              ? ele?.userDetails?.[0]?.phoneNumber
                              : "-"}
                          </div>
                        </td>
                        <td className='position-relative' >
                          {ele?.text ? (
                            <div className="tooltip1">
                              {ele?.text?.substr(0, 8)}...
                              <div className='wrapper'>
                                <div className="tooltip1-text">{ele?.text}</div>
                              </div>
                            </div>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                    );
                  }) : <tr align="center">
                    <td colSpan="5">
                      <h1>Data Not Found</h1>
                    </td>
                  </tr>}
                </tbody>
              ) : (
                <tbody>
                  <tr align="center">
                    <td colSpan="5">
                      <Spinwheel />
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
        </div>
        <div className="d-flex justify-content-center">
          {sos?.length > 0 ?
            <Pagination
              clickHandler={handlePageClick}
              page={page}
              selectedPage={selectedPage}
            />
            : null}
        </div>
      </div>


      {/* block user popup code start here */}
      <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
        <div className="modal-dialog modal-sm modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0">
              <button type="button" className="btn-close block-close" data-bs-dismiss="modal" aria-label="Close" ></button>
            </div>
            <div className="modal-body pe-0 ps-0 block-user">
              <img src="/images/blockuser.png" alt="" width={202} srcSet="" />
              <h1 className="mb-0">
                Are you sure you want to
                <br /> block this SOS ?
              </h1>
            </div>
            <div className="modal-footer pe-0 ps-0 border-0 justify-content-center pt-0 mb-3">
              <button type="button" className="btn-custom w-auto">
                YES BLOCK
              </button>
            </div>
          </div>
        </div>
      </div>
      <AddMessageModal formik={messageFormik} type="Save" />
      <AddMessageLimit />
    </>
  );
}

export default Sos;
