import React from 'react';
import PropTypes from 'prop-types';
import TableHeader from './TableHeader';
import TableRow from './TableRow';
import Spinwheel from '../Spinwheel';

const CommonTable = ({ headers, data, loading }) => {
    return (
        <div className="table-responsive" style={{ overflowX: "inherit" }}>
            <table className="table table-md table-hover card-table">
                <TableHeader headers={headers} />
                <tbody>
                    {loading ? (
                        <tr>
                            <td colSpan={headers.length} className='text-center'>
                                <Spinwheel />
                            </td>
                        </tr>
                    ) : (
                        data?.length > 0 ? (
                            data.map((row, index) => (
                                <TableRow key={index} row={row} headers={headers} />
                            ))
                        ) : (
                            <tr>
                                <td align="center" colSpan={headers.length}>
                                    <h1>Data not found</h1>
                                </td>
                            </tr>
                        )
                    )}
                </tbody>
            </table>
        </div>
    );
};

CommonTable.propTypes = {
    headers: PropTypes.arrayOf(PropTypes.object).isRequired,
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    loading: PropTypes.bool,
};

CommonTable.defaultProps = {
    loading: false,
};

export default CommonTable;
