import React from 'react'
import { ImageUrl } from '../../../../utils/BaseUrl'

function ProductView({ selectItem, setSelectItem }) {

  return (
    <div className="modal fade" id="showDetail" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static"
      data-bs-keyboard="false" >
      <div className="modal-dialog modal-lg modal-dialog-centered" >
        <div className="modal-content">
          <div className="modal-header border-0">
            <button type="button" className="btn-close block-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { setSelectItem({}) }}></button>
          </div>
          <div className="modal-body">
            <div className="mb-3">
              <h4 className='fw-bold'>Name</h4>
              <p className='mb-0'>{selectItem?.name}</p>
            </div>
            <hr />
            <div className='row g-0'>
              <div className="mb-3 col-lg-6">
                <h4 className='fw-bold'>Category</h4>
                <p className='mb-0'>{selectItem?.category?.name}</p>
              </div>
              <div className="mb-3 col-lg-6">
                <h4 className='fw-bold'>Sub category</h4>
                <p className='mb-0'>{selectItem?.subcategory?.name}</p>
              </div>
            </div>

            <hr />
            <div className='row g-0'>
              <div className="mb-3 col-lg-6">
                <h4 className='fw-bold'>Price</h4>
                <p className='mb-0'>{selectItem?.price}</p>
              </div>

              <div className="mb-3 col-lg-6">
                <h4 className='fw-bold'>Discount</h4>
                <p className='mb-0'>{selectItem?.discount}</p>
              </div>
            </div>
            <hr />
            <div className='row g-0'>
              <div className="mb-3 col-lg-6">
                <h4 className='fw-bold'>Size</h4>
                <p className='mb-0'>{selectItem?.size?.join(", ")}</p>
              </div>

              <div className="mb-3 col-lg-6">
                <h4 className='fw-bold'>Remaining</h4>
                <p className='mb-0'>{selectItem?.remainingIs}</p>
              </div>

            </div>
            <hr />
            <div className='row g-0'>
              <div className="mb-3 col-lg-6">
                <h4 className='fw-bold'>Status</h4>
                <p className='mb-0'>{selectItem?.status}</p>
              </div>
            </div>
            <hr />
            <div className="mb-3">
              <h4 className='fw-bold'>Color image</h4>
              <div className='d-flex'>
                {
                  selectItem?.color?.map?.((ele, index) => {
                    return <div style={{ height: 50, width: 70 }} key={`assdsdf${index}`} className="me-2">
                      <img src={ImageUrl + ele.colorImage} className='h-100 w-100' alt='' />
                      <p>{ele?.color}</p>
                    </div>
                  })
                }
              </div>
            </div>
            <br />

            <hr />
            <div className="mb-3">
              <h4 className='fw-bold'>Product image</h4>
              <div className='d-flex'>
                {
                  selectItem?.productImage?.map?.((ele, index) => {
                    return (
                      <div style={{ height: 100, width: 150 }} key={`ksjdfsdf${index}`} className="me-2">
                        <img src={ImageUrl + ele} className='h-100 w-100' alt='' />
                      </div>
                    )
                  })
                }
              </div>
            </div>
            <hr />
            <div className="mb-3">
              <h4 className='fw-bold'>Description</h4>
              <p className='mb-0'>{selectItem?.desc}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductView
