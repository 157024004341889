import React from 'react'

function ManageStatusModel({ updateStateData, setUpdateStatusData, statusUpdateHandler }) {
  const handleStatus = (e) => {
    setUpdateStatusData({...updateStateData,[e.target.name]:e.target.value})
  }
 
  return (
    <div className="modal fade" id="ManageStatusModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-sm modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header border-0">
            <button type="button" className="btn-close block-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body block-user">
            <div className="col-lg-12" style={{ zIndex: "1" }}>
              <form action="" className='p-4' >
                <h2 style={{textAlign: "left"}}>
                  Application status
                </h2>
                <select name="status" id="modal" className="form-select border border-1" value={updateStateData?.status} onChange={handleStatus}  >
                  <option value="Applied">Applied</option>
                  <option value="Application sent">Application sent</option>
                  <option value="Application viewed">Application viewed</option>
                  <option value="Shortlisted">Shortlisted</option>
                  <option value="Awaiting recruiter action">Awaiting recruiter action</option>
                  <option value="Approved">Approved</option>
                  <option value="Rejected">Rejected</option>
                </select>
                <div className="modal-footer border-0 justify-content-center pt-4">
                  <button type="button" className="btn-custom w-auto" data-bs-dismiss="modal" onClick={(e) => { statusUpdateHandler(e)}}  >Update</button>
                  <button type="button" className="btn-custom w-auto" data-bs-dismiss='modal' >
                    Cancel
                  </button>
                </div>
                </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ManageStatusModel