import { useFormik } from 'formik';
import { debounce } from 'lodash';
import React, { useCallback, useState } from 'react'
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import AsyncCreatableSelect from "react-select/async-creatable";
import { useAdminContext } from '../../../context/adminContext';
import { addCompanyApi, addIndustryAndSkillsApi, addJobsApi, addReviewAndRatingApi, getAllCompaniesListApi, getAllIndustryAndSkillsByType, getAllReviewsByCompanyIdApi, updateJobDetailsByIdApi, updateReviewByIdApi, uploadImage } from '../../../utils/ApiEndpointFunctions';
import Header from '../../common/Header';
import Spinwheel from '../../common/Spinwheel';
import { companyPostInitialValues, companyPostValidationSchema, jobsPostInitialValues, jobsPostValidationSchema, ratingIntialValues, ratingValidatonSchema } from '../../common/Validation';
import AddCompany from './AddCompany';
import CreatableSelect from 'react-select/creatable';
import { ImageUrl } from '../../../utils/BaseUrl';
import ReviewAndRating from './modals/ReviewAndRating';

const AddJob = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [company, setCompany] = useState([]);
  const [addSpin, setAddSpin] = useState(false)
  const { updateJob } = useAdminContext();
  const [addCompanySpin, setaddCompanySpin] = useState(false)
  const [fileTypeError, setFileTypeError] = useState("")
  const [isEdit, setIsEdit] = useState(true);
  const [modalType, setModalType] = useState("");
  const [allReview, setAllReview] = useState([]);




  const handleRatingModal = (type, ele = {}) => {
    if (type === "Add") {
      setModalType(type)
      ratingFormik.setValues(ratingIntialValues)
      ratingFormik.setFieldValue("companyId", companyFormik.values._id)
      ratingFormik.setErrors({})
      ratingFormik.setTouched({})
    } else {
      setModalType(type)
      ratingFormik.setValues({
        name: ele?.name,
        rating: ele?.rating,
        companyId: ele?.companyId,
        review: ele?.review,
        jobId: ele?.jobId,
        id: ele?._id
      })
    }
  }

  const AddReviewSubmit = async (values) => {
    try {
      if (modalType === "Add") {
        const res = await addReviewAndRatingApi(values)
        if (res.status === 200) {
          toast.success(res.data?.message)
          getAllReviewsByCompanyId(companyFormik.values._id)
        }
      } else if (modalType === "Update") {
        const res = await updateReviewByIdApi(values);
        if (res.status === 200) {
          toast.success(res.data?.message)
        
        }
      }
    } catch (error) {
      console.log(error)
      toast.error("Something went wrong")
    }
  }

  const ratingFormik = useFormik({
    initialValues: { ...ratingIntialValues },
    onSubmit: AddReviewSubmit,
    validationSchema: ratingValidatonSchema
  })


  const getAllReviewsByCompanyId = async (id) => {
    try {
      const res = await getAllReviewsByCompanyIdApi(id);
      if (res.status === 200) {
        setAllReview(res.data?.data)
      } else {
      }
    } catch (error) {
      console.log(error)

    }
  }


  const avareageCompanyReview = () => {
    let count = 0;
    allReview?.map((ele) => {
      count += Number(ele?.rating)
    })
    return allReview?.length ? "Avarage rating: " +  (count / allReview?.length).toFixed(1) : ""
  } 



  let path = location?.pathname?.split('/').filter((ele) => {
    if (ele === "add" || ele === "update") {
      return true;
    }
    return false;
  })[0];
  path = path[0]?.toUpperCase() + path?.slice(1);

  const getAllCompaniesList = async () => {
    const res = await getAllCompaniesListApi();
    if (res.status === 200) {
      setCompany(res.data?.data?.map((ele) => {
        return {
          value: ele,
          label: ele?.name
        }
      }))
    }
  }

  const getAllSkills = async () => {
    try {
      const res = await getAllIndustryAndSkillsByType("skill")
      if (res.status === 200) {
        let skills = res.data?.data
        return skills?.map((ele) => {
          return { value: ele.name, label: ele.name }
        })
      }
    } catch (error) {
    }
  }

  const skillDebaunce = useCallback(
    debounce((inputText, callback) => {
      getAllSkills(inputText).then((options) => callback(options));
    }, 500),
    []
  );

  const handleNewSkill = async (newData) => {
    try {
      if (newData?.__isNew__) {
        let payload = { type: 'skill', name: newData.value }
        await addIndustryAndSkillsApi(payload);
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleSubmit = async (values) => {
    setAddSpin(true)
    setTimeout(async () => {

      try {
        let res
        if (path === "Add") {
          res = await addJobsApi({
            ...values, salaryRange: `${values.salaryRangeFrom}-${values.salaryRangeTo}`, companyId: values.companyId?._id
          })
        } else {
          res = await updateJobDetailsByIdApi({
            ...values, id: values._id, salaryRange: `${values.salaryRangeFrom}-${values.salaryRangeTo}`, companyId: values.companyId?._id
          })
        }
        if (res.status === 200) {
          // toast.success(res.data?.message)
          path === "Add" ?
            toast.success("Job posted successfully") :
            toast.success(res.data?.message, { duration: 1500 });
          setAddSpin(false)
          navigate("/job", { replace: true });
        }
      } catch (error) {
        toast.error("Something went wrong")
        setAddSpin(false)
      }
    }, 500)
  }

  const formik = useFormik({
    initialValues: path === "Update" ? updateJob : jobsPostInitialValues,
    onSubmit: handleSubmit,
    validationSchema: jobsPostValidationSchema
  });


  // company  +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++



  const getAllIndustry = async () => {
    try {
      const res = await getAllIndustryAndSkillsByType("industry")
      if (res.status === 200) {
        let skills = res.data?.data
        return skills?.map((ele) => {
          return { value: ele.name, label: ele.name }
        })
      }
    } catch (error) {
    }
  }

  const industryDebaunce = useCallback(
    debounce((inputText, callback) => {
      getAllIndustry(inputText).then((options) => callback(options));
    }, 500),
    []
  );

  const handleNewIndustry = async (newData) => {
    try {
      if (newData?.__isNew__) {
        let payload = { type: 'industry', name: newData.value }
        await addIndustryAndSkillsApi(payload);
      }
    } catch (error) {
      console.log(error)
    }
  }



  const handleSubmitCompany = async (values) => {
    setaddCompanySpin(true)
    setTimeout(async () => {
      try {
        const res = await addCompanyApi(values)
        if (res.status === 200) {
          setIsEdit(true)
          toast.success(res.data?.message)
          formik.setFieldValue("companyId", res.data?.data); companyFormik.setValues(res.data?.data);
          setaddCompanySpin(false)
        }
      } catch (error) {
        toast.error(error?.response?.data?.message)
        setaddCompanySpin(false)
      }
    }, 500)
  }
  const companyFormik = useFormik({
    initialValues: path === "Update" ? updateJob?.companyId : companyPostInitialValues,
    onSubmit: handleSubmitCompany,
    validationSchema: companyPostValidationSchema
  });

  const profileChange = async (e) => {
    const file = e.target.files[0];
    companyFormik.setFieldTouched(`logo`, true)
    const types = ['image/jpeg', 'image/png', 'image/gif']
    if (file) {
      if (types.includes(file?.type)) {
        setFileTypeError("")
        const formData = new FormData();
        formData.append("file", file);
        formData.append("type", 5)
        formData.append("folder", "company")
        const res = await uploadImage('/users/fileUpload', formData)
        if (res.status === 200 && res.data?.status === 1) {
          companyFormik.setFieldValue("logo", res.data?.data?.fileName)
          toast.success("File uploaded")
        }
      } else {
        companyFormik.setFieldValue("logo", "")
        setFileTypeError("Must be image only")
      }
    }

  }

  const selectCompanyHandler = (e) => {
    if (e.__isNew__) {
      companyFormik.resetForm()
      companyFormik.setFieldValue("name", e.label)
      setIsEdit(false)

      formik.setFieldValue("companyId", "")
    } else {
      setIsEdit(true)
      formik.setFieldValue("companyId", e.value); companyFormik.setValues(e.value);
    }
  }


  useEffect(() => {
    getAllCompaniesList();
    if (!Object.keys(updateJob)?.length) {
      navigate(`/job/add`, { replace: "true" })
    }
  }, [])

  useEffect(() => {
    if (companyFormik.values._id) getAllReviewsByCompanyId(companyFormik.values._id)
  }, [companyFormik.values._id])
  
  return (
    <>
      {/*<Sidebar />*/}
      <div className="main-content vh-100 vh_md-auto overflow-y-a">
        <Header title="Jobs" />
        {/* CARDS */}
        <div className="container-fluid">
          <div className="row">
            <nav className="mb-5">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">
                    <i className="fe fe-home"></i> Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/job" className="breadcrumb-item">
                    Jobs
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {path}-Job
                </li>
              </ol>
            </nav>
            <div className='d-flex justify-content-between' >
              <h2 className="report">Company details</h2>
              <div className='d-flex align-items-center'>
                <div className='fw-bold'>{avareageCompanyReview()}</div>
                {companyFormik?.values?._id && <Link className='border border-2 p-1 m-1' to="" data-bs-toggle="modal" data-bs-target="#ReviewAndRating" onClick={() => handleRatingModal("Add")} >Add review</Link>}
                {companyFormik?.values?._id && <Link to={`/job/reviews/${companyFormik?.values?._id}`} state={{ companyName: companyFormik?.values?._id }} className='border border-2 p-1 m-1'>View review</Link>}
              </div>
            </div>
            <hr />
            <div>
              <br />

              {/* <AddCompany formik={companyFormik} /> */}
              <form action="" onSubmit={companyFormik?.handleSubmit} >
                <div className="row row-cols-1 row-cols-lg-3 row-cols-md-2" align-items-center>
                  {isEdit ? <div>
                    <label
                      className="form-label text-start d-block"
                      htmlFor="companyId"
                    >
                      Company
                    </label>
                    <CreatableSelect name="companyId" id="companyId" isSearchable options={company} value={companyFormik.values?.name ? { label: companyFormik?.values?.name, value: companyFormik?.values } : null} onChange={(e) => { selectCompanyHandler(e) }} isDisabled={path === "Update" ? true : false} />
                    {formik?.touched?.companyId && formik?.errors?.companyId ? (<div className='req-error'>{formik?.errors?.companyId}</div>) : null}
                  </div> : <div>
                    <label htmlFor="name" className="form-label">Company Name</label>
                    <div className="position-relative">
                      <input className="form-control" type="text" id="name" placeholder="Enter your company name" name="name" value={companyFormik.values?.name} onChange={companyFormik.handleChange} onBlur={companyFormik.handleBlur} />
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-fill input-icon" viewBox="0 0 16 16">
                        <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                      </svg>
                      {companyFormik.touched.name && companyFormik.errors.name ? (<div className='req-error'>{companyFormik.errors.name}</div>) : null}
                    </div>
                  </div>}

                  <div className=""><div className="">
                    <label
                      className="form-label text-start d-block"
                      htmlFor="industry"
                    >
                      Industry
                    </label>
                    <AsyncCreatableSelect
                      cacheOptions
                      defaultOptions
                      isSearchable={true}
                      loadOptions={industryDebaunce}
                      createOptionPosition="first"
                      className="basic-multi-select"
                      name="industry"
                      id='industry'
                      value={companyFormik.values?.industry ? { label: companyFormik.values?.industry, value: companyFormik.values?.industry } : null}
                      placeholder="Select Industry"
                      onChange={(selectedOptions) => {
                        handleNewIndustry(selectedOptions)
                        companyFormik.setFieldValue("industry", selectedOptions?.value)
                      }}
                      onBlur={companyFormik.handleBlur}
                      maxMenuHeight={9 * 36}
                      isDisabled={isEdit}
                    />
                    {companyFormik.touched?.industry && companyFormik.errors?.industry ? (<div className='req-error'>{companyFormik?.errors?.industry}</div>) : null}
                  </div></div>
                  <div className="">
                    <div>
                      <label htmlFor="locality" className="form-label">Location/Locality</label>
                      <div className="position-relative">
                        <input disabled={isEdit} className="form-control" type="text" id="locality" placeholder="Enter your locality" name="locality" value={companyFormik?.values?.locality} onChange={companyFormik?.handleChange} onBlur={companyFormik?.handleBlur} />
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-geo-alt input-icon" viewBox="0 0 16 16">
                          <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                          <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                        </svg>
                        {companyFormik.touched.locality && companyFormik.errors.locality ? (<div className='req-error'>{companyFormik.errors.locality}</div>) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <div className="row row-cols-1 row-cols-lg-3 row-cols-md-2" align-items-center>
                  <div className="">
                    <div>
                      <label htmlFor="emailAddress" className="form-label">Email</label>
                      <div className="position-relative">
                        <input disabled={isEdit} className="form-control" type="email" id="emailAddress" placeholder="Enter your email" name="emailAddress" value={companyFormik?.values?.emailAddress} onChange={companyFormik?.handleChange} onBlur={companyFormik?.handleBlur} />
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={16}
                          height={16}
                          fill="currentColor"
                          className="bi bi-envelope-fill input-icon"
                          viewBox="0 0 16 16"
                        >
                          <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z" />
                        </svg>
                        {companyFormik.touched.emailAddress && companyFormik.errors.emailAddress ? (<div className='req-error'>{companyFormik.errors.emailAddress}</div>) : null}
                      </div>
                    </div>
                  </div>

                  <div className="">
                    <div>
                      <label htmlFor="phoneNumber" className="form-label">Phone number</label>
                      <div className="position-relative">
                        <input disabled={isEdit} className="form-control" type="tel" id="phoneNumber" placeholder="Enter your phone number" name="phoneNumber" value={companyFormik.values?.phoneNumber} onChange={companyFormik.handleChange} onBlur={companyFormik.handleBlur} />

                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-telephone-fill input-icon" viewBox="0 0 16 16">
                          <path fillRule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                        </svg>
                        {companyFormik.touched.phoneNumber && companyFormik.errors.phoneNumber ? (<div className='req-error'>{companyFormik.errors.phoneNumber}</div>) : null}
                      </div>
                    </div>
                  </div>

                  <div className="">
                    <div>
                      <label htmlFor="address" className="form-label">Address</label>
                      <div className="position-relative">
                        <input disabled={isEdit} className="form-control" type="text" id="address" placeholder="Enter your address" name="address" value={companyFormik?.values?.address} onChange={companyFormik?.handleChange} onBlur={companyFormik?.handleBlur} />
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-geo-alt input-icon" viewBox="0 0 16 16">
                          <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                          <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                        </svg>
                        {companyFormik.touched.address && companyFormik.errors.address ? (<div className='req-error'>{companyFormik.errors.address}</div>) : null}
                      </div>
                    </div>
                  </div>
                </div>

                <br />
                <div className="row row-cols-1 row-cols-lg-3 row-cols-md-2 align-items-center" >
                  <div className="">
                    <div>
                      <label htmlFor="url" className="form-label">Website</label>
                      <div className="position-relative">
                        <input disabled={isEdit} className="form-control" type="text" id="url" placeholder="https://example.com" name='url' value={companyFormik.values?.url} onChange={companyFormik.handleChange} onBlur={companyFormik.handleBlur} />
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-globe input-icon" viewBox="0 0 16 16">
                          <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 0 0 5.145 4H7.5V1.077zM4.09 4a9.267 9.267 0 0 1 .64-1.539 6.7 6.7 0 0 1 .597-.933A7.025 7.025 0 0 0 2.255 4H4.09zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5h2.49zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5H4.847zM8.5 5v2.5h2.99a12.495 12.495 0 0 0-.337-2.5H8.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5H4.51zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5H8.5zM5.145 12c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12H5.145zm.182 2.472a6.696 6.696 0 0 1-.597-.933A9.268 9.268 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472zM3.82 11a13.652 13.652 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5H3.82zm6.853 3.472A7.024 7.024 0 0 0 13.745 12H11.91a9.27 9.27 0 0 1-.64 1.539 6.688 6.688 0 0 1-.597.933zM8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855.173-.324.33-.682.468-1.068H8.5zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm2.802-3.5a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5h2.49zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 0 0-3.072-2.472c.218.284.418.598.597.933zM10.855 4a7.966 7.966 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4h2.355z" />
                        </svg>
                        {companyFormik.touched.url && companyFormik.errors.url ? (<div className='req-error'>{companyFormik.errors.url}</div>) : null}
                      </div>
                    </div>
                  </div>

                  <div className=""><div className="">
                    <label htmlFor="profile" className="form-label">Company logo</label>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="position-relative">
                        {<img src={companyFormik.values?.logo ? ImageUrl + companyFormik.values?.logo : process.env.PUBLIC_URL + "/images/person-circle.svg"} width="70px" height="70px" className="rounded-circle" alt="Selected file" />}
                        {!isEdit && 
                          <>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-camera pro-camera" viewBox="0 0 16 16">
                              <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1v6zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z" />
                              <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x xmark" viewBox="0 0 16 16" onClick={() => { companyFormik.setFieldValue("logo", "others/person-circle.svg") }} >
                              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                            </svg>
                          </>
                        }
                      </div>
                      <div className="btn_upload">
                        <input disabled={isEdit} type="file" id="profile" className="" name="logo" accept="image/*" onChange={profileChange} />
                        Upload
                      </div>
                    </div>
                    {fileTypeError?.length > 0 ? <div className='req-error'><br />{fileTypeError}</div> : companyFormik.touched.logo && companyFormik.errors.logo ? (<div className='req-error'><br />{companyFormik.errors.logo}</div>) : null}
                  </div></div>

                  <div className="">
                    <div>
                      <label htmlFor="description" className="form-label">Description</label>
                      <div className="position-relative">

                        <textarea disabled={isEdit} className="form-control" id='description' value={companyFormik.values?.description} onChange={companyFormik.handleChange} onBlur={companyFormik.handleBlur} ></textarea>

                        {companyFormik.touched.description && companyFormik.errors.description ? (<div className='req-error'>{companyFormik.errors.description}</div>) : null}
                      </div>
                    </div>
                  </div>

                  <div className="">
                    <div className="row gap-2">
                      <button type="submit" className="btn-custom w-auto mt-2" disabled={addCompanySpin} >{addCompanySpin ? <Spinwheel sizeClass="spin-small" /> : "Save"}</button>
                      {companyFormik?.values?._id && <div className="btn-custom w-auto mt-2" onClick={() => setIsEdit(!isEdit)} > {isEdit ? "Edit" : "Cancel"} </div>}
                    </div>
                  </div>
                </div>
              </form>
              <hr />
              <div>
                <h2 className="report">Job details</h2>
              </div>

              <form action="" onSubmit={formik.handleSubmit} >

                <div className="row row-cols-1 row-cols-lg-3 row-cols-md-2" align-items-center>
                  <div className=''>
                    <label htmlFor="title" className="form-label">Title</label>
                    <div className="position-relative">
                      <input className="form-control" type="text" id="title" placeholder="Enter title" name="title" value={formik.values.title} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-house-door-fill input-icon" viewBox="0 0 16 16">
                        <path d="M6.5 14.5v-3.505c0-.245.25-.495.5-.495h2c.25 0 .5.25.5.5v3.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5Z" />
                      </svg>
                      {formik.touched.title && formik.errors.title ? (<div className='req-error'>{formik.errors.title}</div>) : null}
                    </div>
                  </div>

                  <div className="position-relative">
                    <label htmlFor="requiredSkill" className="form-label">Required skills</label>
                    <AsyncCreatableSelect
                      isMulti
                      cacheOptions
                      defaultOptions
                      loadOptions={skillDebaunce}
                      className="basic-multi-select"
                      isSearchable={true}
                      createOptionPosition="first"
                      name="skillsRequired"
                      placeholder="Select skills.."
                      // defaultValue={formik.values.skillsRequired}
                      value={formik.values.skillsRequired?.map(e => { return { label: e, value: e } })}
                      onChange={(selectedOptions) => {
                        handleNewSkill(selectedOptions[selectedOptions.length - 1])
                        formik.setFieldValue("skillsRequired", selectedOptions?.map((e) => e?.value))
                      }}
                    />
                    {formik.touched.skillsRequired && formik.errors.skillsRequired ? (<div className='req-error'>{formik.errors.skillsRequired}</div>) : null}
                  </div>

                  <div className=''>
                    <label
                      className="form-label text-start d-block"
                      htmlFor="workExp"
                    >
                      Work experience
                    </label>
                    <select name="workExp" id="workExp" className="form-select form-control" value={formik.values.workExp} onChange={formik.handleChange} onBlur={formik.handleBlur} >
                      <option value="">Select experience</option>
                      <option value="Fresher">Fresher</option>
                      <option value="0-1 year">0 - 1 year</option>
                      <option value="1-2 years">1 - 2 years</option>
                      <option value="2-3 years">2 - 3 years</option>
                      <option value="3-4 years">3 - 4 years</option>
                      <option value="4-5 years">4 - 5 years</option>
                      <option value="5-6 years">5 - 6 years</option>
                      <option value="6-7 years">6 - 7 years</option>
                      <option value="7-8 years">7 - 8 years</option>
                      <option value="8-9 years">8 - 9 years</option>
                      <option value="9-10 years">9 - 10 years</option>
                      <option value="10+ years">10+ years</option>
                    </select>
                    {formik.touched.workExp && formik.errors.workExp ? (<div className='req-error'>{formik.errors.workExp}</div>) : null}
                  </div>
                </div>

                <br />

                <div className="row row-cols-1 row-cols-lg-3 row-cols-md-2 align-items-center">
                  <div className=''>
                    <label
                      className="form-label text-start d-block"
                      htmlFor="employmentType"
                    >
                      Employment type
                    </label>
                    <select name="employmentType" id="employmentType" className="form-select form-control" value={formik.values.employmentType} onChange={formik.handleChange} onBlur={formik.handleBlur} >
                      <option value="">Select employment type</option>
                      <option value="Full-time">Full-time</option>
                      <option value="Part-time">Part-time</option>
                      <option value="Contract">Contract</option>
                    </select>
                    {formik.touched.employmentType && formik.errors.employmentType ? (<div className='req-error'>{formik.errors.employmentType}</div>) : null}

                  </div>

                  <div className=''>
                    <label htmlFor="noOfPosition" className="form-label">Required employee</label>
                    <div className="position-relative">
                      <input className="form-control" type="text" id="noOfPosition" placeholder="Enter required employee" name="noOfPosition" value={formik.values.noOfPosition} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                      <i className="fe fe-users input-icon" />
                      {formik.touched.noOfPosition && formik.errors.noOfPosition ? (<div className='req-error'>{formik.errors.noOfPosition}</div>) : null}
                    </div>
                  </div>

                  <div className=''>
                    <label htmlFor="salaryRange" className="form-label">Salary range (per annum)</label>
                    <div className="d-flex gap-4">
                      <div className="position-relative w-100">
                        <input className="form-control" type="text" id="salaryRangeFrom" placeholder="Enter from" name="salaryRangeFrom" value={formik.values.salaryRangeFrom} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                        <span className='input-icon '>฿</span>
                        {formik.touched.salaryRangeFrom && formik.errors.salaryRangeFrom ? (<div className='req-error'>{formik.errors.salaryRangeFrom}</div>) : null}
                      </div>

                      <div className="position-relative w-100">
                        <input className="form-control" type="text" id="salaryRangeTo" placeholder="Enter to" name="salaryRangeTo" value={formik.values.salaryRangeTo} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                        <span className='input-icon '>฿</span>
                        {formik.touched.salaryRangeTo && formik.errors.salaryRangeTo ? (<div className='req-error'>{formik.errors.salaryRangeTo}</div>) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <div className="row row-cols-1 row-cols-lg-3 row-cols-md-2 align-items-center">
                  <div>
                    <div className="position-relative ">
                      <label htmlFor="description" className="form-label">Job description</label>
                      <textarea className="form-control" id='description' value={formik.values.description} name='description' onChange={formik.handleChange} onBlur={formik.handleBlur} ></textarea>

                      {formik.touched.description && formik.errors.description ? (<div className='req-error'>{formik.errors.description}</div>) : null}
                    </div>
                  </div>
                  <div className="">
                    <div>
                      <label htmlFor="location" className="form-label">Location</label>
                      <div className="position-relative">
                        <input className="form-control" type="text" id="location" placeholder="Enter location" name="location" value={formik?.values?.location} onChange={formik?.handleChange} onBlur={formik?.handleBlur} />
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-geo-alt input-icon" viewBox="0 0 16 16">
                          <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                          <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                        </svg>
                        {formik.touched.location && formik.errors.location ? (<div className='req-error'>{formik.errors.location}</div>) : null}
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className='d-flex justify-content-between align-items-end'>
                      <label htmlFor="rangeSelector" className="form-label">Hide salary details from candidates</label>
                      <div className="form-check form-switch map-togle-container">
                        <input className="form-check-input" type="checkbox" name='rangeSelector' role="switch" id="rangeSelector" checked={formik.values.rangeSelector} onChange={formik.handleChange} />
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <br />
                <br /> <br />
                <div className="text-center d-flex justify-content-around">
                  <button type="submit" className="btn-custom w-auto mt-2" disabled={addSpin} >{addSpin ? <Spinwheel sizeClass="spin-small" /> : "Save"}</button>
                  <div className="btn-custom w-auto mt-2" onClick={() => { window.history.back(); }}  >Cancel</div>
                </div>
              </form>
              <br />
            </div>
            <div className="col-lg-8"></div>
          </div>
        </div>
      </div>
      <ReviewAndRating
        formik={ratingFormik}
        modalType={modalType}
      />
    </>
  );
}

export default AddJob