import { useFormik } from 'formik';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import toast from 'react-hot-toast';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAdminContext } from '../../../../context/adminContext';
import Sidebar from '../../../../Layout/Sidebar';
import { uploadImage, createOrUpdateMovieApi, getAllMovieLanguageApi, createOrUpdateMovieLanguageApi, getMovieByIdApi, createOrUpdateBusRouteApi, getAllBusOperatorApi, getBusRouteByIdApi, getAddressWithCordinates, getMapData, createOrUpdateHotelApi, getHotelByIdApi } from '../../../../utils/ApiEndpointFunctions';
import { ImageUrl } from '../../../../utils/BaseUrl';
import Spinwheel from '../../../common/Spinwheel';
import JoditEditor from 'jodit-react';
import { hotelInitialValue, hotelValidationSchema } from '../../../common/Validation';
import ReactDatePicker from 'react-datepicker';
import moment from 'moment';
import Header from '../../../common/Header';
import TimePicker from 'react-time-picker';
import TimeField from 'react-simple-timefield';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import AsyncCreatableSelect from 'react-select/async-creatable';
import DateTimePicker from 'react-datetime-picker';
import Select from 'react-select';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import AsyncSelect from 'react-select/async';
import GoogleMapLocation from '../../../common/GoogleMapLocation';
import { debounce } from 'lodash';

function AddHotel() {
   const [fileTypeError, setFileTypeError] = useState({ id: null, message: "", error: 0 })
   const navigate = useNavigate()
   const param = useParams()
   const [data, setData] = useState(hotelInitialValue)
   const [addSpin, setAddSpin] = useState(false)
   const location = useLocation();
   const [reRendor, setReRender] = useState(false);
   let path = location.pathname
   path = path.split("/").slice(-1)[0];
   path = (path[0].toUpperCase() + path.slice(1))
   const editor = useRef(null);
   const editor2 = useRef(null);
   const config = useMemo(() => {
      return {
         disablePlugins: ['Find', "Preview", "fullsize", "Change", "About", 'Print', "image", "file", "spellcheck", "selectall", "copyformat", "table", "hr", "symbols", "lineHeight", "classSpan", "paragraph"],
         removeButtons: ['undo', 'redo', 'Print', 'find', "video", "cut", "copy", "paste", "image", "source", "symbols", "lineHeight", "selectall", "paragraph", "superscript", "subscript"],
         placeholder: 'Start writing...',
         askBeforePasteFromWord: true,
         askBeforePasteHTML: false,
         enter: "br",
         hidePoweredByJodit: true,
         STATUSES: {
            beforeDestruct: "beforeDestruct",
            beforeInit: "beforeInit",
            destructed: "destructed",
            ready: "ready",
         }
      }
   }, []
   );



   const handleSubmit = async (values) => {
      try {
         let payload = {
            ...values,
         }
         if (param?.id) {
            payload._id = param?.id
         }

         setAddSpin(true)
         const res = await createOrUpdateHotelApi(payload);
         if (res?.status === 200) {
            toast.success(res.data?.message)
            setAddSpin(false)
            navigate("/ticket/hotel/all-hotel", { replace: true })
         }
      } catch (error) {
         toast.error(error?.response?.data?.message);
         setAddSpin(false)
      }
   }

   const formik = useFormik({
      initialValues: param?.id ? data : hotelInitialValue,
      onSubmit: handleSubmit,
      validationSchema: hotelValidationSchema,
   });




   const removeRow = (e, index, type) => {
      e.preventDefault()
      formik.setFieldValue(type, formik.values?.[type]?.filter((ele, i) => {
         if (i === index) return false;
         return true;
      }))
   }

   const addNewRow = (e, index, type) => {
      e.preventDefault();
      formik.setFieldValue(type, [...formik.values?.[type], {
         name: "",
         img: "",
         role: ""
      }])
   }




   const getHotelById = async () => {
      try {
         const res = await getHotelByIdApi(param?.id)
         setData(res?.data?.data || hotelInitialValue)
         formik.setValues(res?.data?.data || hotelInitialValue)
      } catch (error) {
         console.log(error);
      }
   }

   const handleUpload = async (e, id, field) => {
      try {
         const file = e.target.files[0];
         const types = ['image/jpeg', 'image/png', 'image/gif']
         if (file) {
            if (types.includes(file.type)) {
               setFileTypeError({ id: id, message: "", error: 0 });
               const formData = new FormData();
               formData.append("file", file);
               formData.append("type", 3)
               formData.append("folder", "movie")
               const res = await uploadImage('/users/fileUpload', formData)
               formik.setFieldTouched(field, true)
               if (res.status === 200 && res.data?.status === 1) {
                  formik.setFieldValue(field, res.data?.data?.fileName)
                  toast.success("File uploaded");
               }
            } else {
               formik.setFieldTouched(field, true)
               formik.setFieldValue(field, "")
               setFileTypeError({ id: id, message: "Must be image only", error: 1 });
            }
         }

      } catch (error) {
         formik.setFieldTouched(field, true)
         console.log(error)
         if (error?.response === undefined) {
            toast.error("File size is too large")
         }
      }
   }

   const addressDebaunce = useCallback(
      debounce((inputText, callback) => {
         getMapData(10, 1, inputText).then((options) => {
            const resOptions = options.data?.data?.map(ele => {
               return { label: ele?.address, value: ele }
            })
            callback(resOptions)
         });
      }, 500),
      []
   );

   const cordinateHandler = async (ele) => {

      formik.setFieldValue("googleLocation.lat", ele.lat)
      formik.setFieldValue("googleLocation.lang", ele.lng)

      const res = await getAddressWithCordinates(ele.lat, ele.lng)
      if (res.status === 200) {
         formik.setFieldValue("googleLocation.address", res.data?.data?.address)
      }
   }

   const propertyTypeOptions = [
      { label: "Hotel", value: "Hotel" },
      { label: "Apartment", value: "Apartment" },
      { label: "Resort", value: "Resort" }
   ]


   useEffect(() => {
      if (param?.id) {
         getHotelById()
      }
   }, [param?.id])






   return (
      <>
         {/*<Sidebar />*/}
         <div className="main-content vh-100 vh_md-auto overflow-y-a">
            <Header title={<>Hotel</>} />
            <div className="container-fluid">
               <div className="row">
                  <nav className="mb-5">
                     <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                           <Link to="/dashboard">
                              <i className="fe fe-home"></i> Dashboard
                           </Link>
                        </li>
                        <Link to="/ticket/hotel/booking" className="breadcrumb-item active" aria-current="page">
                           Hotel Booking
                        </Link>
                        <li className="breadcrumb-item active" aria-current="page">
                           Hotel
                        </li>
                     </ol>
                  </nav>
                  <div>
                     <h2 className="report">Hotel details</h2>
                  </div>
                  <hr />

                  <div className="col-12" style={{ zIndex: "1" }}>
                     <form action="" onSubmit={formik.handleSubmit} >
                        <div className="row row-cols-lg-3 row-cols-md-2">
                           <div>
                              <label htmlFor="image" className="form-label">Image</label>
                              <div className="d-flex justify-content-between align-items-center">
                                 <div className="position-relative">
                                    {<img src={formik?.values?.image ? ImageUrl + formik?.values?.image : process.env.PUBLIC_URL + "/images/image.svg"} width="70px" height="50px" style={{ objectFit: "contain" }} className="rounnded" alt="Selected file" />}
                                 </div>
                                 <div className="btn_upload">
                                    <input type="file" id="image" className="form-control" name="image" accept="image/*" onChange={(e) => handleUpload(e, "image", "image")} />
                                    Upload
                                 </div>
                              </div>
                              {formik.touched.image && (formik.errors.image || <div className='req-error'>{fileTypeError?.id == "image" && fileTypeError?.error != 0 && fileTypeError?.message}</div>)}
                              <br />
                           </div>
                           <div>
                              <label htmlFor="name" className="form-label">Name</label>
                              <div className="position-relative">
                                 <input className="form-control" type="text" id="name" placeholder="Enter name" name="name" value={formik.values.name} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                 <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-house-door-fill input-icon" viewBox="0 0 16 16">
                                    <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z" />
                                    <path d="M3 8.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm0-5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5v-1z" />
                                 </svg>
                                 {formik.touched.name && formik.errors.name ? (<div className='req-error'>{formik.errors.name}</div>) : null}
                              </div>
                              <br />
                           </div>
                           <div>
                              <label htmlFor="address" className="form-label">Address</label>
                              <div className="position-relative">
                                 <input className="form-control" type="text" id="address" placeholder="Enter address" name="address" value={formik.values.address} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                 <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-house-door-fill input-icon" viewBox="0 0 16 16">
                                    <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z" />
                                    <path d="M3 8.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm0-5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5v-1z" />
                                 </svg>
                                 {formik.touched.address && formik.errors.address ? (<div className='req-error'>{formik.errors.address}</div>) : null}
                              </div>
                              <br />
                           </div>
                           <div>
                              <label htmlFor="starRating" className="form-label">Star rating</label>
                              <div className="position-relative">
                                 <input className="form-control" type="number" id="starRating" placeholder="Enter rating" name="starRating" value={formik.values.starRating} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                 <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-house-door-fill input-icon" viewBox="0 0 16 16">
                                    <path d="M0 1.5V13a1 1 0 0 0 1 1V1.5a.5.5 0 0 1 .5-.5H14a1 1 0 0 0-1-1H1.5A1.5 1.5 0 0 0 0 1.5z" />
                                    <path d="M3.5 2A1.5 1.5 0 0 0 2 3.5v11A1.5 1.5 0 0 0 3.5 16h6.086a1.5 1.5 0 0 0 1.06-.44l4.915-4.914A1.5 1.5 0 0 0 16 9.586V3.5A1.5 1.5 0 0 0 14.5 2h-11zm6 8.5a1 1 0 0 1 1-1h4.396a.25.25 0 0 1 .177.427l-5.146 5.146a.25.25 0 0 1-.427-.177V10.5z" />
                                 </svg>
                                 {formik.touched.starRating && formik.errors.starRating ? (<div className='req-error'>{formik.errors.starRating}</div>) : null}
                                 <br />
                              </div>

                           </div>
                           <div>
                              <label htmlFor="minimumPrice" className="form-label">Minimum price</label>
                              <div className="position-relative">
                                 <input className="form-control" type="number" id="minimumPrice" placeholder="Enter price" name="minimumPrice" value={formik.values.minimumPrice} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                 <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-house-door-fill input-icon" viewBox="0 0 16 16">
                                    <path d="M0 1.5V13a1 1 0 0 0 1 1V1.5a.5.5 0 0 1 .5-.5H14a1 1 0 0 0-1-1H1.5A1.5 1.5 0 0 0 0 1.5z" />
                                    <path d="M3.5 2A1.5 1.5 0 0 0 2 3.5v11A1.5 1.5 0 0 0 3.5 16h6.086a1.5 1.5 0 0 0 1.06-.44l4.915-4.914A1.5 1.5 0 0 0 16 9.586V3.5A1.5 1.5 0 0 0 14.5 2h-11zm6 8.5a1 1 0 0 1 1-1h4.396a.25.25 0 0 1 .177.427l-5.146 5.146a.25.25 0 0 1-.427-.177V10.5z" />
                                 </svg>
                                 {formik.touched.minimumPrice && formik.errors.minimumPrice ? (<div className='req-error'>{formik.errors.minimumPrice}</div>) : null}
                              </div>
                           </div>
                           <div>
                              <label htmlFor="propertyType" className="form-label">Property type</label>
                              <Select
                                 className="basic-single"
                                 name="propertyType"
                                 options={propertyTypeOptions}
                                 value={{ label: formik.values.propertyType, value: formik.values.propertyType }}
                                 onChange={(e) => formik.setFieldValue("propertyType", e?.value)}
                              />
                              {formik.touched.propertyType && formik.errors.propertyType ? (<div className='req-error'>{formik.errors.propertyType}</div>) : null}
                           </div>
                           <div className='my-3'>
                              <div className='d-flex justify-content-between '>
                                 <label htmlFor="isFreeCancellation" className="form-label">Free cancellation</label>
                                 <div className="form-check form-switch map-togle-container">
                                    <input className="form-check-input" type="checkbox" name='isFreeCancellation' role="switch" id="isFreeCancellation" checked={formik.values.isFreeCancellation} onChange={formik.handleChange} />
                                 </div>
                              </div>
                              <br />
                           </div>
                        </div>
                        <div className='my-3'>
                           <h3>Facilities</h3>
                           {formik.values?.facilities?.map((ele2, index2) => {
                              return (
                                 <div className='row mt-3' key={"slkjdfow4hkn" + index2}>
                                    <div className="col-lg-3">
                                       <label htmlFor={"name" + index2} className="form-label">Name</label>
                                       <div className="position-relative">
                                          <input className="form-control" type="text" id={"name" + index2} placeholder="Enter facility" name="name" value={formik.values?.facilities?.[index2]?.name} onChange={(e) => { formik.setFieldValue(`facilities[${index2}].name`, e.target.value) }} onBlur={() => { formik.setFieldTouched(`facilities[${index2}].name`, true) }} />
                                          {formik.touched?.facilities?.[index2]?.name && formik.errors?.facilities?.[index2]?.name ? (<div className='req-error'>{formik.errors?.facilities?.[index2]?.name}</div>) : null}
                                       </div>
                                    </div>
                                    <div className="col-lg-4">
                                       <div className='d-flex'><label htmlFor="profile" className="form-label">Icon</label></div>
                                       <div>
                                          <div id={`facilities-icon-${index2}`} className="d-flex justify-content-between align-items-center">
                                             <div className="position-relative">

                                                {<img src={formik.values?.facilities?.[index2]?.icon ? ImageUrl + formik.values?.facilities?.[index2]?.icon : process.env.PUBLIC_URL + "/images/image.svg"} width="50px" height="50px" className="rounded" alt="Selected file" />}
                                                {
                                                   <>
                                                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-camera pro-camera" viewBox="0 0 16 16">
                                                         <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1v6zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z" />
                                                         <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
                                                      </svg>
                                                   </>
                                                }
                                             </div>
                                             <div className="btn_upload">
                                                <input type="file" id="upload_file" accept="image/*" className="form-control" name="profileImage" onChange={(e) => { handleUpload(e, `facilities-icon-${index2}`, `facilities[${index2}].icon`) }} />
                                                Upload
                                             </div>


                                          </div>
                                          {formik.touched?.facilities?.[index2]?.icon && formik.errors?.facilities?.[index2]?.icon ? (<div className='req-error'>{formik.errors?.facilities?.[index2]?.icon}</div>) : null}
                                       </div>

                                    </div>
                                    <div className="col-2 d-flex justify-content-center align-items-center">

                                       <div className=" text-end mb-3">
                                          {(formik.values?.facilities?.length === (index2 + 1) && formik.values?.facilities?.length < 5) && <button type="button" className="me-3 plusButton" onClick={(e) => { addNewRow(e, index2, "facilities") }}><span>+</span></button>}
                                          {formik.values?.facilities?.length !== 1 && <button type="button" className=" plusButton" onClick={(e) => { removeRow(e, index2, "facilities") }}><span>-</span></button>}
                                       </div>
                                    </div>
                                 </div>
                              )
                           })
                           }
                        </div>
                        <div className="w-100">
                           <label htmlFor="Address" className="form-label">Google location</label>
                           <AsyncSelect
                              cacheOptions
                              defaultOptions
                              isSearchable={true}
                              value={formik?.values?.googleLocation?.address ? { label: formik?.values?.googleLocation?.address, value: formik?.values?.googleLocation?.address } : undefined}
                              loadOptions={addressDebaunce}
                              name="googleLocation"
                              id="googleLocation"
                              placeholder="Select location"
                              onChange={(selectedOptions) => {
                                 formik.setFieldValue("googleLocation.address", selectedOptions?.value?.address)
                                 formik.setFieldValue("googleLocation.lat", selectedOptions?.value?.latitude)
                                 formik.setFieldValue("googleLocation.lang", selectedOptions?.value?.longitude)
                              }}
                              onBlur={(e) => formik.handleBlur(e)}
                           />

                           {formik?.touched?.googleLocation && formik?.errors?.googleLocation ? (<div className='req-error'>{formik?.errors?.googleLocation}</div>) : null}

                           <div className='mt-3' style={{ height: "350px", width: '100%' }}>
                              <GoogleMapLocation
                                 lat={Number(formik?.values?.googleLocation?.lat || "15.8700")}
                                 lng={Number(formik?.values?.googleLocation?.lang || "100.9925")}
                                 onClick={cordinateHandler}
                              />
                           </div>
                        </div>
                        <div className='my-3'>
                           <label htmlFor="hotelCondition" className="form-label">Hotel c onditions</label>
                           <div className="position-relative">
                              <JoditEditor
                                 ref={editor}
                                 config={config}
                                 value={formik.values.hotelCondition}
                                 id='hotelCondition'
                                 onBlur={(e) => {
                                    formik.setFieldTouched("hotelCondition", true);
                                    e === "<p><br></p>" ? formik.setFieldValue("hotelCondition", "") : formik.setFieldValue("hotelCondition", e);
                                 }}
                                 onChange={(e) => { }}
                              />
                              {formik.touched.hotelCondition && formik.errors.hotelCondition ? (<div className='req-error'>{formik.errors.hotelCondition}</div>) : null}
                           </div>
                        </div>
                        <div className='my-3'>
                           <label htmlFor="about" className="form-label">About</label>
                           <div className="position-relative">
                              <JoditEditor
                                 ref={editor2}
                                 config={config}
                                 value={formik.values.about}
                                 id='about'
                                 onBlur={(e) => {
                                    formik.setFieldTouched("about", true);
                                    e === "<p><br></p>" ? formik.setFieldValue("about", "") : formik.setFieldValue("about", e);
                                 }}
                                 onChange={(e) => { }}
                              />
                              {formik.touched.about && formik.errors.about ? (<div className='req-error'>{formik.errors.about}</div>) : null}
                           </div>
                        </div>

                        <br />
                        <div className="text-center d-flex justify-content-around">
                           <button type="submit" className="btn-custom w-auto mt-2" disabled={addSpin} >{addSpin ? <Spinwheel sizeClass="spin-small" /> : "Save"}</button>
                           <div className="btn-custom w-auto mt-2" onClick={() => { window.history.back(); }}  >Cancel</div>
                        </div>
                     </form>
                     <br />
                  </div>
                  <div className="col-lg-8"></div>
               </div>
            </div>
         </div>
      </>
   );
}

export default AddHotel