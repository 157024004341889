import React, { useEffect, useState } from 'react'
import Spinwheel from '../../common/Spinwheel';
import { ImageUrl } from '../../../utils/BaseUrl';
import { Link, useNavigate } from 'react-router-dom';
import Header from '../../common/Header';
import { useAdminContext } from '../../../context/adminContext';
import { getAllOrdersApi, getOrderByIdApi, updateOrderStatusByIdApi } from '../../../utils/ApiEndpointFunctions';
import moment from 'moment';
import Pagination from '../../common/Pagination';
import UpdateOrderStatus from './UpdateOrderStatus';
import toast from 'react-hot-toast';
import { useFormik } from 'formik';
import * as Yup from 'yup'
import Table from './Table';
import OrderDetailModal from './OrderDetailModal'


function Orders() {
  const { adminDetail, } = useAdminContext()
  const [tableData, setTableData] = useState(null);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [page, setPage] = useState(0)
  const [tableSpin, setTableSpin] = useState(true);
  let [selectedPage, setSelectPage] = useState(0);
  const [orderData,setOrderData] = useState()
  const navigate = useNavigate();
  const limit = 10;
  const updateStatusModalId = "updateStatusModalId" 
  const [updateLoader, setUpdateLoader] = useState(false);
  const [type, setType] = useState("newOrder")
  const orderShowModalId = "orderShowModalId"


  const getTableList = async () => {
   try {
     setTableSpin(false)
     const res = await getAllOrdersApi(type, limit, selectedPage + 1);
     if (res?.status === 200) {
       setTableData(res.data?.data?.orders);
       const totaldata = res.data?.data?.totalData
       setPage(Math.ceil(totaldata / limit));
     }
   } catch (error) {
    console.log(error)
   }
    setTableSpin(true)
  }

  const getOrderById = async (id) => {
    try {
      const res = await getOrderByIdApi(id) 
      if (res.status === 200) {
        setOrderData(res.data?.data?.data);
      }
    } catch (error) {
      console.log(error)
    }
  }

  const updateStatusFormik = useFormik({
    initialValues: { status: "" },
    onSubmit: async (values) => {
      try {
        setUpdateLoader(true);
        const res = await updateOrderStatusByIdApi({ ...values, _id: selectedItemId })
        if (res.status === 200) {
          toast.success(res.data?.message)
          getTableList()
          document.getElementById(updateStatusModalId).click()
        }
      } catch (error) {
        if (error && error?.response) return toast.error(error.response?.data?.message)
        toast.error("something went wrong")
      }
      setUpdateLoader(false);
    },
    validationSchema: Yup.object().shape({
      status: Yup.string().required("Status is required")
    })
  })

  const handlePageClick = (event) => {
    setSelectPage(event.selected);
  };

  useEffect(() => {
    getTableList();
  }, [type,selectedPage])

  return (
    <>
      {/*<Sidebar />*/}
      <div className="main-content vh-100 vh_md-auto overflow-y-a">

        <Header title="Orders" />
        <div className="container-fluid">
          <div className="row">
            <nav className="mb-5">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">
                    <i className="fe fe-home"></i> Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                 Orders
                </li>
              </ol>
            </nav>
            <div className="d-flex justify-content-end mb-4 flex-wrap">
         
              <div style={{ display: "-webkit-inline-box" }}>
               
              </div>
            </div>
            <hr />
          </div>
          <div className="card p-3 shadow">
            <nav>
              <div className="nav nav-tabs mb-3" id="nav-tab" role="tablist">
                <button className="nav-link active me-5" id="nav-official-tab" data-bs-toggle="tab" data-bs-target="#nav-official" type="button" role="tab" aria-controls="nav-official" aria-selected="true" onClick={(e) => {setType("newOrder"); setSelectPage(0)}}>New orders</button>
                <button className="nav-link me-5" id="nav-orders-tab" data-bs-toggle="tab" data-bs-target="#nav-orders" type="button" role="tab" aria-controls="nav-orders" aria-selected="false" onClick={(e) => {setType("orderHistory"); setSelectPage(0)}} >Orders history</button>
              </div>
            </nav>
            <div className="tab-content" id="nav-tabContent">
              <div className="tab-pane fade active show" id="nav-official" role="tabpanel" aria-labelledby="nav-official-tab">
                <Table
                  tableData={tableData}
                  updateStatusModalId={updateStatusModalId}
                  updateStatusFormik={updateStatusFormik}
                  tableSpin={tableSpin}
                  setSelectedItemId={setSelectedItemId}
                  getOrderById={getOrderById}
                  orderShowModalId={orderShowModalId}
                />
              </div>
              <div className="tab-pane fade " id="nav-orders" role="tabpanel" aria-labelledby="nav-orders-tab">
                <Table
                  tableData={tableData}
                  updateStatusModalId={updateStatusModalId}
                  updateStatusFormik={updateStatusFormik}
                  tableSpin={tableSpin}
                  setSelectedItemId={setSelectedItemId}
                  getOrderById={getOrderById}
                  orderShowModalId={orderShowModalId}
                />
              </div>
            </div>
          </div>
         
          <div className='d-flex justify-content-center'>
            {<Pagination
              clickHandler={handlePageClick}
              page={page}
              selectedPage={selectedPage}
            /> }
          </div>
        </div>
      </div>
      {/* <RemoveModal removeHandler={removeHandler} modalId="exampleModal" message={<>Are you sure you want to<br /> remove this?</>} /> */}
      <UpdateOrderStatus formik={updateStatusFormik} modalId={updateStatusModalId} addLoader={updateLoader} />
      <OrderDetailModal showModalData={orderData} modalId={orderShowModalId} />
    </>
  );
}

export default Orders