import React from 'react'
import { ImageUrl } from '../../../utils/BaseUrl'
import moment from 'moment'

function OrderDetailModal({ showModalData, modalId }) {

  return (
    <div className="modal fade" id={modalId} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static"
      data-bs-keyboard="false" >
      <div className="modal-dialog modal-lg modal-dialog-centered" >
        <div className="modal-content">
          <div className="modal-header border-0">
            <button type="button" className="btn-close block-close" data-bs-dismiss="modal" aria-label="Close" ></button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="row row-cols-lg-3 row-cols-md-2">
                <div className="mb-3">
                  <h4 className='fw-bold'>Order id</h4>
                  <p className='mb-0'>{showModalData?.orderDetails?.orderId}</p>
                </div>               
                <div className="mb-3">
                  <h4 className='fw-bold'>Status</h4>
                  <p className='text-capitalize mb-0'>{showModalData?.status}</p>
                </div>
                <div className="mb-3">
                  <h4 className='fw-bold'>Amount</h4>
                  <p className='mb-0'>{showModalData?.price * showModalData?.quantity}</p>

                </div>
                <div className="mb-3">
                  <h4 className='fw-bold'>Payment status</h4>
                  <p className='mb-0'>{showModalData?.isPayment ? "Yes" : "No"}</p>

                </div>
               { showModalData?.trackData?.map?.((ele,index) => {
                console.log(ele)
                return (
                  <div key={index} className="mb-3">
                    <h4 className='fw-bold'>{ele?.type?.[0]?.toUpperCase() + ele?.type?.slice(1)}</h4>
                    <p className='mb-0'>{Boolean(ele?.date) && moment(ele?.date).format("DD/MM/YYYY HH:mm")}</p>
                  </div>
                )
               }) }
               
              </div>
              <hr />
              <div className="col-12 d-flex justify-content-between">
                <h4 className='fw-bold'>Return reason :-</h4>
                <p className='mb-0'>{showModalData?.returnReason}</p>
              </div>
              <hr />

              <div className='col-12'>
                <h4 className='fw-bold'>Vendor details</h4>
                <div className="row row-cols-md-2">
                  <div className="mb-3">
                    <h4 className='fw-bold'>Name</h4>
                    <p className='mb-0'>{showModalData?.products?.vendorDetails?.name }</p>
                  </div>

                  <div className="mb-3">
                    <h4 className='fw-bold'>Email</h4>
                    <p className='mb-0'>{showModalData?.products?.vendorDetails?.email}</p>
                  </div>

                </div>
              </div>

              <hr />
              <div className='col-12'>
                <h4 className='fw-bold'>User details</h4>
                <div className="row row-cols-lg-3 row-cols-md-2">
                  <div className="mb-3">
                    <h4 className='fw-bold'>Name</h4>
                    <p className='mb-0 text-break'>{showModalData?.orderDetails?.deleveryAddress?.name}</p>
                  </div>

                  <div className="mb-3">
                    <h4 className='fw-bold'>Email</h4>
                    <p className='mb-0 text-break'>{showModalData?.orderDetails?.deleveryAddress?.email}</p>
                  </div>

                  <div className="mb-3">
                    <h4 className='fw-bold'>Phone number</h4>
                    <p className='mb-0 text-break'>{showModalData?.orderDetails?.deleveryAddress?.phone}</p>
                  </div>
                </div>
                
              </div>

              <hr />

              <div className='col-12'>
                <h4 className='fw-bold'>Product details</h4>
                
                  <div className='row pt-2 px-5'>
                    <table>
                      <thead>
                        <tr>
                          <th scope="col">Product name</th>
                          <th scope="col">Quantity</th>
                          <th scope="col">Price</th>
                        <th scope="col">Size</th>
                        <th scope="col">Color</th>
                        <th scope="col">Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                            <tr >
                              <td>{showModalData?.products?.name}</td>
                              <td>{showModalData?.quantity}</td>
                              <td>{showModalData?.price}</td>
                              <td>{showModalData?.size}</td>
                              <td>{showModalData?.color}</td>
                              <td>{showModalData?.price * showModalData?.quantity}</td>
                            </tr>
                         
                      </tbody>
                    </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OrderDetailModal