import React from 'react'
import Spinwheel from '../../common/Spinwheel'
import moment from 'moment'

function VendorUsers({ tableData, tableSpin }) {
  return (
    <div className="table-responsive" >
      <table className="table table-hover table-nowrap card-table">
        <thead>
          <tr>
            <th>
              <span
                className="list-sort text-muted"
              >
                S.No
              </span>
            </th>
            <th>
              <span
                className="list-sort text-muted"
              >
                Name
              </span>
            </th>
            <th>
              <span
                className="list-sort text-muted"
              >
                Email Id
              </span>
            </th>
           
            <th>
              <span
                className="list-sort text-muted"
              >
                Joining date
              </span>
            </th>
            <th colSpan={2}>
              <span
                className="list-sort text-muted"
              >
                status
              </span>
            </th>


          </tr>
        </thead>
        {
          tableSpin ?
            <tbody className="list fs-base">
              {tableData?.length > 0 ? tableData?.map((ele, index) => {
                return (<tr key={index} >
                  <td>{index + 1}</td>
                  <td>
                    <span
                      className="item-name text-reset"
                    >
                      {ele?.name ? ele?.name : "-"}
                    </span>
                  </td>
                  <td>
                    <span
                      className="item-phone text-reset"
                    >
                      {ele?.email ? ele?.email : "-"}
                    </span>
                  </td>
                  
                  <td>
                    <span
                      className="item-phone text-reset"
                    >
                      {ele?.joinDate ? moment(ele?.joinDate).format("DD/MM/YYYY") : "-"}
                    </span>
                  </td>
                  <td >
                    <span
                      className="item-phone text-reset"
                    >
                      <div className={`badge ${ele?.joinDate ? "badge bg-success-soft" : "bg-dark-soft"} `}>
                        {ele?.joinDate ? "Active" : "Inactive"}
                      </div>
                    </span>

                  </td>

                </tr>
                )
              }) : <tr align="center">
                <td colSpan="9">
                  <h1>
                    Data Not Found
                  </h1>
                </td>
              </tr>}
            </tbody> : (
              <tbody>
                <tr align="center">
                  <td colSpan="9">
                    <Spinwheel />
                  </td>
                </tr>
              </tbody>
            )
        }
      </table>

    </div>
  )
}

export default VendorUsers