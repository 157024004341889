import React, { useEffect, useState } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import { useAdminContext } from '../context/adminContext'
import Spinwheel from '../components/common/Spinwheel'
import Sidebar from '../Layout/Sidebar'
import { getToken } from "../utils/HelperFuncitons"
import Login from '../components/unauthorized/signin/Login'
import Forgotpassword from '../components/unauthorized/signin/Forgotpassword'
import Resetpassword from '../components/authorized/resetpassword/Resetpassword'
import Dashboard from '../components/authorized/dashboard/Dashboard'
import Users from '../components/authorized/users/Users'
import Usersedit from '../components/authorized/users/Usersedit'
import Administrators from '../components/authorized/administators/Administrators'
import Profiledetails from '../components/authorized/profiles/Profiledetails'
import Userreports from '../components/authorized/users/Userreports'
import Essentialnumber from '../components/authorized/essentialnumbers/Essentialnumber'
import AddEssential from '../components/authorized/essentialnumbers/AddEssential'
import Sos from "../components/authorized/sos/Sos"
import Document from '../components/authorized/document/Document'
import AddOffices from '../components/authorized/document/AddOffices'
import Map from "../components/authorized/map/Map"
import AddMap from "../components/authorized/map/AddMap"
import EditMap from '../components/authorized/map/EditMap'
import Jobs from '../components/authorized/jobs/Jobs'
import AddCompany from '../components/authorized/jobs/AddCompany'
import AddJob from '../components/authorized/jobs/AddJob'
import AppliedUsers from '../components/authorized/jobs/AppliedUsers/AppliedUsers'
import Reviews from '../components/authorized/jobs/review/Reviews'
import SosMessageList from "../components/authorized/sos/SosMessageList"
import News from "../components/authorized/news/News"
import NewsAdd from "../components/authorized/news/AddNews"
import Entertainment from "../components/authorized/entertainment/Entertainment"
import EntertainmentAdd from "../components/authorized/entertainment/AddAndUpdate"
import Living from "../components/authorized/living/Living"
import LivingAddUpdate from "../components/authorized/living/AddAndUpdate"
import AdminUsers from "../components/authorized/profiles/AdminUsers"
import AdminUsers1 from "../components/authorized/profiles/AdminUsers1"
import Course from '../components/authorized/study/Course'
import AddCourse from '../components/authorized/study/AddCourse'
import AddChapter from '../components/authorized/study/chapters/AddChapter'
import Quiz from '../components/authorized/study/Quiz'
import Offices from '../components/authorized/document/Offices'
import Chapter from '../components/authorized/study/chapters/Chapter'
import ViewQuiz from '../components/authorized/study/ViewQuiz'
import Shop from '../components/authorized/shop/Shop'
import UpsertShop from '../components/authorized/shop/UpsertShop'
import Product from '../components/authorized/shop/product/Product'
import ProductAdd from '../components/authorized/shop/product/ProductAdd'
import ProductEdit from '../components/authorized/shop/product/ProductEdit'
import Vendors from "../components/authorized/vendor/Vendor"
import Shops from "../components/authorized/vendor/Shops"
import ProductVendor from '../components/authorized/vendor/ProductVendor'
import UserDetails from '../components/authorized/users/UserDetails'
import ManageNewsCarousel from '../components/authorized/news/ManageNewsCarousel'
import ManageEntertainmentCarousel from '../components/authorized/entertainment/ManageEntertainmentCarousel'
import ManageLivingCarousel from '../components/authorized/living/ManageLivingCarousel'
import ManageCourseCarousel from '../components/authorized/study/ManageCourseCarousel'
import Orders from '../components/authorized/orders/Orders'
import PageManagement from '../components/authorized/pageManagement/PageManagement'
import SystemReports from '../components/authorized/systemAndreports/SystemReports'
import CategoryAndSubCategory from '../components/authorized/category/CategoryAndSubCategory'
import ManageCategoryCarousel from '../components/authorized/category/ManageCategoryCarousel'
import Transfer from '../components/authorized/moneyTransfer/MoneyTransfer'
import BusTicket from '../components/authorized/tickets/busTicket/busTicket'
import Movies from '../components/authorized/tickets/movieTicket/movies'
import Movie from '../components/authorized/tickets/movieTicket/movie'
import MovieShow from '../components/authorized/tickets/movieTicket/movieShow'
import MovieTicket from '../components/authorized/tickets/movieTicket/ticket'
import MovieCinema from '../components/authorized/tickets/movieTicket/movieCinema'
import BusRoutes from '../components/authorized/tickets/busTicket/busRoutes'
import BusRoute from '../components/authorized/tickets/busTicket/busRoute'
import BusOperator from '../components/authorized/tickets/busTicket/busOperator'
import HotelBooking from '../components/authorized/tickets/hotelTicket/hotelBooking'
import AllHotel from '../components/authorized/tickets/hotelTicket/allHotel'
import AddHotel from '../components/authorized/tickets/hotelTicket/addHotel'
import AllHotelRoom from '../components/authorized/tickets/hotelTicket/allHotelRoom'
import AddHotelRoom from '../components/authorized/tickets/hotelTicket/addHotelRoom'
import AllFlightBooking from '../components/authorized/tickets/flight/allFlightBooking'
import AllTrainTicket from '../components/authorized/tickets/TrainTicket/ticket'
import AllTrain from '../components/authorized/tickets/TrainTicket/allTrain'
import TrainCoach from '../components/authorized/tickets/TrainTicket/trainCoach'
import AllTrainStation from '../components/authorized/tickets/TrainTicket/AllTrainStation'
import AllTrainStop from '../components/authorized/tickets/TrainTicket/AllTrainStop'
import TrainTicketBanner from '../components/authorized/tickets/TrainTicket/trainTicketBanner'
import MovieTicketBanner from '../components/authorized/tickets/movieTicket/movieBanner'
import BusTicketBanner from '../components/authorized/tickets/busTicket/busBanner'
import HotelTicketBanner from '../components/authorized/tickets/hotelTicket/hotelBanner'
import FlightTicketBanner from '../components/authorized/tickets/flight/flightTicketBanner'

export default function Mainroute() {
  const [token, setToken] = useState(getToken())
  const location = useLocation();
  useEffect(() => {
    setToken(getToken());
  }, [token])
  const { adminDetail } = useAdminContext();
  const PrivateRoute = () => {
    return (
      <>
        <Routes>
          { <Route path="/dashboard" element={<Dashboard />} />}
          {adminDetail?.role === "admin" && <Route path="/users" element={<Users />} />}
          {adminDetail?.role === "admin" && <Route path="/users/:id" element={<UserDetails />} />}
          {adminDetail?.role === "admin" && <Route path="/users/edit/:id" element={<Usersedit />} />}
          {adminDetail?.role === "admin" && <Route path="/administrators" element={<Administrators />} />}
          {adminDetail?.role === "admin" && <Route path="/profiledetails" element={<Profiledetails />} />}
          {adminDetail?.role === "admin" && <Route path="/userreports" element={<Userreports />} />}
          {adminDetail?.role === "admin" && <Route path="/essentialnumber" element={<Essentialnumber />} />}
          {adminDetail?.role === "admin" && <Route path="/essentialnumber/update" element={<AddEssential />} />}
          {adminDetail?.role === "admin" && <Route path="/essentialnumber/add" element={<AddEssential />} />}
          {adminDetail?.role === "admin" && <Route path="/sos" element={<Sos />} />}
          {adminDetail?.role === "admin" && <Route path="/sos/messagelist" element={<SosMessageList />} />}
          {adminDetail?.role === "admin" && <Route path="/document" element={<Document />} />}
          {adminDetail?.role === "admin" && <Route path="/document/OfficesAndEmbassies/add" element={<AddOffices />} />}
          {adminDetail?.role === "admin" && <Route path="/document/OfficesAndEmbassies/update" element={<AddOffices />} />}
          {adminDetail?.role === "admin" && <Route path='/document/OfficesAndEmbassies/:id' element={<Offices />} />}
          {adminDetail?.role === "admin" && <Route path="/map" element={<Map />} />}
          {adminDetail?.role === "admin" && <Route path="/map/add" element={<AddMap />} />}
          {adminDetail?.role === "admin" && <Route path="/map/update" element={<EditMap />} />}
          {(adminDetail?.role === "admin" || adminDetail?.role === "jobVendor") && <Route path="/job" element={<Jobs />} />}
          {(adminDetail?.role === "admin" || adminDetail?.role === "jobVendor") && <Route path="/job/add-Company" element={<AddCompany />} />}
          {(adminDetail?.role === "admin" || adminDetail?.role === "jobVendor") && <Route path="/job/add" element={<AddJob />} />}
          {(adminDetail?.role === "admin" || adminDetail?.role === "jobVendor") && <Route path="/job/update" element={<AddJob />} />}
          {(adminDetail?.role === "admin" || adminDetail?.role === "jobVendor") && <Route path="/job/reviews/:companyId" element={<Reviews />} />}
          {(adminDetail?.role === "admin" || adminDetail?.role === "jobVendor") && <Route path="/job/applied-users/:id" element={<AppliedUsers />} />}
          {adminDetail?.role === "admin" && <Route path='/news' element={<News />} />}
          {adminDetail?.role === "admin" && <Route path='/news/add' element={<NewsAdd />} />}
          {adminDetail?.role === "admin" && <Route path='/news/carousel' element={<ManageNewsCarousel />} />}
          {adminDetail?.role === "admin" && <Route path='/news/update' element={<NewsAdd />} />}
          {adminDetail?.role === "admin" && <Route path='/entertainment' element={<Entertainment />} />}
          {adminDetail?.role === "admin" && <Route path='/entertainment/carousel' element={<ManageEntertainmentCarousel />} />}
          {adminDetail?.role === "admin" && <Route path='/entertainment/add' element={<EntertainmentAdd />} />}
          {adminDetail?.role === "admin" && <Route path='/entertainment/update' element={<EntertainmentAdd />} />}
          {adminDetail?.role === "admin" && <Route path='/living' element={<Living />} />}
          {adminDetail?.role === "admin" && <Route path='/living/carousel' element={<ManageLivingCarousel />} />}
          {adminDetail?.role === "admin" && <Route path='/living/add' element={<LivingAddUpdate />} />}
          {adminDetail?.role === "admin" && <Route path='/living/update' element={<LivingAddUpdate />} />}
          {adminDetail?.role === "admin" && <Route path='/admin-user/add' element={<AdminUsers />} />}
          {adminDetail?.role === "admin" && <Route path='/admin-user' element={<AdminUsers1 />} />}
          {adminDetail?.role === "admin" && <Route path='/course' element={<Course />} />}
          {adminDetail?.role === "admin" && <Route path='/course/carousel' element={<ManageCourseCarousel />} />}
          {adminDetail?.role === "admin" && <Route path='/course/add' element={<AddCourse />} />}
          {adminDetail?.role === "admin" && <Route path='/course/update' element={<AddCourse />} />}
          {adminDetail?.role === "admin" && <Route path='/course/chapter/add/:id' element={<AddChapter />} />}
          {adminDetail?.role === "admin" && <Route path='/course/chapter/update' element={<AddChapter />} />}
          {adminDetail?.role === "admin" && <Route path='/course/chapter/:id' element={<Chapter />} />}
          {adminDetail?.role === "admin" && <Route path='/course/quiz/add/:id' element={<Quiz />} />}
          {adminDetail?.role === "admin" && <Route path='/course/quiz/update/:id' element={<Quiz />} />}
          {adminDetail?.role === "admin" && <Route path='/course/quiz/:id' element={<ViewQuiz />} />}
          {adminDetail?.role === "admin" && <Route path='/vendor' element={<Vendors />} />}
          {/* {adminDetail?.role === "admin" && <Route path='/vendor/:vendorId' element={<Shops />} />} */}
          {adminDetail?.role === "admin" && <Route path='/vendor/product/:vendorId/' element={<Product />} />}
          {adminDetail?.role === "admin" && <Route path='/vendor/product/add/:vendorId/' element={<ProductAdd />} />}
          {adminDetail?.role === "admin" && <Route path='/vendor/product/update/:vendorId/' element={<ProductEdit />} />}
          {adminDetail?.role === "admin" && <Route path='/pages' element={<PageManagement />} />}
          {adminDetail?.role === "admin" && <Route path='/pages' element={<PageManagement />} />}
          {adminDetail?.role === "admin" && <Route path='/systemreports' element={<SystemReports />} />}
          {adminDetail?.role === "admin" && <Route path='/category' element={<CategoryAndSubCategory />} />}
          {adminDetail?.role === "admin" && <Route path='/category/carousel' element={<ManageCategoryCarousel />} />}
          {adminDetail?.role === "admin" && <Route path='/transfers' element={<Transfer />} />}
          {(adminDetail?.role === "admin" || adminDetail?.role === "ticketVendor") && <Route path='/ticket/bus/tickets' element={<BusTicket />} />}
          {(adminDetail?.role === "admin" || adminDetail?.role === "ticketVendor") && <Route path='/ticket/bus/routes' element={<BusRoutes />} />}
          {(adminDetail?.role === "admin" || adminDetail?.role === "ticketVendor") && <Route path='/ticket/bus/routes/carousel' element={<BusTicketBanner />} />}
          {(adminDetail?.role === "admin" || adminDetail?.role === "ticketVendor") && <Route path='/ticket/bus/route' element={<BusRoute />} />}
          {(adminDetail?.role === "admin" || adminDetail?.role === "ticketVendor") && <Route path='/ticket/bus/route/:id' element={<BusRoute />} />}
          {(adminDetail?.role === "admin" || adminDetail?.role === "ticketVendor") && <Route path='/ticket/bus/operator' element={<BusOperator />} />}
          {(adminDetail?.role === "admin" || adminDetail?.role === "ticketVendor") && <Route path='/ticket/movies' element={<Movies />} />}
          {(adminDetail?.role === "admin" || adminDetail?.role === "ticketVendor") && <Route path='/ticket/movies/movie/carousel' element={<MovieTicketBanner />} />}
          {(adminDetail?.role === "admin" || adminDetail?.role === "ticketVendor") && <Route path={'/ticket/movie'} element={<Movie />} />}
          {(adminDetail?.role === "admin" || adminDetail?.role === "ticketVendor") && <Route path={'/ticket/movie/:id'} element={<Movie />} />}
          {(adminDetail?.role === "admin" || adminDetail?.role === "ticketVendor") && <Route path={'/ticket/movie/show/:id'} element={<MovieShow />} />}
          {(adminDetail?.role === "admin" || adminDetail?.role === "ticketVendor") && <Route path={'/ticket/movie/tickets'} element={<MovieTicket />} />}
          {(adminDetail?.role === "admin" || adminDetail?.role === "ticketVendor") && <Route path={'/ticket/movie/cinema'} element={<MovieCinema />} />}
          {(adminDetail?.role === "admin" || adminDetail?.role === "ticketVendor") && <Route path='/ticket/hotel/booking' element={<HotelBooking />} />}
          {(adminDetail?.role === "admin" || adminDetail?.role === "ticketVendor") && <Route path='/ticket/hotel/all-hotel' element={<AllHotel />} />}
          {(adminDetail?.role === "admin" || adminDetail?.role === "ticketVendor") && <Route path='/ticket/hotel/hotel' element={<AddHotel />} />}
          {(adminDetail?.role === "admin" || adminDetail?.role === "ticketVendor") && <Route path='/ticket/hotel/carousel' element={<HotelTicketBanner />} />}
          {(adminDetail?.role === "admin" || adminDetail?.role === "ticketVendor") && <Route path='/ticket/hotel/hotel/:id' element={<AddHotel />} />}
          {(adminDetail?.role === "admin" || adminDetail?.role === "ticketVendor") && <Route path='/ticket/hotel/hotel/all-room/:id' element={<AllHotelRoom />} />}
          {(adminDetail?.role === "admin" || adminDetail?.role === "ticketVendor") && <Route path='/ticket/hotel/hotel/room/:hotelId' element={<AddHotelRoom />} />}
          {(adminDetail?.role === "admin" || adminDetail?.role === "ticketVendor") && <Route path='/ticket/hotel/hotel/room/:hotelId/:id' element={<AddHotelRoom />} />}
          {(adminDetail?.role === "admin" || adminDetail?.role === "ticketVendor") && <Route path='/ticket/flight/booking' element={<AllFlightBooking />} />}
          {(adminDetail?.role === "admin" || adminDetail?.role === "ticketVendor") && <Route path='/ticket/flight/carousel' element={<FlightTicketBanner />} />}
          {(adminDetail?.role === "admin" || adminDetail?.role === "ticketVendor") && <Route path='/ticket/train/tickets' element={<AllTrainTicket />} />}
          {(adminDetail?.role === "admin" || adminDetail?.role === "ticketVendor") && <Route path='/ticket/train/all-train' element={<AllTrain />} />}
          {(adminDetail?.role === "admin" || adminDetail?.role === "ticketVendor") && <Route path='/ticket/train/all-station' element={<AllTrainStation />} />}
          {(adminDetail?.role === "admin" || adminDetail?.role === "ticketVendor") && <Route path='/ticket/train/train/coach/:id' element={<TrainCoach />} />}
          {(adminDetail?.role === "admin" || adminDetail?.role === "ticketVendor") && <Route path='/ticket/train/train/stop/:id' element={<AllTrainStop />} />}
          {(adminDetail?.role === "admin" || adminDetail?.role === "ticketVendor") && <Route path='/ticket/train/train/carousel' element={<TrainTicketBanner />} />}
          
  


          {/* {(adminDetail?.role === "admin" || adminDetail?.role === "shopVendor") && <Route path='/shop' element={<Shop />} />} */}
          {/* {(adminDetail?.role === "admin" || adminDetail?.role === "shopVendor") && <Route path='/shop/add' element={<UpsertShop />} />}
          {(adminDetail?.role === "admin" || adminDetail?.role === "shopVendor") && <Route path='/shop/update' element={<UpsertShop />} />} */}
          {(adminDetail?.role === "admin" || adminDetail?.role === "shopVendor") && <Route path='/product' element={<Product />} />}
          {(adminDetail?.role === "admin" || adminDetail?.role === "shopVendor") && <Route path='/product/add' element={<ProductAdd />} />}
          {(adminDetail?.role === "admin" || adminDetail?.role === "shopVendor") && <Route path='/product/update' element={<ProductEdit />} />}
          {(adminDetail?.role === "admin" || adminDetail?.role === "shopVendor") && <Route path='/Orders' element={<Orders />} />}
          {(adminDetail?.role === "admin" || adminDetail?.role === "shopVendor") && < Route path="*" element={<Dashboard />} />}
        </Routes>
      </>


    );
  };
  const PublicRoute = () => {
    return (
      <Routes>
        <Route path="/" element={<Login setToken={setToken} />} />
        <Route path="/forgotpassword" element={<Forgotpassword />} />
        <Route path="/resetpassword" element={<Resetpassword />} />
        <Route path="*" element={<Login setToken={setToken} />} />
      </Routes>
    );
  };

  return (
    <div>
      {token ?
        adminDetail ?
          <>
            {location.pathname !== "/profiledetails" ? <Sidebar /> : ""}
           { PrivateRoute()}
          </>
          : <div className="d-flex justify-content-center">
            <Spinwheel />
          </div>
        : PublicRoute() }
    </div>
  )
}


