import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { useAdminContext } from '../../../../context/adminContext';
import { getAllBusTicketPGApi,  updateBusTicketStatusApi,} from '../../../../utils/ApiEndpointFunctions';
import Header from '../../../common/Header';
import { ticketStatusInitalValue, ticketStatusValidationSchema } from "../../../common/Validation"
import Pagination from '../../../common/Pagination';
import Spinwheel from '../../../common/Spinwheel';
import { ImageUrl } from '../../../../utils/BaseUrl';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import UpdateTicketStatus from '../../../common/updateTicketStatus';
import { toast } from 'react-hot-toast';
import { debounce } from 'lodash';
import moment from 'moment';
import ViewBusTicket from './viewBusTicket';

function BusTicket() {
  const navigate = useNavigate()
  const [tableSpin, setTableSpin] = useState(true);
  const [busTicket, setBusTicket] = useState([]);
  let [selectedPage, setSelectPage] = useState(0);
  const [page, setPage] = useState(0);
  const busTicketModalId = "busTicketModalId"
  const ticketStatusModalId = "ticketStatusModalId"
  const [selectedBusTicket, setSelectedBusTicket] = useState(null)
  const [updateTicket, setUpdateTicket] = useState(false)
  const limit = 10;

  const getAllBusTicket = async (selectedPage, search) => {
    try {
      setTableSpin(false)
      const res = await getAllBusTicketPGApi(search || "", selectedPage + 1, limit)
      if (res.status === 200) {
        setBusTicket(res.data?.data?.data)
        const totaldata = res.data?.data?.totalData

        setPage(Math.ceil(totaldata / limit));
      }
      setTableSpin(true)
    } catch (error) {
      setTableSpin(true)
    }
  }

  const handlePageClick = (event) => {
    setSelectPage(event.selected);
    getAllBusTicket(event.selected)
  };

  const handleSubmit = async (values) => {
    const payload = {
      ...values,
      _id: selectedBusTicket,
    }
    try {
      setUpdateTicket(true)
      const res = await updateBusTicketStatusApi(payload);
      if (res?.status === 200) {
        toast.success(res.data?.message)
        setUpdateTicket(false)
        getAllBusTicket(0)
        document.getElementById(ticketStatusModalId).click()
        setSelectedBusTicket(null)
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setUpdateTicket(false)
    }
  }


  const formik = useFormik({
    initialValues: ticketStatusInitalValue,
    onSubmit: handleSubmit,
    validationSchema: ticketStatusValidationSchema,
  })
  const onChange = async (e) => {
    let search = e.target.value;
    getAllBusTicket(0, search)
  };

  const debouncedOnChange = debounce(onChange, 500);

  useEffect(() => {
    getAllBusTicket(selectedPage)
  }, [])




  return (
    <>
      {/*<Sidebar />*/}
      <div className="main-content vh-100 vh_md-auto overflow-y-a">
        <Header title="Bus ticket" />
        {/* CARDS */}
        <div className="container-fluid">
          <div className="row">
            <nav className="mb-5">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">
                    <i className="fe fe-home"></i> Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Bus ticket
                </li>
              </ol>
            </nav>
            <div className="mb-4" style={{ textAlign: "end" }}>
              <div className='d-flex justify-content-end gap-2' style={{ display: "-webkit-inline-box" }}>
                <div className='d-flex gap-2 align-items-center justify-content-center'>
                  <button onClick={() => navigate(`/ticket/bus/routes`)} className="btn-custom w-auto" style={{ cursor: "pointer" }}>
                    Routes
                  </button>
                  <div className="position-relative">
                    <input
                      className="form-search me-3"
                      type="search"
                      name=""
                      onChange={debouncedOnChange}
                      placeholder="Search"
                    />
                    <img
                      className="users-search"
                      src="/images/Icon feather-search.svg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <hr />

            <div className="card p-3 table-responsive" >
              <table className="table table-hover table-nowrap card-table">
                <thead>
                  <tr>
                    <th>
                      <span
                        className="list-sort text-muted"
                      >
                        S.No
                      </span>
                    </th>
                    <th>
                      <span
                        className="list-sort text-muted"
                      >
                        Name
                      </span>
                    </th>
                    <th>
                      <span
                        className="list-sort text-muted"
                      >
                        Departure
                      </span>
                    </th>
                  
                    <th>
                      <span
                        className="list-sort text-muted"
                      >
                        Arrival
                      </span>
                    </th>
                    <th>
                      <span
                        className="list-sort text-muted"
                      >
                        Departure Time
                      </span>
                    </th>
                    <th>
                      <span
                        className="list-sort text-muted"
                      >
                        Arrival Time
                      </span>
                    </th>
                    <th>
                      <span
                        className="list-sort text-muted"
                      >
                        Seats
                      </span>
                    </th>
                    <th>
                      <span
                        className="list-sort text-muted"
                      >
                        Amount
                      </span>
                    </th>
                    <th>
                      <span
                        className="list-sort text-muted"
                      >
                        Payment
                      </span>
                    </th>
                    <th>
                      <span
                        className="list-sort text-muted"
                      >
                        Status
                      </span>
                    </th>
                    <th></th>


                  </tr>
                </thead>
                {
                  tableSpin ?
                    <tbody className="list fs-base">
                      {busTicket?.length > 0 ? busTicket?.map((ele, index) => {
                        return (<tr key={index} >
                          <td>{index + 1}</td>
                          <td className='position-relative'>
                            {ele?.userId?.name?.length > 30 ? <span>
                              {ele?.userId?.name?.substr(0, 30)}...
                              <div className="tooltip1 item-name text-reset" ></div>
                              <div className='wrapper'>
                                <div className="tooltip1-text">{ele?.userId?.name}</div>
                              </div>
                            </span> :
                              <span>{ele?.userId?.name || "-"}</span>}
                          </td>
                          <td className='position-relative'>
                            {ele?.busRouteId?.departurePlace?.length > 30 ? <span>
                              {ele?.busRouteId?.departurePlace?.substr(0, 30)}...
                              <div className="tooltip1 item-name text-reset" ></div>
                              <div className='wrapper'>
                                <div className="tooltip1-text">{ele?.busRouteId?.departurePlace}</div>
                              </div>
                            </span> :
                              <span>{ele?.busRouteId?.departurePlace || "-"}</span>}

                          </td>
                      
                          <td className='position-relative'>
                            {ele?.busRouteId?.arrivalPlace?.length > 30 ? <span>
                              {ele?.busRouteId?.arrivalPlace?.substr(0, 30)}...
                              <div className="tooltip1 item-name text-reset" ></div>
                              <div className='wrapper'>
                                <div className="tooltip1-text">{ele?.busRouteId?.arrivalPlace}</div>
                              </div>
                            </span> :
                              <span>{ele?.busRouteId?.arrivalPlace || "-"}</span>}
                          </td>
                          <td className='position-relative'>
                            <span>{ele?.busRouteId?.departure ? moment(ele?.busRouteId?.departure).format("DD/MM/YYYY h:mm:ss a") : "-"}</span>
                          </td>
                          <td className='position-relative'>
                            <span>{ele?.busRouteId?.arrivalTime ? moment(ele?.busRouteId?.arrivalTime).format("DD/MM/YYYY h:mm:ss a") : "-"}</span>
                          </td>
                          <td className='position-relative'>
                            {ele?.passengers?.length}
                          </td>
                          <td className='position-relative'>
                            {ele?.totalPrice}
                          </td>
                          <td className='position-relative'>
                            {ele?.payment?.status == "success" ? "Yes" : "No"}
                          </td>
                          <td className='position-relative'>
                            {ele?.status || "Pending"}
                          </td>

                          <td className="text-end">
                         
                              <div className="dropdown">
                                <span
                                  className="dropdown-ellipses dropdown-toggle"
                                  role="button"
                                  data-bs-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <i className="fe fe-more-vertical" />
                                </span>
                                <div className="dropdown-menu dropdown-menu-end">
                                  <div data-bs-toggle="modal" data-bs-target={`#${busTicketModalId}`} className=' dropdown-item' style={{ cursor: "pointer" }} onClick={() => { setSelectedBusTicket(ele?._id) }}>
                                    View
                                  </div>
                                  {ele?.status?.toLowerCase()=="pending" &&  <div data-bs-toggle="modal" data-bs-target={`#${ticketStatusModalId}`} className=' dropdown-item' style={{ cursor: "pointer" }} onClick={() => { setSelectedBusTicket(ele?._id) }}>
                                    Update status
                                  </div>}
                                </div>
                              </div>
                          </td>
                        </tr>
                        )
                      }) : <tr align="center">
                        <td colSpan="9">
                          <h1>
                            Data Not Found
                          </h1>
                        </td>
                      </tr>}
                    </tbody> : (
                      <tbody>
                        <tr align="center">
                          <td colSpan="9">
                            <Spinwheel />
                          </td>
                        </tr>
                      </tbody>
                    )
                }
              </table>
            </div>
            <div className="d-flex justify-content-center">
              {busTicket?.length > 0 ? <Pagination
                clickHandler={handlePageClick}
                page={page}
                selectedPage={selectedPage}
              />
                : null}
            </div>
          </div>
          <ViewBusTicket modalId={busTicketModalId} id={selectedBusTicket} />
          <UpdateTicketStatus modalId={ticketStatusModalId} formik={formik} addLoader={updateTicket} />
        </div>
      </div>
    </>
  )
}

export default BusTicket