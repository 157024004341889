import { useFormik } from 'formik'
import * as Yup from "yup"
import React, { useState } from 'react'
import { getSosLimitationApi, setSosLimitationApi } from '../../../utils/ApiEndpointFunctions'
import toast from 'react-hot-toast'
import { useEffect } from 'react'

function AddMessageLimit() {
  const getSosLimitation = async () => {
    try {
      const res = await getSosLimitationApi();
      if (res.status === 200) {
        formik.setFieldValue("limit", res.data?.data?.limit)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const handleSubmit = async (values) => {
    try {
      const res = await setSosLimitationApi(values);
    if(res.status === 200) {
      toast.success("SOS Message limit updated")
    }
    } catch (error) {
      console.log(error)
    }
  }
  const formik = useFormik({
    initialValues:{limit:""},
    onSubmit:handleSubmit,
    validationSchema:Yup.object().shape({
      limit: Yup.mixed().test('limit', 'Limit must be a number', value => {
        if (!value) return false;
        const ratingNum = parseInt(value);
        return !isNaN(ratingNum)
      }).required('Limit is required')
    })
  })
  
  useEffect(() => {
    getSosLimitation();
  },[])
  return (
    <>
      <div className="modal fade" id="addSentLimit" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
        <div className="modal-dialog modal-sm modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0">
              <button type="button" className="btn-close block-close" data-bs-dismiss="modal" aria-label="Close" ></button>
            </div>
            <div className="modal-body block-user">
              <form action="" className="text-start" onSubmit={formik.handleSubmit} >
                <label htmlFor="limit" className="form-label">Message limit</label>
                <div className="position-relative">
                  <input className="form-control border" id='limit' name="limit" value={formik?.values?.limit} onChange={formik?.handleChange} onBlur={formik?.handleBlur} placeholder="Enter message limit" />
                  {formik.touched.limit && formik.errors.limit ? (<div className='req-error'>{formik.errors.limit}</div>) : null}
                </div>
                <div className="modal-footer justify-content-center ">
                  <button type="submit" className="btn-custom w-auto mt-2" data-bs-dismiss={Object.keys(formik?.errors).length > 0 ? '' : 'modal'} onClick={() => { }} >
                    Save
                  </button>
                  <button type="button" className="btn-custom w-auto mt-2" data-bs-dismiss='modal' >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddMessageLimit