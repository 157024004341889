import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import Header from '../../../common/Header';
import TicketBanner from '../../../common/TicketBanner';


function HotelTicketBanner() {
  return (
    <>
      {/*<Sidebar />*/}
      <div className="main-content vh-100 vh_md-auto overflow-y-a">
        <Header title="Hotel" />
        {/* CARDS */}
        <div className="container-fluid">
          <div className="row">
            <nav className="mb-5">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">
                    <i className="fe fe-home"></i> Dashboard
                  </Link>
                </li>
                <Link to={"/ticket/hotel/booking"} className="breadcrumb-item active" aria-current="page">
                Hotel Booking
                </Link>
                <Link to={"/ticket/hotel/all-hotel"} className="breadcrumb-item active" aria-current="page">
                Hotels
                </Link>
                <li className="breadcrumb-item active" aria-current="page">
                carousel
                </li>
              </ol>
            </nav>
            <hr />
              <TicketBanner type={"hotel"}/>
       
          </div>
        </div>
      </div>
    </>
  )
}

export default HotelTicketBanner