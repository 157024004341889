import React from 'react'
import { ImageUrl } from '../../../utils/BaseUrl'
import moment from 'moment'
function ModalVideo({ newsData, setNewsData }) {

  return (
    <div className="modal fade" id="videoStatusModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static"
      data-bs-keyboard="false" >
      <div className="modal-dialog modal-lg modal-dialog-centered" >
        <div className="modal-content">
          <div className="modal-header border-0">
            <button type="button" className="btn-close block-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { setNewsData({})}}></button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-7 d-flex ">
                <div className='w-100'>
                  {newsData?.type === "video" ? <video height={220} controls className='rounded' poster={newsData?.thumbnail ? ImageUrl + newsData?.thumbnail : "/images/invite.png"} width={"100%"} >
                    <source src={newsData?.video ? ImageUrl + newsData?.video : '/video/demo.mp4'} />
                  </video> :
                    <img src={newsData?.thumbnail ? ImageUrl + newsData?.thumbnail : "/images/image.png"} alt="articles" style={{ height: 200, width: 400, objectFit: 'fill'}} />
                  }
                  <br />
                  <br />
                  
                  <div className='data__modal' dangerouslySetInnerHTML={{ __html: newsData?.newsContent }}></div>
                </div>
              </div>
              <div className="col-5">
                <div className="mb-3">
                  <h4 className='fw-bold'>Title</h4>
                  <p className='mb-0'>{newsData?.newsTitle}</p>
                  <hr className='mt-1 fw-bold' style={{ height: '2px' }} />
                </div>
                <div className="mb-3">
                  <h4 className='fw-bold'>Headline</h4>
                  <p className='mb-0'>{newsData?.newsHeading}</p>
                  <hr className='mt-1 fw-bold' style={{ height: '2px' }} />
                </div>
                {/* <div className="mb-3">
                  <h4 className='fw-bold'>News Content</h4>
                  <p className='mb-0'>{newsData?.newsContent}</p>
                  <hr className='mt-1 fw-bold' style={{ height: '2px' }} />
                </div> */}
                <div className="mb-3">
                  <h4 className='fw-bold'>News publish date</h4>
                  <p className='mb-0'>{newsData?.publishDate && moment(newsData?.publishDate).format("DD/MM/YYYY")}</p>
                  <hr className='mt-1 fw-bold' style={{ height: '2px' }} />
                </div>
                <div className="mb-3">
                  <h4 className='fw-bold'>Publisher name</h4>
                  <p className='mb-0'>{newsData?.publishBy}</p>
                  <hr className='mt-1 fw-bold' style={{ height: '2px' }} />
                </div>
                <div className="mb-3">
                  <h4 className='fw-bold'>Published</h4>
                  <p className='mb-0'>{newsData?.isPublished ? 'Yes' : 'No'}</p>
                  <hr className='mt-1 fw-bold' style={{ height: '2px' }} />
                </div>
                <div className="mb-3">
                  <h4 className='fw-bold'>Top news</h4>
                  <p className='mb-0'>{newsData?.onPriority ? 'Yes' : 'No'}</p>
                  <hr className='mt-1 fw-bold' style={{ height: '2px' }} />
                </div>
                <div className="mb-3">
                  <h4 className='fw-bold'>Banner</h4>
                  <p className='mb-0'>{newsData?.isBanner ? 'Yes' : 'No'}</p>
                  <hr className='mt-1 fw-bold' style={{ height: '2px' }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModalVideo