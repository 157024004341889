import React from 'react'
import { ImageUrl } from '../../../../utils/BaseUrl'

function ChapterDetailModal({ chapter, setChapter }) {

  return (
    <div className="modal fade" id="showChapterDetail" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static"
      data-bs-keyboard="false" >
      <div className="modal-dialog modal-lg modal-dialog-centered" >
        <div className="modal-content">
          <div className="modal-header border-0">
            <button type="button" className="btn-close block-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { setChapter({}) }}></button>
          </div>
          <div className="modal-body">
            <div className='text-center fw-bold'>
              <h2>{chapter?.chapterTitle}</h2>
            </div>
            {chapter?.type === "video" ?
              <div className='m-auto mb-3' style={{ width: 500, height: 300 }}>
                <video className='h-100' style={{ borderRadius: "10px" }} controls poster={ ImageUrl + chapter?.thumbnail}>
                  <source src={ImageUrl + chapter?.videos} type="video/mp4" />
                </video>
              </div> :
              <div className='m-auto mb-3' style={{ width: 500, height: 300 }}>
                <img src={ImageUrl + chapter?.thumbnail} className='w-100 h-100' style={{ objectFit: 'fill', borderRadius: "10px" }} alt='' />
              </div>
            }
            <div>
              <p dangerouslySetInnerHTML={{ __html: chapter?.content }}></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


export default ChapterDetailModal