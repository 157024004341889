import React, { useState } from 'react'
import { ImageUrl } from '../../../utils/BaseUrl'
import { uploadImage } from '../../../utils/ApiEndpointFunctions';
import toast from 'react-hot-toast';
import Spinwheel from '../../common/Spinwheel';

function AddOrUpdateAppLInk({formik, modalId, loader}) {

  const [fileTypeError, setFileTypeError] = useState("")

  const fileUploadHandker = async (e) => {
    const file = e.target.files[0];
    formik.setFieldTouched(`image`, true)
    const types = ['image/jpeg', 'image/png', 'image/gif']

    if (file) {
      if (types.includes(file?.type)) {
        setFileTypeError("")
        const formData = new FormData();
        formData.append("file", file);
        formData.append("type", 5)
        formData.append("folder", "essential")
        const res = await uploadImage('/users/fileUpload', formData)
        if (res.status === 200 && res.data?.status === 1) {
          formik.setFieldValue("image", res.data?.data?.fileName)
          toast.success("File uploaded")
        }
      } else {
        formik.setFieldValue("image", "")
        setFileTypeError("Must be image only")
      }
    }
  }

  

  return (
    <>
      {<div className="modal fade" id={modalId} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-md modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0">
              <button type="button" className="btn-close block-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body  block-user">
              <form action="" className="text-start" onSubmit={formik?.handleSubmit} >

                <label htmlFor="profile" className="form-label">Image </label>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="position-relative">
                    {<img src={formik?.values?.image ? ImageUrl + encodeURIComponent(formik?.values?.image) : process.env.PUBLIC_URL + "/images/person-circle.svg"} width="70px" height="70px" className="rounded-circle" alt="Selected file" />}
                    {
                      <>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-camera pro-camera" viewBox="0 0 16 16">
                          <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1v6zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z" />
                          <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x xmark" viewBox="0 0 16 16" onClick={() => { formik?.setFieldValue("image", "others/person-circle.svg") }} >
                          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                        </svg>
                      </>
                    }
                    {fileTypeError ? <div className='req-error'>{fileTypeError}</div> : formik?.touched?.image && formik?.errors?.image ? (<div className='req-error'>{formik?.errors?.image}</div>) : null}
                  </div>
                  <div className="btn_upload">
                    <input type="file" id="profile" accept="image/*" className="form-control border border-1" name="profileImage" onChange={fileUploadHandker} />
                    Upload
                  </div>
                 
                </div>
                <br />
                <label htmlFor="first" className="form-label"> Name</label>
                <div className="position-relative"><input className="form-control border border-1" type="text" id="first" placeholder="Enter name" name="name" value={formik?.values?.name} onChange={formik?.handleChange} onBlur={formik?.handleBlur} /><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-fill input-icon" viewBox="0 0 16 16">
                  <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                </svg>
                  {formik?.touched?.name && formik?.errors?.name ? (<div className='req-error'>{formik?.errors?.name}</div>) : null}
                </div>

                <br />
                <label htmlFor="androidUrl" className="form-label">Android url</label>
                <div className="position-relative"><input className="form-control border border-1" type="text" id="androidUrl" placeholder="Enter url" name="androidUrl" value={formik?.values?.androidUrl} onChange={formik?.handleChange} onBlur={formik?.handleBlur} />
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-globe input-icon" viewBox="0 0 16 16"><path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 0 0 5.145 4H7.5V1.077zM4.09 4a9.267 9.267 0 0 1 .64-1.539 6.7 6.7 0 0 1 .597-.933A7.025 7.025 0 0 0 2.255 4H4.09zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5h2.49zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5H4.847zM8.5 5v2.5h2.99a12.495 12.495 0 0 0-.337-2.5H8.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5H4.51zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5H8.5zM5.145 12c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12H5.145zm.182 2.472a6.696 6.696 0 0 1-.597-.933A9.268 9.268 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472zM3.82 11a13.652 13.652 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5H3.82zm6.853 3.472A7.024 7.024 0 0 0 13.745 12H11.91a9.27 9.27 0 0 1-.64 1.539 6.688 6.688 0 0 1-.597.933zM8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855.173-.324.33-.682.468-1.068H8.5zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm2.802-3.5a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5h2.49zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 0 0-3.072-2.472c.218.284.418.598.597.933zM10.855 4a7.966 7.966 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4h2.355z"></path></svg>
                  {formik?.touched?.androidUrl && formik?.errors?.androidUrl ? (<div className='req-error'>{formik?.errors?.androidUrl}</div>) : null}
                </div>

                <br />

                <label htmlFor="iosUrl" className="form-label">IOS url</label>
                <div className="position-relative"><input className="form-control border border-1" type="text" id="iosUrl" placeholder="Enter url" name="iosUrl" value={formik?.values?.iosUrl} onChange={formik?.handleChange} onBlur={formik?.handleBlur} />
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-globe input-icon" viewBox="0 0 16 16"><path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 0 0 5.145 4H7.5V1.077zM4.09 4a9.267 9.267 0 0 1 .64-1.539 6.7 6.7 0 0 1 .597-.933A7.025 7.025 0 0 0 2.255 4H4.09zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5h2.49zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5H4.847zM8.5 5v2.5h2.99a12.495 12.495 0 0 0-.337-2.5H8.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5H4.51zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5H8.5zM5.145 12c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12H5.145zm.182 2.472a6.696 6.696 0 0 1-.597-.933A9.268 9.268 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472zM3.82 11a13.652 13.652 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5H3.82zm6.853 3.472A7.024 7.024 0 0 0 13.745 12H11.91a9.27 9.27 0 0 1-.64 1.539 6.688 6.688 0 0 1-.597.933zM8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855.173-.324.33-.682.468-1.068H8.5zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm2.802-3.5a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5h2.49zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 0 0-3.072-2.472c.218.284.418.598.597.933zM10.855 4a7.966 7.966 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4h2.355z"></path></svg>
                  {formik?.touched?.iosUrl && formik?.errors?.iosUrl ? (<div className='req-error'>{formik?.errors?.iosUrl}</div>) : null}
                </div>

                <br />
               
               
                <div className="text-center d-flex justify-content-center">
                  <button type="submit" className="btn-custom w-auto m-2" disabled={loader}>{loader ? <Spinwheel sizeClass="spin-small" /> :"Submit"}</button>
                  <button type="button" className="btn-custom w-auto m-2" data-bs-dismiss='modal' >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      }
    </>
  )
}

export default AddOrUpdateAppLInk