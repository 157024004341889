import moment from 'moment'
import React from 'react'
import ReactDatePicker from 'react-datepicker'
import Spinwheel from '../../../common/Spinwheel'
import { useFormik } from 'formik'
import { WorkpermitCiApplicatonFormIntialValue, WorkpermitCiApplicatonFormValidation } from '../../../common/Validation'
import Attachment from '../../../common/Attachment'

function WorkpermitApplicationFrom({ formik, addDocLoader, docSpin, handlefile, submitHandler }) {

  
 
  return (
    <div>
    <div className="row row-cols-2">
      <div>
        <div className='d-flex'>
          <label htmlFor="name" className="form-label">Name</label>
        </div>
        <div className="position-relative">
          <input className="form-control border border-1" type="text" id="name" placeholder="Enter name" name="name" value={formik.values?.name} onChange={formik.handleChange} onBlur={formik.handleBlur} />
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-text input-icon" viewBox="0 0 16 16">
            <path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5M5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1z" />
            <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1" />
          </svg>
          {formik.touched.name && formik.errors.name ? (<div className='req-error'>{formik.errors.name}</div>) : null}
        </div>
      </div>

      <div>
        <div className='d-flex'>
          <label htmlFor="aliasName" className="form-label">Alias name</label>
        </div>
        <div className="position-relative">
          <input className="form-control border border-1" type="text" id="aliasName" placeholder="Enter..." name="aliasName" value={formik.values?.aliasName} onChange={formik.handleChange} onBlur={formik.handleBlur} />
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-text input-icon" viewBox="0 0 16 16">
            <path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5M5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1z" />
            <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1" />
          </svg>
          {formik.touched.aliasName && formik.errors.aliasName ? (<div className='req-error'>{formik.errors.aliasName}</div>) : null}
        </div>
      </div>

      <div>
        <div className='d-flex'>
          <label htmlFor="nrcNumber" className="form-label">Nrc</label>
        </div>
        <div className="position-relative">
          <input className="form-control border border-1" type="text" id="nrcNumber" placeholder="Enter..." name="nrcNumber" value={formik.values?.nrcNumber} onChange={formik.handleChange} onBlur={formik.handleBlur} />
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-text input-icon" viewBox="0 0 16 16">
            <path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5M5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1z" />
            <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1" />
          </svg>
          {formik.touched.nrcNumber && formik.errors.nrcNumber ? (<div className='req-error'>{formik.errors.nrcNumber}</div>) : null}
        </div>
      </div>

      <div>
        <div className='d-flex'>
          <label htmlFor="parentName" className="form-label">Parent name</label>
        </div>
        <div className="position-relative">
          <input className="form-control border border-1" type="text" id="parentName" placeholder="Enter..." name="parentName" value={formik.values?.parentName} onChange={formik.handleChange} onBlur={formik.handleBlur} />
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-text input-icon" viewBox="0 0 16 16">
            <path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5M5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1z" />
            <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1" />
          </svg>
          {formik.touched.parentName && formik.errors.parentName ? (<div className='req-error'>{formik.errors.parentName}</div>) : null}
        </div>
      </div>

      <div>
        <div className='d-flex'>
          <label htmlFor="placeOfBirth" className="form-label">Birth place</label>
        </div>
        <div className="position-relative">
          <input className="form-control border border-1" type="text" id="placeOfBirth" placeholder="Enter name" name="placeOfBirth" value={formik.values.placeOfBirth} onChange={formik.handleChange} onBlur={formik.handleBlur} />
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-text input-icon" viewBox="0 0 16 16">
            <path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5M5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1z" />
            <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1" />
          </svg>
          {formik.touched.placeOfBirth && formik.errors.placeOfBirth ? (<div className='req-error'>{formik.errors.placeOfBirth}</div>) : null}
        </div>
      </div>
  

      <div>
        <div className='d-flex'>
          <label htmlFor="religion" className="form-label">Religion</label>
        </div>
        <div className="position-relative">
          <input className="form-control border border-1" type="text" id="religion" placeholder="Enter" name="religion" value={formik.values?.religion} onChange={formik.handleChange} onBlur={formik.handleBlur} />
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-text input-icon" viewBox="0 0 16 16">
            <path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5M5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1z" />
            <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1" />
          </svg>
          {formik.touched.religion && formik.errors.religion ? (<div className='req-error'>{formik.errors.religion}</div>) : null}
        </div>
      </div>

      <div>
        <div className='d-flex'>
          <label htmlFor="language" className="form-label">language</label>
        </div>
        <div className="position-relative">
          <input className="form-control border border-1" type="text" id="language" placeholder="Enter" name="language" value={formik.values?.language} onChange={formik.handleChange} onBlur={formik.handleBlur} />
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-text input-icon" viewBox="0 0 16 16">
            <path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5M5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1z" />
            <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1" />
          </svg>
          {formik.touched.language && formik.errors.language ? (<div className='req-error'>{formik.errors.language}</div>) : null}
        </div>
      </div>

      <div>
        <div className='d-flex justify-content-start'>  <label htmlFor="permanentAddress" className="form-label">Permanent address</label></div>
          <textarea id="permanentAddress" name='permanentAddress' class="form-control border" placeholder="Enter.." value={formik?.values?.permanentAddress} onChange={formik?.handleChange} onBlur={formik.handleBlur} ></textarea>
          {formik.touched.permanentAddress && formik.errors.permanentAddress ? (<div className='req-error'>{formik.errors.permanentAddress}</div>) : null}

      </div>

      <div>
        <label htmlFor="dob" className="form-label">Date of birth</label>
        <div className="position-relative">
          <ReactDatePicker
              selected={formik.values.dob ? new Date(formik.values.dob) : ""}
            name='dob'
            id="dob"
            onChange={(e) => {
              e ?
                formik.setFieldValue("dob", e) :
                formik.setFieldValue("dob", "")
            }}
            onBlur={formik.handleBlur}
            className='form-control w-100 border border-1'
            placeholderText='Pickup date'
            autoComplete="off"
            dateFormat={"dd/MM/yyyy"}
            maxDate={new Date()}
          />
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar input-icon" viewBox="0 0 16 16">
            <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z" />
          </svg>

          {formik.touched.dob && formik.errors.dob ? (<div className='req-error'>{formik.errors.dob}</div>) : null}
        </div>
      </div>

    

      <div>
        <div className='d-flex'>
          <label htmlFor="myanmarPhone" className="form-label">Myanmar phone</label>
        </div>
        <div className="position-relative">
          <input className="form-control border border-1" type="text" id="myanmarPhone" placeholder="Enter..." name="myanmarPhone" value={formik.values?.myanmarPhone} onChange={formik.handleChange} onBlur={formik.handleBlur} />
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-text input-icon" viewBox="0 0 16 16">
            <path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5M5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1z" />
            <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1" />
          </svg>
          {formik.touched.myanmarPhone && formik.errors.myanmarPhone ? (<div className='req-error'>{formik.errors.myanmarPhone}</div>) : null}
        </div>
      </div>


      <div>
        <div className='d-flex'>
          <label htmlFor="relationship" className="form-label">Relationship</label>
        </div>
        <div className="position-relative">
          <input className="form-control border border-1" type="text" id="relationship" placeholder="Enter..." name="relationship" value={formik.values?.relationship} onChange={formik.handleChange} onBlur={formik.handleBlur} />
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-text input-icon" viewBox="0 0 16 16">
            <path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5M5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1z" />
            <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1" />
          </svg>
          {formik.touched.relationship && formik.errors.relationship ? (<div className='req-error'>{formik.errors.relationship}</div>) : null}
        </div>
      </div>

      <div>
        <div className='d-flex'>
          <label htmlFor="companyName" className="form-label">Company name</label>
        </div>
        <div className="position-relative">
          <input className="form-control border border-1" type="text" id="companyName" placeholder="Enter..." name="companyName" value={formik.values?.companyName} onChange={formik.handleChange} onBlur={formik.handleBlur} />
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-text input-icon" viewBox="0 0 16 16">
            <path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5M5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1z" />
            <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1" />
          </svg>
          {formik.touched.companyName && formik.errors.companyName ? (<div className='req-error'>{formik.errors.companyName}</div>) : null}
        </div>
      </div>

      <div>
        <div className='d-flex justify-content-start'>  <label htmlFor="companyAddress" className="form-label"> Company address</label></div>
          <textarea id="companyAddress" name='companyAddress' class="form-control border" placeholder="Enter.." value={formik?.values?.companyAddress} onBlur={formik.handleBlur} onChange={formik?.handleChange} ></textarea>
          {formik.touched.companyAddress && formik.errors.companyAddress ? (<div className='req-error'>{formik.errors.companyAddress}</div>) : null}

      </div>

      <div>
        <div className='d-flex'>
          <label htmlFor="companyPhone" className="form-label">Company phone number</label>
        </div>
        <div className="position-relative">
          <input className="form-control border border-1" type="text" id="companyPhone" placeholder="Enter..." name="companyPhone" value={formik.values?.companyPhone} onChange={formik.handleChange} onBlur={formik.handleBlur} />
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-text input-icon" viewBox="0 0 16 16">
            <path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5M5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1z" />
            <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1" />
          </svg>
          {formik.touched.companyPhone && formik.errors.companyPhone ? (<div className='req-error'>{formik.errors.companyPhone}</div>) : null}
        </div>
      </div>


      <div>
        <label htmlFor="entryDateThailand" className="form-label">Entry date of thailand</label>
        <div className="position-relative">
          <ReactDatePicker
              selected={formik.values.entryDateThailand ? new Date(formik.values.entryDateThailand) : ""}
            name='entryDateThailand'
            id="entryDateThailand"
            onChange={(e) => {
              e ?
                formik.setFieldValue("entryDateThailand", e) :
                formik.setFieldValue("entryDateThailand", "")
            }}
            onBlur={formik.handleBlur}
            className='form-control w-100 border border-1'
            placeholderText='Pickup date'
            autoComplete="off"
            dateFormat={"dd/MM/yyyy"}
          />
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar input-icon" viewBox="0 0 16 16">
            <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z" />
          </svg>

          {formik.touched.entryDateThailand && formik.errors.entryDateThailand ? (<div className='req-error'>{formik.errors.entryDateThailand}</div>) : null}
        </div>
      </div>

      <div>
        <div className='d-flex justify-content-start'>  <label htmlFor="addressInThailand" className="form-label"> Address in thailand</label></div>
          <textarea id="addressInThailand" name='addressInThailand' class="form-control border" placeholder="Enter.." value={formik?.values?.addressInThailand} onBlur={formik.handleBlur} onChange={formik?.handleChange} ></textarea>
          {formik.touched.addressInThailand && formik.errors.addressInThailand ? (<div className='req-error'>{formik.errors.addressInThailand}</div>) : null}
      </div>


      <div>
        <div className='d-flex'>
          <label htmlFor="thaiPhone" className="form-label">Thai phone</label>
        </div>
        <div className="position-relative">
          <input className="form-control border border-1" type="text" id="thaiPhone" placeholder="Enter..." name="thaiPhone" value={formik.values?.thaiPhone} onChange={formik.handleChange} onBlur={formik.handleBlur} />
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-text input-icon" viewBox="0 0 16 16">
            <path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5M5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1z" />
            <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1" />
          </svg>
          {formik.touched.thaiPhone && formik.errors.thaiPhone ? (<div className='req-error'>{formik.errors.thaiPhone}</div>) : null}
        </div>
      </div>


      <div>
        <div className='d-flex'>
          <label htmlFor="ciOldLicenseNumber" className="form-label">CI old license number</label>
        </div>
        <div className="position-relative">
          <input className="form-control border border-1" type="text" id="ciOldLicenseNumber" placeholder="Enter..." name="ciOldLicenseNumber" value={formik.values?.ciOldLicenseNumber} onChange={formik.handleChange} onBlur={formik.handleBlur} />
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-text input-icon" viewBox="0 0 16 16">
            <path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5M5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1z" />
            <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1" />
          </svg>
          {formik.touched.ciOldLicenseNumber && formik.errors.ciOldLicenseNumber ? (<div className='req-error'>{formik.errors.ciOldLicenseNumber}</div>) : null}
        </div>
      </div>

        <div>
          <label htmlFor="dateOfIssue" className="form-label">Date of issue</label>
          <div className="position-relative">
            <ReactDatePicker
              selected={formik.values.dateOfIssue ? new Date(formik.values.dateOfIssue) : ""}
              name='dateOfIssue'
              id="dateOfIssue"
              onChange={(e) => {
                e ?
                  formik.setFieldValue("dateOfIssue", e) :
                  formik.setFieldValue("dateOfIssue", "")
              }}
              onBlur={formik.handleBlur}
              className='form-control w-100 border border-1'
              placeholderText='Pickup date'
              autoComplete="off"
              dateFormat={"dd/MM/yyyy"}
            />
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar input-icon" viewBox="0 0 16 16">
              <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z" />
            </svg>

            {formik.touched.dateOfIssue && formik.errors.dateOfIssue ? (<div className='req-error'>{formik.errors.dateOfIssue}</div>) : null}
          </div>
        </div>

        <div>
          <div className='d-flex'>
            <label htmlFor="issuedPlace" className="form-label">Issue place</label>
          </div>
          <div className="position-relative">
            <input className="form-control border border-1" type="text" id="issuedPlace" placeholder="Enter name" name="issuedPlace" value={formik.values.issuedPlace} onChange={formik.handleChange} onBlur={formik.handleBlur} />
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-text input-icon" viewBox="0 0 16 16">
              <path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5M5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1z" />
              <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1" />
            </svg>
            {formik.touched.issuedPlace && formik.errors.issuedPlace ? (<div className='req-error'>{formik.errors.issuedPlace}</div>) : null}
          </div>
        </div>

    </div>

      <Attachment formik={formik} docSpin={docSpin} handlefile={handlefile} />

      <div className="modal-footer border-0 justify-content-center pt-4">
        <button type="button" className="btn-custom w-auto" disabled={addDocLoader} onClick={(e) => { e.preventDefault(); formik.handleSubmit(); }}  >{addDocLoader ? <Spinwheel sizeClass="spin-small" /> : "Submit"}</button>
        <button type="button" className="btn-custom w-auto" data-bs-dismiss='modal' >
          Cancel
        </button>
      </div>
    </div>
  )
}


export default WorkpermitApplicationFrom