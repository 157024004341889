import React, { useEffect, useState } from "react";

import Sidebar from "../../../Layout/Sidebar";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAdminContext } from "../../../context/adminContext";
import { ImageUrl } from "../../../utils/BaseUrl";
import { deleteOfficesAndEmbassiesDetailsByIdApi, getAllOfficesAndEmbassiesDetailsByDocIdApi } from "../../../utils/ApiEndpointFunctions";
import toast from "react-hot-toast";
import Spinwheel from "../../common/Spinwheel";
import RemoveModal from "../../common/RemoveModal";
import Header from "../../common/Header";



export default function Offices() {
  const { adminDetail, allDocuments  } = useAdminContext();
  const [removeData,setRemoveData] = useState({});
  const [tableSpin, setTableSpin] = useState(true);
  const [allOffices, setAllOffices] = useState([])
  const param = useParams();
  const navigate = useNavigate();
  const getAllOffices = async () => {
    try {
      if(param?.id) {
        setTableSpin(false)
        const res = await getAllOfficesAndEmbassiesDetailsByDocIdApi(param?.id)
        if (res.status === 200) {
          setAllOffices(res.data?.data,)
        }
      }
      setTableSpin(true)
    } catch (error) {
      setTableSpin(true)
      console.log(error)
    }
  } 

  const removeHandler = async (e) => {
    e.preventDefault()
    try {
      const res = await deleteOfficesAndEmbassiesDetailsByIdApi(removeData);
      if(res.status === 200) {
        toast.success("Removed")
        getAllOffices();
      }
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(()=>{
    getAllOffices();
    if (!(allDocuments?.length > 0)) {
      navigate("/document")
    }
  },[])
  return (
    <>
      {/*<Sidebar />*/}
      <div className="main-content vh-100 vh_md-auto overflow-y-a">
        
        <Header title="Document" />
       
        {/* CARDS */}
        <div className="container-fluid">
          <div className="row">
            <nav className="mb-5">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">
                    <i className="fe fe-home"></i> Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/document" className="breadcrumb-item">
                    Document
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Offices &amp; embassies
                </li>
              </ol>
            </nav>
            <div className="d-flex justify-content-between mb-4 flex-wrap">
              <h1 className="header-title">{allDocuments?.[0]?.docName}</h1>
              <div style={{ display: "-webkit-inline-box" }}>
                <div className="dropdown show">
                  <div className="dropdown me-3"><button className="btn btn-second dropdown-toggle" style={{ padding: "7px 10px" }} type="button" data-bs-toggle="dropdown" aria-expanded="false">Add</button>
                    <ul className="dropdown-menu" style={{ marginTop: "-10px" }}>
                      <li><Link className="dropdown-item" to={"/document/OfficesAndEmbassies/add"} >Offices &amp; embassies</Link></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <hr />
          </div>
          <div className="card p-3 shadow table-responsive" >
            <table className="table table-sm table-hover table-nowrap card-table">
              <thead>
                <tr>
                  <th>
                    <span
                      className="list-sort text-muted"
                    >
                      S.No
                    </span>
                  </th>
                  <th>
                    <span
                      className="list-sort text-muted"
                    >
                      Name
                    </span>
                  </th>
                  <th colSpan={2}>
                    <span
                      className="list-sort text-muted"
                    >
                      Address
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody className="list fs-base">
                {
                  tableSpin ?
                  allOffices?.length > 0 ?
                      allOffices?.map?.((e, i) => {
                      return <tr key={i}>
                        <td>{i + 1}</td>
                        <td>
                          {/* Avatar */}
                          <div className="avatar avatar-xs align-middle me-2">
                            <img
                              className="avatar-img rounded-circle"
                              src={e?.imageLogo ? ImageUrl + encodeURIComponent(e?.imageLogo) : process.env.PUBLIC_URL + "/images/person-circle.svg"}
                              alt=""
                            />
                          </div>
                          <span
                            className="item-name text-reset"
                          >
                            {e?.name}
                          </span>
                        </td>
                        <td >
                          <span
                            className="item-name text-reset"
                          > {e?.address ? <div type="button" data-toggle="tooltip" data-placement="left" >
                            {e?.address}
                          </div> : "-"}
                          </span>
                        </td>
                        <td className="text-end">
                          <div className="dropdown">
                            <Link
                              className="dropdown-ellipses dropdown-toggle"
                              role="button"
                              data-bs-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <i className="fe fe-more-vertical" />
                            </Link>
                            <div className="dropdown-menu dropdown-menu-end">
                              <Link to="/document/OfficesAndEmbassies/update" state={{data:e}} className="dropdown-item" >
                                Edit
                              </Link>
                              <div onClick={() => { setRemoveData({ id: e?._id, myDocumentId: e?.myDocumentId }) }} data-bs-toggle="modal" data-bs-target="#exampleModal" className="dropdown-item" style={{ cursor: "pointer" }}>
                                Remove
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    }) : <tr align="center">
                      <td colSpan="3">
                        <h1> Data Not Found </h1>
                      </td>
                    </tr>
                    : <tr align="center">
                      <td colSpan="4">
                        <Spinwheel />
                      </td>
                    </tr>
                }
              </tbody>
            </table>
          </div>
          <div className='d-flex justify-content-center'>
          </div>
        </div>
      </div>
      <RemoveModal removeHandler={removeHandler} modalId="exampleModal" message={<>Are you sure you want to<br /> remove this?</>} />
    </>
  );
}