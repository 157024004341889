import { createContext, useContext, useState } from "react";

export const adminContext = createContext({
    adminDetail: null

});

export function AdminContextProvider({ children }) {
    const [adminDetail, setAdminDetail] = useState(null);
    const [vendorDetail, setVendorDetail] = useState(null);
    const [allDocuments, setAllDocuments] = useState([]);
    const [updateJob,setUpdateJob] = useState({});
    const [shopDetail,setShopDetail]= useState();

    return (
        <adminContext.Provider value={{
            adminDetail,
            setAdminDetail,
            
            allDocuments,
            setAllDocuments,

            updateJob,
            setUpdateJob,

            vendorDetail,
            setVendorDetail,

            shopDetail,
            setShopDetail,

        }}>
            {children}
        </adminContext.Provider>
    );
}

export function useAdminContext() {
    return useContext(adminContext);
}