import { useFormik } from "formik";
import JoditEditor from "jodit-react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import toast from "react-hot-toast";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { addChaptersByCourseIdApi, updateCourseChaptersApi, uploadImage } from "../../../../utils/ApiEndpointFunctions";
import { ImageUrl } from "../../../../utils/BaseUrl";
import Header from "../../../common/Header";
import Spinwheel from "../../../common/Spinwheel";
import { addChapterIntialValue, addChapterValidationSchema, } from "../../../common/Validation";

function AddChapter() {
  const navigate = useNavigate()
  const [addSpin, setAddSpin] = useState(false)
  const [videoUpload, setVideoUpload] = useState(null);
  const location = useLocation();
  const [fileTypeError, setFileTypeError] = useState({ image: "", videos: "" })
  let path = location.pathname
  path = path.split("/")?.filter((e) => {
    if (e === "add" || e === "update") {
      return true
    }
    return false;
  })[0]
  path = (path[0].toUpperCase() + path.slice(1));
  const param = useParams();

  const editor = useRef(null);
  const config = useMemo(() => {
    return {
      disablePlugins: ['Find', "Preview", "fullsize", "Change", "About", 'Print', "image", "file", "spellcheck", "selectall", "copyformat", "table", "hr", "symbols", "lineHeight", "classSpan", "paragraph"],
      removeButtons: ['undo', 'redo', 'Print', 'find', "video", "cut", "copy", "paste", "image", "source", "symbols", "lineHeight", "selectall", "paragraph", "superscript", "subscript"],
      readonly: false,
      placeholder: 'Start writing...',
      askBeforePasteFromWord: false,
      askBeforePasteHTML: false,
      enter: "br",
      hidePoweredByJodit: true

    }
  }, []
  );


  const initialValues = {
    courseId: location?.state?.courseId,
    courseChapter: location?.state?.courseChapter?.map?.((e) => {
      return {
        type: e.type,
        chapterTitle: e.chapterTitle,
        thumbnail: e.thumbnail,
        videos: e.videos,
        content: e.content,
      }
    })
  }
  const handleSubmit = async (values) => {
    try {
      let res
      if (path === "Add") {
        setAddSpin(true)
        res = await addChaptersByCourseIdApi(values)
      } else {
        setAddSpin(true)
        res = await updateCourseChaptersApi(values);
      }
      if (res?.status === 200) {
        toast.success(res.data?.message)
        navigate(`/course/chapter/${values?.courseId}`, { replace: true })
      }
      setAddSpin(false)
    } catch (error) {
      error?.response?.data?.message && toast.error(error?.response?.data?.message);
      setAddSpin(false)
    }
  }

  const handleVideo = async (index, file) => {
    try {
      setVideoUpload(index)
      const types = ['video/mp4', 'video/avi', 'video/mov', 'video/webm'];
      if (file) {
        if (types.includes(file.type)) {
          setFileTypeError({ ...fileTypeError, video: "" });
          const formData = new FormData();
          formData.append("file", file);
          formData.append("type", 4)
          formData.append("folder", "chapter")
          const res = await uploadImage('/users/fileUpload', formData)
          handleChapterBlur(index, "videos");
          if (res.status === 200 && res.data?.status === 1) {
            formik.setFieldValue(`courseChapter[${index}].videos`, res.data?.data?.fileName);
            formik.setFieldValue(`courseChapter[${index}].videoTimeDuration`, res.data?.data?.videoTimeDuration);
            toast.success("File uploaded");
          }
        } else {
          formik.setFieldValue("videos", "");
          setFileTypeError({ ...fileTypeError, video: "Must be video only" })
          handleChapterBlur(index, "videos");
        }
      }
      setVideoUpload(null);
    } catch (error) {
      setVideoUpload(null);
      handleChapterBlur(index, "videos");
      console.log(error);
      if (error?.response === undefined) {
        toast.error("File size is too large")
      }
    }

  }

  const handleThumbnail = async (index, file) => {
    const types = ['image/jpeg', 'image/png', 'image/gif']
    setFileTypeError({ ...fileTypeError, image: "" });
    if (file) {
      if (types.includes(file.type)) {
        setFileTypeError({ ...fileTypeError, image: "" });
        const formData = new FormData();
        formData.append("file", file);
        formData.append("type", 3);
        formData.append("folder", "chapter")
        const res = await uploadImage('/users/fileUpload', formData)
        handleChapterBlur(index, "thumbnail")
        if (res.status === 200 && res.data?.status === 1) {
          formik.setFieldValue(`courseChapter[${index}].thumbnail`, res.data?.data?.fileName);
          toast.success("File uploaded");
        }
      } else {
        formik.setFieldValue(`courseChapter[${index}].thumbnail`, "");
        setFileTypeError({ ...fileTypeError, image: "Must be image only" });
      }
    }
  }

  const chapterAdd = (e) => {
    e.preventDefault();
    formik.setFieldValue("courseChapter", [...formik.values?.courseChapter, {
      type: "video",
      chapterTitle: "",
      thumbnail: "",
      videos: "",
      content: "",
    }])
  }
  const chapterRemove = (e, index,name) => {
    e.preventDefault()
  
    formik.setFieldValue(name, formik.values.courseChapter?.filter?.((ele, i) => {
      if (i === index) return false;
      return true;
    }))
  }

  const handleChapterChange = (index, fieldName, value) => {
    formik.setFieldValue(`courseChapter[${index}].${fieldName}`, value)
  }

  const handleChapterBlur = (index, fieldName) => {
    formik.setFieldTouched(`courseChapter[${index}].${fieldName}`, true)
  }


  useEffect(() => {
    if ((path === "Update") && location.state == null) {
      navigate("/course/chapter");
    }
  }, [])

  const formik = useFormik({
    initialValues: location?.state ? initialValues : { ...addChapterIntialValue, courseId: param.id },
    onSubmit: handleSubmit,
    validationSchema: addChapterValidationSchema,
  });
  return (
    <>
      {/*<Sidebar />*/}
      <div className="main-content vh-100 vh_md-auto overflow-y-a">
        <Header title="Study" />
        <div className="container-fluid">
          <div className="row">
            <nav className="mb-5">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">
                    <i className="fe fe-home"></i> Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/course" className="breadcrumb-item">
                    Course
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {path}-chapter
                </li>
              </ol>
            </nav>
            <div>
              <h2 className="report">Chapter details</h2>
            </div>
            <hr />

            <div className="col-lg-10" style={{ zIndex: "1" }}>
              <form action="" onSubmit={formik.handleSubmit} >
                {/* <label
                  className="form-label text-start d-block"
                  htmlFor="companyId"
                >
                  Course
                </label>
                <select name="course" id="type" className="form-select form-control" value={formik.values.course} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                  <option value="apna course">Select course</option>
                </select>
                {formik.touched.course && formik.errors.course ? (<div className='req-error'>{formik.errors.course}</div>) : null}
                <br /> */}
                {formik.values.courseChapter?.map((ele, index) =>
                  <div key={index}>
                    <div className=" text-end mb-3">
                      {
                        formik.values.courseChapter?.length === (index + 1) &&
                        <button type="button" className="me-3 plusButton" onClick={chapterAdd}><span>+</span></button>
                      }

                      {
                        formik.values.courseChapter?.length > 1 && <button type="button" className=" plusButton" onClick={(e) => { chapterRemove(e, index,"courseChapter") }}><span>-</span></button>
                      }
                    </div>
                    <div className="card ">
                      <div className="card-body" style={{ backgroundColor: '#ebe3d93b' }}>
                        <div className="row mb-3">
                          <div className="col-lg-6">
                            <label
                              className="form-label text-start d-block"
                              htmlFor={`type${index}`}
                            >
                              Type
                            </label>
                            <select name="type" id={`type${index}`} className="form-select form-control border" value={formik.values?.courseChapter?.[index]?.type} onChange={(e) => { handleChapterChange(index, "type", e.target.value) }} onBlur={() => { handleChapterBlur(index, "type") }} >
                              <option value="article">Article</option>
                              <option value="video">Videos</option>
                            </select>
                            {formik.touched?.courseChapter?.[index]?.type && formik.errors?.courseChapter?.[index]?.type ? (<div className='req-error'>{formik.errors?.courseChapter?.[index]?.type}</div>) : null}
                          </div>
                          <div className="col-lg-6">
                            <label htmlFor={`title${index}`} className="form-label">Chapter name</label>
                            <div className="position-relative">
                              <input className="form-control border" type="text" id={`title${index}`} placeholder="Enter title" name="chapterTitle" value={formik.values?.courseChapter?.[index]?.chapterTitle} onChange={(e) => { handleChapterChange(index, "chapterTitle", e?.target?.value) }} onBlur={() => { handleChapterBlur(index, "chapterTitle") }} />
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-house-door-fill input-icon " viewBox="0 0 16 16">
                                <path d="M0 1.5V13a1 1 0 0 0 1 1V1.5a.5.5 0 0 1 .5-.5H14a1 1 0 0 0-1-1H1.5A1.5 1.5 0 0 0 0 1.5z" />
                                <path d="M3.5 2A1.5 1.5 0 0 0 2 3.5v11A1.5 1.5 0 0 0 3.5 16h6.086a1.5 1.5 0 0 0 1.06-.44l4.915-4.914A1.5 1.5 0 0 0 16 9.586V3.5A1.5 1.5 0 0 0 14.5 2h-11zm6 8.5a1 1 0 0 1 1-1h4.396a.25.25 0 0 1 .177.427l-5.146 5.146a.25.25 0 0 1-.427-.177V10.5z" />
                              </svg>
                              {formik.touched?.courseChapter?.[index]?.chapterTitle && formik.errors?.courseChapter?.[index]?.chapterTitle ? (<div className='req-error'>{formik.errors?.courseChapter?.[index]?.chapterTitle}</div>) : null}
                            </div>
                          </div>
                        </div>
                        <div className="row mb-4">
                          <div className="">
                            <label htmlFor={`thumbnail1${index}`} className="form-label">Thumbnail image</label>
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="position-relative">
                                {<img src={ele?.thumbnail ? ImageUrl + ele?.thumbnail : process.env.PUBLIC_URL + "/images/image.svg"} width="70px" height="50px"  style={{ objectFit: "contain" }} className="rounnded" alt="Selected file" />}
                                {ele.type === "video" && <>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-camera pro-camera" viewBox="0 0 16 16">
                                    <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1v6zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z" />
                                    <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
                                  </svg>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x xmark" viewBox="0 0 16 16" onClick={() => { }} >
                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                  </svg>
                                </>}
                              </div>
                              <div className="btn_upload">
                                <input type="file" id={`thumbnail1${index}`} className="form-control" name="thumbnail" accept="image/*" onChange={(e) => { handleThumbnail(index, e.target.files[0]) }} />
                                Upload
                              </div>
                            </div>
                            <br />
                            {formik.touched?.courseChapter?.[index]?.thumbnail && (formik.errors?.courseChapter?.[index]?.thumbnail || fileTypeError.image?.trim()?.length > 0) ? (<div className='req-error'>{fileTypeError.image?.trim()?.length > 0 ? fileTypeError.image : formik.errors?.courseChapter?.[index]?.thumbnail}</div>) : null}
                          </div>
                          <div className="">
                            {ele?.type === "video" && <>
                              <label htmlFor={`video1${index}`} className="form-label">Upload video</label>
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="position-relative">
                                  <div width="100px" height="70px">
                                    {ele?.videos ? <video width="100px" height="70px" className="rounded" >
                                      <source src={ImageUrl + ele?.videos} type="video/mp4"></source>
                                    </video> : <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-camera-reels" viewBox="0 0 16 16">
                                      <path d="M6 3a3 3 0 1 1-6 0 3 3 0 0 1 6 0zM1 3a2 2 0 1 0 4 0 2 2 0 0 0-4 0z" />
                                      <path d="M9 6h.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 7.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 16H2a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h7zm6 8.73V7.27l-3.5 1.555v4.35l3.5 1.556zM1 8v6a1 1 0 0 0 1 1h7.5a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1z" />
                                      <path d="M9 6a3 3 0 1 0 0-6 3 3 0 0 0 0 6zM7 3a2 2 0 1 1 4 0 2 2 0 0 1-4 0z" />
                                    </svg>}
                                  </div>
                                </div>
                                <div className="btn_upload">
                                  <input type="file" id={`video1${index}`} className="form-control" name="videos" accept="video/*" onChange={(e) => { handleVideo(index, e.target.files[0]) }} />
                                  {videoUpload === index ? "Uploading" : "Upload"}
                                </div>
                              </div>
                              <br />
                              {formik.touched?.courseChapter?.[index]?.videos && formik.errors?.courseChapter?.[index]?.videos ? (<div className='req-error'>{fileTypeError.videos?.trim()?.length > 0 ? fileTypeError.videos : formik.errors?.courseChapter?.[index]?.videos}</div>) : null}
                            </>}
                          </div>
                        </div>
                        
                        <label htmlFor="description" className="form-label">Discription</label>
                        <div className="position-relative">
                          <JoditEditor
                            ref={editor}
                            value={formik.values?.courseChapter?.[index]?.content}
                            onBlur={(e) => {handleChapterBlur(index,"content"); handleChapterChange(index, "content", e) }}
                            config={config}
                          />
                          {formik.touched?.courseChapter?.[index]?.content && formik.errors?.courseChapter?.[index]?.content ? (<div className='req-error'>{formik.errors?.courseChapter?.[index]?.content}</div>) : null}
                        </div>
                      </div>
                    </div></div>)}
                <br />
                <div className="text-center d-flex justify-content-around">
                  <button type="submit" className="btn-custom w-auto mt-2" disabled={addSpin} >{addSpin ? <Spinwheel sizeClass="spin-small" /> : "Save"}</button>
                  <div className="btn-custom w-auto mt-2" onClick={() => { window.history.back(); }} >Cancel</div>
                </div>
              </form>
              <br />
            </div>
            <div className="col-lg-8"></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddChapter

