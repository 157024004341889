import React from 'react'

function AddMessageModal({formik,type}) {
  return (
    <>
      <div className="modal fade" id="addMessage" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
        <div className="modal-dialog modal-sm modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0">
              <button type="button" className="btn-close block-close" data-bs-dismiss="modal" aria-label="Close" ></button>
            </div>
            <div className="modal-body block-user">
              <form action="" className="text-start" onSubmit={formik.handleSubmit} >
                <label htmlFor="name" className="form-label">Message</label>
                <div className="position-relative">
                  <textarea className="form-control border" id='name' name="name" value={formik?.values?.name} onChange={formik?.handleChange} onBlur={formik?.handleBlur} placeholder="Enter your message here" ></textarea>
                  {formik.touched.name && formik.errors.name ? (<div className='req-error'>{formik.errors.name}</div>) : null}
                </div>
                <div className="modal-footer justify-content-center ">
                  <button type="submit" className="btn-custom w-auto mt-2" data-bs-dismiss={Object.keys(formik?.errors).length > 0 ? '' : 'modal'} onClick={() => { }} >
                    Submit
                  </button>
                  <button type="button" className="btn-custom w-auto mt-2" data-bs-dismiss='modal' >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddMessageModal