import { useFormik } from 'formik';
import moment from 'moment';
import React, { useState } from 'react'
import ReactDatePicker from 'react-datepicker';
import Spinwheel from '../../../common/Spinwheel';
import { NationalIdFormIntialvalue, NationalIdFormValidation } from '../../../common/Validation';
import Attachment from '../../../common/Attachment';

function NationalIdForm({ formik, documentsFormik, addDocLoader, docSpin, handlefile, submitHandler }) {
  
  return (
    <div>
    <div className="row row-cols-2">
      <div>
        <div className='d-flex'>
          <label htmlFor="nrcNumber" className="form-label">NRC number</label>
        </div>
        <div className="position-relative">
          <input className="form-control border border-1" type="text" id="nrcNumber" placeholder="Enter" name="nrcNumber" value={formik.values?.nrcNumber} onChange={formik.handleChange} onBlur={formik.handleBlur} />
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-text input-icon" viewBox="0 0 16 16">
            <path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5M5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1z" />
            <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1" />
          </svg>
          {formik.touched.nrcNumber && formik.errors.nrcNumber ? (<div className='req-error'>{formik.errors.nrcNumber}</div>) : null}
        </div>
      </div>

      <div>
        <div className='d-flex'>
          <label htmlFor="name" className="form-label">Name</label>
        </div>
        <div className="position-relative">
          <input className="form-control border border-1" type="text" id="name" placeholder="Enter " name="name" value={formik.values?.name} onChange={formik.handleChange} onBlur={formik.handleBlur} />
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-text input-icon" viewBox="0 0 16 16">
            <path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5M5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1z" />
            <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1" />
          </svg>
          {formik.touched.name && formik.errors.name ? (<div className='req-error'>{formik.errors.name}</div>) : null}
        </div>
      </div>

      <div>
        <div className='d-flex'>
          <label htmlFor="fatherName" className="form-label">Father name</label>
        </div>
        <div className="position-relative">
          <input className="form-control border border-1" type="text" id="fatherName" placeholder="Enter" name="fatherName" value={formik.values?.fatherName} onChange={formik.handleChange} onBlur={formik.handleBlur} />
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-text input-icon" viewBox="0 0 16 16">
            <path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5M5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1z" />
            <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1" />
          </svg>
          {formik.touched.fatherName && formik.errors.fatherName ? (<div className='req-error'>{formik.errors.fatherName}</div>) : null}
        </div>
      </div>

      <div>
        <label htmlFor="dob" className="form-label">Date of birth</label>
        <div className="position-relative">
          <ReactDatePicker
              selected={formik.values.dob ? new Date(formik.values.dob) : ""}
            name='dob'
            id="dob"
            onChange={(e) => {
              e ?
                formik.setFieldValue("dob", e) :
                formik.setFieldValue("dob", "")
            }}
            // onFocus={}
              onBlur={(e) => { formik.handleBlur(e) }}
            className='form-control w-100  border border-1'
            placeholderText='Pickup date'
            autoComplete="off"
            dateFormat={"dd/MM/yyyy"}
            maxDate={new Date()}
          />
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar input-icon" viewBox="0 0 16 16">
            <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z" />
          </svg>

          {formik.touched.dob && formik.errors.dob ? (<div className='req-error'>{formik.errors.dob}</div>) : null}
        </div>
      </div>

      <div>
        <label htmlFor="bloodGroup" className="form-label">Blood group</label>
          <select name="bloodGroup" className='form-select  border border-1' id="bloodGroup" onChange={formik.handleChange} onBlur={formik.handleBlur}>
        <option value="">Select blood group</option>
        <option value="A+">A+</option>
        <option value="A-">A-</option>
        <option value="B+">B+</option>
        <option value="B-">B-</option>
        <option value="AB+">AB+</option>
        <option value="AB-">AB-</option>
        <option value="O+">O+</option>
        <option value="O-">O-</option>
        </select>
          {formik.touched.bloodGroup && formik.errors.bloodGroup ? (<div className='req-error'>{formik.errors.bloodGroup}</div>) : null}
      </div>

      <div>
        <label htmlFor="gender" className="form-label">Gender</label>
          <select name="gender" className='form-select  border border-1' id="gender" onChange={formik.handleChange} onBlur={formik.handleBlur}>
          <option value="">Select gender</option>
          <option value="male">Male</option>
          <option value="female">Female</option>
          <option value="other">Other</option>
        </select>
          {formik.touched.gender && formik.errors.gender ? (<div className='req-error'>{formik.errors.gender}</div>) : null}
      </div>


      <div>
        <div className='d-flex'>
          <label htmlFor="phoneNumber" className="form-label">Phone number</label>
        </div>
        <div className="position-relative">
          <input className="form-control border border-1" type="text" id="phoneNumber" placeholder="Enter name" name="phoneNumber" value={formik.values?.phoneNumber} onChange={formik.handleChange} onBlur={formik.handleBlur} />
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-text input-icon" viewBox="0 0 16 16">
            <path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5M5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1z" />
            <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1" />
          </svg>
          {formik.touched.phoneNumber && formik.errors.phoneNumber ? (<div className='req-error'>{formik.errors.phoneNumber}</div>) : null}
        </div>
      </div>

      <div>
        <div className='d-flex justify-content-start'>  <label htmlFor="address" className="form-label"> Address</label></div>
          <textarea id="address" name='address' class="form-control  border border-1" placeholder="Enter.." value={formik?.values?.address} onBlur={formik.handleBlur} onChange={formik?.handleChange} ></textarea>
          {formik.touched.address && formik.errors.address ? (<div className='req-error'>{formik.errors.address}</div>) : null}
      </div>
    </div>

      <Attachment formik={formik} docSpin={docSpin} handlefile={handlefile} />

      <div className="modal-footer border-0 justify-content-center pt-4">
        <button type="button" className="btn-custom w-auto" disabled={addDocLoader} onClick={(e) => { e.preventDefault(); formik.handleSubmit(); }}  >{addDocLoader ? <Spinwheel sizeClass="spin-small" /> : "Submit"}</button>
        <button type="button" className="btn-custom w-auto" data-bs-dismiss='modal' >
          Cancel
        </button>
      </div>
    </div>
  )
}


export default NationalIdForm