import React, { useEffect, useMemo, useState } from 'react'
import RemoveModal from '../../common/RemoveModal';
import Select from 'react-select';
import { createAndUpdateDocumentApplicationApi, deleteUserDocsApplicationApi, deleteUserUploadDocumentApi, getCategoryFeildDataApi, getDocsCategoryByTypeApi, getDocsCategorytypeApi, getDocumentByCategoryIdApi, uploadImage } from '../../../utils/ApiEndpointFunctions';
import { Link, useParams } from 'react-router-dom';
import Attachment from '../../common/Attachment';
import toast from 'react-hot-toast';
import { applicationFeildRowData } from '../../common/Validation'
import CommonTable from '../../common/table/CommonTable';
import { useFormik } from 'formik';
import Spinwheel from '../../common/Spinwheel';
import { ImageUrl } from '../../../utils/BaseUrl';
import ReactDatePicker from 'react-datepicker';
import CreatableSelect from 'react-select/creatable';

const FormFeildRender = ({ ele, formik, docSpin, handlefile }) => {

  const addOrRemovePhone = (ind, buttonType) => {
    if (buttonType === "plus") {
      formik.setFieldValue(ele.name, [...formik.values[ele.name], ele.feilds])
    } else if (buttonType === "minus") {
      formik.setFieldValue(ele.name, formik.values[ele.name]?.filter((_, i) => i !== ind))
    }
  }
  console.log(ele)
  switch (ele.type) {
    case "input":
      if (ele.inputType === "file") {
        return <div key={ele.name}>
          <div className='d-flex'>
            <label htmlFor={ele?.name} className="form-label">{ele.label}</label>
          </div>
          <div className="position-relative">
            <Attachment formik={formik} docSpin={docSpin} feildName={ele.name} handlefile={(e) => { handlefile(e, formik, ele.name) }} />
            {/* {formik?.touched?.[ele?.name] && formik?.errors?.[ele?.name] ? (<div className='req-error'>{formik?.errors?.[ele?.name]}</div>) : null} */}
          </div>
        </div>
      } else {

        if (formik.values?.["nationlityType"] === "thai" && (ele.name === "passportNumber" || ele.name === "nationality")) return null;
        if (formik.values?.["nationlityType"] === "foreigner" && ele.name === "thaiNationalIdNo") return null;

        return <div key={ele.name}>
          <div className='d-flex'>
            <label htmlFor={ele.name} className="form-label">{ele.label}</label>
          </div>
          <div className="position-relative">
            <input className="form-control border border-1" type="text" id={ele.name} placeholder="Enter" name={ele.name} value={formik.values?.[ele.name]} onChange={formik.handleChange} onBlur={formik.handleBlur} />
            
            {formik.touched?.[ele.name] && formik.errors?.[ele.name] ? (<div className='req-error'>{formik.errors?.[ele.name]}</div>) : null}
          </div>
        </div>
      }
    case "textarea":
      return <textarea className="form-control" placeholder={ele.placeholder} />
    case "select":
      return <div>
        <div className='d-flex'>
          <label htmlFor={ele?.name} className="form-label">{ele.label}</label>
        </div>
        <select className="form-control border border-2" placeholder={ele.placeholder} id={ele.name} name={ele.name} value={formik.values?.[ele.name]} onChange={formik.handleChange} onBlur={formik.handleBlur} >
          {ele.options.map((item) => <option key={item.value} value={item.value}>{item.name}</option>)}
        </select>
      </div>

    case 'react-select' :

      return <div className="">
        <label
          className="form-label text-start d-block"
          htmlFor="size"
        >
          {ele.name}
        </label>
        <CreatableSelect
          isMulti
          name="colors"
          value={formik.values?.[ele?.name]?.map(ele => ({ label: ele, value: ele }))}
          className="basic-multi-select"
          classNamePrefix="select"
          onChange={(e) => {
            console.log(e, "values", ele?.name)
            formik.setFieldValue(ele?.name, e?.map(ele => ele.value));
          }}
        />
      </div>
  
    case 'date' :     
      return <div>
        <label htmlFor="dob" className="form-label">{ele.name}</label>
        <div className="position-relative">
          <ReactDatePicker
            selected={formik.values?.[ele.name] ? formik.values?.[ele.name] : ""}
            name={ele.name}
            id={ele.name}
            onChange={(e) => {
              e ?
                formik.setFieldValue(ele.name, e) :
                formik.setFieldValue(ele.name, "")
            }}
            onBlur={formik.handleBlur}
            className='form-control w-100 border border-1'
            placeholderText='Pickup date'
            autoComplete="off"
            dateFormat={"dd/MM/yyyy"}
            maxDate={new Date()}
          />
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar input-icon" viewBox="0 0 16 16">
            <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z" />
          </svg>

          {formik.touched.dob && formik.errors.dob ? (<div className='req-error'>{formik.errors.dob}</div>) : null}
        </div>
      </div>
    case 'list' : 

      console.log(formik.values?.[ele.name], ele.name, ele.inputType)
      if (ele.name === "referenceData" ) {
        return <div>
          <hr />
          <label htmlFor="phone" className="form-label">{ele.label}</label>
          {formik.values?.[ele.name]?.map?.((ele1, ind2) => {
            console.log(ele1, ele.name)
            return <div key={ind2} className="position-relative mb-2">
              {/* <input className="form-control border border-1" type="tel" id="phone" placeholder="Enter your phone number" name={`phone[${index}]`} value={formik.values?.phone?.[index]} onChange={formik.handleChange} onBlur={formik.handleBlur} /> */}
              <div className="row row-cols-2">
                <div className="position-relative">
                  <input className="form-control border border-1" type="text" id={ele.name} placeholder="Enter" name={`${ele.name}[${ind2}].name`} value={formik.values?.[ele.name]?.[ind2]?.name} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                  {formik.touched?.[ele.name] && formik.errors?.[ele.name] ? (<div className='req-error'>{formik.errors?.[ele.name]}</div>) : null}
                </div>
                <div className="position-relative">
                  <Attachment formik={formik} docSpin={docSpin} feildName={ele.name} filePosition={ind2} fileName='file' handlefile={(e) => { handlefile(e, formik, `${ele.name}[${ind2}].file`) }} />
                  {formik?.touched?.[ele?.name] && formik?.errors?.[ele?.name] ? (<div className='req-error'>{formik?.errors?.[ele?.name]}</div>) : null}
                </div>
              </div>              
                
              {formik?.values?.[ele.name]?.length - 1 === ind2 && formik?.values?.[ele.name]?.length < 5 && <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style={{ position: "absolute", right: Boolean(formik?.values?.phone?.length - 1) ? '-50px' : "-25", top: "15px" }} class="bi bi-plus-square" viewBox="0 0 16 16" onClick={() => { addOrRemovePhone(ind2, "plus") }}>
                <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
              </svg>}
              {Boolean(formik?.values?.[ele.name]?.length - 1) && <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style={{ position: "absolute", right: '-25px', top: "15px" }} class="bi bi-dash-square" viewBox="0 0 16 16" onClick={() => { addOrRemovePhone(ind2, "minus") }}>
                <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
                <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8" />
              </svg>}
              {formik.touched?.phone?.[ind2] && formik.errors?.phone?.[ind2] ? (<div className='req-error'>{formik.errors?.phone?.[ind2]}</div>) : null}
              <div className="d-flex justify-content-end ">
                <div>
                </div>
              </div>
            </div>
          })}
        </div>
      }
   

    default:
      return null
  }
}


function DocumentApplicationTable() {
  const [isEdit, setIsEdit] = useState(false)
  const [categoryType, setCategoryType] = useState([]);
  const [category, setCategory] = useState([])
  const [selectCategory, setSelectedCategory] = useState({});
  const [selectType, setSelectType] = useState({})
  const params = useParams();
  const [categoryFeildData, setCategoryFeildData] = useState([]);
  const [id, setId] = useState(params?.id)
  const [docSpin, setDocSpin] = useState(true);
  const [tableHeader, setTableHeader] = useState([])
  const [tableRowData, setTableRowData] = useState([])
  const [addDocLoader, setAddDocLoader] = useState(false)
  const [removeData, setRemoveData] = useState({});

  const getDocsCategorytype = async () => {
   try {
     const res = await getDocsCategorytypeApi();
     if (res.status === 200) {
   
       const resultedData = res.data?.data?.types?.map(ele => ({label:ele.type,value:ele.type}))
       setCategoryType(resultedData);
       setSelectType(resultedData[0]);

     }
   } catch (error) {
    console.log(error)
   }
  }

  const getDocsCategory = async (data) => {
    try {
      const res = await getDocsCategoryByTypeApi(data.label);
      if (res.status === 200) {
        const resultData = res.data?.data?.categories?.map(ele => ({label: ele.name, value: ele}))
        setCategory(resultData)
        setSelectedCategory(resultData[0].value)
      }
    } catch (error) {
      console.log(error)
    }
  }


  const typeSelectHandler = (data, type) => {
    if(type === "type") setSelectType(data)
    else setSelectedCategory(data.value)
  }

  const handlefile = async (e, formik, feildName) => {
    try {

      console.log(formik, feildName, "values")
      const file = e.target.files[0];
      if (file) {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("type", 2)
        formData.append("folder", "document")
        const res = await uploadImage('/users/fileUpload', formData)
        if (res.status === 200 && res.data?.status === 1) {
          formik.setFieldValue(feildName, res.data?.data?.fileName)
          toast.success("File uploaded")
        }
      }
    } catch (error) {
      console.log(error)
    }

  }  

 

  const getDocumentByCategoryId = async () => {
    try {
      setDocSpin(true)
      const res = await getDocumentByCategoryIdApi(id, selectCategory._id);
      if (res.status === 200) {
        tableRowDataHandler(res.data?.data?.allDocuments)
      }
     
    } catch (error) {
      console.log(error)
    }
    setDocSpin(false)
  }

  const removeHandler = async () => {
    try {
      const res = await deleteUserDocsApplicationApi(removeData?._id);
      if (res.status === 200) {
        toast.success(res.data?.message)
        getDocumentByCategoryId();
      }
    } catch (error) {
      console.log(error)
      toast.error("Something went wrong")
    }
  }

  

  useEffect(() => {
    if(id) {
      getDocsCategorytype();
    }
  }, [id])

  useEffect(() => {
    if(selectType.label) {
      getDocsCategory(selectType)
     
    }
  }, [selectType])

  const formSubmitHandler = async (values) => {
    try {
      const payload = {...values, userId:id, categoryId: selectCategory?._id}
      console.log(payload, "values", "payload")
      return
      return
      setAddDocLoader(true)
      const res = await createAndUpdateDocumentApplicationApi(payload)
      if(res.status === 200) {
        toast.success(res.data?.message)
        getDocumentByCategoryId();
        document.getElementById("ManageStatusModal")?.click();
      }
    } catch (error) {
      console.log(error)
      toast.error("Something went wrong")
    }
    setAddDocLoader(false)
  }

  const formFormik = useFormik({
    initialValues: applicationFeildRowData[selectCategory.name]?.initialValue,
    validationSchema: applicationFeildRowData[selectCategory.name]?.validation,
    enableReinitialize: true,
    onSubmit: formSubmitHandler
  })

  console.log("values", formFormik.values, formFormik.errors)

  useEffect(() => {
    if(selectCategory?.name) {  
      const currenFeilds = applicationFeildRowData[selectCategory?.name]?.feilds
      setCategoryFeildData(currenFeilds)
     
      setTableHeader([...(currenFeilds ? currenFeilds?.map?.(ele => {
        return {key: ele.name, name: ele.label}
      }): []), {key: "action", name: ""}])

      getDocumentByCategoryId()

    }
  },[selectCategory])

  function tableRowDataHandler(arr = []) { 
    try {
      const currenFeilds = applicationFeildRowData[selectCategory?.name].feilds;

      const result = arr.map(ele => {
        let obj = {};
        console.log(ele)

         currenFeilds?.forEach((el,index, ar) => {
          console.log(el)
          if(el.inputType === "file") {
            obj[el.name] = <div className='position-relative'>
              {ele?.[el.name] ? (
                <div className="tooltip1">
                  <Link to={ImageUrl + ele?.[el.name]} target="__blank">{ele?.[el.name]?.split("/")[1]?.substr?.(0, 20)}...</Link>
                  <div className='wrapper'>
                    <div className="tooltip1-text">{ele?.[el.name]?.split("/")[1]}</div>
                  </div>
                </div>
              ) : (
                "-"
              )}
            </div>
          } else {
            obj[el.name] = <div className='position-relative'>
              {ele?.[el.name] ? (
                <div className="tooltip1">
                  <td>{ele?.[el.name]?.substr?.(0, 20)}</td>
                  <div className='wrapper'>
                    <div className="tooltip1-text">{ele?.[el.name]}</div>
                  </div>
                </div>
              ) : (
                "-"
              )}
            </div>
          }

          if((ar.length - 1) == index) {
            obj["action"] = <td className="text-end">
              <div className="dropdown">
                <span
                  className="dropdown-ellipses dropdown-toggle"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="fe fe-more-vertical" />
                </span>
                <div className="dropdown-menu dropdown-menu-end">
                  <div data-bs-toggle="modal" data-bs-target="#ManageStatusModal" className="dropdown-item" style={{ cursor: "pointer" }} onClick={() => { formFormik.setValues(ele)  }} >
                    View/Edit
                  </div>
                  <div data-bs-toggle="modal" data-bs-target="#removedoc" className="dropdown-item" style={{ cursor: "pointer" }} onClick={() => { setRemoveData(ele) }} >
                    Remove
                  </div>
                </div>
              </div>
            </td>
          }
         
        }) 

        return obj
      })
      console.log(result)
      setTableRowData(result)

    } catch (error) {
      console.log(error)
    }
  }

  
  return (
    <><div className="card p-3  table-responsive" style={{ minHeight: "400px" }} >

      <div className='d-flex justify-content-between'>
        <div className='d-flex gap-3'>
          <div className="" style={{ minWidth: "250px" }}>
            <Select
              className="basic-single mb-2 w-100"
              classNamePrefix="select"
              isSearchable
              value={selectType?.label ? selectType : null}
              options={categoryType}
              onChange={(ele) => { typeSelectHandler(ele, "type") }}            />
          </div>
          <div className="" style={{ minWidth: "250px" }}>
            <Select
              className="basic-single mb-2 w-100"
              classNamePrefix="select"           
              isSearchable
              value={selectCategory ? { value: selectCategory, label: selectCategory?.name } : null}
              options={category}
              onChange={(ele) => { typeSelectHandler(ele, "category") }}
            />
          </div>
        </div>
        <div data-bs-toggle="modal" data-bs-target={`#ManageStatusModal`} className="btn-custom w-auto ms-0 mt-0 mb-3 " >Add</div>
      </div>
      <hr />

      <CommonTable headers={tableHeader} data={tableRowData} loading={docSpin}  />

      <div className="modal fade" id="ManageStatusModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0">
              <button type="button" className="btn-close block-close" data-bs-dismiss="modal" aria-label="Close" ></button>
            </div>
            <div className="modal-body ">
              <div className="col-lg-12" style={{ zIndex: "1" }}>
                <form action="" className='p-4' onSubmit={formFormik.handleSubmit} >
                  <h2 style={{ textAlign: "left" }}>
                    Document form
                  </h2>                  
                  <div className="row">                    
                    {categoryFeildData?.map((ele) => {                    
                      return <FormFeildRender ele={ele} handlefile={handlefile} formik={formFormik} docSpin={docSpin}  />
                    })}
                  </div>
                  <div className="modal-footer border-0 justify-content-center pt-4">
                    <button type="submit" className="btn-custom w-auto" disabled={addDocLoader}   >{addDocLoader ? <Spinwheel sizeClass="spin-small" /> : "Submit"}</button>
                    <button type="button" className="btn-custom w-auto" data-bs-dismiss='modal' >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <RemoveModal removeHandler={removeHandler} modalId="removedoc" message={<>Are you sure you want to<br /> remove this?</>} />
    </div></>
  )
}

export default DocumentApplicationTable