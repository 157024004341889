import React, { useEffect, useState } from "react";
import {
    BarChart,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    Bar,
} from "recharts";

import Sidebar from "../../../Layout/Sidebar";
import { useAdminContext } from "../../../context/adminContext";
import Header from "../../common/Header";
import { getTolalUserVendorAndActiveVendorApi } from "../../../utils/ApiEndpointFunctions";


const month = [
    { name: "Jan", number: 1 },
    { name: "Feb", number: 2 },
    { name: "Mar", number: 3 },
    { name: "Apr", number: 4 },
    { name: "May", number: 5 },
    { name: "Jun", number: 6 },
    { name: "Jul", number: 7 },
    { name: "Aug", number: 8 },
    { name: "Sep", number: 9 },
    { name: "Oct", number: 10 },
    { name: "Nov", number: 11 },
    { name: "Dec", number: 12 },
];



export default function Dashboard() {
    const { adminDetail } = useAdminContext()
    const [totalData, setTotalData] = useState();
    const [userBaseDate, setUserBaseDate] = useState([]);
    const [year, setYear] = useState();
    const [chartWidth, setChartWidth] = useState()

    const getTolalUserVendorAndActiveVendor = async () => {
        try {
            const res = await getTolalUserVendorAndActiveVendorApi();
            if (res.status === 200) {
                setTotalData({ allUsers: res.data?.data?.allUsers, allVendors: res.data?.data?.allVendors, activeVendors: res.data?.data?.activeVendors });
                setUserBaseDate(res.data?.data?.allUserBasedDate)
                if (res.data?.data?.allUserBasedDate?.length > 0) setYear(res.data?.data?.allUserBasedDate[res.data?.data?.allUserBasedDate?.length - 1]?.year)
            }
        } catch (error) {
            console.error(error)
        }
    }


    const formatteddata = () => {
        let data = month?.map((ele, index) => { return { name: ele.name, user: userBaseDate?.filter(ele => ele.year === year)[0]?.months?.filter(e => e.month == ele.number)[0]?.users } })
        return data?.map(ele => { return { name: ele.name, users: ele?.user ? ele.user : 0 } });
    }

    const handleWidthAndHight = () => {
        if (window.innerWidth >= 2317) {
            return 1100
        } else if (window.innerWidth >= 1911) {
            return 1000;
        } else if (window.innerWidth >= 1861) {
            return 900;
        } else if (window.innerWidth >= 1820) {
            return 840;
        } else if (window.innerWidth >= 1440) {
            return 680;
        } else if (window.innerWidth >= 1194) {
            return 550;
        } else if (window.innerWidth >= 1057) {
            return 700
        } else if (window.innerWidth >= 956) {
            return 600;
        } else if (window.innerWidth >= 768) {
            return 400
        } else {
            return 350;
        }
    }

    useEffect(() => {
        getTolalUserVendorAndActiveVendor();
        const handleResize = () => {
            setChartWidth(handleWidthAndHight(window.innerWidth))
        };
        window.addEventListener('resize', handleResize);

        setChartWidth(handleWidthAndHight(window.innerWidth))
        return () => {
            window.removeEventListener('resize', handleResize);
        };

    }, [])


    return (
        <>
            <Sidebar hightlight="dashboard" />
            <div className="main-content vh-100 vh_md-auto overflow-y-a">
                <Header title="Dashboard" />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 col-lg-6 col-xl">
                            
                            <div className="card card-bg">
                                <div className="card-body">
                                    <div className="row align-items-center gx-0">
                                        <div className="col">
                                            
                                            <h6 className="text-uppercase text-muted mb-2">
                                                TOTAL USER
                                            </h6>
                                            
                                            <span className="h2 mb-0">{totalData?.allUsers ? totalData?.allUsers : 0}</span>
                                        </div>
                                        <div className="col-auto">
                                            
                                            <span className="h2 fe text-muted mb-0">
                                                <img src="/images/totaluser.svg" alt="" />
                                            </span>
                                        </div>
                                    </div>{" "}
                                   
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 col-xl">
                            
                            <div className="card card-bg">
                                <div className="card-body">
                                    <div className="row align-items-center gx-0">
                                        <div className="col">
                                            
                                            <h6 className="text-uppercase text-muted mb-2">
                                                TOTAL VENDORS
                                            </h6>
                                          
                                            <span className="h2 mb-0">{totalData?.allVendors ? totalData?.allVendors : 0}</span>
                                        </div>
                                        <div className="col-auto">
                                            
                                            <span className="h2 fe text-muted mb-0">
                                                <img src="/images/totalvendor.svg" alt="" />
                                            </span>
                                        </div>
                                    </div>{" "}
                                    
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 col-xl">
                            {/* Exit */}
                            <div className="card card-bg">
                                <div className="card-body">
                                    <div className="row align-items-center gx-0">
                                        <div className="col">
                                            {/* Title */}
                                            <h6 className="text-uppercase text-muted mb-2">
                                                ACTIVE VENDORS
                                            </h6>
                                            {/* Heading */}
                                            <span className="h2 mb-0">{totalData?.activeVendors ? totalData?.activeVendors : 0}</span>
                                        </div>
                                        <div className="col-auto">
                                            {/* Icon */}
                                            <span className="h2 fe text-muted mb-0">
                                                <img src="/images/totalvendor.svg" alt="" />
                                            </span>
                                        </div>
                                    </div>{" "}
                                    {/* / .row */}
                                </div>
                            </div>
                        </div>
                    </div>{" "}
                    {/* / .row */}
                    <div className="row">
                        <h2 className="report mb-0">Reports</h2>
                        {/* Header */}
                        <div className="header">
                            <div className="header-body">
                                <div className="row align-items-center">
                                    <div className="col">
                                        {/* Nav */}
                                        <ul className="nav nav-tabs nav-overflow header-tabs">
                                            <li className="nav-item">
                                                <span className="nav-link text-nowrap fw-bold active" >
                                                    Total users
                                                </span>
                                            </li>
                                            {/* <li className="nav-item">
                                                <Link className="nav-link text-nowrap">
                                                    Total Vendors
                                                </Link>
                                            </li> */}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Tab content */}
                        <div className="tab-content">
                            <div
                                className="tab-pane fade show active"
                                id="contactsListPane"
                                role="tabpanel"
                                aria-labelledby="contactsListTab"
                            ></div>
                            <div
                                className="tab-pane fade"
                                id="contactsCardsPane"
                                role="tabpanel"
                                aria-labelledby="contactsCardsTab"
                            >
                                {/* Cards */}
                                <div
                                    data-list='{"valueNames": ["item-name", "item-title", "item-email", "item-phone", "item-score", "item-company"], "page": 9, "pagination": {"paginationClass": "list-pagination"}}'
                                    id="contactsCards"
                                >
                                    {/* Header */}
                                    <div className="row align-items-center mb-4">
                                        <div className="col">
                                            {/* Form */}
                                            <form>
                                                <div className="input-group input-group-lg input-group-merge input-group-reverse">
                                                    <input
                                                        className="form-control list-search"
                                                        type="search"
                                                        placeholder="Search"
                                                    />
                                                    <span className="input-group-text">
                                                        <i className="fe fe-search" />
                                                    </span>
                                                </div>
                                            </form>
                                        </div>

                                    </div>{" "}
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-xl-8">
                            <div id="earningsChart" className="card">
                                <div className="card-header flex-wrap">
                                    <h4 className="card-header-title">
                                        Total users <br />
                                    </h4>

                                    {year ?
                                        <div className="dropdown">
                                            <button
                                                className="btn btn-sm btn-white"
                                                type="button"
                                                data-bs-toggle="dropdown"
                                                // data-bs-auto-close="outside"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                            >
                                                <i className="fe fe-sliders me-1"></i> Filter{" "}
                                                <img src="images/chevron-down.svg" alt="" width={17} />{" "}
                                                <span className="badge bg-primary ms-1 d-none">0</span>
                                            </button>

                                            <form className="dropdown-menu dropdown-menu-end dropdown-menu-card">
                                                <table className="table mb-0">

                                                    {userBaseDate.map(el =>
                                                    (<tr>
                                                        <td onClick={() => setYear(el.year)}>
                                                            {" "}
                                                            <input type="radio" name="" id="yer" checked={el?.year == year} />{" "}
                                                            <label htmlFor="yer" style={{ cursor: "pointer" }}>{el?.year}</label>{" "}
                                                        </td>
                                                    </tr>
                                                    ))}

                                                </table>
                                            </form>
                                        </div> : null}
                                </div>
                                <div className="card-body">
                                    {/* Chart */}
                                    <BarChart width={chartWidth} height={300} data={formatteddata()}  >
                                        <CartesianGrid strokeDasharray="1 1" />
                                        <XAxis dataKey="name" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        <Bar dataKey="users" fill="#9B7343" />
                                    </BarChart>
                                </div>
                            </div>
                        </div>
                    </div>{" "}
                    {/* / .row */}
                </div>
            </div>
        </>
    );
}
