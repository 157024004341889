import React, { useState } from 'react'
import { Link } from "react-router-dom";
import { ImageUrl } from '../../../utils/BaseUrl';
import { useAdminContext } from '../../../context/adminContext';
import { useEffect } from 'react';
import { addApplicationFormApi, addDocByAdminApi, deleteDocumentByIdApi, getAllDocuments, updateDocumentByAdmin, uploadImage } from '../../../utils/ApiEndpointFunctions';
import Spinwheel from "../../common/Spinwheel"
import { Formik, useFormik } from 'formik';
import { addDocIntialValue, addDocValidationSchema, addFormIntialValues, addFormValidationSchema } from '../../common/Validation';
import toast from 'react-hot-toast';
import { debounce } from 'lodash';
import DocModal from './DocModal';
import RemoveModal from "../../common/RemoveModal"
import Header from '../../common/Header';
function Document() {
    const { setAllDocuments } = useAdminContext();
    const [allDocument, setAllDocument] = useState([]);
    const [spinWeel, setSpinWheel] = useState(false)
    const [docSpin, setDocSpin] = useState(false)
    const [allDocumentData, setAllDocumentData] = useState([])
    const [modalType, setModalType] = useState("");
    const [removeData, setRemoveData] = useState();
    let [fileTypeError, setFileTypeError] = useState("")
    let firstAccodian = 0;

    const handleLinkClick = () => {
        formFormik.setFieldValue("formLink", "");
        formFormik.setFieldValue("myDocumentId", "");
        formFormik.setFieldTouched("formLink", false)
        formFormik.setFieldTouched("myDocumentId", false)
    }

    const handleAddDocumentClik = () => {
        delete docFormik.values?.id
        docFormik.setFieldValue("docName", "");
        docFormik.setFieldValue("imageLogo", "");
        docFormik.setFieldTouched("docName", false)
        docFormik.setFieldTouched("imageLogo",false)
        setModalType("Add")
    }

    const updateHandler = (ele) => {
        docFormik.setFieldValue("docName", ele?.docName);
        docFormik.setFieldValue("imageLogo", ele?.imageLogo);
        docFormik.setFieldValue("id", ele?._id);
        setModalType("Update")
    }

    const removeHandler = async (e) => {
        e.preventDefault()
        if (removeData) {
            const res = await deleteDocumentByIdApi(removeData)
            if (res.status === 200) {
                toast.success(res.data?.message)
                getAlldocument();
            }
        }
    }

 

    const onSubmit = async (values) => {
        try {
            setDocSpin(true)
            const res = await addApplicationFormApi(values);
            if (res.status === 200) {
                toast.success("Application form added")
                getAlldocument();
            }
            setDocSpin(false)
        } catch (error) {
            setDocSpin(false)
            console.log(error)
        }
    }

    const handlefile = async (e) => {
        try {
            formFormik.setFieldTouched(`formLink`, true);
            const file = e.target.files[0];
            const filetypes = ["application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "text/plain"]
        
            
            if (file) {
                if (filetypes.includes(file.type)) {
                    setFileTypeError("");
                    const formData = new FormData();
                    formData.append("file", file);
                    formData.append("type", 2)
                    formData.append("folder", "document")
                    const res = await uploadImage('/users/fileUpload', formData)
                    if (res.status === 200 && res.data?.status === 1) {
                        formFormik.setFieldValue("formLink", res.data?.data?.fileName)
                        toast.success("File uploaded")
                    }
                } else {
                    formFormik.setFieldValue("formLink", "")
                    setFileTypeError("File must be pdf, wordfile and textfile");
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleSearch = (e) => {
        let search = e.target?.value
        let searchedArray = allDocumentData.filter((ele) => {
            if (search.trim() === "") return true;
            else if (ele.docName.toUpperCase().includes(search.toUpperCase()))
                return true
            else return false;
        })
        setAllDocument(searchedArray)
    }
    const debouncedOnChange = debounce(handleSearch, 100);
    const getAlldocument = async () => {
        setSpinWheel(false)
        const res = await getAllDocuments();
        if (res.status === 200) {
            setAllDocument(res.data?.data)
            setAllDocumentData(res.data?.data)
        }
        setSpinWheel(true)
    }

    const addDocument = async (values) => {
        try {
            let res
            if (modalType === "Add") {
                res = await addDocByAdminApi(values)
            } else if (modalType === "Update") {
                res = await updateDocumentByAdmin(values)
            }
            if (res.status === 200) {
                toast.success(res.data?.message)
                getAlldocument();
            }
        } catch (error) {
            toast.error("something went wrong")
        }
    }

    const docFormik = useFormik({
        initialValues: addDocIntialValue,
        onSubmit: addDocument,
        validationSchema: addDocValidationSchema
    })

    const formFormik = useFormik({
        initialValues: addFormIntialValues,
        onSubmit: onSubmit,
        validationSchema: addFormValidationSchema,
    })

    useEffect(() => {
        // setAllDocument([])
        getAlldocument();
    }, [])
    return (
        <>
            {/*<Sidebar />*/}
            <div className="main-content vh-100 vh_md-auto overflow-y-a">

                <Header title="Document" />
                {/* CARDS */}
                <div className="container-fluid">
                    <div className="row">
                        <nav className="mb-5">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to="/dashboard">
                                        <i className="fe fe-home"></i> Dashboard
                                    </Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Document
                                </li>
                            </ol>
                        </nav>
                        <div className="d-flex justify-content-end mb-4 flex-wrap">
                            <div style={{ display: "-webkit-inline-box" }}>
                                <div className="dropdown show">
                                    
                                    <div className="dropdown me-3"><button className="btn btn-second dropdown-toggle" style={{ padding: "7px 10px" }} type="button" data-bs-toggle="dropdown" aria-expanded="false">Add</button>
                                        <ul className="dropdown-menu" style={{ marginTop: "-10px" }}>
                                            
                                            <div data-bs-toggle="modal" data-bs-target="#ManageStatusModal" className="dropdown-item" style={{ cursor: "pointer" }} onClick={handleLinkClick}  >
                                                Application form
                                            </div>
                                            {/* <div data-bs-toggle="modal" data-bs-target="#DocModal" className="dropdown-item" style={{ cursor: "pointer" }} onClick={handleAddDocumentClik}  >
                                                Document
                                            </div> */}
                                        </ul>
                                    </div>
                                    
                                </div>
                                <div className="position-relative">
                                    <input
                                        className="form-search me-3"
                                        type="search"
                                        name=""
                                        id=""
                                        placeholder="Search"
                                        onChange={debouncedOnChange}
                                    />
                                    <img
                                        className="users-search"
                                        src="/images/Icon feather-search.svg"
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                        <hr />
                    </div>

                    <div className="card">
                        <div className="accordion" id="accordionExample">
                            {
                                spinWeel ?
                                    allDocument?.length > 0 ?
                                        allDocument?.map((ele, index) => {
                                            let isfirstAccordion = ((ele?.officesAndEmbassiesData?.length > 0) && (firstAccodian++ === 0));
                                            return <div className="accordion-item" key={index} >
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div className='d-flex align-items-center'>
                                                        <div className="avatar avatar-xs align-middle me-2">
                                                            <img
                                                                className="avatar-img rounded-circle ms-2"
                                                                src={ele?.imageLogo ? ImageUrl + encodeURIComponent(ele?.imageLogo) : process.env.PUBLIC_URL + "/images/person-circle.svg"}
                                                                alt=""
                                                            />
                                                        </div>
                                                        <h2 className="accordion-header px-2" id={`heading${index}`}>
                                                            {ele?.docName}
                                                        </h2>
                                                    </div>
                                                    
                                                    <div className="d-flex align-items-center">
                                                        <div className="dropdown">
                                                            <span
                                                                className="dropdown-ellipses dropdown-toggle"
                                                                role="button"
                                                                data-bs-toggle="dropdown"
                                                                aria-haspopup="true"
                                                                aria-expanded="false"
                                                            >
                                                                <i className="fe fe-more-vertical" />
                                                            </span>
                                                            <div className="dropdown-menu dropdown-menu-end">
                                                                {/* <div data-bs-toggle="modal" data-bs-target="#DocModal" className="dropdown-item" style={{ cursor: "pointer" }} onClick={() => { updateHandler(ele) }} >
                                                                    Edit
                                                                </div> */}
                                                                {/* <div className="dropdown-item" data-bs-toggle="modal" data-bs-target="#removeDoc" style={{ cursor: "pointer" }} onClick={() => { setRemoveData(ele?._id) }}  >
                                                                    Remove
                                                                </div> */}
                                                                <Link className="dropdown-item" to={`/document/OfficesAndEmbassies/${ele?._id}`} onClick={() => { setAllDocuments([ele]) }}  >
                                                                    Offices &amp; Embassies
                                                                </Link>
                                                            </div>
                                                        </div>
                                                        <button
                                                            className={`accordion-button ${"collapsed"} `}
                                                            type="button"
                                                            style={{ width: 'auto' }}
                                                            data-bs-toggle="collapse"
                                                            data-bs-target={`#collapse${index}`}
                                                            aria-expanded={"true"}
                                                            aria-controls={`collapse${index}`}
                                                        >

                                                        </button>
                                                    </div>
                                                </div>
                                                <div id={`collapse${index}`} className={`accordion-collapse ${"collapse"}`} aria-labelledby={`heading${index}`} data-bs-parent="#accordionExample">
                                                    <div className="accordion-body">
                                                        {ele?.applicationForm?.formLink && <div>
                                                            <div className="col-md-4 mb-3">
                                                                <div className='applicationformnid'>
                                                                    <h4 className='text-center'>Application form</h4>
                                                                    <hr />
                                                                    <p>{ele?.applicationForm?.formLink}</p>
                                                                    <div className='text-end'>
                                                                        <a href={ImageUrl + encodeURIComponent(ele?.applicationForm?.formLink)} target="_rajeev" title='Form Download' className='form-download' download="application_form.pdf" >
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-download" viewBox="0 0 16 16">
                                                                                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                                                                <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                                                                            </svg>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>}
                                                        <div className="table-responsive">
                                                            <table className="table table-sm table-hover table-nowrap card-table">
                                                                <thead>
                                                                    <tr>
                                                                        <th>
                                                                            <span
                                                                                className="list-sort text-muted"
                                                                            >
                                                                                S.No
                                                                            </span>
                                                                        </th>
                                                                        <th>
                                                                            <span
                                                                                className="list-sort text-muted"
                                                                            >
                                                                                Name
                                                                            </span>
                                                                        </th>
                                                                        <th>
                                                                            <span
                                                                                className="list-sort text-muted"
                                                                            >
                                                                                Email
                                                                            </span>
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className="list fs-base">
                                                                    {
                                                                        ele?.officesAndEmbassiesData?.length > 0 ?
                                                                            ele?.userDetails?.map?.((e, i) => {
                                                                               
                                                                                return <tr key={i}>
                                                                                    <td>{i + 1}</td>
                                                                                    <td>
                                                                                        <div className="avatar avatar-xs align-middle me-2">
                                                                                            <img
                                                                                                className="avatar-img rounded-circle"
                                                                                                src={e?.imageLogo ? ImageUrl + encodeURIComponent(e?.imageLogo) : process.env.PUBLIC_URL + "/images/person-circle.svg"}
                                                                                                alt=""
                                                                                            />
                                                                                        </div>
                                                                                        <span
                                                                                            className="item-name text-reset"
                                                                                        >
                                                                                            {e?.name}
                                                                                        </span>
                                                                                    </td>
                                                                                    <td >
                                                                                        <span
                                                                                            className="item-name text-reset"
                                                                                        > {e?.email ? <div  data-toggle="tooltip" data-placement="left" >
                                                                                            {e?.email}
                                                                                        </div> : "-"}
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            }) : <tr align="center">
                                                                                <td colSpan="3">
                                                                                    <h1> Data Not Found </h1>
                                                                                </td>
                                                                            </tr>
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }) : <div className="d-flex justify-content-center">
                                            <h1> Data Not Found </h1></div>
                                    : <div className="d-flex justify-content-center">
                                        <Spinwheel />
                                    </div>
                            }
                        </div>

                    </div>
                </div>
            </div>
            <RemoveModal removeHandler={removeHandler} modalId="removeDoc" message={<>Are you sure you want to<br /> remove this?</>} />
            <div className="modal fade" id="ManageStatusModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-sm modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header border-0">
                            <button type="button" className="btn-close block-close" data-bs-dismiss="modal" aria-label="Close" ></button>
                        </div>
                        <div className="modal-body block-user">
                            <div className="col-lg-12" style={{ zIndex: "1" }}>
                                <form action="" className='p-4' onSubmit={formFormik.handleSubmit} >
                                    <h2 style={{ textAlign: "left" }}>
                                        Application form
                                    </h2>
                                    <select name="myDocumentId" id="modal" className="form-select border mb-2" value={formFormik.values.myDocumentId} onChange={formFormik.handleChange} onBlur={formFormik.handleBlur} >
                                        <option value="">Choose document</option>
                                        {
                                            allDocument ? allDocument?.map((ele, index) => {
                                                return <option key={ele?._id} value={ele?._id}>{ele?.docName}</option>
                                            }) : ""
                                        }
                                    </select>
                                    {formFormik.touched.myDocumentId && formFormik.errors.myDocumentId ? (<div className='req-error'>{formFormik.errors.myDocumentId}</div>) : null}
                                    <label className="uploadFile form-control border">
                                        <span className="filename">
                                            {docSpin ? <Spinwheel sizeClass="spin-small" /> : formFormik.values?.formLink?.trim() ? formFormik.values?.formLink : "Attachment"}
                                        </span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-paperclip input-icon" viewBox="0 0 16 16">
                                            <path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0V3z" />
                                        </svg>
                                        <input type="file" id="formLink" className="inputfile form-control" name="formLink" disabled={docSpin} accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/plain" onChange={handlefile} />
                                    </label>
                                    {fileTypeError?.trim()?.length > 0 ? <div className='req-error'>{fileTypeError}</div>  :formFormik.touched.formLink && formFormik.errors.formLink ? (<div className='req-error'>{formFormik.errors.formLink}</div>) : null}
                                    <div className="modal-footer border-0 justify-content-center pt-4">
                                        <button type="submit" className="btn-custom w-auto" data-bs-dismiss={Object.keys(formFormik?.errors).length > 0 ? '' : 'modal'} onClick={(e) => { }}  >Submit</button>
                                        <button type="button" className="btn-custom w-auto" data-bs-dismiss='modal' >
                                            Cancel
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <DocModal docFormik={docFormik} modalType={modalType} />
        </>
    );
}

export default Document