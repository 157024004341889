import React, { useState } from "react";
import Sidebar from "../../../Layout/Sidebar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AddEssentialInitialValue, essentialValidation } from "../../common/Validation";
import { useFormik } from "formik";
import { addEssential, editEssential, uploadImage } from "../../../utils/ApiEndpointFunctions";
import toast from "react-hot-toast";
import { useAdminContext } from "../../../context/adminContext";
import Spinwheel from "../../common/Spinwheel";
import { ImageUrl } from "../../../utils/BaseUrl";
import Header from "../../common/Header";

export default function AddEssential() {
    const [addEssentialSpin, setAddEssentialSpin] = useState(false)
    const location = useLocation();
    const [fileTypeError, setFileTypeError] = useState("")

    let path = location.pathname
    path = path.split("/")?.filter((e) => {
        if (e === "add" || e === "update") {
            return true
        }
        return false;
    })[0]
    path = (path[0].toUpperCase() + path.slice(1));

    if ((path === "Update") && location.state == null) {
        navigate("/essentialnumber/add");
    }

    const updateEssentialInitialValues = {
        _id: location.state?.essentialData?._id,
        logo: location.state?.essentialData?.logo,
        name: location.state?.essentialData?.name,
        phone: location.state?.essentialData?.phone
    }
    const navigate = useNavigate();

    const handleSubmit = async (values) => {
        try {
            let res
            setAddEssentialSpin(true)
            if (path === "Add") {
                res = await addEssential(values)
            } else {
                res = await editEssential(values)
            }
            if (res.status === 200) {
                if (res.data?.message === "Essential number already exist") {
                    toast.error("Already exist")
                } else {
                    res.data?.message === "Essential phone number updated successfully" ?
                        toast.success("Essential number updated") :
                        toast.success(res.data?.message);
                    navigate("/essentialnumber", { replace: true })
                }
            }
            setAddEssentialSpin(false)
        } catch (error) {
            console.log(error)
            setAddEssentialSpin(false)
        }
    }

    const essentialFormik = useFormik({
        initialValues: location.state ? updateEssentialInitialValues : AddEssentialInitialValue,
        onSubmit: handleSubmit,
        validationSchema: essentialValidation
    })

    const addOrRemovePhone = (ind , buttonType) => {
        if (buttonType === "plus") {
            essentialFormik.setFieldValue("phone", [...essentialFormik.values.phone,''])
        } else if (buttonType === "minus") {
            essentialFormik.setFieldValue("phone", essentialFormik.values.phone?.filter((_, i) => i!== ind))
        }
    }

    const logoChange = async (e) => {
        try {
            const file = e.target.files[0];
            const types = ['image/jpeg', 'image/png', 'image/gif'];
            if (file) {
                if (types.includes(file?.type)) {
                    setFileTypeError("")
                    const formData = new FormData();
                    formData.append("file", file);
                    formData.append("type", 5)
                    formData.append("folder", "essential")
                    const res = await uploadImage('/users/fileUpload', formData)
                    if (res.status === 200 && res.data?.status === 1) {
                        essentialFormik.setFieldValue("logo", res.data?.data?.fileName)
                        toast.success("File uploaded")
                    }
                } else {
                    essentialFormik.setFieldValue("logo", "")
                    setFileTypeError("Must be image only")
                }
            }
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <>
            {/*<Sidebar />*/}
            <div className="main-content vh-100 vh_md-auto overflow-y-a">

                <Header title={`Essential Number ${path}`} />
                {/* CARDS */}
                <div className="container-fluid">
                    <div className="row">
                        <nav className="mb-5">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to="/dashboard">
                                        <i className="fe fe-home"></i> Dashboard
                                    </Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to="/essentialnumber" className="breadcrumb-item">
                                        Essential number
                                    </Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    {path} essential number
                                </li>
                            </ol>
                        </nav>
                        <div>
                            <h2 className="report">{path} essential number</h2>
                        </div>
                        <hr />

                        <div className="col-8" style={{ zIndex: "1" }}>
                            <form action="" onSubmit={essentialFormik.handleSubmit} >
                                <label htmlFor="profile" className="form-label"> Logo image </label>
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="position-relative">
                                        {<img src={essentialFormik.values?.logo ? ImageUrl + encodeURIComponent(essentialFormik.values?.logo) : process.env.PUBLIC_URL + "/images/person-circle.svg"} width="70px" height="70px" className="rounded-circle" alt="Selected file" />}
                                        {
                                            <>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-camera pro-camera" viewBox="0 0 16 16">
                                                    <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1v6zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z" />
                                                    <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
                                                </svg>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x xmark" viewBox="0 0 16 16" onClick={() => { essentialFormik.setFieldValue("logo", "others/person-circle.svg") }} >
                                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                </svg>
                                            </>
                                        }
                                        {fileTypeError && <div className='req-error'>{fileTypeError}</div>}

                                    </div>
                                    <div className="btn_upload">
                                        <input type="file" id="profile" accept="image/*" className="form-control" name="profileImage" onChange={logoChange} />
                                        Upload
                                    </div>
                                </div>
                                <br />
                                <label htmlFor="first" className="form-label"> Name</label>
                                <div className="position-relative"><input className="form-control" type="text" id="first" placeholder="Enter your name" name="name" value={essentialFormik.values.name} onChange={essentialFormik.handleChange} onBlur={essentialFormik.handleBlur} /><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-fill input-icon" viewBox="0 0 16 16">
                                    <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                                </svg>
                                    {essentialFormik.touched.name && essentialFormik.errors.name ? (<div className='req-error'>{essentialFormik.errors.name}</div>) : null}
                                </div>
                                {/* <label htmlFor="first" className="form-label"> Last Name</label>
                                <div className="position-relative"><input className="form-control" type="text" id="first" placeholder="Enter your last name" name="lastName" value={essentialFormik.values.lastName} onChange={essentialFormik.handleChange} onBlur={essentialFormik.handleBlur} /><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-fill input-icon" viewBox="0 0 16 16">
                                    <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                                </svg>
                                    {essentialFormik.touched.lastName && essentialFormik.errors.lastName ? (<div className='req-error'>{essentialFormik.errors.lastName}</div>) : null}
                                </div> */}
                                <br />
                                <label htmlFor="phone" className="form-label">Phone number</label>
                                {essentialFormik?.values?.phone?.map?.((ele, index) => {
                                    
                                    return <div key={index} className="position-relative mb-2"><input className="form-control" type="tel" id="phone" placeholder="Enter your phone number" name={`phone[${index}]`} value={essentialFormik.values?.phone?.[index]} onChange={essentialFormik.handleChange} onBlur={essentialFormik.handleBlur} />
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-telephone-fill input-icon" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                                        </svg>
                                        {essentialFormik?.values?.phone?.length - 1 === index && essentialFormik?.values?.phone?.length  < 5 && <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style={{ position: "absolute", right: Boolean(essentialFormik?.values?.phone?.length - 1) ? '-50px':"-25",top:"15px"}} class="bi bi-plus-square" viewBox="0 0 16 16" onClick={() => {addOrRemovePhone(index,"plus")}}>
                                            <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
                                            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
                                        </svg>}
                                        {Boolean(essentialFormik?.values?.phone?.length - 1) && <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style={{ position: "absolute", right: '-25px', top: "15px" }} class="bi bi-dash-square" viewBox="0 0 16 16" onClick={() => { addOrRemovePhone(index, "minus") }}>
                                            <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
                                            <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8" />
                                        </svg>}
                                        {essentialFormik.touched?.phone?.[index] && essentialFormik.errors?.phone?.[index] ? (<div className='req-error'>{essentialFormik.errors?.phone?.[index]}</div>) : null}
                                        <div className="d-flex justify-content-end ">
                                            <div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                })}
                                <br />
                                <div className="text-center d-flex justify-content-around">
                                    <button type="submit" className="btn-custom w-auto mt-2" disabled={addEssentialSpin}>{addEssentialSpin ? <Spinwheel sizeClass="spin-small" /> : "Save"}</button>
                                    <div className="btn-custom w-auto mt-2" onClick={() => { window.history.back(); }}  >Cancel</div>
                                </div>
                            </form>
                            <br />
                        </div>
                        <div className="col-lg-8"></div>
                    </div>
                </div>
            </div>
        </>
    );
}

