import React from 'react'
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { updateSalaryRangeSelectorApi } from '../../../../utils/ApiEndpointFunctions';
import { ImageUrl } from '../../../../utils/BaseUrl'

function CompanyDetailModal({ companyDetail, setCompanyDetail,job }) {
  
  return (
    <div className="modal fade" id="companyDetailModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-sm modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header border-0">
            <button type="button" className="btn-close block-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
          <h1 className='text-center'>{job?.title}</h1>
            {/* <img src="/images/blockuser.png" alt="" width={202} srcSet="" />
            <h1 className="mb-0">job?.companyId Modal</h1> */}
            <h2>
               { job?.companyId?.logo && <div className="avatar avatar-xs align-middle me-2">
                  <img
                    className="avatar-img rounded-circle"
                  src={job?.companyId?.logo ? ImageUrl + job?.companyId?.logo : process.env.PUBLIC_URL + "/images/person-circle.svg"}
                    alt="..."
                  />
                </div>}
                &nbsp;Company details
            
            </h2>
      <hr />
            <table className='table'>
              <tbody>
                <tr>
                  <td>Website</td>
                  <td>{job?.companyId?.url ? <Link to={job?.companyId?.url} target="_blank" >{job?.companyId?.url}</Link> : '-'}</td>
                </tr>
                <tr>
                  <td>Name</td>
                  <td>{job?.companyId?.name ? job?.companyId?.name : '-'}</td>
                </tr>
                <tr>
                  <td>Phone number</td>
                  <td>{job?.companyId?.phoneNumber ? job?.companyId?.phoneNumber:'-'}</td>
                </tr>
                <tr>
                  <td>Email</td>
                  <td>{job?.companyId?.emailAddress ? job?.companyId?.emailAddress : '-'}</td>
                </tr>
                <tr>
                  <td>Locality</td>
                  <td>{job?.companyId?.locality ? job?.companyId?.locality : '-'}</td>
                </tr>
                <tr>
                  <td>Industry</td>
                  <td>{job?.companyId?.industry ? job?.companyId?.industry : '-'}</td>
                </tr>
                <tr>
                  <td>Description</td>
                  <td className='position-relative'>
                    {job?.companyId?.description ? (
                      <div className="tooltip1">
                        {job?.companyId?.description?.substr(0, 10)}...
                        <div className='wrapper'>
                          <div className="tooltip1-text">{job?.companyId?.description}</div>
                      </div>
                      </div>
                    ) : (
                      "-"
                    )}
                  </td>
                </tr>
                {/* <tr>
                  <td>Salary Range</td>
                  <td>{job?.companyId?.salaryRange ? job?.companyId?.salaryRange : '-'}</td>
                </tr> */}
                {/* <tr>
                  <td> <label className="form-check-label" htmlFor="Selector">Range Selector</label></td>
                  <td >
                    <div className="form-check form-switch map-togle-container d-flex justify-content-center">
                      <input className="form-check-input text-end" type="checkbox" name='Selector' role="switch" id="Selector" checked={job?.companyId?.rangeSelector || false} onChange={(e) => { selectorHandler(!job?.companyId?.rangeSelector) }} />
                    </div>
                  </td>
                </tr> */}
              </tbody>
            </table>
          </div>
          {/* <div className="modal-footer pe-0 ps-0 border-0 justify-content-center pt-0 mb-3">
            <button type="button" className="btn-custom w-auto" data-bs-dismiss="modal"  >YES</button>
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default CompanyDetailModal