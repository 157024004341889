import moment from 'moment'
import React from 'react'
import Spinwheel from '../../../common/Spinwheel'
import { ImageUrl } from '../../../../utils/BaseUrl'
import { Link } from 'react-router-dom'

function WorkpermitApplicationTable({ tableData, tableSpin, setIsEdit, setRemoveData, formik }) {

  const updateDataHandler = (ele) => {

 

    formik.setValues({

      _id: ele._id ? ele._id : undefined,
      name: ele.name ? ele.name : "",
      aliasName: ele.aliasName ? ele.aliasName : "",
      dob: ele.dob ? ele.dob : "",
      nrcNumber: ele.nrcNumber ? ele.nrcNumber : "",
      parentName: ele.parentName ? ele.parentName : "",
      placeOfBirth: ele.placeOfBirth ? ele.placeOfBirth : "",
      religion: ele.religion ? ele.religion : "",
      language: ele.language ? ele.language : "",
      permanentAddress: ele.permanentAddress ? ele.permanentAddress : "",
      myanmarPhone: ele.myanmarPhone ? ele.myanmarPhone : "",
      relationship: ele.relationship ? ele.relationship : "",
      companyName: ele.companyName ? ele.companyName : "",
      companyAddress: ele.companyAddress? ele.companyAddress : "",
      companyPhone: ele.companyPhone? ele.companyPhone : "",
      entryDateThailand: ele.entryDateThailand? moment(ele.entryDateThailand).format("YYYY-MM-DD") : "",
      addressInThailand: ele.addressInThailand? ele.addressInThailand : "",
      thaiPhone: ele.thaiPhone? ele.thaiPhone : "",
      ciOldLicenseNumber: ele.ciOldLicenseNumber? ele.ciOldLicenseNumber : "",
      dateOfIssue: ele.dateOfIssue? moment(ele.dateOfIssue).format("YYYY-MM-DD") : "",
      file: ele.file? ele.file : "",

    })

  }
  return (
    <div>
      <table className="table table-sm table-hover table-nowrap card-table">
        <thead>
          <tr>
            <th><span className="list-sort text-muted">Name</span></th>
            <th><span className="list-sort text-muted">Alias Name</span></th>
            <th><span className="list-sort text-muted">Date of Birth</span></th>
            <th><span className="list-sort text-muted">NRC</span></th>
            <th><span className="list-sort text-muted">Parent's Name</span></th>
            <th><span className="list-sort text-muted">Place of Birth</span></th>
            <th><span className="list-sort text-muted">Religion</span></th>
            <th><span className="list-sort text-muted">Thai Phone</span></th>
            <th><span className="list-sort text-muted">CI Old License Number</span></th>
            <th><span className="list-sort text-muted">Issue Date</span></th>
            <th colSpan={2}><span className="list-sort text-muted">file</span></th>
          </tr>
        </thead>
        <tbody>
          {tableSpin ? <tr>
            <td colSpan={20}>
              <div className="text-center">
                <Spinwheel />
              </div>
            </td>
          </tr> :
           tableData?.length ? tableData?.map(ele => {
          return (
            <tr>
              <td>{ele.name}</td>
              <td>{ele.aliasName}</td>
              <td>{moment(ele.dob).format("DD/MM/YYYY")}</td>
              <td>{ele.nrcNumber}</td>
              <td>{ele.parentName}</td>
              <td>{ele.placeOfBirth}</td>
              <td>{ele.religion}</td>
              <td>{ele.thaiPhone}</td>
              <td>{ele.ciOldLicenseNumber}</td>
              <td>{moment(ele.dateOfIssue).format("DD/MM/YYYY")}</td>
              <td className='position-relative'>
                {ele?.file ? (
                  <div className="tooltip1">
                    <Link to={ImageUrl + encodeURIComponent(ele?.file)} target="__blank">{ele?.file?.substr?.(0, 20)}...</Link>
                    <div className='wrapper'>
                      <div className="tooltip1-text">{ele?.file}</div>
                    </div>
                  </div>
                ) : (
                  "-"
                )}
              </td>

              <td className="text-end">
                <div className="dropdown">
                  <span
                    className="dropdown-ellipses dropdown-toggle"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="fe fe-more-vertical" />
                  </span>
                  <div className="dropdown-menu dropdown-menu-end">
                    <div data-bs-toggle="modal" data-bs-target="#ManageStatusModal" className="dropdown-item" style={{ cursor: "pointer" }} onClick={() => { setIsEdit(true); updateDataHandler(ele) }} >
                      View/Edit
                    </div>
                    <div data-bs-toggle="modal" data-bs-target="#removedoc" className="dropdown-item" style={{ cursor: "pointer" }} onClick={() => { setRemoveData(ele) }} >
                      Remove
                    </div>
                  </div>
                </div>
              </td>
              </tr>
          )
        }) :
          <tr>
            <td colSpan={20}> <h1 className='text-center'>Data not found</h1></td>
          </tr>}
          
        </tbody>
      </table>

    </div>
  )
}

export default WorkpermitApplicationTable