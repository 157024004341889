import React, { useEffect, useState } from 'react'
import { useCallback } from 'react';

import { Link } from 'react-router-dom';
import { useAdminContext } from '../../../context/adminContext';
import { createVendorUserApi, getAllShopsByVendorApi, getAllVendorDetailsApi, inviteVendorByEmailApi, removeVendorByIdApi, updateVendorByIdApi } from '../../../utils/ApiEndpointFunctions';
import Header from '../../common/Header';
import toast from "react-hot-toast";
import Pagination from '../../common/Pagination';
import Spinwheel from '../../common/Spinwheel';
import InviteVendorModal from './InviteVendorModal';
import { useFormik } from 'formik';
import { VendorUserValidationSchema, inviteVendorInitialValue, inviteVendorValidationSchema, optIntialValue, vendorUserIntialValues } from '../../common/Validation';
import moment from 'moment';
import AddVendorModal from './AddVendorModal';
import RemoveModal from '../../common/RemoveModal';
import { debounce } from 'lodash';

function Venders() {
  const [tableSpin, setTableSpin] = useState(true);
  const { setVendorDetail } = useAdminContext();
  const [vendors, setVendors] = useState([]);
  const [removeDate, setRemoveData] = useState();
  const [addVendorLoader,setAddVendorLoader] = useState(false)
  const limit = 10;
  const inviteModalId = "inviteModalId";
  const addVendorDetailModal = "addVendorDetailModalID"
  const [page, setPage] = useState(0);
  let [selectedPage, setSelectPage] = useState(0);
  

  const getVendorList = async (search) => {
    try {
      setTableSpin(false)
      const res = await getAllVendorDetailsApi(search,selectedPage+1,limit)
      if (res.status === 200) {
        setVendors(res.data?.data?.data)
        const totaldata = res.data?.data?.totalData
        setPage(Math.ceil(totaldata / limit));
      }
      setTableSpin(true)
    } catch (error) {
      setTableSpin(true)
    }
  }

  const removeVendorHandler = async () => {
    try {
      const res = await removeVendorByIdApi(removeDate)
      if (res.status === 200) {
        toast.success(res.data?.message)
        getVendorList("")
      }
      
    } catch (error) {
      console.log(error)
    }
  }

  const inviteFormik = useFormik({
    initialValues: inviteVendorInitialValue,
    onSubmit: async (values) => {
   
      try {
        document.getElementById(inviteModalId).click();
        const res = await inviteVendorByEmailApi(values)
        if (res.status === 200) {
          toast.success(res.data.message)
        }
      } catch (error) {
        if(error?.response) toast.error(error?.response?.data?.message);
        else toast.error("Something went wrong");
      }
    },
    validationSchema: inviteVendorValidationSchema

  }) 

  const vendorFormik = useFormik({
    initialValues: vendorUserIntialValues,
    onSubmit: async (values) => {
      try {
        setAddVendorLoader(true)
    
        const res = values?._id ? await updateVendorByIdApi(values) : await createVendorUserApi(values);
        if (res.status === 200) {
          toast.success(res.data?.message)
          getVendorList()
        }
      } catch (error) {
        if(error?.response) toast.error(error?.response?.data?.message);
        else toast.error("Something went wrong");
      }
      setAddVendorLoader(false)
      document.getElementById(addVendorDetailModal).click();
      
    },
    validationSchema: VendorUserValidationSchema
  })

  const onChange = async (e) => {
    let search = e.target.value;
    getVendorList(search)

  };
  const debouncedOnChange = debounce(onChange, 500);

  const handlePageClick = (event) => {
    setSelectPage(event.selected);
    getVendorList("");
  };

  useEffect(() => {
    getVendorList("")
  }, [])

  return (
    <>
      {/*<Sidebar />*/}
      <div className="main-content vh-100 vh_md-auto overflow-y-a">
        <Header title="Vendor" />
        {/* CARDS */}
        <div className="container-fluid">
          <div className="row">
            <nav className="mb-5">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">
                    <i className="fe fe-home"></i> Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Vendor
                </li>
              </ol>
            </nav>
            <div className="mb-4" style={{ textAlign: "end" }}>
              <div className='d-flex justify-content-end gap-2' style={{ display: "-webkit-inline-box" }}>

                <div className="btn-custom w-auto" data-bs-toggle="modal" data-bs-target={`#${inviteModalId}`} onClick={() => { inviteFormik.resetForm();}} >Invite vendor</div>
                <div className="btn-custom w-auto" data-bs-toggle="modal" data-bs-target={`#${addVendorDetailModal}`} onClick={() => { vendorFormik.resetForm(); }} >Add</div>
                <div className="position-relative">
                  <input
                    className="form-search me-3"
                    type="search"
                    name=""
                    onChange={debouncedOnChange}
                    placeholder="Search"
                  />
                  <img
                    className="users-search"
                    src="/images/Icon feather-search.svg"
                    alt=""
                  />
                </div>
                {/* <div className="dropdown show">
                  <div className="dropdown me-3"><button className="btn btn-second dropdown-toggle" style={{ padding: "3px 10px" }} type="button" data-bs-toggle="dropdown" aria-expanded="false">Add</button>
                    <ul className="dropdown-menu" style={{ marginTop: "-10px" }}>
                    </ul>
                  </div>
                </div>
                <div className="position-relative">
                  <input
                    className="form-search me-3"
                    type="search"
                    name=""
                    
                    placeholder="Search"
                  />
                  <img
                    className="users-search"
                    src="/images/Icon feather-search.svg"
                    alt=""
                  />
                </div> */}
              </div>
            </div>
            <hr />

            <div className="card p-3 table-responsive" >
              <table className="table table-hover table-nowrap card-table">
                <thead>
                  <tr>
                    <th>
                      <span
                        className="list-sort text-muted"
                      >
                        S.No
                      </span>
                    </th>
                    <th>
                      <span
                        className="list-sort text-muted"
                      >
                        Name
                      </span>
                    </th>
                    <th>
                      <span
                        className="list-sort text-muted"
                      >
                        Email id
                      </span>
                    </th>
                    <th>
                      <span
                        className="list-sort text-muted"
                      >
                        Products
                      </span>
                    </th>

                    <th>
                      <span
                        className="list-sort text-muted"
                      >
                        total sell
                      </span>
                    </th>
                    <th>
                      <span
                        className="list-sort text-muted"
                      >
                        Join date
                      </span>
                    </th>
                    <th colSpan={2}>
                      <span
                        className="list-sort text-muted"
                      >
                        status
                      </span>
                    </th>
                   
                   
                  </tr>
                </thead>
                {
                  tableSpin ?
                    <tbody className="list fs-base">
                      {vendors?.length > 0 ? vendors?.map((ele, index) => {
                        return (<tr key={index} >
                          <td>{index + 1}</td>
                          <td>
                            <Link
                              className="item-name text-reset"
                              to={`/vendor/product/${ele?._id}`}
                              state={{ jobName: ele?.name, shops: ele?.shops }}
                              onClick={(e) => {
                                setVendorDetail(ele)
                              }}
                            >
                              {ele?.name ? ele?.name : "-"}
                            </Link>
                          </td>
                          <td>
                            <span
                              className="item-phone text-reset"
                            >
                              {ele?.email ? ele?.email : "-"}
                            </span>
                          </td>
                          <td>
                            <span
                              className="item-phone text-reset"
                            >
                              {ele?.products ? ele?.products : "-"}
                            </span>
                          </td>

                          <td>
                            <span
                              className="item-phone text-reset"
                            >
                              {ele?.totalSell ? ele?.totalSell : "-"}
                            </span>
                          </td>
                          <td>
                            <span
                              className="item-phone text-reset"
                            >
                              {ele?.joinDate ? moment(ele?.joinDate).format("DD/MM/YYYY") : "-"}
                            </span>
                          </td>
                          <td >
                            <span
                              className="item-phone text-reset"
                            >
                              <div className={`badge ${ele?.joinDate ? "badge bg-success-soft" : "bg-dark-soft"} `}>
                                {ele?.joinDate ? "Active" : "Inactive"}
                              </div>
                            </span>
                            
                          </td>


                          <td className="text-end">
                            <div className="dropdown">
                              <span
                                className="dropdown-ellipses dropdown-toggle"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <i className="fe fe-more-vertical" />
                              </span>
                              <div className="dropdown-menu dropdown-menu-end">
                                <div
                                  className="dropdown-item"
                                  data-bs-toggle="modal"
                                  data-bs-target={`#${addVendorDetailModal}`}
                                  onClick={() => {
                                    vendorFormik.setValues({
                                      _id: ele?._id ? ele?._id : "",
                                      name: ele?.name ? ele?.name : "",
                                      email: ele?.email ? ele?.email : "",
                                      profileImage: ele?.profileImage ? ele?.profileImage : ""
                                    })}}>
                                  Edit
                                </div>
                                <div data-bs-toggle="modal" data-bs-target="#removeModal" className="dropdown-item" style={{ cursor: "pointer" }} onClick={() => { setRemoveData(ele?._id); }}  >
                                  Remove
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                        )
                      }) : <tr align="center">
                        <td colSpan="9">
                          <h1>
                            Data Not Found
                          </h1>
                        </td>
                      </tr>}
                    </tbody> : (
                      <tbody>
                        <tr align="center">
                          <td colSpan="9">
                            <Spinwheel />
                          </td>
                        </tr>
                      </tbody>
                    )
                }
              </table>

            </div>
          </div>
          <div className="d-flex justify-content-center">
            {vendors?.length > 0 ? <Pagination
              clickHandler={handlePageClick}
              page={page}
              selectedPage={selectedPage}
            />
              : null}
          </div>

        </div>
      </div>

      <RemoveModal removeHandler={removeVendorHandler} modalId="removeModal" message={<>Are you sure you want to<br /> remove this?</>} />
      <InviteVendorModal modalId={inviteModalId} formik={inviteFormik} />
      <AddVendorModal modalId={addVendorDetailModal} formik={vendorFormik} addSpin={addVendorLoader} />

    </>
  )
}

export default Venders