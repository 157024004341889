import React from 'react'

function ReviewAndRating({ formik, modalType }) {

  return (
    <>
      {<div className="modal fade" id="ReviewAndRating" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-md modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0">
              <button type="button" className="btn-close block-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body  block-user">
              <form action="" className="text-start" onSubmit={formik.handleSubmit} >

                <label htmlFor="name" className="form-label">Name</label>
                <div className="position-relative"><input className="form-control border" type="text" id="name" name="name" placeholder="Enter your name" value={formik.values.name} onChange={formik.handleChange} onBlur={formik.handleBlur} /><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-fill input-icon" viewBox="0 0 16 16">
                  <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                </svg>
                  {formik.touched.name && formik.errors.name ? (<div className='req-error'>{formik.errors.name}</div>) : null}
                </div>
                <br />
                <label htmlFor="rating" className="form-label">Rating</label>
                <div className="position-relative">
                  <input className="form-control border" type="text" id="rating" name="rating" placeholder="Enter your rating" value={formik.values.rating} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-bookmark-star input-icon" viewBox="0 0 16 16">
                    <path d="M7.84 4.1a.178.178 0 0 1 .32 0l.634 1.285a.178.178 0 0 0 .134.098l1.42.206c.145.021.204.2.098.303L9.42 6.993a.178.178 0 0 0-.051.158l.242 1.414a.178.178 0 0 1-.258.187l-1.27-.668a.178.178 0 0 0-.165 0l-1.27.668a.178.178 0 0 1-.257-.187l.242-1.414a.178.178 0 0 0-.05-.158l-1.03-1.001a.178.178 0 0 1 .098-.303l1.42-.206a.178.178 0 0 0 .134-.098L7.84 4.1z" />
                    <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.777.416L8 13.101l-5.223 2.815A.5.5 0 0 1 2 15.5V2zm2-1a1 1 0 0 0-1 1v12.566l4.723-2.482a.5.5 0 0 1 .554 0L13 14.566V2a1 1 0 0 0-1-1H4z" />
                  </svg>
                  {formik.touched.rating && formik.errors.rating ? (<div className='req-error'>{formik.errors.rating}</div>) : null}
                </div>
                <br />
                <label htmlFor="review" className="form-label">Review</label>
                <div className="position-relative">

                  <textarea className="form-control border" id='review' value={formik.values.review} onChange={formik.handleChange} onBlur={formik.handleBlur} placeholder="Enter your review here" ></textarea>

                  {formik.touched.review && formik.errors.review ? (<div className='req-error'>{formik.errors.review}</div>) : null}
                </div>
                <br />
                <div className="text-center d-flex justify-content-center">
                  <button type="submit" className="btn-custom w-auto m-2" data-bs-dismiss={Object.keys(formik?.errors).length > 0 ? '' : 'modal'} onClick={() => { }} >{modalType}</button>
                  <button type="button" className="btn-custom w-auto m-2" data-bs-dismiss='modal' >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      }
    </>
  )
}

export default ReviewAndRating