import React, { useState, useEffect } from "react";
import Sidebar from "../../../Layout/Sidebar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { addEmbassiesIntialValues, addEmbassiesValidation } from "../../common/Validation"
import { useFormik } from "formik";
import { addOfficesAndEmbassies, updateOfficesAndEmbassiesDetailsByIdApi, uploadImage } from "../../../utils/ApiEndpointFunctions";
import toast from "react-hot-toast";
import { useAdminContext } from "../../../context/adminContext";
import Spinwheel from "../../common/Spinwheel";
import { ImageUrl } from "../../../utils/BaseUrl";
import Header from "../../common/Header";

export default function AddOffices() {
  const { adminDetail,allDocuments } = useAdminContext()
  const [addSpin, setAddSpin] = useState(false)
  const navigate = useNavigate();

  const location = useLocation();
  let path = location.pathname
  path = path.split("/").slice(-1)[0];
  path = (path[0].toUpperCase() + path.slice(1))



  const handleSubmit = async (values) => {
    try {
      setAddSpin(true)
      let res
      if(path === "Add") {
        res = await addOfficesAndEmbassies(values)
      } else if(path === "Update") {
        res = await updateOfficesAndEmbassiesDetailsByIdApi(values)
      }
      if (res.status === 200) {
        path === "Add" ? toast.success("Added") : toast.success("Updated");
        navigate(`/document/OfficesAndEmbassies/${res.data?.data?.myDocumentId}`, { replace: true });
      }
      setAddSpin(false)
    } catch (error) {
      setAddSpin(false)
      toast.error(error?.response?.data?.message)
      console.log(error)
    }
  }

  const formik = useFormik({
    initialValues: location.state ? location.state?.data : { ...addEmbassiesIntialValues, myDocumentId: allDocuments?.[0]?._id },
    onSubmit: handleSubmit,
    validationSchema: addEmbassiesValidation
  })
  const imageUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("type", 2)
      formData.append("folder", "document")
      const res = await uploadImage('/users/fileUpload', formData)
      if (res.status === 200 && res.data?.status === 1) {
        formik.setFieldValue("imageLogo", res.data?.data?.fileName)
        toast.success("File uploaded")
      }
    }
  }
  useEffect(() => {
    if (!(allDocuments?.length > 0)) {
      navigate("/document")
    }
    if ((path === "Update") && location.state == null) {
      navigate("/document/OfficesAndEmbassies/add",{replace:true});
    }
  }, [])
  return (
    <>
      {/*<Sidebar />*/}
      <div className="main-content vh-100 vh_md-auto overflow-y-a">
        <Header title="Document" />
        
        <div className="container-fluid">
          <div className="row">
            <nav className="mb-5">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">
                    <i className="fe fe-home"></i> Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/document" className="breadcrumb-item">
                    Document
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {path}  Embassies &amp; offices 
                </li>
              </ol>
            </nav>
            
            <div className="col-8" style={{ zIndex: "1" }}>
              <form action="" className="text-start" onSubmit={formik.handleSubmit} >
                <label htmlFor="profile" className="form-label"> Embassies &amp; offices photo</label>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="position-relative">
                    <img src={formik.values.imageLogo ? ImageUrl + encodeURIComponent(formik.values.imageLogo) : process.env.PUBLIC_URL + "/images/person-circle.svg"} width="70px" height="70px" style={{ objectFit: "contain" }} className="rounded-circle" alt="Selected file" />
                    <>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-camera pro-camera" viewBox="0 0 16 16">
                        <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1v6zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z" />
                        <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
                      </svg>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x xmark" viewBox="0 0 16 16">
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                      </svg>
                    </>
                  </div>
                  <div className="btn_upload">
                    <input type="file" id="upload_file" className="form-control" accept="image/*" name="file" onChange={imageUpload} />
                    Upload
                  </div>
                </div>
                <br />
                <label
                  className="form-label text-start d-block"
                  htmlFor="modal"
                >
                  Category
                </label>
                <select name="myDocumentId" id="modal" className="form-select form-control" value={ allDocuments?.[0]?._id } onChange={formik.handleChange} onBlur={formik.handleBlur} disabled >
                  <option value="">Select Category</option>
                  {
                    allDocuments?.map?.((ele, key) => {
                      return (
                        <option key={key} value={ele?._id}>{ele?.docName}</option>
                      )
                    })
                  }
                </select>
                {formik.touched.myDocumentId && formik.errors.myDocumentId ? (<div className='req-error'>{formik.errors.myDocumentId}</div>) : null}
                <br />
                <label htmlFor="first" className="form-label"> Office name</label>
                <div className="position-relative"><input className="form-control" type="text" id="officeName" placeholder="Enter office name" name="name" value={formik.values.name} onChange={formik.handleChange} onBlur={formik.handleBlur} /><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-fill input-icon" viewBox="0 0 16 16">
                  <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                </svg>
                  {formik.touched.name && formik.errors.name ? (<div className='req-error'>{formik.errors.name}</div>) : null}
                </div>
                <br />
                <label htmlFor="address" className="form-label"> Address</label>
                <div className="position-relative">
                  <input className="form-control" type="text" id="address" placeholder="Enter address" name="address" value={formik.values.address} onChange={formik.handleChange} onBlur={formik.handleBlur} /><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-fill input-icon" viewBox="0 0 16 16">
                    <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                  </svg>
                  {formik.touched.address && formik.errors.address ? (<div className='req-error'>{formik.errors.address}</div>) : null}
                </div>
                <br />
                <div className="text-center d-flex justify-content-around">
                  <button type="submit" className="btn-custom w-auto mt-2" disabled={addSpin}>{addSpin ? <Spinwheel sizeClass="spin-small" /> : "Save"}</button>
                  <div className="btn-custom w-auto mt-2" onClick={() => { window.history.back(); }}  >Cancel</div>
                </div>
              </form>
              <br />
            </div>
            <div className="col-lg-8"></div>
          </div>
        </div>
      </div>
    </>
  );
}
