import React from 'react'
import toast from 'react-hot-toast'
import {  bannerOnOffVideoAndArticles, publishOrUnpublishVideoAndArticles, } from '../../../utils/ApiEndpointFunctions'

function Modal({ modalUpdate, setModalUpdate, getAllVideoAndArticles, type, selectedPage }) {
  
  const changeStatusHandler = () => {
    setModalUpdate({ ...modalUpdate, status: !modalUpdate.status })
  }
  const updateStatus = async (e) => {
    e.preventDefault();
    try {
      let res;
      if (modalUpdate?.type === "Published/Unpublished") {
        res = await publishOrUnpublishVideoAndArticles({id:modalUpdate?.id,status:modalUpdate?.status});
      }  
      else if (modalUpdate?.type === "Mark as a Banner") {
        res = await bannerOnOffVideoAndArticles({ id: modalUpdate?.id, status: modalUpdate?.status })
      }
      if (res?.status === 200) {
        toast.success("updated successfully");
        getAllVideoAndArticles(selectedPage, type);
      }
    } catch (error) {
      toast.error("something went wrong")
      console.log(error)
    }
  }

  return (
    <div className="modal fade" id="updateStatusModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-sm modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header border-0">
            <button type="button" className="btn-close block-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body block-user">
            <div className="col-lg-12" style={{ zIndex: "1" }}>
              <div className='text-left'>
                <h2 >Are you sure you want to update?</h2>
              </div>
              <hr />
              <form action="" className='p-0' >
                
                <div className='d-flex justify-content-around '>
                  <label htmlFor="isPinned" className="form-label"> {modalUpdate?.type} </label>
                  <div className="form-check form-switch map-togle-container">
                    <input className="form-check-input" type="checkbox" role="switch" id="isPinned" checked={modalUpdate?.status} onChange={changeStatusHandler} />
                  </div>
                </div>
                <div className="modal-footer border-0 justify-content-center pt-4">
                  <button type="button" className="btn-custom w-auto" data-bs-dismiss='modal' onClick={(e) => {updateStatus(e)}} >Update</button>
                  {/* <button type="button" className="btn-custom w-auto" data-bs-dismiss='modal' >
                    Cancel
                  </button> */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Modal