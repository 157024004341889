import React, { useMemo, useState } from 'react'
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useAdminContext } from '../../../../context/adminContext';
import Sidebar from '../../../../Layout/Sidebar';
import { getJobDetailsByIdApi, updateUserApplicationStatusApi } from '../../../../utils/ApiEndpointFunctions';
import { ImageUrl } from '../../../../utils/BaseUrl';
import Header from '../../../common/Header';
import Spinwheel from '../../../common/Spinwheel';
import ManageStatusModel from '../modals/ManageStatusModel';
import { debounce } from 'lodash';

function AppliedUsers() {
  const {adminDetail} = useAdminContext()
  const params = useParams();
  const [appliedCondidate,setAppliedCondidate] = useState([])
  const [tableSpin,setTabelSpin] = useState(true)
  const [jobDetail,setJobDetail] = useState([])
  const [updateStateData,setUpdateStatusData] = useState({jobId:"",userId:"",status:""})
  const location = useLocation();
  const [id,setId] = useState(params?.id)
  const [searchText, setSearchText] =  useState();

  const statusUpdateHandler = async (e) => {
    e.preventDefault();
    try {
      const res = await updateUserApplicationStatusApi(updateStateData)
      if (res.status === 200) {
        toast.success("Application status updated")
        getJobDetailsById();
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  }
 
  const getJobDetailsById = async (searchText) => {
    setTabelSpin(false)
    
    try {
      const res = await getJobDetailsByIdApi(id, searchText);
      if(res.status ===  200)  {
        setAppliedCondidate(res.data?.data?.candidateDetails)
        setJobDetail(res.data?.data)
        setTabelSpin(true)
      }
    } catch (error) {
      console.log(error)
      setTabelSpin(true)
    }
  }

  const debounceChange = useMemo(() => debounce(getJobDetailsById,500),[id])
  

  function handleDownload(event, urls, filename) {
    function download(url, filename) {
      fetch(url)
        .then(response => response.blob())
        .then(blob => {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = filename;
          link.click();
        })
        .catch(console.error);
    }
    download(urls, filename)
    }

  useEffect(() => {
    if(id) {
      getJobDetailsById(params?.id)
    }
  },[id])

  useEffect(() => {
    debounceChange(searchText)
  },[searchText])
  return (
    <>
      {/*<Sidebar />*/}
      <div className="main-content vh-100 vh_md-auto overflow-y-a">
        <Header title="Jobs" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <nav className="mb-5">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">
                      <i className="fe fe-home"></i> Dashboard
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/job">
                      jobs
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Applied-users
                  </li>
                </ol>
              </nav>
            </div>
            <div className="d-flex justify-content-end mb-4 flex-wrap">
              <div style={{ display: "-webkit-inline-box" }}>
                <div className="position-relative">
                  <input
                    className="form-search me-3"
                    type="search"
                    name=""
                    id=""
                    onChange={(e) => {setSearchText(e.target.value)}}
                    placeholder="Search"
                  />
                  <img
                    className="users-search"
                    src="/images/Icon feather-search.svg"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <h5 className="header-title">{location?.state?.jobName}</h5>
            <hr />
          </div>
          <div className="card p-3 shadow table-responsive" >
            <table className="table table-sm table-hover table-nowrap card-table">
              <thead>
                <tr>
                  <th>
                    <span
                      className="list-sort text-muted"
                      
                    >
                      S.No
                    </span>
                  </th>
                  <th>
                    <span
                      className="list-sort text-muted"
                      
                    >
                      Name
                    </span>
                  </th>
                  <th>
                    <span
                      className="list-sort text-muted"
                      
                    >
                      Email ID
                    </span>
                  </th>
                  <th>
                    <span
                      className="list-sort text-muted"
                      
                    >
                      Phone Number
                    </span>
                  </th>
                  <th>
                    <span
                      className="list-sort text-muted"
                      
                    >
                      Status
                    </span>
                  </th>
                  <th>
                    <span
                      className="list-sort text-muted"
                      
                    >
                      Employment Type
                    </span>
                  </th>
                  <th colSpan={2}>
                    <span
                      className="list-sort text-muted"
                      
                    >
                      Resume
                    </span>
                  </th>
                </tr>
              </thead>
              {tableSpin ? (
                <tbody className="list fs-base">
                  {appliedCondidate?.length > 0 ? appliedCondidate?.map?.((ele, index) => {
                    
                    return (
                      <tr key={index}>
                        <td>{ index + 1}</td>
                        <td>
                          {ele?.userDetails?.name ? (
                            <>
                              {/* <div className="avatar avatar-xs align-middle me-2">
                                <img
                                  className="avatar-img rounded-circle"
                                  src="/images/profiledp.png"
                                  alt="..."
                                />
                              </div> */}
                              <span
                                className="item-name text-reset"
                              >
                                {ele?.userDetails?.name}
                              </span>
                            </>
                          ) : (
                            "-"
                          )}
                        </td>
                        <td>
                          <a
                            className="item-email text-reset"
                            href="mailto:john.doe@company.com"
                          >
                            {ele?.userDetails?.email}
                          </a>
                        </td>
                        <td>
                          <Link className="item-name text-reset">
                            {" "}
                            {ele?.userDetails?.phoneNumber
                              ? ele?.userDetails?.phoneNumber
                              : "-"}
                          </Link>
                        </td>
                        <td>
                          <span
                            className="item-phone text-reset"
                          >
                            {ele?.userApplicationStatus ? ele?.userApplicationStatus : "-"}
                          </span>
                        </td>
                        <td>
                          <span
                            className="item-phone text-reset"
                          >
                            {jobDetail?.employmentType ? jobDetail?.employmentType : "-"}
                          </span>
                        </td>
                        {/* <td>
                          <DownloadLink
                            label="downloadCSVFile"
                            filename="One.pdf"
                            exportFile={() => ImageUrl + ele?.resume }
                          />
                        </td> */}
                        <td>
                          <div className='text-start'>
                            <button title='Resume Download' className='form-download' onClick={(e) => handleDownload(e,ImageUrl + ele?.resume, "resume.pdf")} >
                              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-download" viewBox="0 0 16 16">
                                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                              </svg>
                            </button>
                          </div>
                        </td>
                        <td className="text-end">
                          <div className="dropdown">
                            <span
                              className="dropdown-ellipses dropdown-toggle"
                              role="button"
                              data-bs-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <i className="fe fe-more-vertical" />
                            </span>
                            <div className="dropdown-menu dropdown-menu-end">
                              {/* <Link to={`/map/update?id=${ele?._id}`} className="dropdown-item" state={{ status, data: ele }} >
                                Edit
                              </Link> */}
                              <div
                                data-bs-toggle="modal"
                                data-bs-target="#ManageStatusModal"
                                className="dropdown-item"
                                style={{ cursor: "pointer" }}
                                onClick={() => { setUpdateStatusData({ jobId: ele?.jobId, userId: ele?.userDetails?._id, status: ele?.userApplicationStatus }) }}  >
                                Update status
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  }) : <tr align="center">
                    <td colSpan="10">
                      <h1>Data Not Found</h1>
                    </td>
                  </tr>
                  }
                </tbody>
              ) : (
                <tbody>
                  <tr align="center">
                    <td colSpan="10">
                      <Spinwheel />
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>
      <ManageStatusModel
        updateStateData={updateStateData}
        setUpdateStatusData={setUpdateStatusData}
        statusUpdateHandler={statusUpdateHandler}
      />
    </>
  )
}

export default AppliedUsers