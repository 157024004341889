import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { useAdminContext } from '../../../../context/adminContext';
import { createOrUpdateMovieCinemaApi, getAllMovieApi, getAllMovieCinemaPGApi, getAllMovieTicketsApi, removeCinemaApi, updateMovieTicketStatusApi } from '../../../../utils/ApiEndpointFunctions';
import Header from '../../../common/Header';
import ViewMovieTicket from './viewTicket';
import { movieCinemaInitalValue, movieCinemaValidationSchema } from "../../../common/Validation"
import Pagination from '../../../common/Pagination';
import Spinwheel from '../../../common/Spinwheel';
import { ImageUrl } from '../../../../utils/BaseUrl';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import UpdateTicketStatus from '../../../common/updateTicketStatus';
import { toast } from 'react-hot-toast';
import AddCinemaModal from './addCinema';
import RemoveModal from '../../../common/RemoveModal';
import { debounce } from 'lodash';

function MovieCinema() {
  const navigate = useNavigate()
  const [tableSpin, setTableSpin] = useState(true);
  const [cinema, setCinema] = useState([]);
  let [selectedPage, setSelectPage] = useState(0);
  const [page, setPage] = useState(0);
  const cinemaModalId = "cinemaModalId"
  const ticketStatusModalId = "ticketStatusModalId"
  const removeCinemaModalId = "removeCinemaModalId"
  const [addLoader, setAddLoader] = useState(false)
  const [selectedCinema, setSelectedCinema] = useState(null)
  const limit = 10;

  const getAllMovieCinema = async (selectedPage, search) => {
    try {
      setTableSpin(false)
      const res = await getAllMovieCinemaPGApi(search || "", selectedPage + 1, limit)
      if (res.status === 200) {
        setCinema(res.data?.data?.data)
        const totaldata = res.data?.data?.totalData

        setPage(Math.ceil(totaldata / limit));
      }
      setTableSpin(true)
    } catch (error) {
      setTableSpin(true)
    }
  }

  const handlePageClick = (event) => {
    setSelectPage(event.selected);
    getAllMovieCinema(event.selected)
  };

  const handleSubmit = async (values) => {
    try {
      setAddLoader(true)
      const res = await createOrUpdateMovieCinemaApi(values);
      if (res?.status === 200) {
        toast.success(res.data?.message)
  
        getAllMovieCinema(0)
        document.getElementById(cinemaModalId).click()
      }
    } catch (error) {
      console.log(error)
      toast.error(error?.response?.data?.message);
      
    }

    setAddLoader(false)
  }

  const formik = useFormik({
    initialValues: movieCinemaInitalValue,
    onSubmit: handleSubmit,
    validationSchema: movieCinemaValidationSchema,
  })

  const onChange = async (e) => {
    let search = e.target.value;
    getAllMovieCinema(0, search)
  };

  const debouncedOnChange = debounce(onChange, 500);

  useEffect(() => {
    getAllMovieCinema(selectedPage)
  }, [])

  const handleRemoveCinema = async () => {
    try {
      const res = await removeCinemaApi(selectedCinema);
      if (res?.status === 200) {
        toast.success(res.data?.message)
        getAllMovieCinema(0)
        document.getElementById(removeCinemaModalId).click()
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }


  return (
    <>
      {/*<Sidebar />*/}
      <div className="main-content vh-100 vh_md-auto overflow-y-a">
        <Header title="Movie ticket" />
        {/* CARDS */}
        <div className="container-fluid">
          <div className="row">
            <nav className="mb-5">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">
                    <i className="fe fe-home"></i> Dashboard
                  </Link>
                </li>
                <Link to={"/ticket/movie/tickets"} className="breadcrumb-item active" aria-current="page">
                  Movie ticket
                </Link>
                <li className="breadcrumb-item active" aria-current="page">
                  Movie theater
                </li>
              </ol>
            </nav>
            <div className="mb-4" style={{ textAlign: "end" }}>
              <div className='d-flex justify-content-end gap-2' style={{ display: "-webkit-inline-box" }}>
                <div className='d-flex gap-2 align-items-center justify-content-center'>
                  <button data-bs-toggle="modal" data-bs-target={`#${cinemaModalId}`} className="btn-custom w-auto" onClick={formik.resetForm} style={{ cursor: "pointer" }}>
                    Add
                  </button>
                  <div className="position-relative">
                    <input
                      className="form-search me-3"
                      type="search"
                      name=""
                      onChange={debouncedOnChange}
                      placeholder="Search"
                    />
                    <img
                      className="users-search"
                      src="/images/Icon feather-search.svg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <hr />

            <div className="card p-3 table-responsive" >
              <table className="table table-hover table-nowrap card-table">
                <thead>
                  <tr>
                    <th>
                      <span
                        className="list-sort text-muted"
                      >
                        S.No
                      </span>
                    </th>
                    <th>
                      <span
                        className="list-sort text-muted"
                      >
                        Name
                      </span>
                    </th>
                    <th>
                      <span
                        className="list-sort text-muted"
                      >
                        Location
                      </span>
                    </th>
                    <th></th>


                  </tr>
                </thead>
                {
                  tableSpin ?
                    <tbody className="list fs-base">
                      {cinema?.length > 0 ? cinema?.map((ele, index) => {
                        return (<tr key={index} >
                          <td>{index + 1}</td>
                          <td className='position-relative'>
                            {/* <div className="avatar avatar-xs align-middle me-2">
                              <img
                                className="avatar-img rounded-circle"
                                src={ele?.movie?.coverImg ? ImageUrl + ele?.movie?.coverImg : process.env.PUBLIC_URL + "/images/person-circle.svg"}
                                alt=""
                              />
                            </div> */}
                            {ele?.name?.length > 30 ? <span>
                              {ele?.name?.substr(0, 30)}...
                              <div className="tooltip1 item-name text-reset" ></div>
                              <div className='wrapper'>
                                <div className="tooltip1-text">{ele?.name}</div>
                              </div>
                            </span> :
                              <span>{ele?.name || "-"}</span>}

                          </td>
                          <td className='position-relative'>
                            {ele?.location?.length > 30 ? <span>
                              {ele?.location?.substr(0, 30)}...
                              <div className="tooltip1 item-name text-reset" ></div>
                              <div className='wrapper'>
                                <div className="tooltip1-text">{ele?.location}</div>
                              </div>
                            </span> :
                              <span>{ele?.location || "-"}</span>}
                          </td>


                          <td className="text-end">
                            <div className="dropdown">
                              <span
                                className="dropdown-ellipses dropdown-toggle"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <i className="fe fe-more-vertical" />
                              </span>
                              <div className="dropdown-menu dropdown-menu-end">
                                <div data-bs-toggle="modal" data-bs-target={`#${removeCinemaModalId}`} className=' dropdown-item' style={{ cursor: "pointer" }} onClick={() => { setSelectedCinema(ele?._id) }}>
                                  Remove
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                        )
                      }) : <tr align="center">
                        <td colSpan="9">
                          <h1>
                            Data Not Found
                          </h1>
                        </td>
                      </tr>}
                    </tbody> : (
                      <tbody>
                        <tr align="center">
                          <td colSpan="9">
                            <Spinwheel />
                          </td>
                        </tr>
                      </tbody>
                    )
                }
              </table>
            </div>
            <div className="d-flex justify-content-center">
              {cinema?.length > 0 ? <Pagination
                clickHandler={handlePageClick}
                page={page}
                selectedPage={selectedPage}
              />
                : null}
            </div>
          </div>
          <AddCinemaModal formik={formik} modalId={cinemaModalId} addLoader={addLoader} />
          <RemoveModal removeHandler={handleRemoveCinema} modalId={removeCinemaModalId} message={"Are you sure you want to remove?"} />
        </div>
      </div>
    </>
  )
}

export default MovieCinema