import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { arrayMove, List } from "react-movable";
import { Link, useLocation, useParams } from "react-router-dom";
import { deleteCourseChapterByIdApi, getAllChaptersByCourseIdApi, updateCourseChaptersApi } from "../../../../utils/ApiEndpointFunctions";
import { ImageUrl } from "../../../../utils/BaseUrl";
import Header from "../../../common/Header";
import RemoveModal from "../../../common/RemoveModal";
import Spinwheel from "../../../common/Spinwheel";
import ChapterDetailModal from "./ChapterDetailModal";





export default function Chapter() {
  const [removeData, setRemoveData] = useState();
  const [tableSpin, setTableSpin] = useState(true);
  const [allChapter, setAllChapter] = useState([])
  const [chapter, setChapter] = useState();
  const param = useParams();
  const location = useLocation();

  const getAllChapters = async () => {
    try {
      if (param?.id) {
        setTableSpin(false)
        const res = await getAllChaptersByCourseIdApi(param?.id)
        if (res.status === 200) {
          setAllChapter(res.data?.data,)
        }
      }
      setTableSpin(true)
    } catch (error) {
      setTableSpin(true)
      console.log(error)
    }
  }

  const removeHandler = async (e) => {
    e.preventDefault()
    try {
      const res = await deleteCourseChapterByIdApi(removeData);
      if (res.status === 200) {
        toast.success("Removed")
        getAllChapters();
      }
    } catch (error) {
      console.log(error)
    }
  }
  const handleOrderChanged = async ({ oldIndex, newIndex }) => {
    const newArray = arrayMove(allChapter, oldIndex, newIndex);
    let payload = newArray?.map(ele => {
      return {
        chapterTitle: ele?.chapterTitle,
        content: ele?.content,
        thumbnail: ele?.thumbnail,
        type: ele?.type,
        videos: ele?.videos,
        courseId: ele?.courseId
      }
    })
    setAllChapter(newArray);
    await updateCourseChaptersApi(payload, 1);
  };
  useEffect(() => {
    getAllChapters();
  }, [])
  return (
    <>
      {/*<Sidebar />*/}
      <div className="main-content vh-100 vh_md-auto overflow-y-a">
        <Header title="Study" />
        <div className="container-fluid">
          <div className="row">
            <nav className="mb-5">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">
                    <i className="fe fe-home"></i> Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/course" className="breadcrumb-item">
                    Course
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Chapter
                </li>
              </ol>
            </nav>
            <div className="d-flex justify-content-between mb-4 flex-wrap">
              <div className="header-title">{location?.state?.name}</div>
              <div style={{ display: "-webkit-inline-box" }}>
                <div className="dropdown show">
                  <div className="dropdown me-3"><button className="btn btn-second dropdown-toggle" style={{ padding: "7px 10px" }} type="button" data-bs-toggle="dropdown" aria-expanded="false">Action</button>
                    <ul className="dropdown-menu" style={{ marginTop: "-10px" }}>
                      <li><Link className="dropdown-item" to={`/course/chapter/add/${param?.id}`} >Add chapters</Link></li>
                      {allChapter.length > 0 && <li><Link className="dropdown-item" to={`/course/chapter/update`} state={{ courseId: param?.id, courseChapter: allChapter }} >Update chapters</Link></li>}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <hr />
          </div>
          <div className="card p-3 shadow table-responsive" >

            <List
              values={allChapter}
              onChange={handleOrderChanged}
              renderList={(item) => {
                return (
                  <table className="table table-sm table-nowrap card-table course-table">
                    <thead>
                      <tr>
                        <th>
                          <span
                            className="list-sort text-muted"
                          >
                            S.No
                          </span>
                        </th>
                        <th colSpan={2}>
                          <span
                            className="list-sort text-muted"
                          >
                            Title
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody {...item?.props}>{
                      tableSpin ?
                        allChapter?.length > 0 ?
                          item?.children :
                          <tr align="center">
                            <td colSpan="3">
                              <h1> Data Not Found </h1>
                            </td>
                          </tr> :
                        <tr align="center">
                          <td colSpan="4">
                            <Spinwheel />
                          </td>
                        </tr>}</tbody>
                  </table>
                )
              }}
              renderItem={(ele) => {
                return (
                  <tr key={ele.index} >
                    <td data-movable-handle {...ele.props} >{ele.index + 1} </td>
                    <td >
                      {/* Avatar */}
                      <div className="avatar avatar-xs align-middle me-2">
                        <img
                          className="avatar-img rounded-circle"
                          src={ele.value?.thumbnail ? ImageUrl + ele.value?.thumbnail : process.env.PUBLIC_URL + "/images/person-circle.svg"}
                          alt=""
                        />
                      </div>
                      <span
                        className="item-name text-reset"
                      >
                        {ele.value?.chapterTitle}
                      </span>
                    </td>
                    <td className="text-end">

                      <div className="dropdown">
                        <Link
                          className="dropdown-ellipses dropdown-toggle"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i className="fe fe-more-vertical" />
                        </Link>
                        <div className="dropdown-menu dropdown-menu-end">
                          <a data-bs-toggle="modal" data-bs-target="#showChapterDetail" className="dropdown-item" style={{ cursor: "pointer" }} onClick={() => { setChapter(ele.value) }}  >
                            Chapter Details
                          </a>
                          <div data-bs-toggle="modal" data-bs-target="#exampleModal" className="dropdown-item" style={{ cursor: "pointer" }} onClick={() => { setRemoveData(ele?.value?._id) }} >
                            Remove
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                )
              }}
            />
          </div>
          <div className='d-flex justify-content-center'>
          </div>
        </div>
      </div>
      <RemoveModal removeHandler={removeHandler} modalId="exampleModal" message={<>Are you sure you want to<br /> remove this?</>} />
      <ChapterDetailModal chapter={chapter} setChapter={setChapter} />
    </>
  );
}