import React, { useState } from 'react'
import { ImageUrl } from '../../../../utils/BaseUrl'
import { Link } from 'react-router-dom'
import toast from 'react-hot-toast';
import { updateSalaryRangeSelectorApi } from '../../../../utils/ApiEndpointFunctions'
import moment from 'moment';

function JobsDetails(props) {
  const { jobDetails, setJobDetails, getJobList } = props
  
  const [salaryRange,setSalaryRange] = useState(false)

  const selectorHandler = async (isRange) => {
    try {
      setSalaryRange(true)
      const res = await updateSalaryRangeSelectorApi({ id: jobDetails?._id, status: isRange });
      if (res.status === 200) {
        toast.success("Update successfully");
        setJobDetails({ ...jobDetails, rangeSelector: isRange })
        getJobList();
      }
    } catch (error) {
      console.log(error)
    }
    setSalaryRange(false)
  }
  return (
    <div className="modal fade" id="jobDetailsModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-sm modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header border-0">
            <button type="button" className="btn-close block-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <h1 className='text-center'>{jobDetails?.title}</h1>
            {/* <img src="/images/blockuser.png" alt="" width={202} srcSet="" />
            <h1 className="mb-0">jobDetails Modal</h1> */}
            <h2>
              {jobDetails?.logo && <div className="avatar avatar-xs align-middle me-2">
                <img
                  className="avatar-img rounded-circle"
                  src={jobDetails?.logo ? ImageUrl + jobDetails?.logo : process.env.PUBLIC_URL + "/images/person-circle.svg"}
                  alt="..."
                />
              </div>}
              &nbsp;Job details

            </h2>
            <hr />
            <table className='table'>
              <tbody>
                <tr>
                  <td>Title</td>
                  <td>{jobDetails?.title ? jobDetails?.title : '-'}</td>
                </tr>
                <tr>
                  <td>Status</td>
                  <td>{jobDetails?.jobStatus ? jobDetails?.jobStatus : '-'}</td>
                </tr>
                <tr>
                  <td>Employment</td>
                  <td>{jobDetails?.employmentType ? jobDetails?.employmentType : '-'}</td>
                </tr>
                <tr>
                  <td>Required employee</td>
                  <td>{jobDetails?.noOfPosition ? jobDetails?.noOfPosition : '-'}</td>
                </tr>
                <tr>
                  <td>Work experience</td>
                  <td>{jobDetails?.workExp ? jobDetails?.workExp : '-'}</td>
                </tr>
                <tr>
                  <td>Required skills</td>
                  <td style={{overflowWrap:"anywhere"}}>{jobDetails?.skillsRequired?.length ? jobDetails?.skillsRequired?.join(", ") : '-'}</td>
                </tr>
                <tr>
                  <td>Description</td>
                  <td className='position-relative'>
                    {jobDetails?.description ? (
                      <div className="tooltip1">
                        {jobDetails?.description?.substr(0, 10)}...
                        <div className='wrapper'>
                          <div className="tooltip1-text">{jobDetails?.description}</div>
                        </div>
                      </div>
                    ) : (
                      "-"
                    )}
                  </td>
                </tr>
                <tr>
                  <td>Time of job posting</td>
                  <td>{jobDetails?.createdAt ? moment(jobDetails?.createdAt).format("DD/MM/YYYY HH:mm")  : '-'}</td>
                </tr>
                <tr>
                  <td>Salary range</td>
                  <td> {jobDetails?.salaryRange ?"฿ " + jobDetails?.salaryRange : '-'}</td>
                </tr>
                <tr>
                  <td>Location</td>
                  <td>{jobDetails?.location ? jobDetails?.location : '-'}</td>
                </tr>
                <tr>
                  <td> <label className="form-check-label" htmlFor="Selector">Hide salary details from candidates</label></td>
                  <td >
                    <div className="form-check form-switch map-togle-container d-flex justify-content-center">
                      <input className="form-check-input text-end" disabled={salaryRange} type="checkbox" name='Selector' role="switch" id="Selector" checked={jobDetails?.rangeSelector || false} onChange={(e) => { selectorHandler(!jobDetails?.rangeSelector) }} />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
         
        </div>
      </div>
    </div>
  
  )
}

export default JobsDetails