import React  from 'react'
import { Link } from 'react-router-dom'
import Spinwheel from '../../common/Spinwheel'

function Table({ tableData, status, selectedPage, setRemoveData, tableSpin, limit, setModalUpdate, setShowModalData }) {

  return (
    <div className="card p-3 table-responsive" >
      <table className="table table-sm table-hover table-nowrap card-table">
        <thead>
          <tr>
            <th>
              <span
                className="list-sort text-muted"
                
              >
                S.No
              </span>
            </th>
            <th>
              <span
                className="list-sort text-muted"
                
              >
                Title
              </span>
            </th>
            <th>
              <span
                className="list-sort text-muted"
                
              >
                SubTitle
              </span>
            </th>

            <th>
              <span
                className="list-sort text-muted"

              >
                Content Source
              </span>
            </th>

            <th>
              <span
                className="list-sort text-muted"

              >
               Type
              </span>
            </th>

            <th colSpan={2}>
              <span
                className="list-sort text-muted"
                
              >
                Status
              </span>
            </th>
          </tr>
        </thead>
        {
          tableSpin ?
            <tbody className="list fs-base">
              {tableData?.length > 0 ? tableData?.map((ele, index) => {
                return (<tr key={index} >
                  <td>{((selectedPage) * limit) + index + 1}</td>
                  <td className='position-relative'>
                    <span
                      className="item-name text-reset" 
                      data-bs-toggle="modal" data-bs-target="#videoStatusModal"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => setShowModalData(ele)}
                    >
                      {ele?.title ? (
                        <div className="tooltip1">
                          {ele?.title?.substr(0, 30)}...
                          <div className='wrapper'>
                            <div className="tooltip1-text">{ele?.title}</div>
                        </div>
                        </div>
                      ) : (
                        "-"
                      )}
                    </span>
                  </td>
                  <td className='position-relative'>
                    {ele?.subtitle ? (
                      <div className="tooltip1">
                        {ele?.subtitle?.substr(0, 30)}...
                        <div className='wrapper'>
                          <div className="tooltip1-text">{ele?.subtitle}</div>
                        </div>
                      </div>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td className='position-relative'>
                    {ele?.contentSource ? (
                      <div className="tooltip1">
                        {ele?.contentSource?.substr(0, 30)}...
                        <div className='wrapper'>
                          <div className="tooltip1-text">{ele?.contentSource}</div>
                        </div>
                      </div>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td className='position-relative'>
                    <div className='text-capitalize'> {ele?.type}</div>
                  </td>
                  <td className='position-relative'>
                    <div className='text-capitalize'> {ele?.isPublish === true ? "Published" : "Unpublished"}</div>
                  </td>
                  <td className="text-end">
                    <div className="dropdown">
                      <span
                        className="dropdown-ellipses dropdown-toggle"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i className="fe fe-more-vertical" />
                      </span>
                      <div className="dropdown-menu dropdown-menu-end">
                        <Link to={`/entertainment/update`} className="dropdown-item" state={{ status, data: { ...ele, id: ele?._id } }} >
                          Edit
                        </Link>
                        <div data-bs-toggle="modal" data-bs-target="#removeNews" className="dropdown-item" style={{ cursor: "pointer" }} onClick={() => { setRemoveData(ele) }} >
                          Remove
                        </div>
                        <div data-bs-toggle="modal"
                          data-bs-target="#publishedUnpublished"
                          className="dropdown-item"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setModalUpdate({
                              id: ele?._id,
                              status: ele?.isPublish,
                              type: "Published/Unpublished"
                            })
                          }}
                        >
                          {ele?.isPublish === true ? "Unpublished" : "Published"}
                        </div>
                        {/* <div data-bs-toggle="modal"
                          data-bs-target="#updateStatusModal"
                          className="dropdown-item"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setModalUpdate({
                              id: ele?._id,
                              status: ele?.isBanner,
                              type: "Mark as a Banner"
                            })
                          }} >
                          Mark as a Banner
                        </div> */}
                      </div>
                    </div>
                  </td>
                </tr>
                )
              }) : <tr align="center">
                <td colSpan="12">
                  <h1>
                    Data Not Found
                  </h1>
                </td>
              </tr>}
            </tbody> : (
              <tbody>
                <tr align="center">
                  <td colSpan="12">
                    <Spinwheel />
                  </td>
                </tr>
              </tbody>
            )
        }
      </table>
    </div>
  )
}

export default Table