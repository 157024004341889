import { useFormik } from 'formik';
import React, { useState } from 'react'
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { useAdminContext } from '../../../context/adminContext';
import Sidebar from '../../../Layout/Sidebar';
import { deleteSosMessagesByIdApi, getAllSosMessagesByAdminApi, updateSosMessagesByIdApi } from '../../../utils/ApiEndpointFunctions';
import { ImageUrl } from '../../../utils/BaseUrl';
import Header from '../../common/Header';
import RemoveModal from '../../common/RemoveModal';
import Spinwheel from '../../common/Spinwheel';
import { addSosMessIntialValues, addSosMessValidationSchema } from '../../common/Validation';
import AddMessageModal from './AddMessageModal';

const SosMessageList = () => {
  const { adminDetail } = useAdminContext();
  const [tableSpin, setTableSpin] = useState(false);
  const [allMessage, setAllMessage] = useState([]);
  const [removeData,setRemoveData] = useState([]);

  const removeMessage = async (e) => {
    e.preventDefault();
    try {
      const res = await deleteSosMessagesByIdApi(removeData)
      if(res.status === 200) {
        toast.success("SOS message deleted")
        getAllMessage();
      }
    } catch (error) {
      toast.error("Something went wrong")
      console.log(error)     
    }
  }

  const getAllMessage = async () => {
    setTableSpin(false)
    try {
      const res = await getAllSosMessagesByAdminApi()
      if(res.status === 200) {
        setAllMessage(res.data?.data)
      }
      setTableSpin(true)

    } catch (error) {
      console.log(error);      
      setTableSpin(true)
    }
  }

  const editHandler = (ele) => {
    messageFormik.setValues({name:ele?.name,id:ele?._id})
  }

  const updateMessageHandle = async (values) => {
    try {
      const res = await updateSosMessagesByIdApi(values);
      if (res.status === 200) {
        toast.success("SOS message updated")
        getAllMessage();
      }
    } catch (error) {
      toast.error("Something went wrong")
    }

  }

  const messageFormik = useFormik({
    initialValues: addSosMessIntialValues,
    onSubmit: updateMessageHandle,
    validationSchema: addSosMessValidationSchema
  })
  
  useEffect(() => {
    getAllMessage();
  },[])
  return (
    <>
      {/*<Sidebar />*/}
      <div className="main-content vh-100 vh_md-auto overflow-y-a">
        <Header title="SOS" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <nav className="mb-5">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">
                      <i className="fe fe-home"></i> Dashboard
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/sos">
                      SOS
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Message-list
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="card" >
            <table className="table table-sm table-hover table-nowrap card-table">
              <thead>
                <tr>
                  <th>
                    <span
                      className="list-sort text-muted"
                      
                    >
                      S.No
                    </span>
                  </th>
                  <th colSpan={2}>
                    <span
                      className="list-sort text-muted"
                      
                    >
                      Message
                    </span>
                  </th>
                </tr>
              </thead>
              {
                tableSpin ?
                  <tbody className="list fs-base">
                    {allMessage?.length > 0 ? allMessage?.map((ele, index) => {
                      return (<tr key={index} >
                        <td>{index + 1}</td>
                        
                        <td className='position-relative'>
                          {ele?.name ? (
                            <div className="tooltip1">
                              {ele?.name?.substr(0, 20)}...
                              <div className='wrapper'>
                              <div className="tooltip1-text">{ele?.name}</div>
                              </div>
                            </div>
                          ) : (
                            "-"
                          )}
                        </td>
                        <td className="text-end">
                          <div className="dropdown">
                            <span
                              className="dropdown-ellipses dropdown-toggle"
                              role="button"
                              data-bs-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <i className="fe fe-more-vertical" />
                            </span>
                            <div className="dropdown-menu dropdown-menu-end">
                              <div data-bs-toggle="modal" data-bs-target="#addMessage" className="dropdown-item" style={{ cursor: "pointer" }} onClick={() => {editHandler(ele)}} >
                                Edit
                              </div>
                              <div data-bs-toggle="modal" data-bs-target="#removeMessage" className="dropdown-item" style={{ cursor: "pointer" }} onClick={() => { setRemoveData(ele?._id) }}  >
                                Remove
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      )
                    }) : <tr align="center">
                      <td colSpan="5">
                        <h1>
                          Data Not Found
                        </h1>
                      </td>
                    </tr>}
                  </tbody> : (
                    <tbody>
                      <tr align="center">
                        <td colSpan="5">
                          <Spinwheel />
                        </td>
                      </tr>
                    </tbody>
                  )
              }
            </table>
          </div>
        </div>
      </div>
      <RemoveModal removeHandler={removeMessage} modalId="removeMessage" message={<>Are you sure you want to<br /> remove this?</>} />
      <AddMessageModal formik={messageFormik} type="Save" />
    </>
  )
}

export default SosMessageList