  import React, { useState } from 'react'
  import { Link } from 'react-router-dom'
  import Spinwheel from '../../common/Spinwheel'
  import { arrayMove, List } from "react-movable";
  import { removeNewsFromCarouselApi, updateNewsCarouselApi } from '../../../utils/ApiEndpointFunctions';
  import toast from "react-hot-toast";
  import RemoveModal from '../../common/RemoveModal';

function CarouselTable({ tableData, resetTableData, selectedPage, tableSpin, limit, setNewsData, setTableData, setRemoveData,  carouselFormik, carouselModalId }) {
    // const [removeData, setRemoveData] = useState();
    const removeCarouselModalId = "removeCarouselModalId"

    const handleOrderChanged = async ({ oldIndex, newIndex }) => {
      const newArray = arrayMove(tableData, oldIndex, newIndex);
      setTableData(newArray);
      let payload = newArray.map(ele => ({_id: ele._id}));
      try {
        const res = await updateNewsCarouselApi({ carousel: payload });
        if(res.status === 200) {
          toast.success(res.data?.message)
        }
      } catch (error) {
        console.log(error);
        toast.error("Something went wrong")
      }
    };

   
    

    return (
      <>
      <List 
          values={tableData}
          onChange={handleOrderChanged}
          renderList={(item) => {
            return (
              <div className='table-responsive'>
                <table className="table table-hover table-nowrap card-table">
                  <thead>
                    <tr>
                      <th>
                        <span
                          className="list-sort text-muted"

                        >
                          S.No
                        </span>
                      </th>
                      <th>
                        <span
                          className="list-sort text-muted"

                        >
                          Title
                        </span>
                      </th>
                      <th>
                        <span
                          className="list-sort text-muted"

                        >
                          HeadLine
                        </span>
                      </th>
                      <th>
                        <span
                          className="list-sort text-muted"

                        >
                          Publisher Name
                        </span>
                      </th>
                      <th>
                        <span
                          className="list-sort text-muted"

                        >
                          Type
                        </span>
                      </th>
                      <th colSpan={2}>
                        <span
                          className="list-sort text-muted"

                        >
                          Status
                        </span>
                      </th>
                    </tr>
                  </thead>

                  <tbody {...item?.props} className="list fs-base">
                    {tableSpin ? tableData?.length > 0 ? item?.children : <tr align="center">
                      <td colSpan="12">
                        <h1>
                          Data Not Found
                        </h1>
                      </td>
                    </tr> : <tr align="center">
                      <td colSpan="12">
                        <Spinwheel />
                      </td>
                    </tr>}
                  </tbody>
                </table>
              </div>
            )
          }}

          renderItem={(ele) => {
          
            return (
              <tr {...ele.props} key={ele?.index} style={{ ...ele.props.style, zIndex: undefined }} >
                <td data-movable-handle  >{((selectedPage) * limit) + ele.index + 1}</td>
                <td className='position-relative'>
                  <span
                    className="item-name text-reset"
                    data-bs-toggle="modal" data-bs-target="#videoStatusModal"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => setNewsData(ele?.value)}
                  >
                    {ele?.value?.newsTitle ? (
                      <div className="tooltip1">
                        {ele?.value?.newsTitle?.substr(0, 30)}...
                        <div className='wrapper'>
                          <div className="tooltip1-text">{ele?.value?.newsTitle}</div>
                        </div>
                      </div>
                    ) : (
                      "-"
                    )}
                  </span>
                </td>
                <td className='position-relative'>
                  {ele?.value?.newsHeading ? (
                    <div className="tooltip1">
                      {ele?.value?.newsHeading?.substr(0, 30)}...
                      <div className='wrapper'>
                        <div className="tooltip1-text">{ele?.value?.newsHeading}</div>
                      </div>
                    </div>
                  ) : (
                    "-"
                  )}
                </td>
                <td className='position-relative'>
                  {ele?.value?.publishBy ? (
                    <div className="tooltip1">
                      {ele?.value?.publishBy?.substr(0, 30)}...
                      <div className='wrapper'>
                        <div className="tooltip1-text">{ele?.value?.publishBy}</div>
                      </div>
                    </div>
                  ) : (
                    "-"
                  )}
                </td>
                <td className='position-relative'>
                  <div className='text-capitalize'> {ele?.value?.type}</div>
                </td>
                <td className='position-relative'>
                  <div className='text-capitalize'> {ele?.value?.isPublished === true ? "Published" : "Unpublished"}</div>
                </td>

                <td className="text-end">
                  <div className="dropstart">
                    <span
                      className=" "
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="fe fe-more-vertical" />
                    </span>
                    <div className="dropdown-menu">
                      <>
                        <div className="dropdown-item" data-bs-toggle="modal" data-bs-target={`#${removeCarouselModalId}`} style={{ cursor: "pointer" }} onClick={() => { setRemoveData(ele.value._id) }} >
                          Remove
                        </div>
                      </> 
                    </div>
                  </div>
                </td>
              </tr>
            )
          }}
      />

      </>

    )
  }

  export default CarouselTable