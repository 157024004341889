import { useFormik } from "formik";
import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { resetPasswordValidation, resetpasswordInitial } from "../../common/Validation"
import { resetPassword } from "../../../utils/ApiEndpointFunctions"
import toast from "react-hot-toast";
import Spinwheel from "../../common/Spinwheel";

const Resetpassword = () => {
    const [eyebutton, setEyeButton] = useState({ password: false, confirmPassword: false });
    const location = useLocation();
    const [resetSpinWheel, setResetSpinWheel] = useState(false);
    const token = location.state

    const navigate = useNavigate();
    const handleEye = (flag) => {
        const ele = document.getElementById(flag)
        ele.type = ele.type === "password" ? "text" : "password";
        setEyeButton({ ...eyebutton, [flag]: !eyebutton[flag] })
    }
    const handleSubmit = async (values) => {
        const payload = {
            token: token,
            password: values.password
        }
        if (token) {
            setResetSpinWheel(true)
            const res = await resetPassword(payload);
            if (res.status === 200 && res.data?.status === 1) {
                toast.success("Password reset successfully")
                navigate("/")
                setResetSpinWheel(false)
            } else {
                toast.error(res.data?.message)
                setResetSpinWheel(false)
            }
        }

    }
    const resetFormik = useFormik({
        initialValues: resetpasswordInitial,
        onSubmit: handleSubmit,
        validationSchema: resetPasswordValidation
    })
    document.body.classList.add("body-bg");
    return (
        <>
            <main>
                <section className="section-bg">
                    <div className="container-fluid">
                        <div className="row top-bg">
                            <img
                                className="left-mask-bg d-none d-xl-block"
                                src="/images/Mask Group 650.png"
                                alt=""
                            />
                            <img
                                className="right-mask-bg d-none d-xl-block"
                                src="/images/Mask Group 650.png"
                                alt=""
                            />
                            <div className="d-flex justify-content-center">
                                <img
                                    className="bottom-mask-bg d-none d-xl-block"
                                    src="/images/Mask Group 650.png"
                                    alt=""
                                />
                            </div>
                            <div className="col-xl-3 col-lg-5 col-md-7 col-sm-12 mx-auto">
                                <div className="d-grid text-center">
                                    <div className="d-flex justify-content-center">
                                        <img
                                            src="/images/logo.png"
                                            alt="logo"
                                            height={104}
                                            width={104}
                                        />
                                    </div>
                                    <br />
                                    <br />
                                    <h2 className="sign-in">Reset Password?</h2>
                                    <p>
                                        Reset your Password and create new <br /> Password.
                                    </p>
                                    <br />
                                    <form action onSubmit={resetFormik.handleSubmit}>
                                        <label
                                            className="form-label text-start d-block"
                                            htmlFor="password"
                                        >
                                            Password
                                        </label>
                                        <div className="position-relative">
                                            <input
                                                className="form-control"
                                                type="password"
                                                name="password"
                                                id="password"
                                                placeholder="Enter your password"
                                                value={resetFormik.values.password}
                                                onChange={resetFormik.handleChange}
                                                onBlur={resetFormik.handleBlur}
                                            />
                                            <div style={{ cursor: "pointer" }} onClick={(e) => { handleEye("password") }} >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={16}
                                                    height={16}
                                                    fill="currentColor"
                                                    className={`bi bi-eye-fill input-icon ${eyebutton.password ? "" : "d-none"}`}
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                                                    <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                                                </svg>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={16}
                                                    height={16}
                                                    fill="currentColor"
                                                    className={`bi bi-eye-slash-fill input-icon ${eyebutton.password ? "d-none" : ""}`}
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />
                                                    <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z" />
                                                </svg>
                                            </div>
                                            {resetFormik.touched.password && resetFormik.errors.password ? (<div className='req-error'>{resetFormik.errors.password}</div>) : null}

                                        </div>
                                        <br />
                                        <label
                                            className="form-label text-start d-block"
                                            htmlFor="confirmPassword"
                                        >
                                            Confirm Password
                                        </label>
                                        <div className="position-relative">
                                            <input
                                                className="form-control"
                                                type="password"
                                                name="confirmPassword"
                                                id="confirmPassword"
                                                placeholder="Enter your password again"
                                                value={resetFormik.values.confirmPassword}
                                                onChange={resetFormik.handleChange}
                                                onBlur={resetFormik.handleBlur}
                                            />
                                            <div style={{ cursor: "pointer" }} onClick={(e) => { handleEye("confirmPassword") }}>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={16}
                                                    height={16}
                                                    fill="currentColor"
                                                    className={`bi bi-eye-fill input-icon ${eyebutton.confirmPassword ? "" : "d-none"}`}
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                                                    <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                                                </svg>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={16}
                                                    height={16}
                                                    fill="currentColor"
                                                    className={`bi bi-eye-slash-fill input-icon ${eyebutton.confirmPassword ? "d-none" : ""}`}
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />
                                                    <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z" />
                                                </svg>
                                            </div>
                                            {resetFormik.touched.confirmPassword && resetFormik.errors.confirmPassword ? (<div className='req-error'>{resetFormik.errors.confirmPassword}</div>) : null}

                                        </div>
                                        <br />
                                        <br />
                                        <button type="submit" className="btn-custom" disabled={resetSpinWheel}>{resetSpinWheel ? <Spinwheel sizeClass="spin-small" /> : "CONFIRM"}</button>
                                        {/* <br />
                                        <br />
                                        <span>Enter your password again </span>
                                        <Link to="" className="text-decoration-underline" style={{ color: "#9B7343" }}>
                                            Send again
                                        </Link> */}
                                    </form>
                                    <br />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
};

export default Resetpassword;
