import React from 'react'
import { ImageUrl } from '../../../utils/BaseUrl'
import moment from 'moment'
function ModalVideo({ showModalData, setShowModalData }) {
  return (
    <div className="modal fade" id="videoStatusModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static"
      data-bs-keyboard="false" >
      <div className="modal-dialog modal-lg modal-dialog-centered" >
        <div className="modal-content">
          <div className="modal-header border-0">
            <button type="button" className="btn-close block-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { setShowModalData({})}}></button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-7 d-flex">
                <div className='w-100'>
                  {showModalData?.type === "video" ? <video height={220} controls className='rounded' poster={showModalData?.thumbnail ? ImageUrl + showModalData?.thumbnail : "/images/invite.png"} width={"100%"} >
                    <source src={showModalData?.video ? ImageUrl + showModalData?.video : '/video/demo.mp4'} />
                  </video> :
                    <img src={showModalData?.thumbnail ? ImageUrl + showModalData?.thumbnail : "/images/image.png"} alt="articles" style={{ height: 200, width: 400, objectFit: 'fill' }} />
                  }
                  <br />
                  <br />  
                  <p dangerouslySetInnerHTML={{__html:showModalData?.content}}></p>
                </div>
                
              </div>
              <div className="col-5">
                <div className="mb-3">
                  <h4 className='fw-bold'>Title</h4>
                  <p className='mb-0'>{showModalData?.title}</p>
                  <hr className='mt-1 fw-bold' style={{ height: '2px' }} />
                </div>
                <div className="mb-3">
                  <h4 className='fw-bold'>Subtitle</h4>
                  <p className='mb-0'>{showModalData?.subtitle}</p>
                  <hr className='mt-1 fw-bold' style={{ height: '2px' }} />
                </div>
                <div className="mb-3">
                  <h4 className='fw-bold'>Content source</h4>
                  <p className='mb-0'>{showModalData?.contentSource}</p>
                  <hr className='mt-1 fw-bold' style={{ height: '2px' }} />
                </div>
                <div className="mb-3">
                  <h4 className='fw-bold'>Creator name</h4>
                  <p className='mb-0'>{showModalData?.creatorName}</p>
                  <hr className='mt-1 fw-bold' style={{ height: '2px' }} />
                </div>
                <div className="mb-3">
                  <h4 className='fw-bold'>Publish date</h4>
                  <p className='mb-0'>{showModalData?.publishDate && moment(showModalData?.publishDate).format("DD/MM/YYYY")}</p>
                  <hr className='mt-1 fw-bold' style={{ height: '2px' }} />
                </div>
                <div className="mb-3">
                  <h4 className='fw-bold'>Published</h4>
                  <p className='mb-0'>{showModalData?.isPublish ? 'Yes' : 'No'}</p>
                  <hr className='mt-1 fw-bold' style={{ height: '2px' }} />
                </div>
                <div className="mb-3">
                  <h4 className='fw-bold'>Popular</h4>
                  <p className='mb-0'>{showModalData?.isPopular ? 'Yes' : 'No'}</p>
                  <hr className='mt-1 fw-bold' style={{ height: '2px' }} />
                </div>                
                <div className="mb-3">
                  <h4 className='fw-bold'>Banner</h4>
                  <p className='mb-0'>{showModalData?.isBanner ? 'Yes' : 'No'}</p>
                  <hr className='mt-1 fw-bold' style={{ height: '2px' }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModalVideo