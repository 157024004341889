import { debounce, update } from 'lodash';
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { Link, useLocation, useParams } from "react-router-dom";
import { deleteProductByIdApi, getAllProductsByShopIdApi, getDetailsByShopIdApi, updateProductstatusByIdApi } from '../../../../utils/ApiEndpointFunctions';
import Header from '../../../common/Header';
import Pagination from '../../../common/Pagination';
import RemoveModal from '../../../common/RemoveModal';
import Spinwheel from '../../../common/Spinwheel';
import ProductView from './ProductView';
import { useFormik } from 'formik';
import * as Yup from 'yup'
import UpdateProductStatus from './UpdateProductStatus';

function Product() {
  const [tableSpin, setTableSpin] = useState(true);
  const [products, setProducts] = useState([]);
  const [searchText, setSearchText] = useState("")
  const [removeData, setRemoveData] = useState(null);
  const [selectedPage, setSelectPage] = useState(0);
  const [page, setPage] = useState(0);
  const [selectItem, setSelectItem] = useState();
  const [shopDetails,setShopDetails] = useState()
  const limit = 10;
  const params = useParams();
  const updateStatusModalId="updateStatusModalId"
  const location = useLocation();
  const [updateLoader, setUpdateLoader] = useState(false);
  const getProductList = async (selectedPage) => {
    try {
      setTableSpin(false)
      const res = await getAllProductsByShopIdApi(limit, selectedPage + 1, searchText, params?.vendorId)
    if (res.status === 200) {
      setProducts(res.data?.data?.allProductsData)
      const totaldata = res.data?.data?.totalData
      setPage(Math.ceil(totaldata / limit));
    }
    setTableSpin(true)
    } catch (error) {
      setTableSpin(true)
      console.log(error)
    }
  }

  const removeJobsHandler = async () => {
    const res = await deleteProductByIdApi(removeData);
    if (res.status === 200) {
      toast.success(res.data?.message)
      getProductList(selectedPage)
    }
  }

  const onChange = async (e) => {
    let search = e.target.value;
    setTableSpin(false);
    setSearchText(search)
    const res = await getAllProductsByShopIdApi(params?.shopId, limit, 1, search, params?.vendorId);
    if (res.status === 200) {
      setProducts(res.data?.data?.allProductsData)
      const totaldata = res.data?.data?.totalData
      setPage(Math.ceil(totaldata / limit));
      setSelectPage(0)
      setTableSpin(true);
    }
  };

  const debouncedOnChange = debounce(onChange, 500);

  const handlePageClick = (event) => {
    setSelectPage(event.selected);
    getProductList(event.selected)
  };

  // const getDetailsByShopid = async () => {
  //   try {
  //     const res = await getDetailsByShopIdApi(params?.shopId);
  //     if(res.status===200) {
        
  //       setShopDetails(res.data?.data)
  //     }
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  useEffect(() => {
    getProductList(selectedPage)
    // getDetailsByShopid();
  }, [])

  const updateStatusFormik = useFormik({
    initialValues:{status:""},
    onSubmit:async (values) => {
      try {
        setUpdateLoader(true);
        const res = await updateProductstatusByIdApi({...values,_id:removeData})
        if(res.status===200) {
          toast.success(res.data?.message)
          getProductList(selectedPage)
          document.getElementById(updateStatusModalId).click()
        }
      } catch (error) {
        if (error && error?.response) return toast.error(error.response?.data?.message)
        toast.error("something went wrong")
      }
      setUpdateLoader(false);
    },
    validationSchema:Yup.object().shape({
      status:Yup.string().required("Status is required")
    })
  })

  return (
    <>
      <div className="main-content vh-100 vh_md-auto overflow-y-a">
        <Header title={params?.vendorId ? "Vendor" : "Shop"} />
        {/* CARDS */}
        <div className="container-fluid">
          <div className="row">
            <nav className="mb-5">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">
                    <i className="fe fe-home"></i> Dashboard
                  </Link>
                </li>
                {params?.vendorId && <li className="breadcrumb-item">
                  <Link to="/vendor" className="breadcrumb-item">
                    Vendor
                  </Link>
                </li>}
                <li className="breadcrumb-item active" aria-current="page">
                  Products
                </li>
              </ol>
            </nav>
            <div className="mb-4 d-flex justify-content-between" style={{ textAlign: "end" }} >
              <div className="header-title">{shopDetails?.name}</div>
              <div style={{ display: "-webkit-inline-box" }} >
                <div className="dropdown show">
                  <div className="dropdown me-3"><button className="btn btn-second dropdown-toggle" style={{ padding: "7px 10px" }} type="button" data-bs-toggle="dropdown" aria-expanded="false">Add</button>
                    <ul className="dropdown-menu" style={{ marginTop: "-10px" }}>
                      <li><Link className="dropdown-item" to={params?.vendorId ? `/vendor/product/add/${params?.vendorId}` :`/product/add`} >Product</Link></li>
                    </ul>
                  </div>
                </div>
                <div className="position-relative">
                  <input
                    className="form-search me-3"
                    type="search"
                    name=""
                    onChange={debouncedOnChange}
                    placeholder="Search"
                  />
                  <img
                    className="users-search"
                    src="/images/Icon feather-search.svg"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <hr />

            <div className="card p-3 table-responsive" >
              <table className="table table-hover table-nowrap card-table">
                <thead>
                  <tr>
                    <th>
                      <span
                        className="list-sort text-muted"
                      >
                        S.No
                      </span>
                    </th>
                    <th>
                      <span
                        className="list-sort text-muted"

                      >
                        Name
                      </span>
                    </th>
                    <th>
                      <span
                        className="list-sort text-muted"

                      >
                        Category
                      </span>
                    </th>
                    <th>
                      <span
                        className="list-sort text-muted"

                      >
                        Subcategory
                      </span>
                    </th>
                    <th>
                      <span
                        className="list-sort text-muted"

                      >
                        Price
                      </span>
                    </th>
                    <th>
                      <span
                        className="list-sort text-muted"

                      >
                        Discount %
                      </span>
                    </th>

                    <th>
                      <span className='list-sort text-muted'>
                        Status
                      </span>
                    </th>

                    <th>
                      <span className='list-sort text-muted'>
                        total sell
                      </span>
                    </th>

                    <th colSpan={2}>
                      <span
                        className="list-sort text-muted"

                      >
                        Size
                      </span>
                    </th>
                  </tr>
                </thead>
                {
                  tableSpin ?
                    <tbody className="list fs-base">
                      {products?.length > 0 ? products?.map((ele, index) => {
                        return (<tr key={index} >
                          <td>{((selectedPage) * limit) + index + 1}</td>
                          <td>
                            <span
                              className="item-name text-reset"
                              // to={`/job/applied-users/${ele?._id}`}
                              // state={{ jobName: ele?.title }}

                            >
                              {ele?.name ? ele?.name : "-"}
                            </span>
                          </td>
                          <td>
                            <span
                              className="item-phone text-reset"
                            >
                              {ele?.category ? ele?.category?.name : "-"}
                            </span>
                          </td>
                          <td>
                            <span
                              className="item-phone text-reset"
                            >
                              {ele?.subcategory ? ele?.subcategory?.name : "-"}
                            </span>
                          </td>
                          <td>
                            <span
                              className="item-phone text-reset"
                            >
                              {ele?.price ? ele?.price : "-"}
                            </span>
                          </td>
                          <td>
                            <span
                              className="item-phone text-reset"
                            >
                              {ele?.discount ? ele?.discount : "-"}
                            </span>
                          </td>
                          <td>
                            <span>
                              {ele?.status ? ele?.status : "-"}
                            </span>
                          </td>

                          <td>
                            <span
                              className="item-phone text-reset"
                            >
                              { ele?.totalSell}
                            </span>
                          </td>
                          <td>
                            <span
                              className="item-phone text-reset"
                            >
                              {ele?.size?.length ? ele?.size?.join(", ") : "-"}
                            </span>
                          </td>
                          <td className="text-end">
                            <div className="dropdown">
                              <span
                                className="dropdown-ellipses dropdown-toggle"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <i className="fe fe-more-vertical" />
                              </span>
                              <div className="dropdown-menu dropdown-menu-end">
                                <Link to={params?.vendorId ? `/vendor/product/update/${params?.vendorId}` : `/product/update`}  className="dropdown-item" state={{data: ele}} onClick={() => { }}>
                                  Edit
                                </Link>
                                <div data-bs-toggle="modal" data-bs-target={`#${updateStatusModalId}`} className="dropdown-item" style={{ cursor: "pointer" }} onClick={() => { setRemoveData(ele?._id); updateStatusFormik.setFieldValue("status",ele?.status); }}  >
                                  Update status
                                </div>
                                <div data-bs-toggle="modal" data-bs-target="#removeModal" className="dropdown-item" style={{ cursor: "pointer" }} onClick={() => { setRemoveData(ele?._id); }}  >
                                  Remove
                                </div>
                                <div data-bs-toggle="modal" data-bs-target="#showDetail" className="dropdown-item" style={{ cursor: "pointer" }} onClick={() => { setSelectItem(ele) }}  >
                                  Product details
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                        )
                      }) : <tr align="center">
                        <td colSpan="9">
                          <h1>
                            Data Not Found
                          </h1>
                        </td>
                      </tr>}
                    </tbody> : (
                      <tbody>
                        <tr align="center">
                          <td colSpan="9">
                            <Spinwheel />
                          </td>
                        </tr>
                      </tbody>
                    )
                }
              </table>

            </div>
          </div>
          <div className="d-flex justify-content-center">
            {products?.length > 0 ? <Pagination
              clickHandler={handlePageClick}
              page={page}
              selectedPage={selectedPage}
            />
              : null}
          </div>

        </div>
      </div>

      <RemoveModal removeHandler={removeJobsHandler} modalId="removeModal" />
      <ProductView selectItem={selectItem} setSelectItem={setSelectItem} message={<>Are you sure you want to<br /> remove this?</>} />
      <UpdateProductStatus formik={updateStatusFormik} modalId={updateStatusModalId} addLoader={updateLoader} />
    </>
  )
}

export default Product