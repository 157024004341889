import React from "react";
import { Link } from "react-router-dom";
import { useAdminContext } from "../../../context/adminContext";
import {ImageUrl} from "../../../utils/BaseUrl"
import Sidebar from "../../../Layout/Sidebar";
import Header from "../../common/Header";




export default function Administrators() {
    const { adminDetail, } = useAdminContext()

    return (
        <>
            {/*<Sidebar />*/}
            <div className="main-content vh-100 vh_md-auto overflow-y-a">
               
                <Header title="Administrators/Vendors" />
                {/* / .header */}
                {/* CARDS */}
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 col-lg-6 col-xl">
                            {/* Value  */}
                            <div className="card card-bg">
                                <div className="card-body">
                                    <div className="row align-items-center gx-0">
                                        <div className="col">
                                            {/* Title */}
                                            <h6 className="text-uppercase text-muted mb-2">
                                                TOTAL VENDORS
                                            </h6>
                                            {/* Heading */}
                                            <span className="h2 mb-0">450</span>
                                        </div>
                                        <div className="col-auto">
                                            {/* Icon */}
                                            <span className="h2 fe text-muted mb-0">
                                                <img src="/images/totaluser.svg" alt="" />
                                            </span>
                                        </div>
                                    </div>{" "}
                                    {/* / .row */}
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 col-xl">
                            <div className="card card-bg">
                                <div className="card-body">
                                    <div className="row align-items-center gx-0">
                                        <div className="col">
                                            <h6 className="text-uppercase text-muted mb-2">
                                                TOTAL REVENUE
                                            </h6>
                                            <span className="h2 mb-0">200</span>
                                        </div>
                                        <div className="col-auto">
                                            <span className="h2 fe text-muted mb-0">
                                                <img src="/images/totalvendor.svg" alt="" />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 col-xl">

                        </div>
                        <div className="col-12">
                            <nav className="mb-5">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/dashboard">
                                            <i className="fe fe-home"></i> Dashboard
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        Administrators/Vendors
                                    </li>
                                </ol>
                            </nav>
                        </div>
                        <div className="d-flex justify-content-end flex-wrap mb-4">
                            {/* <div>
                                <h2 className="report">Administrators/Vendors List</h2>
                            </div> */}
                            <div style={{ display: "-webkit-inline-box" }}>
                                <div className="position-relative">
                                    <input
                                        className="form-search me-3"
                                        type="search"
                                        name=""
                                        id=""
                                        placeholder="Search"
                                    />
                                    <img
                                        className="users-search"
                                        src="/images/Icon feather-search.svg"
                                        alt=""
                                    />
                                </div>

                                {/* <div className="dropdown">
                                    <button
                                        className="btn btn-sm btn-white"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        data-bs-auto-close="outside"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                    >
                                        <i className="fe fe-sliders me-1"></i> Filter{" "}
                                        <img src="/images/chevron-down.svg" alt="" width={17} />{" "}
                                        <span className="badge bg-primary ms-1 d-none">0</span>
                                    </button>

                                    <form className="dropdown-menu dropdown-menu-end dropdown-menu-card">
                                        <table className="table mb-0">
                                            <tr>
                                                <td>
                                                    {" "}
                                                    <input type="radio" name="" id="mon" />{" "}
                                                    <label htmlFor="mon"> lorem1</label>{" "}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    {" "}
                                                    <input type="radio" name="" id="yer" />{" "}
                                                    <label htmlFor="yer"> lorem2</label>{" "}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    {" "}
                                                    <input type="radio" name="" id="day" />{" "}
                                                    <label htmlFor="day"> lorem3</label>{" "}
                                                </td>
                                            </tr>
                                        </table>
                                    </form>
                                </div> */}
                                <span data-bs-toggle="modal" data-bs-target="#exampleModal" className="invite-vindor"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-lg" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                                </svg> Invite Vendors </span>
                            </div>
                        </div>
                        <hr />


                    </div>
                    <div className="card">
                        <div className="table-responsive">
                            <table className="table table-sm table-hover table-nowrap card-table">
                                <thead>
                                    <tr>
                                        <th>
                                            <a
                                                className="list-sort text-muted"
                                                
                                                href=""
                                            >
                                                S.No
                                            </a>
                                        </th>
                                        <th>
                                            <a
                                                className="list-sort text-muted"
                                                
                                                href=""
                                            >
                                                Vendors Name
                                            </a>
                                        </th>
                                        <th>
                                            <a
                                                className="list-sort text-muted"
                                                
                                                href=""
                                            >
                                                Email ID
                                            </a>
                                        </th>
                                        <th>
                                            <a
                                                className="list-sort text-muted"
                                                
                                                href=""
                                            >
                                                Module
                                            </a>
                                        </th>
                                        <th>
                                            <a
                                                className="list-sort text-muted"
                                                
                                                href=""
                                            >
                                                Contact
                                            </a>
                                        </th>
                                        <th>

                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="list fs-base">
                                    <tr>
                                        <td>1</td>
                                        <td>
                                            {/* Avatar */}
                                            <div className="avatar avatar-xs align-middle me-2">
                                                <img
                                                    className="avatar-img rounded-circle"
                                                    src="/images/profiledp.png"
                                                    alt=""
                                                />
                                            </div>
                                            <a
                                                className="item-name text-reset"
                                                href="profile-posts.html"
                                            >
                                                Jhon Joe
                                            </a>
                                        </td>
                                        <td>
                                            <span className="item-title">
                                                User234@gmail.com
                                            </span>
                                        </td>
                                        <td>
                                            <a
                                                className="item-email text-reset"
                                                href="mailto:john.doe@company.com"
                                            >
                                                Shopping
                                            </a>
                                        </td>
                                        <td>
                                            <a
                                                className="item-phone text-reset"
                                                href="tel:1-123-456-4890"
                                            >
                                                +89687677576
                                            </a>
                                        </td>
                                        <td className="text-end">
                                            <div className="dropdown">
                                                <a
                                                    className="dropdown-ellipses dropdown-toggle"
                                                    href=""
                                                    role="button"
                                                    data-bs-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                >
                                                    <i className="fe fe-more-vertical" />
                                                </a>
                                                <div className="dropdown-menu dropdown-menu-end">
                                                    <Link to="/ventoredit" className="dropdown-item">
                                                        Edit
                                                    </Link>
                                                    <Link to="" className="dropdown-item">
                                                        Remove
                                                    </Link>
                                                    <Link to="" data-bs-toggle="modal" data-bs-target="#exampleModal" className="dropdown-item">
                                                        Products
                                                    </Link>
                                                    <Link to="#!" className="dropdown-item">
                                                        Other
                                                    </Link>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>2</td>
                                        <td>
                                            {/* Avatar */}
                                            <div className="avatar avatar-xs align-middle me-2">
                                                <img
                                                    className="avatar-img rounded-circle"
                                                    src="/images/profiledp.png"
                                                    alt="..."
                                                />
                                            </div>
                                            <a
                                                className="item-name text-reset"
                                                href="profile-posts.html"
                                            >
                                                Jhon Joe
                                            </a>
                                        </td>
                                        <td>
                                            <span className="item-title">
                                                User234@gmail.com
                                            </span>
                                        </td>
                                        <td>
                                            <a
                                                className="item-email text-reset"
                                                href="mailto:john.doe@company.com"
                                            >
                                                Shopping
                                            </a>
                                        </td>
                                        <td>
                                            <a
                                                className="item-phone text-reset"
                                                href="tel:1-123-456-4890"
                                            >
                                                +89687677576
                                            </a>
                                        </td>
                                        <td className="text-end">
                                            <div className="dropdown">
                                                <a
                                                    className="dropdown-ellipses dropdown-toggle"
                                                    href=""
                                                    role="button"
                                                    data-bs-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                >
                                                    <i className="fe fe-more-vertical" />
                                                </a>
                                                <div className="dropdown-menu dropdown-menu-end">
                                                    <Link to="" className="dropdown-item">
                                                        Edit
                                                    </Link>
                                                    <Link to="" className="dropdown-item">
                                                        Remove
                                                    </Link>
                                                    <Link to="" data-bs-toggle="modal" data-bs-target="#exampleModal" className="dropdown-item">
                                                        Products
                                                    </Link>
                                                    <Link to="#!" className="dropdown-item">
                                                        Other
                                                    </Link>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>3</td>
                                        <td>
                                            {/* Avatar */}
                                            <div className="avatar avatar-xs align-middle me-2">
                                                <img
                                                    className="avatar-img rounded-circle"
                                                    src="/images/profiledp.png"
                                                    alt="..."
                                                />
                                            </div>
                                            <a
                                                className="item-name text-reset"
                                                href="profile-posts.html"
                                            >
                                                Jhon Joe
                                            </a>
                                        </td>
                                        <td>
                                            <span className="item-title">
                                                User234@gmail.com
                                            </span>
                                        </td>
                                        <td>
                                            <a
                                                className="item-email text-reset"
                                                href="mailto:john.doe@company.com"
                                            >
                                                Shopping
                                            </a>
                                        </td>
                                        <td>
                                            <a
                                                className="item-phone text-reset"
                                                href="tel:1-123-456-4890"
                                            >
                                                +89687677576
                                            </a>
                                        </td>
                                        <td className="text-end">
                                            <div className="dropdown">
                                                <a
                                                    className="dropdown-ellipses dropdown-toggle"
                                                    href=""
                                                    role="button"
                                                    data-bs-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                >
                                                    <i className="fe fe-more-vertical" />
                                                </a>
                                                <div className="dropdown-menu dropdown-menu-end">
                                                    <Link to="" className="dropdown-item">
                                                        Edit
                                                    </Link>
                                                    <Link to="" className="dropdown-item">
                                                        Remove
                                                    </Link>
                                                    <Link to="" data-bs-toggle="modal" data-bs-target="#exampleModal" className="dropdown-item">
                                                        Products
                                                    </Link>
                                                    <Link to="#!" className="dropdown-item">
                                                        Other
                                                    </Link>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>4</td>
                                        <td>
                                            {/* Avatar */}
                                            <div className="avatar avatar-xs align-middle me-2">
                                                <img
                                                    className="avatar-img rounded-circle"
                                                    src="/images/profiledp.png"
                                                    alt="..."
                                                />
                                            </div>
                                            <a
                                                className="item-name text-reset"
                                                href="profile-posts.html"
                                            >
                                                Jhon Joe
                                            </a>
                                        </td>
                                        <td>
                                            <span className="item-title">
                                                User234@gmail.com
                                            </span>
                                        </td>
                                        <td>
                                            <a
                                                className="item-email text-reset"
                                                href="mailto:john.doe@company.com"
                                            >
                                                Shopping
                                            </a>
                                        </td>
                                        <td>
                                            <a
                                                className="item-phone text-reset"
                                                href="tel:1-123-456-4890"
                                            >
                                                +89687677576
                                            </a>
                                        </td>
                                        <td className="text-end">
                                            <div className="dropdown">
                                                <a
                                                    className="dropdown-ellipses dropdown-toggle"
                                                    href=""
                                                    role="button"
                                                    data-bs-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                >
                                                    <i className="fe fe-more-vertical" />
                                                </a>
                                                <div className="dropdown-menu dropdown-menu-end">
                                                    <Link to="" className="dropdown-item">
                                                        Edit
                                                    </Link>
                                                    <Link to="" className="dropdown-item">
                                                        Remove
                                                    </Link>
                                                    <Link to="" data-bs-toggle="modal" data-bs-target="#exampleModal" className="dropdown-item">
                                                        Products
                                                    </Link>
                                                    <Link to="#!" className="dropdown-item">
                                                        Other
                                                    </Link>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>5</td>
                                        <td>
                                            {/* Avatar */}
                                            <div className="avatar avatar-xs align-middle me-2">
                                                <img
                                                    className="avatar-img rounded-circle"
                                                    src="/images/profiledp.png"
                                                    alt="..."
                                                />
                                            </div>
                                            <a
                                                className="item-name text-reset"
                                                href="profile-posts.html"
                                            >
                                                Jhon Joe
                                            </a>
                                        </td>
                                        <td>
                                            <span className="item-title">
                                                User234@gmail.com
                                            </span>
                                        </td>
                                        <td>
                                            <a
                                                className="item-email text-reset"
                                                href="mailto:john.doe@company.com"
                                            >
                                                Shopping
                                            </a>
                                        </td>
                                        <td>
                                            <a
                                                className="item-phone text-reset"
                                                href="tel:1-123-456-4890"
                                            >
                                                +89687677576
                                            </a>
                                        </td>
                                        <td className="text-end">
                                            <div className="dropdown">
                                                <a
                                                    className="dropdown-ellipses dropdown-toggle"
                                                    href=""
                                                    role="button"
                                                    data-bs-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                >
                                                    <i className="fe fe-more-vertical" />
                                                </a>
                                                <div className="dropdown-menu dropdown-menu-end">
                                                    <Link to="" className="dropdown-item">
                                                        Edit
                                                    </Link>
                                                    <Link to="" className="dropdown-item">
                                                        Remove
                                                    </Link>
                                                    <Link to="" data-bs-toggle="modal" data-bs-target="#exampleModal" className="dropdown-item">
                                                        Products
                                                    </Link>
                                                    <Link to="#!" className="dropdown-item">
                                                        Other
                                                    </Link>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>

            {/* invite vindors popup code start here */}
            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-sm modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header border-0">
                            <button type="button" className="btn-close block-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body pe-0 ps-0 block-user">
                            <img src="/images/invite.png" alt="" width={120} srcSet="" />
                            <h1 className="mb-0 mt-2">Invite Vendors</h1>
                            <p>Invite new vendor to create their profile in the portal <br /> Enter Email or other links to invite to the portal.</p>
                        </div>
                        <form action="" className="px-5">
                            <label
                                className="form-label text-start d-block"
                                htmlFor="email"
                            >
                                Email address
                            </label>
                            <div className="position-relative">
                                <input
                                    className="form-control"
                                    type="email"
                                    name=""
                                    id="email"
                                    placeholder="@example.com"
                                />
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={16}
                                    height={16}
                                    fill="currentColor"
                                    className="bi bi-envelope-fill input-icon"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z" />
                                </svg>
                            </div>
                            <br />
                            <label
                                className="form-label text-start d-block"
                                htmlFor="modal"
                            >
                                Module
                            </label>
                            <select name="" id="" className="form-select form-control">
                                <option value="">Select module</option>
                                <option value="">Shopping</option>
                                <option value="">Job</option>
                                <option value="">Ticket</option>
                                <option value="">Other</option>
                            </select>
                        </form>
                        <br />
                        <div className="modal-footer pe-0 ps-0 border-0 justify-content-center pt-0 mb-3">
                            <button type="button" className="btn-custom w-auto">SEND</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
