import React, { useEffect, useState } from 'react'
import moment from 'moment';
import { ImageUrl } from '../../../../utils/BaseUrl';
import { Link } from 'react-router-dom';
import { getFlightBookingApi } from '../../../../utils/ApiEndpointFunctions';
import Spinwheel from '../../../common/Spinwheel';

function ViewTrainTicketModal({ modalId,data }) {
console.log("trainTicketModal",data);
   return (
      <div className="modal fade" id={modalId} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
         <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">
               <div className="modal-header border-0">
                  <button type="button" className="btn-close block-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div className="modal-body">
                  <div className="col-lg-12" style={{ zIndex: "1" }}>
                     <div action="" className='p-4' >
                        <h2 className='text-center'>
                          Ticket
                        </h2>
                        <div className="row">
                           <div className="mb-3">
                              <h4 className='fw-bold'>User name</h4>
                              <p className='text-capitalize mb-0'>{data?.userId?.name || "-"}</p>
                           </div>
                           <h5 className='fw-bold text-center'>Train Details</h5>
                           <div className="row row-cols-lg-3 row-cols-md-2">
                           <div className="mb-3">
                                 <h4 className='fw-bold'>Name</h4>
                                 <div className='position-relative'>
                                    <p className='mb-0'>{data?.trainId?.trainName || "-"}</p>
                                 </div>
                              </div>
                              <div className="mb-3">
                                 <h4 className='fw-bold'>Number</h4>
                                 <div className='position-relative'>
                                    <p className='mb-0'>{data?.trainId?.trainNumber || "-"}</p>
                                 </div>
                              </div>
                              <div className="mb-3">
                                 <h4 className='fw-bold'>Departure</h4>
                                 <div className='position-relative'>
                                    <p className='mb-0'>{data?.trainId?.departurePlace?.name || "-"}</p>
                                 </div>
                              </div>
                           
                              <div className="mb-3">
                                 <h4 className='fw-bold'>Arrival</h4>
                                 <div className='position-relative'>
                                    <p className='mb-0'>{data?.trainId?.arrivalPlace?.name || "-"}</p>
                                 </div>
                              </div>
                              <div className="mb-3">
                                 <h4 className='fw-bold'>Departure Date</h4>
                                 <div className='position-relative'>
                                    <p className='mb-0'>{data?.trainId?.departureTime ? moment(data?.trainId?.departureTime).format("DD/MM/YYYY h:mm:ss a") : "-"}</p>
                                 </div>
                              </div>
                              <div className="mb-3">
                                 <h4 className='fw-bold'>Arrival Date</h4>
                                 <div className='position-relative'>
                                    <p className='mb-0'>{data?.trainId?.arrivalTime ? moment(data?.trainId?.arrivalTime).format("DD/MM/YYYY h:mm:ss a") : "-"}</p>
                                 </div>
                              </div>
                           </div>
                           <hr />
                           <h5 className='fw-bold text-center'>Payment</h5>
                           <div className="row row-cols-lg-3 row-cols-md-2">
                              <div className="mb-3">
                                 <h4 className='fw-bold'>Amount</h4>
                                 <p className='mb-0'>{data?.invoiceNo?.amount}</p>
                              </div>
                              <div className="mb-3">
                                 <h4 className='fw-bold'>Status</h4>
                                 <p className='mb-0'>{data?.invoiceNo?.status == "success" ? "Yes" : "No"}</p>
                              </div>
                           </div>

                           <hr />
                           <h5 className='fw-bold text-center'>Details</h5>
                        
                           <div className="row row-cols-lg-3 row-cols-md-2">
                           <div className='mb-3'>
                              <h4 className='fw-bold'>Pickup point</h4>
                                 <p className='mb-0'>{data?.pickPoint?.name || "-"} {data?.pickPoint?.alias ? `(${data?.pickPoint?.alias})` : "-"}</p>
                              </div>
                              <div className='mb-3'>
                              <h4 className='fw-bold'>Pickup arrival time</h4>
                                 <p className='mb-0'>{data?.pickPoint?.arrivalTime ? moment(data?.pickPoint?.arrivalTime).format("DD/MM/YYYY h:mm:ss a") : "-"}</p>
                              </div>
                              <div className='mb-3'>
                              <h4 className='fw-bold'>Pickup departure time</h4>
                                 <p className='mb-0'>{data?.pickPoint?.departureTime ? moment(data?.pickPoint?.departureTime).format("DD/MM/YYYY h:mm:ss a") : "-"}</p>
                              </div>
                              <div className='mb-3'>
                              <h4 className='fw-bold'>Drop point</h4>
                                 <p className='mb-0'>{data?.dropPoint?.name || "-"} {data?.dropPoint?.alias ? `(${data?.dropPoint?.alias})` : "-"}</p>
                              </div>
                              <div className='mb-3'>
                              <h4 className='fw-bold'>Drop arrival time</h4>
                                 <p className='mb-0'>{data?.dropPoint?.arrivalTime ? moment(data?.dropPoint?.arrivalTime).format("DD/MM/YYYY h:mm:ss a") : "-"}</p>
                              </div>
                              <div className='mb-3'>
                              <h4 className='fw-bold'>Drop departure time</h4>
                                 <p className='mb-0'>{data?.dropPoint?.departureTime ? moment(data?.dropPoint?.departureTime).format("DD/MM/YYYY h:mm:ss a") : "-"}</p>
                              </div>
                              <div className='mb-3'>
                              <h4 className='fw-bold'>Coach type</h4>
                                 <p className='mb-0'>{data?.coachType || "-"} </p>
                              </div>
                              <div className='mb-3'>
                                 <h4 className='fw-bold'>Status</h4>
                                 <p className='mb-0'>{data?.status}</p>
                              </div>
                              <div>
                                 {data?.ticketFile && <div className='mb-3'>
                                    <h4 className='fw-bold'>Attachment</h4>
                                    <Link to={ImageUrl + data?.ticketFile} target='_blank'>
                                       View
                                    </Link>
                                 </div>}
                              </div>
                           </div>
                           <hr />
                           <h5 className='fw-bold text-center'>Passengers</h5>
                           <div className="row row-cols-md-3">
                              <div>
                                 <h4 className='fw-bold'>Name</h4>
                              </div>
                              <div>
                                 <h4 className='fw-bold'>Gender</h4>
                              </div>
                              <div>
                                 <h4 className='fw-bold'>Age</h4>
                              </div>
                              {data?.passengers?.map(traveler => <>
                                 <div>
                                    <p className='mb-0'>{traveler?.name}</p>
                                 </div>
                                 <div>
                                    <p className='mb-0'>{traveler?.gender}</p>
                                 </div>
                                 <div>
                                    <p className='mb-0'>{traveler?.age}</p>
                                 </div>
                              </>)}
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}

export default ViewTrainTicketModal