import React, { useEffect, useState } from 'react'
import Spinwheel from '../../../common/Spinwheel'
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import { ImageUrl } from '../../../../utils/BaseUrl';
import { getAllTrainStationApi, uploadImage } from '../../../../utils/ApiEndpointFunctions';
import toast from 'react-hot-toast';
import Select from 'react-select';
function AddOrUpdateTrainModal({ formik, modalId, addLoader }) {
  const [fileTypeError, setFileTypeError] = useState({ id: null, message: "", error: 0 })
  const [data, setData] = useState([])

  const handleUpload = async (e, id, field) => {
    try {
      const file = e.target.files[0];
      const types = ['image/jpeg', 'image/png', 'image/gif']
      if (file) {
        if (types.includes(file.type)) {
          setFileTypeError({ id: id, message: "", error: 0 });
          const formData = new FormData();
          formData.append("file", file);
          formData.append("type", 3)
          formData.append("folder", "movie")
          const res = await uploadImage('/users/fileUpload', formData)
          formik.setFieldTouched(field, true)
          if (res.status === 200 && res.data?.status === 1) {
            formik.setFieldValue(field, res.data?.data?.fileName)
            toast.success("File uploaded");
          }
        } else {
          formik.setFieldTouched(field, true)
          formik.setFieldValue(field, "")
          setFileTypeError({ id: id, message: "Must be image only", error: 1 });
        }
      }

    } catch (error) {
      formik.setFieldTouched(field, true)
      console.log(error)
      if (error?.response === undefined) {
        toast.error("File size is too large")
      }
    }
  }

  const TrainStationOptions = async () => {
    try {
      const res = await getAllTrainStationApi()
      let result = res.data?.data?.map(station => { return { label: station?.name, value: station?._id } })
      setData(result)
      return result
    } catch (error) {
      console.log(error);
      return []
    }
  }

  useEffect(() => {
    TrainStationOptions();
  } , [])



  return (
    <div className="modal fade" id={modalId} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-sm modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header border-0">
            <button type="button" className="btn-close block-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <div className="col-lg-12" style={{ zIndex: "1" }}>
              <form action="" className='p-4' >
                <h2 className='text-center'>
                  Train
                </h2>
                <div className='d-flex flex-column gap-2'>
                  <div>
                    <label htmlFor="image" className="form-label">Cover image</label>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="position-relative">
                        {<img src={formik.values.image ? ImageUrl + formik.values.image : process.env.PUBLIC_URL + "/images/image.svg"} width="70px" height="50px" style={{ objectFit: "contain" }} className="rounnded" alt="Selected file" />}
                      </div>
                      <div className="btn_upload">
                        <input type="file" id="image" className="form-control" name="image" accept="image/*" onChange={(e) => handleUpload(e, "image", "image")} />
                        Upload
                      </div>
                    </div>
                    {formik.touched.image && (formik.errors.image || <div className='req-error'>{fileTypeError?.id == "image" && fileTypeError?.error != 0 && fileTypeError?.error}</div>)}
                    <br />
                  </div>
                  <div className="position-relative">
                    <label htmlFor='trainName'>Name</label>
                    <input className="form-control border border-1" type="text" id="trainName" placeholder="Enter name of train" name="trainName" value={formik.values?.trainName} onChange={formik.handleChange} onBlur={() => formik.handleBlur} />
                    {formik.touched?.trainName ? (<div className='req-error'>{formik.errors?.trainName}</div>) : null}
                  </div>
                 
                  <div className="position-relative">
                    <label htmlFor='trainNumber'>Number</label>
                    <input className="form-control border border-1" type="text" id="trainNumber" placeholder="Enter trainNumber" name="trainNumber" value={formik.values?.trainNumber} onChange={formik.handleChange} onBlur={() => formik.handleBlur} />
                    {formik.touched?.trainNumber ? (<div className='req-error'>{formik.errors?.trainNumber}</div>) : null}
                  </div>
                  <div>
                    <label htmlFor="departurePlace" className="form-label">Departure</label>
                    <Select cacheOptions defaultOptions value={formik.values?.departurePlace} onChange={(e) => formik.setFieldValue("departurePlace", e)} options={data} />
                    {formik.touched.departurePlace && formik.errors.departurePlace ? (<div className='req-error'>{formik.errors.departurePlace}</div>) : null}
                  </div>
                  <div>
                  <label htmlFor='departureTime'>Departure date</label>
                    <DateTimePicker maxDate={new Date(formik.values?.arrivalTime)} name='departureTime' className="w-100 form-control border border-1" format="dd-MM-yyy h:mm:ss a" onChange={(e) => formik.setFieldValue("departureTime", e)} value={formik.values?.departureTime ? new Date(formik.values?.departureTime) : null} />
                    {formik.touched?.departureTime ? (<div className='req-error'>{formik.errors?.departureTime}</div>) : null}
               
                  </div>

                  <div>
                    <label htmlFor="arrivalPlace" className="form-label">Arrival</label>
                    <Select cacheOptions defaultOptions value={formik.values?.arrivalPlace} onChange={(e) => formik.setFieldValue("arrivalPlace", e)} options={data} />
                    {formik.touched.arrivalPlace && formik.errors.arrivalPlace ? (<div className='req-error'>{formik.errors.arrivalPlace}</div>) : null}
                  </div>
                  
                  <div>
                  <label htmlFor='arrivalTime'>Arrival date</label>
                    <DateTimePicker name='arrivalTime' minDate={new Date(formik.values?.departureTime)} className="w-100 form-control border border-1" format="dd-MM-yyy h:mm:ss a" onChange={(e) => formik.setFieldValue("arrivalTime", e)} value={formik.values?.arrivalTime ? new Date(formik.values?.arrivalTime) : null} />
                    {formik.touched?.arrivalTime ? (<div className='req-error'>{formik.errors?.arrivalTime}</div>) : null}
                 
                  </div>
                </div>
                <div className="modal-footer border-0 justify-content-center pt-4">
                  <button type="button" className="btn-custom w-auto" onClick={(e) => { formik?.handleSubmit() }}  >{addLoader ? <Spinwheel sizeClass="spin-small" /> : "Save"}</button>
                  <button type="button" className="btn-custom w-auto" data-bs-dismiss='modal' >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddOrUpdateTrainModal