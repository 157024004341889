import { useFormik } from 'formik';
import React, { useState } from 'react'
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { Link, useLocation, useParams } from 'react-router-dom'
import { useAdminContext } from '../../../../context/adminContext';
import Sidebar from '../../../../Layout/Sidebar';
import { addReviewAndRatingApi, deleteReviewById, getAllReviewsByCompanyIdApi, getCompanyDetailsByIdApi, updateReviewByIdApi } from '../../../../utils/ApiEndpointFunctions';
import { ImageUrl } from '../../../../utils/BaseUrl';
import Header from '../../../common/Header';
import RemoveModal from '../../../common/RemoveModal';
import Spinwheel from '../../../common/Spinwheel';
import { ratingIntialValues, ratingValidatonSchema } from '../../../common/Validation';
import ReviewAndRating from '../modals/ReviewAndRating';

function Reviews() {
  const params = useParams();
  const {adminDetail} = useAdminContext();
  const [allReview, setAllReview] = useState([]);
  const [reviewTableSpin, setReviewTableSpin] = useState(false);
  const [removeData,setRemoveData] = useState(null);
  const [modalType,setModalType] = useState("");
  const location = useLocation();
  const [companyDetails, setCompanyDetails] = useState()
  const handleRatingModal = (type,ele={}) => {
    if(type === "Add") {
      setModalType(type)
      ratingFormik.setValues(ratingIntialValues)
      ratingFormik.setFieldValue("companyId", params?.companyId)
      ratingFormik.setErrors({})
      ratingFormik.setTouched({})
    } else {
      setModalType(type)
      ratingFormik.setValues({
        name: ele?.name,
        rating: ele?.rating,
        companyId: ele?.companyId,
        review: ele?.review,
        jobId: ele?.jobId,
        id:ele?._id
      })
    }    
  }
  const getAllReviewsByCompanyId = async () => {
    setReviewTableSpin(false)
    try {
      const res = await getAllReviewsByCompanyIdApi(params?.companyId);
      if (res.status === 200) {
        setAllReview(res.data?.data)
        setReviewTableSpin(true)
      } else {
        setReviewTableSpin(true)
      }
    } catch (error) {
      console.log(error)
      setReviewTableSpin(true)
    }
  }

  const removeReviewsHandler = async (e) => {
    e.preventDefault();
    try {
      const res = await deleteReviewById(removeData)
      if (res.status === 200) {
        toast.success(res.data?.message)
        getAllReviewsByCompanyId()
      }
    } catch (error) {
      console.log(error)
    }
  }

    const AddReviewSubmit = async (values) => {
      try {
        if(modalType === "Add") {
          const res = await addReviewAndRatingApi(values)
          if (res.status === 200) {
            toast.success(res.data?.message)
            getAllReviewsByCompanyId()
          }
        } else if(modalType === "Update") {
          const res = await updateReviewByIdApi(values);
          if(res.status === 200) {
            toast.success(res.data?.message)
            getAllReviewsByCompanyId()
          }
        }
      } catch (error) {
        console.log(error)
        toast.error("Somethink went wrong")
      }
    }

  const ratingFormik = useFormik({
    initialValues: { ...ratingIntialValues},
    onSubmit: AddReviewSubmit,
    validationSchema: ratingValidatonSchema
  })

  const companyDetailById = async (id) => {
    try {
      const res = await getCompanyDetailsByIdApi(id);
      if (res.status === 200) {
        setCompanyDetails(res.data?.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getAllReviewsByCompanyId()
    if (params?.companyId) companyDetailById(params?.companyId)
  },[])
  return (
    <>
      {/*<Sidebar />*/}
      <div className="main-content vh-100 vh_md-auto overflow-y-a">
        <Header title="Jobs" />
        <div className="container-fluid">
          <div className="row">


            <div className="col-12">
              <nav className="mb-5">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">
                      <i className="fe fe-home"></i> Dashboard
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/job">
                      jobs
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Review &amp; rating
                  </li>
                </ol>
              </nav>
            </div>
            <div className="d-flex justify-content-end mb-4 flex-wrap">
              <div style={{ display: "-webkit-inline-box" }}>
                <div className="dropdown show">
                  <div className="dropdown me-3"><button className="btn btn-second dropdown-toggle" style={{ padding: "7px 10px" }} type="button" data-bs-toggle="dropdown" aria-expanded="false">Add</button>
                    <ul className="dropdown-menu" style={{ marginTop: "-10px" }}>
                      {/* <li><Link className="<ReviewAndRating/><ReviewAndRating/>-item" to={"/map-add"} state={{ status: "Personal" }} >Personal</Link></li> */}
                      <div data-bs-toggle="modal" data-bs-target="#ReviewAndRating" className="dropdown-item" style={{ cursor: "pointer" }} onClick={()=>handleRatingModal("Add")} >
                        Add review
                      </div>
                      {/* <li><Link className="dropdown-item" to={`/job/reviews/add/${params?.jid}/${params?.cid}`} >Add-Review</Link></li> */}
                      {/* <li><Link className="dropdown-item" to={"/job/add"} >add</Link></li> */}
                      {/* <li><Link className="dropdown-item" to={"/map-add"} state={{ status: "touristAttraction" }} >Tourist</Link></li> */}
                    </ul>
                  </div>
                </div>

                {/* <div className="position-relative">
                  <input
                    className="form-search me-3"
                    type="search"
                    name=""
                    id=""
                    placeholder="Search"
                  />
                  <img
                    className="users-search"
                    src="/images/Icon feather-search.svg"
                    alt=""
                  />
                </div> */}
              </div>
            </div>
            <h5 className="header-title">{companyDetails?.name}</h5>
            <hr />
          </div>
          <div className="card" >
            <table className="table table-sm table-hover table-nowrap card-table">
              <thead>
                <tr>
                  <th>
                    <span
                      className="list-sort text-muted"
                      
                    >
                      S.No
                    </span>
                  </th>
                  <th>
                    <span
                      className="list-sort text-muted"
                      
                    >
                      Name
                    </span>
                  </th>
                  <th>
                    <span
                      className="list-sort text-muted"
                      
                    >
                      Rating
                    </span>
                  </th>
                  <th colSpan={2}>
                    <span
                      className="list-sort text-muted"
                      
                    >
                      Review
                    </span>
                  </th>
                </tr>
              </thead>
              {
                reviewTableSpin ?
                  <tbody className="list fs-base">
                    {allReview?.length > 0 ? allReview?.map((ele, index) => {
                      return (<tr key={index} >
                        <td>{index + 1}</td>
                        <td>
                          {/* Avatar */}
                          <span
                            className="item-name text-reset"
                          >
                            {ele?.name ? ele?.name : "-"}
                          </span>
                        </td>
                        <td>
                          <span
                            className="item-phone text-reset"
                          >
                            {ele?.rating ? ele?.rating : "-"}
                          </span>
                        </td>
                        <td className='position-relative'>

                          {ele?.review ? (
                            <div className="tooltip1">
                              {ele?.review?.substr(0, 15)}...
                              <div className='wrapper'>
                                <div className="tooltip1-text">{ele?.review}</div>
                              </div>
                            </div>
                          ) : (
                            "-"
                          )}
                        </td>
                        <td className="text-end">
                          <div className="dropdown">
                            <span
                              className="dropdown-ellipses dropdown-toggle"
                              role="button"
                              data-bs-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <i className="fe fe-more-vertical" />
                            </span>
                            <div className="dropdown-menu dropdown-menu-end">
                              <div data-bs-toggle="modal" data-bs-target="#ReviewAndRating" className="dropdown-item" style={{ cursor: "pointer" }} onClick={() => handleRatingModal("Update",ele)} >
                                Edit
                              </div>
                              <div data-bs-toggle="modal" data-bs-target="#removeReview" className="dropdown-item" style={{ cursor: "pointer" }} onClick={() => { setRemoveData(ele?._id); }}  >
                                Remove
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      )
                    }) : <tr align="center">
                      <td colSpan="5">
                        <h1>
                          Data Not Found
                        </h1>
                      </td>
                    </tr>}
                  </tbody> : (
                    <tbody>
                      <tr align="center">
                        <td colSpan="5">
                          <Spinwheel />
                        </td>
                      </tr>
                    </tbody>
                  )
              }
            </table>
          </div>
        </div>
      </div>
      <RemoveModal removeHandler={removeReviewsHandler} modalId="removeReview" message={<>Are you sure you want to<br /> remove this?</>} />
      <ReviewAndRating
        formik={ratingFormik}
        modalType={modalType}
      />
    </>
  )
}

export default Reviews