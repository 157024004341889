import React, { useEffect, useState } from 'react'
import Pagination from '../../common/Pagination';

import { Link } from 'react-router-dom';
import Header from '../../common/Header';
import { useAdminContext } from '../../../context/adminContext';
import { debounce } from 'lodash';
import { addLivingToCarouselApi, getAllAddedCarouselLivingApi, getAllUnCarouselLivingApi, getUserAndVendorApi, removeLivingFromCarouselApi } from '../../../utils/ApiEndpointFunctions';
import toast from 'react-hot-toast';
// import VendorTable from './VendorUsers'
import { useFormik } from 'formik';
import DatePicker from "react-datepicker";

import { systemAndreportInitialValue, systemAndreportValidationSchema } from '../../common/Validation';
import Spinwheel from '../../common/Spinwheel';
import UserTable from './UserTable';
import { saveAs } from 'file-saver'; // Utility to save files to the local system
import * as XLSX from 'xlsx';
import VendorUsers from './VendorUsers';
import moment from 'moment';



function SystemReports() {

  const { adminDetail } = useAdminContext();
  const [tableData, setTableData] = useState([]);
  const [searchSpin, setSearchSpin] = useState(false);
  const [exportSpin, setExportSpin] = useState(false);
  const [tableSpin, setTableSpin] = useState(true);

  const exportToXLS = () => {
    
    setExportSpin(true)

    let data = []
    let defaultZooming = [{ width: 10 }, { width: 20 }, { width: 15 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 15 }, { width: 15 }];


    if (formik.values.type === "users") {
      data = tableData.map((ele, index) => {
        return {
          "S.No": index + 1,
          "User Name": ele?.name ? ele.name : "-",
          "Gender": ele?.gender ? ele?.gender : "-",
          "Home Address": `${ele?.userDetails?.correspondence_Address ? ele?.userDetails?.correspondence_Address + ", " : ""}${ele?.userDetails?.correspondence_country ? ele?.userDetails?.correspondence_country + ", " : ""}${ele?.userDetails?.correspondence_zipcode ? ele?.userDetails?.correspondence_zipcode + ", " : ""}`.length ?
            `${ele?.userDetails?.correspondence_Address ? ele?.userDetails?.correspondence_Address + ", " : ""}${ele?.userDetails?.correspondence_country ? ele?.userDetails?.correspondence_country + ", " : ""}${ele?.userDetails?.correspondence_zipcode ? ele?.userDetails?.correspondence_zipcode + ", " : ""}` : "-",
          "Email ID": ele?.email ? ele?.email : "-",
          "Work Address": `${ele?.userDetails?.work_Address ? ele?.userDetails?.work_Address + ", " : ""}${ele?.userDetails?.work_country ? ele?.userDetails?.work_country + ", " : ""}${ele?.userDetails?.work_zipcode ? ele?.userDetails?.work_zipcode + ", " : ""}`.length ?
            `${ele?.userDetails?.work_Address ? ele?.userDetails?.work_Address + ", " : ""}${ele?.userDetails?.work_country ? ele?.userDetails?.work_country + ", " : ""}${ele?.userDetails?.work_zipcode ? ele?.userDetails?.work_zipcode + ", " : ""}` : "-",
          "Phone number": ele?.phoneNumber ? ele?.phoneNumber : '-',
          "Job type": ele?.userDetails?.jobTitle ? ele?.userDetails?.jobTitle : '-',
          "Status": ele?.isActive ? "Active" : "Blocked"
        }
      })

     
    } else {
      data = tableData.map((ele, index) => {
        return {
          "S.No": index + 1,
          "Name": ele?.name ? ele.name : "-",
          "Email ID": ele?.email ? ele?.email : "-",
          "Joining date": ele?.joinDate ? moment(ele?.joinDate).format("DD/MM/YYYY") : "-",
          "Status": ele?.joinDate ? "Active" : "Inactive"
        }
      })
    }

    setTimeout(() => {
      const workbook = XLSX.utils.book_new();

      const worksheet = XLSX.utils.json_to_sheet(data);

      worksheet['!cols'] = defaultZooming

      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

      const workbookBinary = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });


      const blob = new Blob([workbookBinary], { type: 'application/octet-stream' });
      saveAs(blob, `${formik.values.type}_data.xlsx`);
      setExportSpin(false);
    }, 500);
  };



  const handleSelectDate = (e, type) => {
    formik.setFieldValue(type, e)
  };

  const handleSubmit = async (values) => {
    try {
      
      setSearchSpin(true)
      setTableSpin(false)
      const res = await getUserAndVendorApi(values.type, values.to, values.from)
      if (res.status === 200) {
        setTableData(res.data?.data)
      }
    } catch (error) {
      console.log(error)
    }
    setSearchSpin(false)
    setTableSpin(true)
  }

  const formik = useFormik({
    initialValues: systemAndreportInitialValue,
    onSubmit: handleSubmit,
    validationSchema: systemAndreportValidationSchema
  })

  

  return (
    <>
      {/*<Sidebar />*/}
      <div className="main-content vh-100 vh_md-auto overflow-y-a">
        <Header title="System and reports" />
        <div className="container-fluid">
          <div className="row">
            <nav className="mb-5">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard" >
                    <i className="fe fe-home"></i> Dashboard
                  </Link>
                </li>
                <Link to="/living" className="breadcrumb-item active" aria-current="page">
                  System and reports
                </Link>
              </ol>
            </nav>
            <div className="d-flex justify-content-start mb-4 flex-wrap">

              <div className='d-flex justify-content-end gap-2' style={{ display: "-webkit-inline-box" }}>

                <div className="position-relative">
                  <select name="type" id="modal" className="form-select border border-1" value={formik?.values?.status} onChange={(e) => { setTableData([]); formik?.handleChange(e) }}  >
                    <option value="users">Users</option>
                    <option value="vendors">Vendors</option>
                  </select>
                </div>
                <div className="position-relative w-50">
                  <DatePicker
                    selectsRange={true}
                    dateFormat="dd/MM/yyyy"
                    className='form-control cursor-pointer'
                    startDate={formik.values?.from ? formik.values?.from : null}
                    endDate={formik.values?.to ? formik.values?.to : null}
                    // maxDate={new Date(new Date(formik.values?.from ? formik.values?.from : new Date()).setTime(new Date(formik.values?.from ? formik.values?.from : new Date()).getTime() + (1 * 1000 * 60 * 60 * 24 * 180)))}
                    onChange={(update) => {
                      handleSelectDate(update[0], "from")
                      handleSelectDate(update[1], "to")
                    }}

                    placeholderText='date'
                    showDisabledMonthNavigation
                  />
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar input-icon" viewBox="0 0 16 16">
                    <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z" />
                  </svg>
                </div>
                <button type="button" className="btn-custom w-auto m-1" disabled={searchSpin} onClick={() => { formik.handleSubmit() }}  > {searchSpin ? <Spinwheel sizeClass="spin-small" /> : "Search"}</button>
                {tableData?.length > 0 && <button type="button" className="btn-custom w-auto m-1" disabled={exportSpin} onClick={exportToXLS} > {exportSpin ? <Spinwheel sizeClass="spin-small" /> : "Export"}</button>}
              </div>
            </div>
            <hr />

            <div className="card p-3 ">
              {formik.values.type === "users" ?
                <UserTable tableData={tableData} tableSpin={tableSpin} /> :
                <VendorUsers tableData={tableData} tableSpin={tableSpin} />
              }
            </div>
          </div>

        </div>
      </div>

    </>
  )
}

export default SystemReports