import React from 'react'
import Spinwheel from '../../../common/Spinwheel'

function UpdateProductStatus({ formik, modalId, addLoader}) {
  

  return (
    <div className="modal fade" id={modalId} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-sm modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header border-0">
            <button type="button" className="btn-close block-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body block-user">
            <div className="col-lg-12" style={{ zIndex: "1" }}>
              <form action="" className='p-4' >
                <h2 style={{ textAlign: "left" }}>
                  Product status
                </h2>
               <div>
                  <select name="status" id="modal" className="form-select border border-1" value={formik?.values?.status} onChange={formik?.handleChange}  >
                    <option value="">Choose status</option>
                    <option value="In stock">In stock</option>
                    <option value="Available">Available</option>
                    <option value="Sold out">Sold out</option>
                    <option value="Not available">Not available</option>
                  </select>
                  {formik?.touched?.status && formik?.errors?.status ? (<div className='req-error'>{formik?.errors?.status}</div>) : null}
               </div>
                <div className="modal-footer border-0 justify-content-center pt-4">
                  <button type="button" className="btn-custom w-auto" onClick={(e) => { formik?.handleSubmit() }}  >{addLoader ? <Spinwheel sizeClass="spin-small" /> : "Update"}</button>
                  <button type="button" className="btn-custom w-auto" data-bs-dismiss='modal' >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UpdateProductStatus