import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { useAdminContext } from '../../context/adminContext';
import { AddTicketBannerApi, getAllTicketBannerApi, removeTicketBannerByIdApi, updateTicketBannerApi } from '../../utils/ApiEndpointFunctions';
import Spinwheel from './Spinwheel';
import { ImageUrl } from '../../utils/BaseUrl';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { toast } from 'react-hot-toast';
import AddTicketBanner from './AddTicketBanner';
import { bannerInitialValue, bannerValidationSchema } from './Validation';
import RemoveModal from './RemoveModal';
import { arrayMove, List } from "react-movable";

function TicketBanner({ type }) {
   const navigate = useNavigate()
   const [tableSpin, setTableSpin] = useState(true);
   const [data, setData] = useState([]);
   const [addSpin, setAddSpin] = useState(false)
   const [selectedItem, setSelectedItem] = useState(null)
   const removeModalId = "removeModalId"
   const addTicketBannerModalId = "addTicketBannerModalId"

   const getAllTicketBanner = async () => {
      try {
         setTableSpin(false)
         const res = await getAllTicketBannerApi(type)
         if (res.status === 200) {
            setData(res.data?.data)
         }
         setTableSpin(true)
      } catch (error) {
         setTableSpin(true)
      }
   }

   const handleOrderChanged = async ({ oldIndex, newIndex }) => {
      const newArray = arrayMove(data, oldIndex, newIndex);
      setData(newArray);
      let payload = newArray.map(ele => ({ _id: ele._id }));
      try {
         const res = await updateTicketBannerApi({ carousel: payload });
         if (res.status === 200) {
            toast.success(res.data?.message)
         }
      } catch (error) {
         console.log(error);
         toast.error("Something went wrong")
      }
   };

   const formik = useFormik({
      initialValues: bannerInitialValue,
      validationSchema: bannerValidationSchema,
      onSubmit: async (values) => {
         const payload = {
            ...values,
            type: type,
         }
         setAddSpin(true)
         try {
            const res = await AddTicketBannerApi(payload)
            if (res?.status == 200) {
               toast.success(res?.data?.message)
               document.getElementById(addTicketBannerModalId).click()
               getAllTicketBanner()
            }
         } catch (error) {
            console.log(error);
            toast.error(error?.response?.data?.message || "Failed to add carousel")
         }

         setAddSpin(false)
      }

   })

   console.log(formik);
   useEffect(() => {
      getAllTicketBanner()
   }, [])


   const removeHandler = async () => {
      try {
         const res = await removeTicketBannerByIdApi(selectedItem)
         if (res?.status == 200) {
            toast.success(res?.data?.message)
            document.getElementById(removeModalId).click();
            getAllTicketBanner()
         }
      } catch (error) {
         console.log(error);
         toast.error(error?.response?.data?.message || "Failed to add carousel")
      }
   }

   return (
      <>
         <div className="mb-4" style={{ textAlign: "end" }}>
            <div className='d-flex justify-content-end gap-2' style={{ display: "-webkit-inline-box" }}>
               <div className='d-flex gap-2 align-items-center justify-content-center'>
                  <button data-bs-toggle="modal" data-bs-target={`#${addTicketBannerModalId}`} onClick={() => { formik.resetForm() }} className="btn-custom w-auto" style={{ cursor: "pointer" }}>
                     Add
                  </button>
               </div>
            </div>
         </div>
         <hr />
         <div className="container-fluid">
            <div className="row">
               <div className="card p-3 table-responsive" >
                  <List
                     values={data}
                     onChange={handleOrderChanged}
                     renderList={(ele) => {
                        return (
                           <div className='table-responsive'>
                              <table className="table table-hover table-nowrap card-table">
                                 <thead>
                                    <tr>
                                       <th>
                                          <span
                                             className="list-sort text-muted"
                                          >
                                             S.No
                                          </span>
                                       </th>
                                       <th>
                                          <span
                                             className="list-sort text-muted"
                                          >
                                             Image
                                          </span>
                                       </th>
                                       <th>
                                          <span
                                             className="list-sort text-muted"
                                          >
                                             Desciption
                                          </span>
                                       </th>
                                       <th></th>


                                    </tr>
                                 </thead>

                                 <tbody {...ele?.props} className="list fs-base">
                                    {tableSpin ? data?.length > 0 ? ele?.children : <tr align="center">
                                       <td colSpan="12">
                                          <h1>
                                             Data Not Found
                                          </h1>
                                       </td>
                                    </tr> : <tr align="center">
                                       <td colSpan="12">
                                          <Spinwheel />
                                       </td>
                                    </tr>}
                                 </tbody>
                              </table>
                           </div>
                        )
                     }}

                     renderItem={(ele) => {

                        return (
                           <tr {...ele.props} key={ele?.index} style={{ ...ele.props.style, zIndex: undefined }} >
                              {console.log("ele", ele)}
                              <td data-movable-handle  >{ele.index + 1}</td>
                              <td className='position-relative'>
                                 <div className="avatar avatar-xs align-middle me-2">
                                    <img
                                       className="avatar-img rounded-circle"
                                       src={ele?.value?.image && ele?.value?.image !== "null" ? ImageUrl + encodeURIComponent(ele?.value?.image) : "/images/person-circle.svg"}
                                       alt="..."
                                    />
                                 </div>

                              </td>

                              <td className='position-relative'>
                                 {ele?.value?.description ? (
                                    <div className="tooltip1">
                                       {ele?.value?.description?.substr(0, 30)}...
                                       <div className='wrapper'>
                                          <div className="tooltip1-text">{ele?.value?.description}</div>
                                       </div>
                                    </div>
                                 ) : (
                                    "-"
                                 )}
                              </td>

                              <td className="text-end">

                                 <div className="dropdown">
                                    <span
                                       className="dropdown-ellipses dropdown-toggle"
                                       role="button"
                                       data-bs-toggle="dropdown"
                                       aria-haspopup="true"
                                       aria-expanded="false"
                                    >
                                       <i className="fe fe-more-vertical" />
                                    </span>
                                    <div className="dropdown-menu dropdown-menu-end">
                                       <div data-bs-toggle="modal" data-bs-target={`#${removeModalId}`} className=' dropdown-item' style={{ cursor: "pointer" }} onClick={() => { setSelectedItem(ele?.value?._id) }}>
                                          Remove
                                       </div>
                                    </div>
                                 </div>
                              </td>
                           </tr>
                        )
                     }}
                  />


                  {/* <table className="table table-hover table-nowrap card-table">
                     <thead>
                        <tr>
                           <th>
                              <span
                                 className="list-sort text-muted"
                              >
                                 S.No
                              </span>
                           </th>
                           <th>
                              <span
                                 className="list-sort text-muted"
                              >
                                 Image
                              </span>
                           </th>
                           <th>
                              <span
                                 className="list-sort text-muted"
                              >
                                 Desciption
                              </span>
                           </th>
                           <th></th>


                        </tr>
                     </thead>
                     {
                        tableSpin ?
                           <tbody className="list fs-base">
                              {data?.length > 0 ? data?.map((ele, index) => {
                                 return (<tr key={index} >
                                    <td>{index + 1}</td>
                                    <td className='position-relative'>
                                       <div className="avatar avatar-xs align-middle me-2">
                                          <img
                                             className="avatar-img rounded-circle"
                                             src={ele?.image && ele?.image !== "null" ? ImageUrl + encodeURIComponent(ele?.image) : "/images/person-circle.svg"}
                                             alt="..."
                                          />
                                       </div>

                                    </td>

                                    <td className='position-relative'>
                                       {ele?.description ? (
                                          <div className="tooltip1">
                                             {ele?.description?.substr(0, 30)}...
                                             <div className='wrapper'>
                                                <div className="tooltip1-text">{ele?.description}</div>
                                             </div>
                                          </div>
                                       ) : (
                                          "-"
                                       )}
                                    </td>

                                    <td className="text-end">

                                       <div className="dropdown">
                                          <span
                                             className="dropdown-ellipses dropdown-toggle"
                                             role="button"
                                             data-bs-toggle="dropdown"
                                             aria-haspopup="true"
                                             aria-expanded="false"
                                          >
                                             <i className="fe fe-more-vertical" />
                                          </span>
                                          <div className="dropdown-menu dropdown-menu-end">
                                             <div data-bs-toggle="modal"  data-bs-target={`#${removeModalId}`} className=' dropdown-item' style={{ cursor: "pointer" }} onClick={() => { setSelectedItem(ele?._id) }}>
                                                Remove
                                             </div>
                                          </div>
                                       </div>
                                    </td>
                                 </tr>
                                 )
                              }) : <tr align="center">
                                 <td colSpan="9">
                                    <h1>
                                       Data Not Found
                                    </h1>
                                 </td>
                              </tr>}
                           </tbody> : (
                              <tbody>
                                 <tr align="center">
                                    <td colSpan="9">
                                       <Spinwheel />
                                    </td>
                                 </tr>
                              </tbody>
                           )
                     }
                  </table> */}
               </div>
            </div>
            <AddTicketBanner formik={formik} addSpin={addSpin} modalId={addTicketBannerModalId} />
            <RemoveModal removeHandler={removeHandler} modalId={removeModalId} message={<>Are you sure you want to<br /> remove this?</>} />
         </div>
      </>
   )
}

export default TicketBanner