import React from 'react'
import Spinwheel from '../../../common/Spinwheel'

function SubCategory({ formik, addLoader, modalId }) {

  return (
    <>
      <div className="modal fade" id={modalId} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
        <div className="modal-dialog modal-sm modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0">
              <button type="button" className="btn-close block-close" data-bs-dismiss="modal" aria-label="Close" ></button>
            </div>
            <div className="modal-body block-user">
              <form action="" className="text-start" onSubmit={formik?.handleSubmit} >
                <label htmlFor="name" className="form-label">Name</label>
                <div className="position-relative">
                  <input className="form-control border" id='name' name="name" value={formik?.values?.name} onChange={formik?.handleChange} onBlur={formik?.handleBlur} placeholder="Enter sub category name" />
                  {formik?.touched?.name && formik?.errors?.name ? (<div className='req-error'>{formik?.errors?.name}</div>) : null}
                </div>
                <div className="modal-footer justify-content-center ">
                  <button type="submit" className="btn-custom w-auto mt-2" disabled={addLoader} >
                    {addLoader ? <Spinwheel sizeClass="spin-small" /> : "Submit"}
                  </button>
                  <button type="button" className="btn-custom w-auto mt-2" data-bs-dismiss='modal' >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SubCategory