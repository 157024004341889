import React, { useEffect, useMemo, useState } from "react";

import Sidebar from "../../../Layout/Sidebar";
import { Link, useNavigate } from "react-router-dom";
import { useAdminContext } from "../../../context/adminContext";
import { getEssential, deleteEssentail, addApplinks, editApplinks, getApplinks, deleteApplinks } from "../../../utils/ApiEndpointFunctions";
import toast from "react-hot-toast";
import Spinwheel from "../../common/Spinwheel";
import { ImageUrl } from "../../../utils/BaseUrl";
import { debounce } from "lodash";
import RemoveModal from "../../common/RemoveModal";
import Header from "../../common/Header";
import Pagination from "../../common/Pagination";
import EssentialTable from "./EssentialTable";
import ApplinkTable from "./ApplinkTable";
import AddOrUpdateAppLink from './AddOrUpdateAppLInk'
import { useFormik } from "formik";
import { addApplinksInitialValue, addApplinksValidationSchema } from "../../common/Validation";


export default function Essentialnumber() {
    const { adminDetail, } = useAdminContext()
    const [tableData, setTableData] = useState(null);
    const [removedata, setRemoveData] = useState(null);
    const [addAppLoader, setAddAppLoader] = useState(false)
    const [page, setPage] = useState(0)
    const [tableSpin, setTableSpin] = useState(true);
    const [selectedPage, setSelectPage] = useState(0);
    const [searchText, setSearchText] = useState("");
    const [tab, setTab] = useState("Number")
    const addAndUpdateAppsLinkModalId = "addAndUpdateAppsLinkModalId"
    const limit = 10;

    const getTabledata = async () => {
        try {
            setTableSpin(false)
            const res = tab === "Number" ? await getEssential(limit, selectedPage + 1, searchText) : await getApplinks(limit, selectedPage + 1, searchText);
            if (res?.status === 200) {
                const totaldata = res.data?.totalData
                setPage(Math.ceil(totaldata / limit))
                setTableData(res.data?.data);
                setTableSpin(true)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const onChange = async (e) => {
        try {
            setTableSpin(false)
            const res = tab === "Number" ? await getEssential(limit, 1, e) : await getApplinks(limit, 1, e);
            if (res.status === 200) {
                const totaldata = res.data?.totalData
                setPage(Math.ceil(totaldata / limit))
                setTableData(res.data?.data);
                setSelectPage(0)
                setTableSpin(true)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const debouncedOnChange = useMemo(() => debounce(onChange, 500), [tab])

    const handlePageClick = (event) => {
        setSelectPage(event.selected)
    };

    const removeHandler = async (e) => {
        try {
            e.preventDefault()
            const res = tab === "Number" ? await deleteEssentail(removedata._id) : await deleteApplinks(removedata._id)
            if (res.status === 200) {
                toast.success(res.data?.message)
                getTabledata();
            }
        } catch (error) {
            toast.error("Something went wrong")
        }
    }

    const applinksSumitHandler = async (values) => {
        
        setAddAppLoader(true)
        try {
            const res = values?._id ? await editApplinks(values) : await addApplinks(values);
            if (res.status === 200) {
                
                toast.success(res.data?.message)
                document.getElementById(addAndUpdateAppsLinkModalId).click();
                getTabledata();
            }
        } catch (error) {
            console.log(error)
        }
        setAddAppLoader(false)
    }

    const appslinkFormik = useFormik({
        initialValues: addApplinksInitialValue,
        onSubmit: applinksSumitHandler,
        validationSchema: addApplinksValidationSchema,
    })

    useEffect(() => {
        getTabledata();
    }, [tab, selectedPage])
    

    return (
        <>
            {/*<Sidebar />*/}
            <div className="main-content vh-100 vh_md-auto overflow-y-a">

                <Header title="Essential number" />
                <div className="container-fluid">
                    <div className="row">
                        <nav className="mb-5">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to="/dashboard">
                                        <i className="fe fe-home"></i> Dashboard
                                    </Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Essential number
                                </li>
                            </ol>
                        </nav>
                        <div className="d-flex justify-content-end mb-4 flex-wrap">
                            {/* <div>
                                <h2 className="report">Essential Number List</h2>
                            </div> */}
                            <div style={{ display: "-webkit-inline-box" }}>
                                <div className="dropdown show">
                                    <div className="dropdown me-3"><button className="btn btn-second dropdown-toggle" style={{ padding: "7px 10px" }} type="button" data-bs-toggle="dropdown" aria-expanded="false">Add</button>
                                        <ul className="dropdown-menu" style={{ marginTop: "-10px" }}>
                                            {/* <li><Link className="dropdown-item" to={"/map-add"} state={{ status: "Personal" }} >Personal</Link></li> */}
                                            <li><Link className="dropdown-item" to={"/essentialnumber/add"} >Add essential number</Link></li>
                                            <li><div className="dropdown-item" data-bs-toggle="modal" data-bs-target={`#${addAndUpdateAppsLinkModalId}`} style={{cursor:"pointer"}} onClick={() => { appslinkFormik.resetForm(); }}  >Add app link</div></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="position-relative">
                                    <input
                                        className="form-search me-3"
                                        type="search"
                                        name="search"
                                        value={searchText}
                                        placeholder="Search"
                                        onChange={(e) => { setSearchText(e.target.value); debouncedOnChange(e.target.value)}}
                                    />
                                    <img
                                        className="users-search"
                                        src="/images/Icon feather-search.svg"
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                        <hr />
                    </div>

                    <div className="card p-3 ">
                        <nav>
                            <div className="nav nav-tabs mb-3" id="nav-tab" role="tablist">
                                <button className="nav-link active me-5" id="nav-official-tab" data-bs-toggle="tab" data-bs-target="#nav-official" type="button" role="tab" aria-controls="nav-official" aria-selected="true" onClick={(e) => { setSelectPage(0); setTab("Number"); setSearchText("") }}>Essential number</button>
                                <button className="nav-link me-5" id="nav-tourist-tab" data-bs-toggle="tab" data-bs-target="#nav-tourist" type="button" role="tab" aria-controls="nav-tourist" aria-selected="false" onClick={(e) => { setSelectPage(0); setTab(e, "Apps"); setSearchText("") }}  >App links</button>
                            </div>
                        </nav>
                        <div className="tab-content" id="nav-tabContent">
                            <div className="table-responsive tab-pane fade active show" id="nav-official" role="tabpanel" aria-labelledby="nav-official-tab">
                                <EssentialTable allEssential={tableData} essentialSpin={tableSpin} limit={limit} selectedPage={selectedPage} setRemoveData={setRemoveData} />
                            </div>
                            <div className="tab-pane fade table-responsive" style={{overflowX:"auto"}} id="nav-tourist"  role="tabpanel" aria-labelledby="nav-tourist-tab">
                                <ApplinkTable
                                    tableData={tableData}
                                    formik={appslinkFormik}
                                    limit={limit} selectedPage={selectedPage}
                                    setRemoveData={setRemoveData}
                                    tableSpin={tableSpin}
                                    updateModalId={addAndUpdateAppsLinkModalId}
                                />
                            </div>
                        </div>
                    </div>


                    <div className='d-flex justify-content-center'>
                        {tableData?.length > 0 ?
                            <Pagination
                                clickHandler={handlePageClick}
                                page={page}
                                selectedPage={selectedPage}
                            />
                            : null}
                    </div>
                </div>
            </div>
            <RemoveModal removeHandler={removeHandler} modalId="exampleModal" message={<>Are you sure you want to<br /> remove this?</>} />
            <AddOrUpdateAppLink modalId={addAndUpdateAppsLinkModalId} loader={addAppLoader} formik={appslinkFormik} />
        </>
    );
}