import React, { useState } from 'react'
import Spinwheel from '../common/Spinwheel'
import { uploadImage } from '../../utils/ApiEndpointFunctions';
import { toast } from 'react-hot-toast';

function UpdateTicketStatus({ formik, modalId, addLoader }) {
  let [fileTypeError, setFileTypeError] = useState("")
  const [docSpin, setDocSpin] = useState(false)

  const handlefile = async (e) => {
    try {
      formik.setFieldTouched(`ticketFile`, true);
      const file = e.target.files[0];
      const filetypes = ["application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "text/plain"]


      if (file) {
        if (filetypes.includes(file.type)) {
          setFileTypeError("");
          const formData = new FormData();
          formData.append("file", file);
          formData.append("type", 2)
          formData.append("folder", "document")
          const res = await uploadImage('/users/fileUpload', formData)
          if (res.status === 200 && res.data?.status === 1) {
            formik.setFieldValue("ticketFile", res.data?.data?.fileName)
            toast.success("File uploaded")
          }
        } else {
          formik.setFieldValue("ticketFile", "")
          setFileTypeError("File must be pdf, wordfile and textfile");
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className="modal fade" id={modalId} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-sm modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header border-0">
            <button type="button" className="btn-close block-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body block-user">
            <div className="col-lg-12" style={{ zIndex: "1" }}>
              <form action="" className='p-4' >
                <h2 style={{ textAlign: "left" }}>
                  Ticket Status
                </h2>
                <div>
                  <select name="status" id="modal" className="form-select text-capitalize border border-1" value={formik?.values?.status} onChange={formik?.handleChange}  >
                    <option className='text-capitalize' value="">Choose status</option>
                    <option className='text-capitalize' value="confirmed">confirmed</option>
                    <option className='text-capitalize' value="cancelled">cancelled</option>
                    <option className='text-capitalize' value="refund">refund</option>
                  </select>
                  {formik?.touched?.status && formik?.errors?.status ? (<div className='req-error'>{formik?.errors?.status}</div>) : null}
                </div>
                <div className='mt-2'>
                  <label className="uploadFile form-control border">
                    <span className="filename">
                      {docSpin ? <Spinwheel sizeClass="spin-small" /> : formik.values?.ticketFile?.trim() ? formik.values?.ticketFile : "Attachment"}
                    </span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-paperclip input-icon" viewBox="0 0 16 16">
                      <path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0V3z" />
                    </svg>
                    <input type="file" id="ticketFile" className="inputfile form-control" name="ticketFile" disabled={docSpin} accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/plain" onChange={handlefile} />
                  </label>
                  {fileTypeError?.trim()?.length > 0 ? <div className='req-error'>{fileTypeError}</div> : formik.touched.ticketFile && formik.errors.ticketFile ? (<div className='req-error'>{formik.errors.ticketFile}</div>) : null}
                </div>
                <div className="modal-footer border-0 justify-content-center pt-4">
                  <button type="button" className="btn-custom w-auto" onClick={(e) => { formik?.handleSubmit() }}  >{addLoader ? <Spinwheel sizeClass="spin-small" /> : "Update"}</button>
                  <button type="button" className="btn-custom w-auto" data-bs-dismiss='modal' >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UpdateTicketStatus