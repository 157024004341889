import React from 'react'
import Spinwheel from '../../common/Spinwheel';
import { ImageUrl } from '../../../utils/BaseUrl';

function UserTable({tableData,tableSpin}) {
  return (
    <div className="table-responsive" style={{ overflowX: "auto" }}>
      <table className="table table-sm table-hover table-nowrap card-table">
        <thead>
          <tr>
            <th>
              <span
                className="list-sort text-muted"

              >
                S.No
              </span>
            </th>
            <th>
              <span
                className="list-sort text-muted"
              // data-sort="tables-first"
              // style={{ cursor: "pointer" }}
              >
                User Name
              </span>
            </th>
            <th>
              <span
                className="list-sort text-muted"

              >
                Gender
              </span>
            </th>
            <th>
              <span
                className="list-sort text-muted"

              >
                Home Address
              </span>
            </th>
            <th>
              <span
                className="list-sort text-muted"

              >
                Email ID
              </span>
            </th>
            <th>
              <span
                className="list-sort text-muted"

              >
                Work Address
              </span>
            </th>
            <th >
              <span
                className="list-sort text-muted"

              >
                Phone number
              </span>
            </th>
            <th >
              <span
                className="list-sort text-muted"

              >
                Job type
              </span>
            </th>
            <th colSpan={2}>
              <span
                className="list-sort text-muted"

              >
                Status
              </span>
            </th>
          </tr>
        </thead>
        {
          tableSpin ? <tbody className="list fs-base">
            {
              tableData?.length > 0 ?
                tableData?.map?.((ele, index) => {

                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        {
                          ele?.name ? <>
                            <div className="avatar avatar-xs align-middle me-2">
                              <img
                                className="avatar-img rounded-circle"
                                src={ele?.userDetails?.profile && ele?.userDetails?.profile !== "null" ? ImageUrl + ele?.userDetails?.profile : "/images/person-circle.svg"}
                                alt="..."
                              />
                            </div>
                            <span
                              className="item-name text-reset"
                            >
                              {ele?.name}
                            </span>
                          </> : "-"
                        }
                      </td>
                      <td>
                        <span className="item-title">
                          {ele?.gender ? ele?.gender : "-"}
                        </span>
                      </td>
                      <td>
                        <span className="item-title">
                          {`${ele?.userDetails?.correspondence_Address ? ele?.userDetails?.correspondence_Address + ", " : ""}${ele?.userDetails?.correspondence_country ? ele?.userDetails?.correspondence_country + ", " : ""}${ele?.userDetails?.correspondence_zipcode ? ele?.userDetails?.correspondence_zipcode + ", " : ""}`.length ?
                            `${ele?.userDetails?.correspondence_Address ? ele?.userDetails?.correspondence_Address + ", " : ""}${ele?.userDetails?.correspondence_country ? ele?.userDetails?.correspondence_country + ", " : ""}${ele?.userDetails?.correspondence_zipcode ? ele?.userDetails?.correspondence_zipcode + ", " : ""}` : "-"}
                        </span>
                      </td>
                      <td>
                        <span
                          className="item-email text-reset"
                        >
                          {ele?.email}
                        </span>
                      </td>
                      <td>
                        <span
                          className="item-phone text-reset"
                        >
                          {`${ele?.userDetails?.work_Address ? ele?.userDetails?.work_Address + ", " : ""}${ele?.userDetails?.work_country ? ele?.userDetails?.work_country + ", " : ""}${ele?.userDetails?.work_zipcode ? ele?.userDetails?.work_zipcode + ", " : ""}`.length ?
                            `${ele?.userDetails?.work_Address ? ele?.userDetails?.work_Address + ", " : ""}${ele?.userDetails?.work_country ? ele?.userDetails?.work_country + ", " : ""}${ele?.userDetails?.work_zipcode ? ele?.userDetails?.work_zipcode + ", " : ""}` : "-"}
                        </span>
                      </td>
                      <td>
                        <span
                          className="item-phone text-reset"
                        >
                          {ele?.phoneNumber ? ele?.phoneNumber : '-'}
                        </span>
                      </td>
                      <td>
                        <span
                          className="item-phone text-reset"
                        >
                          {ele?.userDetails?.jobTitle ? ele?.userDetails?.jobTitle : '-'}
                        </span>
                      </td>
                      <td>
                        <span
                          className="item-phone text-reset"
                        >
                          <div className={`badge ${ele?.isActive ? "badge bg-success-soft" : "bg-dark-soft"} `}>
                            {ele?.isActive ? "Active" : "Blocked"}
                          </div>
                        </span>
                      </td>
                     
                    </tr>
                  )
                }) : <tr align="center">
                  <td colSpan="12">
                    <h1>Data Not Found</h1>
                  </td>
                </tr>
            }
          </tbody> : <tbody>
            <tr align="center">
              <td colSpan="12">
                <Spinwheel />
              </td>
            </tr>
          </tbody>
        }
      </table>
    </div>
  )
}

export default UserTable