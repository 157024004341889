import React, { useState } from 'react'
import Spinwheel from '../../../common/Spinwheel'
import { uploadImage } from '../../../../utils/ApiEndpointFunctions';
import { toast } from 'react-hot-toast';
import { ImageUrl } from '../../../../utils/BaseUrl';

function BusOperatorModal({ formik, modalId, addLoader }) {
   const [fileTypeError, setFileTypeError] = useState({ id: null, message: "", error: 0 })

   const handleUpload = async (e, id, field) => {
      try {
        const file = e.target.files[0];
        const types = ['image/jpeg', 'image/png', 'image/gif']
        if (file) {
          if (types.includes(file.type)) {
            setFileTypeError({ id: id, message: "", error: 0 });
            const formData = new FormData();
            formData.append("file", file);
            formData.append("type", 3)
            formData.append("folder", "movie")
            const res = await uploadImage('/users/fileUpload', formData)
            formik.setFieldTouched(field, true)
            if (res.status === 200 && res.data?.status === 1) {
              formik.setFieldValue(field, res.data?.data?.fileName)
              toast.success("File uploaded");
            }
          } else {
            formik.setFieldTouched(field, true)
            formik.setFieldValue(field, "")
            setFileTypeError({ id: id, message: "Must be image only", error: 1 });
          }
        }
  
      } catch (error) {
        formik.setFieldTouched(field, true)
        console.log(error)
        if (error?.response === undefined) {
          toast.error("File size is too large")
        }
      }
    }

  return (
    <div className="modal fade" id={modalId} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-sm modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header border-0">
            <button type="button" className="btn-close block-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <div className="col-lg-12" style={{ zIndex: "1" }}>
              <form action="" className='p-4' >
                <h2 className='text-center'>
                  Bus operator
                </h2>
                <div className='d-flex flex-column gap-2'>
                <div>
                    <label htmlFor="image" className="form-label">Image</label>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="position-relative">
                        {<img src={formik.values.image ? ImageUrl + formik.values.image : process.env.PUBLIC_URL + "/images/image.svg"} width="70px" height="50px" style={{ objectFit: "contain" }} className="rounnded" alt="Selected file" />}
                      </div>
                      <div className="btn_upload">
                        <input type="file" id="image" className="form-control" name="image" accept="image/*" onChange={(e) => handleUpload(e, "image", "image")} />
                        Upload
                      </div>
                    </div>
                    {formik.touched.image && (<div className="req-error">{formik.errors.image}</div> || <div className='req-error'>{fileTypeError?.id == "image" && fileTypeError?.error != 0 && fileTypeError?.message}</div>)}
                    <br />
                  </div>
                  <div className="position-relative">
                    <label htmlFor='name'>Name</label>
                    <input className="form-control border border-1" type="text" id="name" placeholder="Enter name" name="name" value={formik.values?.name} onChange={formik.handleChange} onBlur={() => formik.handleBlur} />
                    {formik.touched?.name ? (<div className='req-error'>{formik.errors?.name}</div>) : null}
                  </div>
                  <div className="position-relative">
                    <label htmlFor='phone'>Phone no.</label>
                    <input className="form-control border border-1" type="text" id="phone" placeholder="Enter phone no." name="phone" value={formik.values?.phone} onChange={formik.handleChange} onBlur={() => formik.handleBlur} />
                    {formik.touched?.phone ? (<div className='req-error'>{formik.errors?.phone}</div>) : null}
                  </div>
                  <div className="position-relative">
                    <label htmlFor='address'>Address</label>
                    <input className="form-control border border-1" type="text" id="address" placeholder="Enter address" name="address" value={formik.values?.address} onChange={formik.handleChange} onBlur={() => formik.handleBlur} />
                    {formik.touched?.address ? (<div className='req-error'>{formik.errors?.address}</div>) : null}
                  </div>
                </div>
                <div className="modal-footer border-0 justify-content-center pt-4">
                  <button type="button" className="btn-custom w-auto" onClick={(e) => { formik?.handleSubmit() }}  >{addLoader ? <Spinwheel sizeClass="spin-small" /> : "Save"}</button>
                  <button type="button" className="btn-custom w-auto" data-bs-dismiss='modal' >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BusOperatorModal