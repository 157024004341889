import { useFormik } from 'formik';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAdminContext } from '../../../context/adminContext';
import { uploadImage, getMapDataDetailsById, updateMapData, getMapData, getAddressWithCordinates } from '../../../utils/ApiEndpointFunctions';
import { ImageUrl } from '../../../utils/BaseUrl';
import Header from '../../common/Header';
import Spinwheel from '../../common/Spinwheel';
import { mapIntialValue, mapValidationSchema } from '../../common/Validation';
import AsyncSelect from 'react-select/async';
import GoogleMapLocation from '../../common/GoogleMapLocation';

function EditMap() {
  const { adminDetail, } = useAdminContext()
  const [mapWheel, setMapWheel] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const [fileTypeError, setFileTypeError] = useState("")

  const formikIntialValue = {
    id: location.state?.data?._id,
    name: location.state?.data?.name,
    address: location.state?.data?.address,
    image: location.state?.data?.image,
    status: location.state?.data?.status,
    isPinned: location.state?.data?.isPinned,
    latitude: location.state?.data?.latitude,
    longitude: location.state?.data?.longitude,
    address2: location.state?.data?.address2,
    description: location.state?.data?.description,
    phoneNumber: location.state?.data?.phoneNumber
  }

  // const getMapDetailById = async (id) => {
  //   const res = await getMapDataDetailsById(id);
  //   if(res.status === 200) {
  //     const data = res.data?.data[0]
  //     mapformik.setValues({
  //       id: data?._id,
  //       name: data?.name,
  //       address: data?.address,
  //       image: data?.image,
  //       status: data?.status,
  //       isPinned:data?.isPinned
  //     })
  //   }
  // }

  // useEffect(() => {
  //   getMapDetailById(id)
  // }, [id])

  const addressDebaunce = useCallback(
    debounce((inputText, callback) => {
      getMapData(10, 1, inputText, location.state?.status).then((options) => {
        const resOptions = options.data?.data?.map(ele => {
          return { label: ele?.address, value: ele }
        })
        callback(resOptions)
      });
    }, 500),
    []
  );

  const handleSubmit = async (values) => {
    try {
      setMapWheel(true)
      const res = await updateMapData(values);
      if (res.status === 200) {
        toast.success(res.data?.message)
        navigate("/map", { replace: true, state: {type: location.state?.type} })
      }
    } catch (error) {
      console.log(error)
      toast.error(error.response?.data?.message)
    }
    setMapWheel(false)
  }


  const mapformik = useFormik({
    initialValues: formikIntialValue || mapIntialValue,
    onSubmit: handleSubmit,
    validationSchema: mapValidationSchema
  });

  const profileChange = async (e) => {
    try {
      const file = e.target.files[0];
      const types = ['image/jpeg', 'image/png', 'image/gif']
      if (file) {
        if (types.includes(file?.type)) {
          setFileTypeError("")
          const formData = new FormData();
          formData.append("file", file);
          formData.append("type", 5)
          formData.append("folder", "map")
          const res = await uploadImage('/users/fileUpload', formData)
          if (res.status === 200 && res.data?.status === 1) {
            mapformik.setFieldValue("image", res.data?.data?.fileName)
            toast.success("File uploaded")
          }
        } else {
          mapformik.setFieldValue("image", "")
          setFileTypeError("Must be image only")
        }
      }
    } catch (error) {
      console.log(error)
    }
  }
  const cordinateHandler = async (ele) => {
    mapformik.setFieldValue("latitude", ele.lat)
    mapformik.setFieldValue("longitude", ele.lng)
    const res = await getAddressWithCordinates(ele.lat, ele.lng)
    if (res.status === 200) {
      mapformik.setFieldValue("address", res.data?.data?.address)
    }
  }


  document.body.classList.add("body-bg");
  return (
    <>
      {/*<Sidebar />*/}
      <div className="main-content vh-100 vh_md-auto overflow-y-a">
        {/* HEADER */}
        <Header title="Map Edit" />
        <div className="container-fluid">
          <div className="row">
            <nav className="mb-5">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">
                    <i className="fe fe-home"></i> Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/map" className="breadcrumb-item">
                    Map
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Add map
                </li>
              </ol>
            </nav>
            <hr />

            <div className="col-8" style={{ zIndex: "1" }}>
              <form action="" className="text-start" onSubmit={mapformik.handleSubmit}>
                <label htmlFor="profile" className="form-label"> Profile Photo</label>

                <div className="d-flex justify-content-between align-items-center">
                  <div className="position-relative">
                    {<img src={mapformik?.values?.image ? ImageUrl + mapformik?.values?.image : process.env.PUBLIC_URL + "/images/person-circle.svg"} width="70px" height="70px" className="rounded-circle" alt="Selected file" />}
                    {
                      <>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-camera pro-camera" viewBox="0 0 16 16"  >
                          <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1v6zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z" />
                          <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x xmark" viewBox="0 0 16 16" onClick={() => { mapformik.setFieldValue("image", "others/person-circle.svg") }} >
                          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                        </svg>
                      </>
                    }
                    {fileTypeError && <div className='req-error'>{fileTypeError}</div>}
                  </div>
                  <div className="btn_upload">
                    <input type="file" id="profile" className="form-control" name="profile" accept="image/*" onChange={profileChange} />
                    Upload
                  </div>
                </div>
                <br />
                <label htmlFor="name" className="form-label">Name</label>
                <div className="position-relative"><input className="form-control" type="text" id="name" name="name" placeholder="Enter your name" value={mapformik.values?.name} onChange={mapformik.handleChange} onBlur={mapformik.handleBlur} /><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-fill input-icon" viewBox="0 0 16 16">
                  <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                </svg>
                  {mapformik?.touched?.name && mapformik?.errors?.name ? (<div className='req-error'>{mapformik?.errors?.name}</div>) : null}
                </div>

                <br />
                <label htmlFor="phoneNumber" className="form-label">Phone number</label>
                <div className="position-relative">
                  <input className="form-control" type="text" id="description" name="phoneNumber" placeholder="Enter phone number" value={mapformik?.values?.phoneNumber} onChange={mapformik.handleChange} onBlur={mapformik.handleBlur} />
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-telephone-fill input-icon" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                  </svg>
                  {mapformik?.touched?.phoneNumber && mapformik?.errors?.phoneNumber ? (<div className='req-error'>{mapformik?.errors?.phoneNumber}</div>) : null}
                </div>
                <br />
                <label htmlFor="address2" className="form-label">Address</label>
                <div className="position-relative">
                  <input className="form-control" type="text" id="description" name="address2" placeholder="Enter address" value={mapformik?.values?.address2} onChange={mapformik.handleChange} onBlur={mapformik.handleBlur} />
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-geo-alt input-icon" viewBox="0 0 16 16">
                    <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                    <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                  </svg>
                  {mapformik?.touched?.address2 && mapformik?.errors?.address2 ? (<div className='req-error'>{mapformik?.errors?.address2}</div>) : null}
                </div>



                <br />
                <label htmlFor="Address" className="form-label"> Location</label>
                <AsyncSelect
                  cacheOptions
                  defaultOptions
                  isSearchable={true}
                  value={mapformik?.values?.address ? { label: mapformik?.values?.address, value: mapformik?.values?.address } : undefined}
                  loadOptions={addressDebaunce}
                  name="address"
                  placeholder="Select location"
                  onChange={(selectedOptions) => {
                    mapformik.setFieldValue("address", selectedOptions?.value?.address)
                    mapformik.setFieldValue("latitude", selectedOptions?.value?.latitude)
                    mapformik.setFieldValue("longitude", selectedOptions?.value?.longitude)
                  }}
                  onBlur={(e) => mapformik.handleBlur(e)}
                />
                <br />
                {mapformik?.values?.latitude && mapformik?.values?.longitude && <div className="w-100">
                  <div style={{ height: "350px", width: '100%' }}>
                    <GoogleMapLocation
                      lat={Number(mapformik?.values?.latitude)}
                      lng={Number(mapformik?.values?.longitude)}
                      onClick={cordinateHandler}
                    />
                  </div>
                </div>}
                <br />

                <label htmlFor="description" className="form-label">Description</label>
                <div className="position-relative">
                  <textarea className="form-control" id='description' placeholder='Enter description' value={mapformik?.values?.description} onChange={mapformik.handleChange} onBlur={mapformik.handleBlur} ></textarea>
                  {mapformik?.touched?.description && mapformik?.errors?.description ? (<div className='req-error'>{mapformik?.errors?.description}</div>) : null}
                </div>
                <br />
                <div className='d-flex justify-content-between '>
                  <label htmlFor="isPinned" className="form-label"> Is Active</label>
                  <div className="form-check form-switch map-togle-container">
                    <input className="form-check-input" type="checkbox" name='isPinned' role="switch" id="isPinned" checked={mapformik?.values?.isPinned} onChange={mapformik?.handleChange} />
                  </div>
                </div>
                <br />
                <div className="text-center d-flex justify-content-around">
                  <button type="submit" className="btn-custom w-auto mt-2" disabled={mapWheel}  > {mapWheel ? <Spinwheel sizeClass="spin-small" /> : "Save"}</button>
                  <div className="btn-custom w-auto mt-2" onClick={() => { window.history.back(); }}  >Cancel</div>
                </div>
              </form>
              <br />
            </div>
            <div className="col-lg-8"></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditMap