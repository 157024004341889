import moment from 'moment/moment'
import React from 'react'
import ReactDatePicker from 'react-datepicker'
import Spinwheel from '../../../common/Spinwheel'
import { useFormik } from 'formik'
import { WorkpermitCiCardIntialValue, WorkpermitCiCardValidation } from '../../../common/Validation'
import Attachment from '../../../common/Attachment'

function WorkpermitCiCard({ formik, addDocLoader, docSpin, handlefile, submitHandler }) {

 
  return (
    <div>
      <div className="row row-cols-2">
      <div>
        <div className='d-flex'>
          <label htmlFor="workPermitNo" className="form-label">Work permit no</label>
        </div>
        <div className="position-relative">
          <input className="form-control border border-1" type="text" id="workPermitNo" placeholder="Enter..." name="workPermitNo" value={formik.values?.workPermitNo} onChange={formik.handleChange} onBlur={formik.handleBlur} />
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-text input-icon" viewBox="0 0 16 16">
            <path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5M5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1z" />
            <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1" />
          </svg>
          {formik.touched.workPermitNo && formik.errors.workPermitNo ? (<div className='req-error'>{formik.errors.workPermitNo}</div>) : null}
        </div>
      </div>

      <div>
        <div className='d-flex'>
          <label htmlFor="name" className="form-label">Name</label>
        </div>
        <div className="position-relative">
          <input className="form-control border border-1" type="text" id="name" placeholder="Enter name" name="name" value={formik.values?.name} onChange={formik.handleChange} onBlur={formik.handleBlur} />
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-text input-icon" viewBox="0 0 16 16">
            <path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5M5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1z" />
            <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1" />
          </svg>
          {formik.touched.name && formik.errors.name ? (<div className='req-error'>{formik.errors.name}</div>) : null}
        </div>
      </div>

      <div>
        <label htmlFor="dob" className="form-label">Date of birth</label>
        <div className="position-relative">
          <ReactDatePicker
            selected={formik.values.dob ? formik.values.dob : ""}
            name='dob'
            id="dob"
            onChange={(e) => {
              e ?
                formik.setFieldValue("dob", e) :
                formik.setFieldValue("dob", "")
            }}
            onBlur={formik.handleBlur}
            className='form-control w-100 border border-1'
            placeholderText='Pickup date'
            autoComplete="off"
            dateFormat={"dd/MM/yyyy"}
            maxDate={new Date()}
          />
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar input-icon" viewBox="0 0 16 16">
            <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z" />
          </svg>

          {formik.touched.dob && formik.errors.dob ? (<div className='req-error'>{formik.errors.dob}</div>) : null}
        </div>
      </div>

      <div>
        <div className='d-flex justify-content-start'>  <label htmlFor="companyAddress" className="form-label"> Company address</label></div>
          <textarea id="companyAddress" name='companyAddress' class="form-control border" placeholder="Enter.." value={formik?.values?.companyAddress} onBlur={formik.handleBlur} onChange={formik?.handleChange} ></textarea>
          {formik.touched.companyAddress && formik.errors.companyAddress ? (<div className='req-error'>{formik.errors.companyAddress}</div>) : null}
      </div>

      <div>
        <label htmlFor="dateOfIssue" className="form-label">Date of issue</label>
        <div className="position-relative">
          <ReactDatePicker
              selected={formik.values.dateOfIssue ? formik.values.dateOfIssue : ""}
            name='dateOfIssue'
            id="dateOfIssue"
            onChange={(e) => {
              e ?
                formik.setFieldValue("dateOfIssue", e) :
                formik.setFieldValue("dateOfIssue", "")
            }}
            onBlur={formik.handleBlur}
            className='form-control w-100 border border-1'
            placeholderText='Pickup date'
          
            autoComplete="off"
            dateFormat={"dd/MM/yyyy"}
          />
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar input-icon" viewBox="0 0 16 16">
            <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z" />
          </svg>

          {formik.touched.dateOfIssue && formik.errors.dateOfIssue ? (<div className='req-error'>{formik.errors.dateOfIssue}</div>) : null}
        </div>
      </div>

      <div>
        <label htmlFor="dateOfExpiry" className="form-label">Date of expiry</label>
        <div className="position-relative">
          <ReactDatePicker
              selected={formik.values.dateOfExpiry ? formik.values.dateOfExpiry : ""}
            name='dateOfExpiry'
            id="dateOfExpiry"
            onChange={(e) => {
              e ?
                formik.setFieldValue("dateOfExpiry", e) :
                formik.setFieldValue("dateOfExpiry", "")
            }}
            onBlur={formik.handleBlur}
            className='form-control w-100 border border-1'
            placeholderText='Pickup date'
           
            autoComplete="off"
            dateFormat={"dd/MM/yyyy"}
          />
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar input-icon" viewBox="0 0 16 16">
            <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z" />
          </svg>

          {formik.touched.dateOfExpiry && formik.errors.dateOfExpiry ? (<div className='req-error'>{formik.errors.dateOfExpiry}</div>) : null}
        </div>
      </div>


      <div>
        <div className='d-flex'>
          <label htmlFor="typeOfWork" className="form-label">Type of work</label>
        </div>
        <div className="position-relative">
          <input className="form-control border border-1" type="text" id="typeOfWork" placeholder="Enter..." name="typeOfWork" value={formik.values?.typeOfWork} onChange={formik.handleChange} onBlur={formik.handleBlur} />
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-text input-icon" viewBox="0 0 16 16">
            <path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5M5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1z" />
            <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1" />
          </svg>
          {formik.touched.typeOfWork && formik.errors.typeOfWork ? (<div className='req-error'>{formik.errors.typeOfWork}</div>) : null}
        </div>
      </div>



      <div>
        <label htmlFor="allowWorkUntil" className="form-label">Allowed to work until</label>
        <div className="position-relative">
          <ReactDatePicker
              selected={formik.values.allowWorkUntil ? formik.values.allowWorkUntil : ""}
            name='allowWorkUntil'
            id="allowWorkUntil"
            onChange={(e) => {
              e ?
                formik.setFieldValue("allowWorkUntil", e) :
                formik.setFieldValue("allowWorkUntil", "")
            }}
            onBlur={formik.handleBlur}
            className='form-control w-100 border border-1'
            placeholderText='Pickup date'
           
            autoComplete="off"
            dateFormat={"dd/MM/yyyy"}
          />
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar input-icon" viewBox="0 0 16 16">
            <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z" />
          </svg>

          {formik.touched.allowWorkUntil && formik.errors.allowWorkUntil ? (<div className='req-error'>{formik.errors.allowWorkUntil}</div>) : null}
        </div>
      </div>
    </div>

      <Attachment formik={formik} docSpin={docSpin} handlefile={handlefile} />

      <div className="modal-footer border-0 justify-content-center pt-4">
        <button type="button" className="btn-custom w-auto" disabled={addDocLoader} onClick={(e) => { e.preventDefault(); formik.handleSubmit(); }}  >{addDocLoader ? <Spinwheel sizeClass="spin-small" /> : "Submit"}</button>
        <button type="button" className="btn-custom w-auto" data-bs-dismiss='modal' >
          Cancel
        </button>
      </div>
    </div>
    
  )
}


export default WorkpermitCiCard