import { useFormik } from 'formik';
import moment from 'moment';
import React from 'react'
import ReactDatePicker from 'react-datepicker';
import { VisaIntialValue, VisaValidation } from '../../../common/Validation';
import Spinwheel from '../../../common/Spinwheel';
import Attachment from '../../../common/Attachment';


function VisaForm({ formik, addDocLoader, docSpin, handlefile, submitHandler }) {


  return (

    <div>

    <div className="row row-cols-2">
      <div>

        <div className='d-flex'>
          <label htmlFor="visaNumber" className="form-label">Visa number</label>
        </div>
        <div className="position-relative">
          <input className="form-control border border-1" type="text" id="visaNumber" placeholder="Enter" name="visaNumber" value={formik.values?.visaNumber} onChange={formik.handleChange} onBlur={formik.handleBlur} />
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-text input-icon" viewBox="0 0 16 16">
            <path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5M5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1z" />
            <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1" />
          </svg>
          {formik.touched.visaNumber && formik.errors.visaNumber ? (<div className='req-error'>{formik.errors.visaNumber}</div>) : null}
        </div>
      </div>

      <div>
        <div className='d-flex'>
          <label htmlFor="name" className="form-label">Passport number</label>
        </div>
        <div className="position-relative">
          <input className="form-control border border-1" type="text" id="passportNumber" placeholder="Enter" name="passportNumber" value={formik.values?.passportNumber} onChange={formik.handleChange} onBlur={formik.handleBlur} />
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-text input-icon" viewBox="0 0 16 16">
            <path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5M5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1z" />
            <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1" />
          </svg>
          {formik.touched.passportNumber && formik.errors.passportNumber ? (<div className='req-error'>{formik.errors.passportNumber}</div>) : null}
        </div>
      </div>

      <div>
        <label htmlFor="validUntil" className="form-label">Valid date</label>
        <div className="position-relative">
          <ReactDatePicker
              selected={formik.values.validUntil ? new Date(formik.values.validUntil) : ""}
            name='validUntil'
            id="validUntil"
            onChange={(e) => {
              e ?
                formik.setFieldValue("validUntil", e) :
                formik.setFieldValue("validUntil", "")
            }}
            onBlur={formik.handleBlur}
            className='form-control w-100 border border-1'
            placeholderText='Pickup date'
            autoComplete="off"
            dateFormat={"dd/MM/yyyy"}
          />
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar input-icon" viewBox="0 0 16 16">
            <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z" />
          </svg>

          {formik.touched.validUntil && formik.errors.validUntil ? (<div className='req-error'>{formik.errors.validUntil}</div>) : null}
        </div>
      </div>

      <div>
        <div className='d-flex'>
          <label htmlFor="typeOfVisa" className="form-label">type</label>
        </div>
        <div className="position-relative">
          <input className="form-control border border-1" type="text" id="typeOfVisa" placeholder="Enter" name="typeOfVisa" value={formik.values?.typeOfVisa} onChange={formik.handleChange} onBlur={formik.handleBlur} />
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-text input-icon" viewBox="0 0 16 16">
            <path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5M5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1z" />
            <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1" />
          </svg>
          {formik.touched.typeOfVisa && formik.errors.typeOfVisa ? (<div className='req-error'>{formik.errors.typeOfVisa}</div>) : null}
        </div>
      </div>

     
    </div>

      <Attachment formik={formik} docSpin={docSpin} handlefile={handlefile} />

      <div className="modal-footer border-0 justify-content-center pt-4">
        <button type="button" className="btn-custom w-auto" disabled={addDocLoader} onClick={(e) => { e.preventDefault(); formik.handleSubmit(); }}  >{addDocLoader ? <Spinwheel sizeClass="spin-small" /> : "Submit"}</button>
        <button type="button" className="btn-custom w-auto" data-bs-dismiss='modal' >
          Cancel
        </button>
      </div>
    </div>
  )
}

export default VisaForm